import { Input, Select } from "antd";
import React from "react";

const SchoolInformation = () => {
  return (
    <>
      <p className="text-2xl mt-1 font-body text-text_primary font-bold">
        School info
      </p>
      <hr className="my-3 text-default" />
      <div>
        <div className="mt-[15px] flex items-center ">
          <p className="basis-1/2">Country </p>
          <div className="w-full">
            <Select
              defaultValue="Ghana"
              allowClear
              className=" w-full h-full  rounded-[20px] "
              options={[{ value: "Filter discount", label: "Filter discount" }]}
            />
          </div>
        </div>
        <div className="mt-[15px] flex items-center ">
          <p className="basis-1/2">Name </p>

          <div className="w-full">
            <Input
              className="h-[40px] text-text_deep border-1 border-outline_deep placeholder-outline_light"
              // value={prodName}
              defaultValue="RoboCentre Main"
              // onChange={(e) => setProdName(e.target.value)}
              placeholder="Product name"
            />
          </div>
        </div>
        <div className="mt-[15px] flex items-center ">
          <p className="basis-1/2">Language of instruction </p>
          <div className="w-full">
            <Select
              defaultValue="English"
              allowClear
              className=" w-full h-full  rounded-[20px] "
              options={[{ value: "Filter discount", label: "Filter discount" }]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolInformation;
