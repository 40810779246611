import moment from "moment";
import { SlSocialFacebook, SlSocialInstagram } from "react-icons/sl";
import { PiTiktokLogoLight, PiYoutubeLogoThin } from "react-icons/pi";
import { CiLinkedin, CiTwitter } from "react-icons/ci";

const RequestMoreDetails = ({ schooData }: { schooData?: any }) => {
  return (
    <div>
      <div className="p-4 text-text_deep drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[25px] my-4 bg-white">
        <p className="text-[20px] text-text_deep mb-2 font-medium ">
          School Information
        </p>
        <hr />
        <div className="grid grid-cols-4 mt-6">
          <section>
            <p className="text-[12px]">School Name</p>
            <p className="mt-1 text-[16px]">
              {schooData?.schoolInformation?.schoolName}
            </p>
          </section>
          <section>
            <p className="text-[12px]">School Address</p>
            <p className="mt-1 text-[16px]">
              <span> {schooData?.address?.city},</span>
              <span> {schooData?.address?.placeName},</span>
              <span> {schooData?.address?.region}</span>
            </p>
          </section>
          <section>
            <p className="text-[12px]">Program Type</p>
            <p className="mt-1 text-[16px]">
              {schooData?.schoolInformation?.programType}
            </p>
          </section>
          <section>
            <p className="text-[12px]">Created Date</p>
            <p className="mt-1 text-[16px]">
              {moment(schooData?.createdAt).format("Do MMM YYYY - hh:mm A")}
            </p>
          </section>
          <section>
            <p className="text-[12px]">Updated Date</p>
            <p className="mt-1 text-[16px]">
              {moment(schooData?.updatedAt).format("Do MMM YYYY - hh:mm A")}
            </p>
          </section>
        </div>
        <div className="mt-10">
          <span className="mb-2">Remarks</span>
          <p>{schooData?.remarks}</p>
        </div>
        <div className="mt-10">
          <p className="mb-2">social Media</p>
          <div className="flex flex-wrap gap-4 mt-2">
            <section>
              <div className="flex items-center">
                <SlSocialFacebook
                  className="text-text_primary"
                  size={"1.5em"}
                />
                <a
                  href={
                    schooData?.socials?.facebook?.startsWith("http")
                      ? schooData?.socials?.facebook
                      : `https://${schooData?.socials?.facebook}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="overflow-hidden whitespace-nowrap italic ml-2 text-ellipsis"
                >
                  {schooData?.socials?.facebook}
                </a>
              </div>
            </section>
            <section>
              <div className="flex items-center">
                <PiYoutubeLogoThin
                  className="text-text_primary"
                  size={"1.5em"}
                />
                <a
                  href={
                    schooData?.socials?.youtube?.startsWith("http")
                      ? schooData?.socials?.youtube
                      : `https://${schooData?.socials?.youtube}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="overflow-hidden whitespace-nowrap ml-2 italic text-ellipsis"
                >
                  {schooData?.socials?.youtube}
                </a>
              </div>
            </section>
            <section>
              <div className="flex items-center">
                <CiTwitter className="text-text_primary" size={"1.5em"} />
                <a
                  href={
                    schooData?.socials?.x?.startsWith("http")
                      ? schooData?.socials?.x
                      : `https://${schooData?.socials?.x}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="overflow-hidden whitespace-nowrap ml-2 italic text-ellipsis"
                >
                  {schooData?.socials?.x}
                </a>
              </div>
            </section>
            <section>
              <div className="flex items-center">
                <SlSocialInstagram
                  className="text-text_primary"
                  size={"1.5em"}
                />
                <a
                  href={
                    schooData?.socials?.instagram?.startsWith("http")
                      ? schooData?.socials?.instagram
                      : `https://${schooData?.socials?.instagram}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="overflow-hidden whitespace-nowrap ml-2 italic text-ellipsis"
                >
                  {schooData?.socials?.instagram}
                </a>
              </div>
            </section>
            <section>
              <div className="flex items-center">
                <PiTiktokLogoLight
                  className="text-text_primary"
                  size={"1.5em"}
                />
                <a
                  href={
                    schooData?.socials?.tiktok?.startsWith("http")
                      ? schooData?.socials?.tiktok
                      : `https://${schooData?.socials?.tiktok}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="overflow-hidden whitespace-nowrap ml-2 italic text-ellipsis"
                >
                  {schooData?.socials?.tiktok}
                </a>
              </div>
            </section>
            <section>
              <div className="flex items-center">
                <CiLinkedin className="text-text_primary" size={"1.5em"} />
                <a
                  href={
                    schooData?.socials?.linkedIn?.startsWith("http")
                      ? schooData?.socials?.linkedIn
                      : `https://${schooData?.socials?.linkedIn}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="overflow-hidden whitespace-nowrap ml-2 italic text-ellipsis"
                >
                  {schooData?.socials?.linkedIn}
                </a>
              </div>
            </section>
          </div>
        </div>
      </div>{" "}
      <div className="p-4 text-text_deep drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[25px] my-4 bg-white">
        <p className="text-[20px] text-text_deep mb-2 font-medium ">
          Principal Information
        </p>
        <hr />
        <div className="grid grid-cols-4 mt-6">
          <section>
            <p className="text-[12px]">First Name</p>
            <p className="mt-1 text-[16px]">
              <span> {schooData?.principal?.firstName}</span>
            </p>
          </section>
          <section>
            <p className="text-[12px]">Middle Name</p>
            <p className="mt-1 text-[16px]">
              <span> {schooData?.principal?.middleName}</span>
            </p>
          </section>
          <section>
            <p className="text-[12px]">Last Name</p>
            <p className="mt-1 text-[16px]">
              <span> {schooData?.principal?.lastName}</span>
            </p>
          </section>

          <section>
            <p className="text-[12px]">Phone</p>
            <p className="mt-1 text-[16px]">
              <span> {schooData?.principal?.phone}</span>
            </p>
          </section>
        </div>
      </div>
      <div className="p-4 text-text_deep drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[25px] my-4 bg-white">
        <p className="text-[20px] text-text_deep mb-2 font-medium ">
          Academic Calendar Information
        </p>
        <hr />
        <div className="grid grid-cols-4 mt-6 gap-5">
          <section>
            <p className="text-[12px]">Current Academic Term name</p>
            <p className="mt-1 text-[16px]">
              <span> {schooData?.currentAcademicTermData?.name}</span>
            </p>
          </section>
          <section>
            <p className="text-[12px]">Current Academic Starts</p>
            <p className="mt-1 text-[16px]">
              <span>
                {" "}
                {moment(schooData?.currentAcademicTermData?.startsOn).format(
                  "Do MMM YYYY - hh:mm A"
                )}
              </span>
            </p>
          </section>
          <section>
            <p className="text-[12px]">Current Academic Ends</p>
            <p className="mt-1 text-[16px]">
              <span>
                {" "}
                {moment(schooData?.currentAcademicTermData?.endsOn).format(
                  "Do MMM YYYY - hh:mm A"
                )}
              </span>
            </p>
          </section>
          <section>
            <p className="text-[12px]">Current Academic Year</p>
            <p className="mt-1 text-[16px]">
              <span> {schooData?.currentAcademicTermData?.year}</span>
            </p>
          </section>

          <section>
            <p className="text-[12px]">Created Date</p>
            <p className="mt-1 text-[16px]">
              {moment(schooData?.currentAcademicTermData?.createdAt).format(
                "Do MMM YYYY - hh:mm A"
              )}
            </p>
          </section>
          <section>
            <p className="text-[12px]">Updated Date</p>
            <p className="mt-1 text-[16px]">
              {moment(schooData?.currentAcademicTermData?.updatedAt).format(
                "Do MMM YYYY - hh:mm A"
              )}
            </p>
          </section>
        </div>
      </div>
      <div className="p-4 text-text_deep drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[25px] my-4 bg-white">
        <p className="text-[20px] text-text_deep mb-2 font-medium ">
          Curriculum Information
        </p>
        <hr />
        {schooData?.curriculumData?.map((curriculum: any) => {
          return (
            <div className="grid grid-cols-4 mt-6">
              {" "}
              <section>
                <p className="text-[12px]">Name</p>
                <p className="mt-1 text-[16px]">
                  <span> {curriculum?.name}</span>
                </p>
              </section>
              <section>
                <p className="text-[12px]">Created Date</p>
                <p className="mt-1 text-[16px]">
                  {moment(curriculum?.createdAt).format(
                    "Do MMM YYYY - hh:mm A"
                  )}{" "}
                </p>
              </section>
              <section>
                <p className="text-[12px]">Updated Date</p>
                <p className="mt-1 text-[16px]">
                  {moment(curriculum?.updatedAt).format(
                    "Do MMM YYYY - hh:mm A"
                  )}{" "}
                </p>
              </section>
            </div>
          );
        })}
      </div>
      <div className="p-4 text-text_deep drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[25px] my-4 bg-white">
        <p className="text-[20px] text-text_deep mb-2 font-medium ">
          Curriculum Level Information
        </p>
        <hr />
        {schooData?.curriculumLevelData?.map((curriculumlevel: any) => {
          return (
            <div className="grid grid-cols-3 gap-4 mt-6">
              <section>
                <p className="text-[12px]">Name</p>
                <div className="">
                  <span>{curriculumlevel?.name}</span>
                </div>
              </section>
              <section>
                <p className="text-[12px]">Age Group</p>
                <div className="">
                  <span>{`${curriculumlevel.ageGroup[0]} - ${
                    curriculumlevel.ageGroup[
                      curriculumlevel.ageGroup.length - 1
                    ]
                  }`}</span>
                </div>
              </section>
              <section>
                <p className="text-[12px]">Updated Date</p>
                <p className="mt-1 text-[16px]">
                  {moment(curriculumlevel?.updatedAt).format(
                    "Do MMM YYYY - hh:mm A"
                  )}{" "}
                </p>
              </section>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RequestMoreDetails;
