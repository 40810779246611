import SearchInput from "../../../../../../components/shared/SearchInput";
import GLevelsComponent from "../../../../../Gradelevel/GLevelsComponent";
import { useNavigate } from "react-router-dom";

const gradList = [
  {
    grade: "Grade 1",
    units: 53,
    students: 4,
    courses: 7,
  },
  {
    grade: "Grade 2",
    units: 10,
    students: 9,
    courses: 5,
  },
  {
    grade: "Grade 3",
    units: 30,
    students: 2,
    courses: 3,
  },
  {
    grade: "Grade 1",
    units: 53,
    students: 4,
    courses: 7,
  },
  {
    grade: "Grade 2",
    units: 10,
    students: 9,
    courses: 5,
  },
  {
    grade: "Grade 3",
    units: 30,
    students: 2,
    courses: 3,
  },
];

const GLevels = ({ roboUrlId }: { roboUrlId: string }) => {
  const navigate = useNavigate();

  const onClickHandler = (event: any, gradeId: string) => {
    event.preventDefault();

    navigate(`/manageRobocenter/${roboUrlId}/${gradeId}`);
    console.log(event, gradeId);
  };
  return (
    <div className="bg-white p-4 mt-10 drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] min-h-[500px] rounded-[20px]">
      <div className="flex justify-between">
        <div>
          <SearchInput />
        </div>
      </div>
      <div className="flex flex-col mt-3">
        {gradList.map((gradeObj, index) => (
          <GLevelsComponent
            key={index}
            grades={gradeObj.grade}
            courses={gradeObj.courses}
            units={gradeObj.units}
            studentsquantity={gradeObj.students}
          />
        ))}
      </div>
    </div>
  );
};

export default GLevels;
