import Calendar from "@toast-ui/react-calendar";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
const MyCalender = () => {
  const calendars = [{ id: "cal1", name: "Personal" }];
  const initialEvents = [
    {
      id: "1",
      calendarId: "cal1",
      title: "Lunch",
      category: "time",
      start: "2024-06-16T12:00:00",
      end: "2024-06-16T13:30:00",
    },
    {
      id: "2",
      calendarId: "cal1",
      title: "Coffee Break",
      category: "time",
      start: "2024-06-16T15:00:00",
      end: "2024-06-16T15:30:00",
    },
  ];

  const onAfterRenderEvent = (event: any) => {
    console.log(event.title);
  };
  return (
    <div className="mt-6">
      <Calendar
        height="500px"
        view="month"
        month={{
          dayNames: ["S", "M", "T", "W", "T", "F", "S"],
          visibleWeeksCount: 3,
        }}
        calendars={calendars}
        events={initialEvents}
        onAfterRenderEvent={onAfterRenderEvent}
      />{" "}
    </div>
  );
};

export default MyCalender;
