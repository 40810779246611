import React, { useState } from "react";
import { Link } from "react-router-dom";
import { StarIcon, XMarkIcon } from "@heroicons/react/24/outline";
import customer_img from "../../images/customer_img.png";
import cus_cart from "../../images/cus_cart.png";
import { demo_transactions } from "../../TestData/CusTransactions";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";

const ViewCustomerDetails = () => {
  const [tabActive, setTabActive] = useState(0);

  //Tab names on modal
  const tabNames = [
    {
      name: "Account",
    },
    {
      name: "Transactions",
    },
    {
      name: "Cart",
    },
    {
      name: "Wishlist",
    },
  ];

  const showActive = (i: any) => {
    if (i === tabActive) {
      return "rounded-full pl-5 pr-5 pt-1 pb-1 shadow-3xl bg-default_blue text-text_white";
    } else {
      return "bg-text_white rounded-full pl-5 pr-5 pt-1 pb-1 shadow-3xl hover:bg-default_blue hover:text-text_white";
    }
  };

  //Table columns for each customer
  const customer_columns = [
    {
      name: "ORDER NO.",
      selector: (row: any) => row.order_number,
    },
    {
      name: "DATE",
      selector: (row: any) => row.date,
    },
    {
      name: "AMOUNT",
      selector: (row: any) => row.amount,
    },
    {
      name: "LOCATION",
      selector: (row: any) => row.location,
    },
    {
      name: "STATUS",
      selector: (row: any) =>
        row.isCompleted ? (
          <p className="text-sm bg-default_green rounded-full text-text_white px-2">
            Completed
          </p>
        ) : (
          <></>
        ),
    },
    {
      name: "TRACKING ID",
      selector: (row: any) => row.tracking_id,
    },
  ];

  return (
    <>
      <div
        className="modal fade modal-xl"
        id="customerdetails"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-super_light">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Customer Details
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <XMarkIcon className="w-5 h-5" />
              </button>
            </div>
            <div className="modal-body bg-super_light">
              <Row>
                <Col md={8}>
                  <Tabs>
                    <TabList className="bottom-0 mb-3 flex gap-4">
                      {tabNames.map((item, index) => (
                        <Tab key={index} className="border-0 bg-transparent">
                          <button
                            key={index}
                            onClick={setTabActive.bind(this, index)}
                            className={showActive(index)}
                          >
                            {item.name}
                          </button>
                        </Tab>
                      ))}
                    </TabList>

                    <hr
                      className="bg-outline_light"
                      style={{ height: "2px" }}
                    />

                    {/* Details */}
                    <TabPanel className="mb-4">
                      <Card className="rounded-2xl border-none shadow-3xl mt-4">
                        <Card.Body className="text-text_primary">
                          <Container>
                            <p className="text-lg pb-2">Personal Details</p>
                            <hr
                              className="bg-outline_light"
                              style={{ height: "2px" }}
                            />

                            <Row className="pt-2">
                              <Col md={4}>
                                <p className="text-sm opacity-80">First Name</p>
                                <p className="text-lg">Juicy</p>

                                <p className="text-sm opacity-80 mt-3">
                                  Last Name
                                </p>
                                <p className="text-lg">Beetle &#128525;</p>
                              </Col>
                              <Col md={4}>
                                <p className="text-sm opacity-80">
                                  Middle Name
                                </p>
                                <p className="text-lg">N/A</p>

                                <p className="text-sm opacity-80 mt-3">
                                  Date Created
                                </p>
                                <p className="text-lg">2022-10-27</p>
                              </Col>
                              <Col md={4}>
                                <p className="text-sm opacity-80">ID</p>
                                <p className="text-lg">GX866hdjFFFG</p>

                                <p className="text-sm opacity-80 mt-3">
                                  Something
                                </p>
                                <p className="text-lg">N/A</p>
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </Card>

                      <Card className="rounded-2xl border-none shadow-3xl mt-4">
                        <Card.Body className="text-text_primary">
                          <Container>
                            <p className="text-lg pb-2">Contact Details</p>
                            <hr
                              className="bg-outline_light"
                              style={{ height: "2px" }}
                            />

                            <Row className="pt-2">
                              <Col md={4}>
                                <p className="text-sm opacity-80">Phone</p>
                                <p className="text-lg">+233205153472</p>

                                <p className="text-sm opacity-80 mt-3">Email</p>
                                <p className="text-lg">example@mingo.com</p>
                              </Col>
                              <Col md={4}>
                                <p className="text-sm opacity-80">Country</p>
                                <p className="text-lg">Ghana</p>

                                <p className="text-sm opacity-80 mt-3">
                                  Region
                                </p>
                                <p className="text-lg">Greater Accra</p>
                              </Col>
                              <Col md={4}>
                                <p className="text-sm opacity-80">City</p>
                                <p className="text-lg">Accra</p>
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </Card>

                      <Card className="rounded-2xl border-none shadow-3xl mt-4">
                        <Card.Body className="text-text_primary">
                          <Container>
                            <p className="text-lg pb-2">Delivery Details</p>
                            <hr
                              className="bg-outline_light"
                              style={{ height: "2px" }}
                            />

                            <Row className="mt-3 shadow-md rounded-xl">
                              <p className="text-lg pt-2">Address Line 1</p>
                              <Col md={6} className="mt-2 mb-2">
                                <p className="text-sm opacity-80">Country</p>
                                <p className="text-lg">Ghana</p>

                                <p className="text-sm opacity-80 mt-3">
                                  Residential Address
                                </p>
                                <p className="text-lg">
                                  Apartment 5Y, Juice Lane
                                </p>
                              </Col>
                              <Col md={6} className="mt-2 mb-2">
                                <p className="text-sm opacity-80">City</p>
                                <p className="text-lg">Accra</p>

                                <p className="text-sm opacity-80 mt-3">
                                  Specific Directions
                                </p>
                                <p className="text-lg">
                                  Ablekuma, Penetcost Junction. Please use the
                                  street named Juice Lane and stop at All Stars
                                  Academy then call my number.
                                </p>
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </Card>
                    </TabPanel>

                    {/* Transactions */}
                    <TabPanel>
                      <p className="text-md">Transactions</p>

                      <Card className="rounded-2xl border-none shadow-3xl mt-2">
                        <Card.Body>
                          <Row>
                            <Col className="flex">
                              <div className="relative">
                                <input
                                  type="text"
                                  className="h-8 w-80 pr-8 pl-5 rounded-full shadow-none border-solid border-outline_light placeholder-outline_deep"
                                  placeholder="Search..."
                                />

                                <div className="absolute top-1.5 right-5">
                                  <i className="fa fa-search text-outline_deep z-20"></i>
                                </div>
                              </div>

                              <div className="absolute right-8 mt-2">
                                <Link
                                  to="#"
                                  className="bg-default_blue text-text_white rounded-full text-sm hover:text-text_white"
                                  style={{ padding: "5px 18px" }}
                                >
                                  Filter Table
                                </Link>
                              </div>
                            </Col>
                          </Row>

                          <DataTable
                            className="mt-4"
                            columns={customer_columns}
                            data={demo_transactions}
                            pagination
                          />
                        </Card.Body>
                      </Card>
                    </TabPanel>

                    {/* Cart */}
                    <TabPanel>
                      <p className="text-md">Cart</p>

                      <Row>
                        <Col className="mt-2" md={12}>
                          <Card className="rounded-2xl border-none shadow-3xl">
                            <Card.Body>
                              <Row>
                                <Col sm={4}>
                                  <img
                                    className="img-fluid"
                                    src={cus_cart}
                                    alt="..."
                                  />
                                </Col>

                                <Col sm={6}>
                                  <p className="text-lg">
                                    SMD LED - RGB APA102C -505 (Strip of 5000)
                                  </p>

                                  <p className="text-md pt-2">MBXS-3678</p>

                                  <div className="flex gap-4 mt-3">
                                    <p className="text-md text-default_blue">
                                      GH¢ 350.00
                                    </p>
                                    <p className="text-md text-text_danger bg-light_red rounded-md px-2">
                                      20% Off
                                    </p>
                                    <div className="flex gap-2">
                                      <StarIcon className="w-5 h-5 fill-default_yellow text-default_yellow" />
                                      <p className="text-md">3.5</p>
                                    </div>
                                  </div>
                                </Col>

                                <Col sm={2}>
                                  <p className="text-center bg-outline_deep text-text_white rounded-full">
                                    In Cart
                                  </p>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>

                        <Col className="mt-2" md={12}>
                          <Card className="rounded-2xl border-none shadow-3xl">
                            <Card.Body>
                              <Row>
                                <Col sm={4}>
                                  <img
                                    className="img-fluid"
                                    src={cus_cart}
                                    alt="..."
                                  />
                                </Col>

                                <Col sm={6}>
                                  <p className="text-lg">
                                    SMD LED - RGB APA102C -505 (Strip of 5000)
                                  </p>

                                  <p className="text-md pt-2">MBXS-3678</p>

                                  <div className="flex gap-4 mt-3">
                                    <p className="text-md text-default_blue">
                                      GH¢ 350.00
                                    </p>
                                    <p className="text-md text-text_danger bg-light_red rounded-md px-2">
                                      20% Off
                                    </p>
                                    <div className="flex gap-2">
                                      <StarIcon className="w-5 h-5 fill-default_yellow text-default_yellow" />
                                      <p className="text-md">3.5</p>
                                    </div>
                                  </div>
                                </Col>

                                <Col sm={2}>
                                  <p className="text-center bg-default_green text-text_white rounded-full">
                                    Ordered
                                  </p>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </TabPanel>

                    {/* Wishlist */}
                    <TabPanel>
                      <p className="text-md">Wishlist</p>

                      <Row>
                        <Col className="mt-2" md={12}>
                          <Card className="rounded-2xl border-none shadow-3xl">
                            <Card.Body>
                              <Row>
                                <Col sm={4}>
                                  <img
                                    className="img-fluid"
                                    src={cus_cart}
                                    alt="..."
                                  />
                                </Col>

                                <Col sm={8}>
                                  <p className="text-lg">
                                    SMD LED - RGB APA102C -505 (Strip of 5000)
                                  </p>

                                  <p className="text-md pt-2">MBXS-3678</p>

                                  <div className="flex gap-4 mt-3">
                                    <p className="text-md text-default_blue">
                                      GH¢ 350.00
                                    </p>
                                    <p className="text-md text-text_danger bg-light_red rounded-md px-2">
                                      20% Off
                                    </p>
                                    <div className="flex gap-2">
                                      <StarIcon className="w-5 h-5 fill-default_yellow text-default_yellow" />
                                      <p className="text-md">3.5</p>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>

                        <Col className="mt-2" md={12}>
                          <Card className="rounded-2xl border-none shadow-3xl">
                            <Card.Body>
                              <Row>
                                <Col sm={4}>
                                  <img
                                    className="img-fluid"
                                    src={cus_cart}
                                    alt="..."
                                  />
                                </Col>

                                <Col sm={8}>
                                  <p className="text-lg">
                                    SMD LED - RGB APA102C -505 (Strip of 5000)
                                  </p>

                                  <p className="text-md pt-2">MBXS-3678</p>

                                  <div className="flex gap-4 mt-3">
                                    <p className="text-md text-default_blue">
                                      GH¢ 350.00
                                    </p>
                                    <p className="text-md text-text_danger bg-light_red rounded-md px-2">
                                      20% Off
                                    </p>
                                    <div className="flex gap-2">
                                      <StarIcon className="w-5 h-5 fill-default_yellow text-default_yellow" />
                                      <p className="text-md">3.5</p>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </TabPanel>
                  </Tabs>
                </Col>

                <Col md={4}>
                  <img
                    className="block mr-auto ml-auto"
                    src={customer_img}
                    alt="..."
                  />

                  <p className="text-center mt-3 text-xl">
                    Fullname Here &#128525;
                  </p>
                  <p className="text-default_blue text-center">mbxue74879e</p>
                </Col>
              </Row>
            </div>
            <div className="modal-footer bg-super_light">
              <Button
                className="text-text_white bg-default_ash"
                variant="secondary"
                data-bs-dismiss="modal"
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCustomerDetails;
