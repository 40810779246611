import { useEffect, useReducer, useState } from "react";
import PageLayout from "../../components/Layouts/page-layout";
import { Input, Button, Spin, message, MenuProps, Dropdown } from "antd";
import BaseService from "../../helpers/baseServices";
import useTableSyle from "../../components/shared/useTableSyle";
import { CiEdit, CiSearch } from "react-icons/ci";
import { IoMdMore } from "react-icons/io";
import DataTable from "react-data-table-component";
import EditcurricullumLevel from "./Modals/EditcurricullumLevel";
import CreatecurricullumLevel from "./Modals/CreatecurricullumLevel";
import CurriculumNameCell from "./CurriculumNameCell";
import moment from "moment";
import { IoSchoolSharp } from "react-icons/io5";
import { FiRefreshCcw } from "react-icons/fi";
import ToolTipComponent from "../../components/shared/ToolTipComponent";
import {
  dateCompareFunction,
  dynamicStringCompare,
} from "../../components/shared/TableSortFunctions";
import { debounce } from "lodash";

const initialState = {
  confirmLoading: false,
  curriculumData: [],
  selectedCurriculum: null,
  isFetching: true,
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_CURRICULUM":
      return { ...state, curriculumData: action.payload };
    case "SET_SELECTED_CURRICULUM":
      return { ...state, selectedCurriculum: action.payload };
    case "SET_FETCHING":
      return { ...state, isFetching: action.payload };
    default:
      return state;
  }
};

const Curriculum = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [inputValue, setInputValue] = useState<string>("");
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [EditOpen, setEditOpen] = useState(false);
  const [customStyles] = useTableSyle();
  const [reFetch, setReFetch] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [AllGradeLevelData, setAllGradeLevelData] = useState<any>();
  const [EditcurricullumLevelID, setEditcurricullumLevelID] =
    useState<string>();
  const [fieldName, setFieldName] = useState<string>();
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [RowName, setRowName] = useState<string>("");

  const showModal = () => setOpen(true);
  const showEditModal = () => setEditOpen(true);

  const handleMenuClick = (e: any, rowId: any) => {
    if (e.key === "1") {
      setEditOpen(true);
      setEditcurricullumLevelID(rowId);
    }
  };

  const fetchGradeLevels = async (lim: number, sk: number) => {
    setIsFetching(true);
    try {
      const filterConditions: Record<string, any>[] = [];

      if (fieldName) {
        filterConditions.push({ name: { $regex: fieldName, $options: "i" } });
      }

      const filter =
        filterConditions.length > 0 ? { $and: filterConditions } : {};
      const filterString = JSON.stringify(filter);

      const response: any = await BaseService.get_api(
        `/robocentre/curriculum-level?sort={"updatedAt":-1}&limit=${lim}&skip=${sk}&filter=${filterString}`
      );
      setAllGradeLevelData(response?.data?.payload);
      setTotal(response?.data?.total || 0);

      console.log(response?.data?.payload);
      setIsFetching(false);
    } catch (error: any) {
      console.log("Error:", error?.response?.data?.error);
    }
  };

  useEffect(() => {
    fetchGradeLevels(limit, skip);
  }, [reFetch, limit, skip, fieldName]);

  const columns: any = [
    {
      name: (
        <p className="p-2 text-lg font-semibold text-text_primary">
          Grade Color
        </p>
      ),
      cell: (row: any) => (
        <div className="w-[50%] flex items-center justify-center">
          <div
            className="py-1 h-8 w-8 text-center rounded-full"
            style={{ backgroundColor: row.color }}
          ></div>
        </div>
      ),
    },
    {
      name: (
        <p className="p-2 text-lg font-semibold text-text_primary">
          Level Name
        </p>
      ),
      cell: (row: any) => <p className="p-6 text-md">{row.name}</p>,
      sortable: true,
      sortFunction: dynamicStringCompare("name"),
    },
    {
      name: (
        <p className="p-2 text-lg font-semibold text-text_primary">
          Curriculum
        </p>
      ),
      cell: (row: any) => (
        <CurriculumNameCell curriculumId={row.curriculumId} />
      ),
      width: "20%",
      sortable: true,
      sortFunction: dynamicStringCompare("curriculumId"),
    },
    {
      name: (
        <p className="p-2 text-lg  font-semibold text-text_primary">
          Age Group
        </p>
      ),
      cell: (row: any) => (
        <p className="text-md w-[50%] text-center ">{`${row.ageGroup[0]} - ${
          row.ageGroup[row.ageGroup.length - 1]
        }`}</p>
      ),
      sortable: true,
    },
    {
      name: (
        <p className="p-2 text-lg font-semibold text-text_primary">
          Created On
        </p>
      ),
      cell: (row: any) => (
        <p className="text-md  text-center ">
          {" "}
          {moment(row?.createdAt).format("Do MMM YYYY - hh:mm A")}
        </p>
      ),
      sortable: true,
      sortFunction: dateCompareFunction("createdAt"),
    },
    {
      name: (
        <p className="p-2 text-lg w-full  font-semibold text-text_primary">
          Updated Last
        </p>
      ),
      cell: (row: any) => (
        <p className="text-md  text-center ">
          {" "}
          {moment(row?.updated_at).format("Do MMM YYYY - hh:mm A")}
        </p>
      ),
      sortable: true,
      sortFunction: dateCompareFunction("updated_at"),
    },
    {
      name: "",
      cell: (row: any) => {
        const menuItems: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <span className="text-default_ash text-sm">Edit Curriculum</span>
            ),
            icon: <CiEdit size={"1.3em"} />,
            onClick: (e) => handleMenuClick(e, row._id),
          },
          // {
          //   key: "2",
          //   label: (
          //     <span className="text-default_ash text-sm">Delete School</span>
          //   ),
          //   icon: <MdOutlineDelete color="red" size={"1.3em"} />,
          //   onClick: (e) => handleMenuClick(e, row._id),
          // },
        ];
        return (
          <Dropdown
            placement="bottomLeft"
            menu={{ items: menuItems }}
            className="text-[14px]"
          >
            <IoMdMore size={"2em"} className="text-[#9494f5]" />
          </Dropdown>
        );
      },
      width: "5%",
    },
  ];

  const fetchCurriculum = async () => {
    dispatch({ type: "SET_FETCHING", payload: true });
    try {
      const response: any = await BaseService.get_api("/robocentre/curriculum");
      dispatch({ type: "SET_CURRICULUM", payload: response?.data?.payload });
    } catch (error: any) {
      console.log("Error:", error?.response?.data?.error);
    } finally {
      dispatch({ type: "SET_FETCHING", payload: false });
    }
  };

  const handleOnClick = async () => {
    setIsProcessing(true);
    try {
      if (inputValue.trim()) {
        const payload = { name: inputValue };
        if (state.selectedCurriculum) {
          console.log("state.selectedCurriculum", state.selectedCurriculum);
          const IDpayload = {
            _id: state.selectedCurriculum._id,
            name: inputValue,
          };
          await BaseService.put_api(`/robocentre/curriculum`, IDpayload);
          message.success("Curriculum updated successfully");
        } else {
          await BaseService.post_api("/robocentre/curriculum", payload);
          message.success("Curriculum created successfully");
        }
        setInputValue("");
        dispatch({ type: "SET_SELECTED_CURRICULUM", payload: null });
        fetchCurriculum();
      }
    } catch (error: any) {
      console.log("Error:", error?.response?.data?.error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
    if (e.target.value === "") {
      dispatch({ type: "SET_SELECTED_CURRICULUM", payload: null });
    }
  };

  const handleClickCurriculum = (curriculum: any) => {
    setInputValue(curriculum.name);
    dispatch({ type: "SET_SELECTED_CURRICULUM", payload: curriculum });
  };

  useEffect(() => {
    fetchCurriculum();
  }, [limit, skip]);
  const handleRowChange = (a: any) => {
    setLimit(a);
  };

  const pageChange = (a: number) => {
    const newSkip = a === 1 ? 0 : (a - 1) * limit;
    setSkip(newSkip);
  };

  const handlerSearchChange = debounce((e: any) => {
    setFieldName(e.target.value);
  }, 500);

  return (
    <PageLayout>
      <div className="m-4">
        <section className="bg-white drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[20px] py-3">
          <h2 className="text-2xl ml-4 mb-3 text-text_primary font-semibold">
            Curriculum
          </h2>
          <div className="flex justify-between items-center">
            <div className="ml-4 w-[50%] flex items-center justify-between gap-4">
              <Input
                size="large"
                placeholder="New curriculum..."
                value={inputValue}
                className="rounded-[20px] border-[1px] border-[#9494f5] text-[#9494f5]"
                onChange={handleInputChange}
              />
              <div className="flex gap-4 mr-4 items-center">
                <Button
                  type="default"
                  icon={
                    <IoSchoolSharp size={"1.5em"} style={{ marginRight: 8 }} />
                  }
                  className="flex rounded-[20px] items-center"
                  onClick={handleOnClick}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  style={{
                    backgroundColor: isHovered ? "#9494F5" : "#6666EA",
                    color: "white",
                    height: "40px",
                    border: "1px solid #9494f5",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div className="flex items-center justify-center">
                    <p>
                      {state.selectedCurriculum
                        ? "Update Curriculum"
                        : "Create Curriculum"}
                    </p>
                  </div>
                </Button>
              </div>
            </div>
          </div>

          <div className="ml-4 mt-10 ">
            <Spin spinning={state.isFetching}>
              <div className="flex items-center mt-4 flex-wrap gap-3 w-full">
                {state.curriculumData.map((curriculum: any) => (
                  <div
                    className="shadow-md px-4 py-2 flex justify-between items-center rounded-[30px] bg-light_white text-text_primary cursor-pointer"
                    key={curriculum._id}
                    onClick={() => handleClickCurriculum(curriculum)}
                  >
                    <span> {curriculum.name}</span>
                    <IoMdMore size={"2em"} className="text-[#9494f5]" />
                  </div>
                ))}
              </div>
            </Spin>
          </div>
        </section>
      </div>

      <div className="m-4">
        <div className="py-3 bg-white drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[20px]">
          <h2 className="text-2xl ml-4 mb-2 text-text_primary font-semibold">
            Curriculum Level
          </h2>

          <section className="flex justify-between items-center">
            <div className="ml-4 w-[40%] flex items-center justify-between gap-4">
              <ToolTipComponent text={"Refresh"}>
                <div className="w-[40px] h-[40px] text-[#9494F5] hover:text-[#6666EA] hover:border-1 hover:border-[#6666EA] rounded-lg border-1 border-[#9494F5] flex items-center justify-center cursor-pointer  p-2">
                  <FiRefreshCcw
                    onClick={() => setReFetch(!reFetch)}
                    size={"2em"}
                  />
                </div>
              </ToolTipComponent>
              <Input
                size="large"
                placeholder="Search for level name...eg:IB primary year"
                className="rounded-[20px] border-[1px] border-[#9494f5] text-[#9494f5] "
                suffix={<CiSearch />}
                onChange={(e) => handlerSearchChange(e)}
              />
            </div>

            <div className="flex gap-4 mr-4 items-center">
              <CreatecurricullumLevel
                showModal={showModal}
                curriculumData={state.curriculumData}
                open={open}
                setOpen={setOpen}
                handleReFetch={() => setReFetch(!reFetch)}
              />
            </div>
          </section>
        </div>

        <div className="bg-white p-4 drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] mt-6 rounded-[20px]">
          <DataTable
            className="mt-4"
            columns={columns}
            data={AllGradeLevelData}
            progressPending={isFetching}
            paginationServer
            progressComponent={<Spin />}
            paginationTotalRows={total}
            onChangeRowsPerPage={handleRowChange}
            onChangePage={pageChange}
            pagination
            pointerOnHover
            highlightOnHover
            customStyles={customStyles}
          />
        </div>
        {EditOpen && (
          <EditcurricullumLevel
            showModal={showEditModal}
            EditcurricullumLevelID={EditcurricullumLevelID}
            curriculumData={state.curriculumData}
            open={EditOpen}
            setOpen={setEditOpen}
            handleReFetch={() => setReFetch(!reFetch)}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default Curriculum;
