import { useState } from 'react'
import { Form, Input, Modal, notification, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { PiCloudArrowUpLight } from 'react-icons/pi'
import BaseService from '../../../../helpers/baseServices'


interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
    dataToEdit: any
}

interface formProps {
    name: string;
    description: string;
}


const EditProfessionModal = ({ isOpened, handleClose, handleReFetch, dataToEdit }: modalProps) => {

    const [form] = useForm()
    const [isBusy, setIsBusy] = useState(false)


    //initialValues
    const initialValues = {
        name: dataToEdit?.name,
        description: dataToEdit?.description
    }


    //handleSubmit
    const handleSubmit = (formValues: formProps) => {
        let cooked_data: any = {
            _id: dataToEdit?._id,
            name: formValues?.name,
            description: formValues?.description
        }

        console.log("data_to_update:", cooked_data)
        setIsBusy(true)

        BaseService.base_put_api(`/mingo-org/profession`, cooked_data)
            .then((response) => {
                console.log("profUpdated:", response?.data)
                notification.success({ message: "Profession has been updated successfully!" })
                form.resetFields()
                setIsBusy(false)
                handleClose()
                handleReFetch()
            }).catch((error: any) => {
                console.log("profErr:", error)
                setIsBusy(false)
                notification.error({ message: error?.response?.data?.error || "An error occured while updating profession! Please retry" })
            })
    }


    return (
        <>
            <Modal title="Update Profession" open={isOpened} onCancel={handleClose} footer={null} keyboard={false} maskClosable={false} closeIcon={!isBusy}>
                <hr />
                <Spin spinning={isBusy}>
                    <Form
                        form={form}
                        layout='vertical'
                        initialValues={initialValues}
                        onFinish={handleSubmit}
                    >
                        <Form.Item label="Job Title" name="name" rules={[{ required: true, message: 'Please enter job title' }]}>
                            <Input className='h-[40px] w-full' placeholder='Ex: Sales Rep' />
                        </Form.Item>

                        <Form.Item label="Job Description" name="description" rules={[{ required: true, message: 'Please enter job description' }]}>
                            <Input.TextArea style={{ height: 80 }} className='w-full' placeholder='Ex: Selling products' />
                        </Form.Item>

                        <Form.Item className='mt-[40px]'>
                            <button className='h-[40px] w-full rounded-[5px] bg-default_blue text-white flex gap-[5px] items-center justify-center'>
                                <PiCloudArrowUpLight className='h-[20px] w-[20px]' />
                                <p>Update Profession</p>
                            </button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal >
        </>
    )
}

export default EditProfessionModal
