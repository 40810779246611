import {
  ArrowDownOnSquareStackIcon,
  CheckIcon,
  PlusIcon,
  PrinterIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  Card,
  CardBody,
  Input,
  List,
  ListItem,
  ListItemPrefix,
  Option,
  Select,
  Typography,
} from "@material-tailwind/react";
import { Fragment, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";

export default function RobocenterSchoolPricing({ school_id }: any) {
  return (
    <Fragment>
      {["Bundle", "Modular 1", "Modular"].map((_d, i) => {
        return (
          <Card key={i} className="mb-3">
            <CardBody>
              <div className="flex justify-between">
                <div>
                  <Typography variant="h3">{_d}</Typography>
                  <Typography variant="small">1 MONTH BILLING</Typography>
                </div>
                <div>
                  <Typography variant="lead">$ 1050.00 / learner</Typography>
                  <Typography variant="small" color="red">
                    Save $500.00
                  </Typography>
                </div>
              </div>
              <div className="flex justify-between mt-4">
                <List className="p-0 my-2">
                  <ListItem className="p-[2px]">
                    <ListItemPrefix>
                      <CheckIcon className="h-5 w-5" />
                    </ListItemPrefix>
                    One time Bundled Robot kit delivered for all modules
                  </ListItem>
                  <ListItem className="p-[2px]">
                    <ListItemPrefix>
                      <CheckIcon className="h-5 w-5" />
                    </ListItemPrefix>
                    3 months estimated completion time
                  </ListItem>
                  <ListItem className="p-[2px]">
                    <ListItemPrefix>
                      <CheckIcon className="h-5 w-5" />
                    </ListItemPrefix>
                    9 hands-on starter
                  </ListItem>
                </List>

                <div className="mt-auto flex gap-1">
                  <Button
                    size="sm"
                    variant="outlined"
                    color="red"
                    className="rounded-full"
                  >
                    Delete
                  </Button>
                  <Button size="sm" className="rounded-full">
                    Edit
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        );
      })}
    </Fragment>
  );
}

export function PricingSide() {
  const [show, setShow] = useState(false);
  const [inputFields, setInputFields] = useState([{ value: "" }]);

  const handleInputChange = (index: any, event: any) => {
    const values = [...inputFields];
    values[index].value = event.target.value;
    setInputFields(values);
  };

  const handleAddFields = () => {
    setInputFields([...inputFields, { value: "" }]);
  };

  const handleRemoveFields = (index: any) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  return (
    <Fragment>
      <Button
        className="flex items-center gap-2 bg-default_blue mb-2"
        size="sm"
        onClick={() => setShow(true)}
      >
        <PlusIcon strokeWidth={2} className="h-5 w-5" /> Create Pricing
      </Button>
      <div className="flex justify-start gap-2">
        <Button
          className="flex items-center gap-2 mb-2"
          variant="outlined"
          size="sm"
        >
          <ArrowDownOnSquareStackIcon strokeWidth={2} className="h-5 w-5" />{" "}
          Export
        </Button>
        <Button className="mb-2" size="sm" variant="outlined">
          <PrinterIcon strokeWidth={2} className="h-5 w-5" />
        </Button>
      </div>

      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header>
          <Modal.Title>Add pricing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Row className="mb-3">
              <Col md={4}>Pricing name</Col>
              <Col md={8}>
                <Input
                  type="text"
                  placeholder="Name"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}>Billing Frequency</Col>
              <Col md={8}>
                <Select
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  value="1 month"
                  onChange={() => {}}
                >
                  <Option value="1 month">1 month</Option>
                  <Option value="3 month">3 month</Option>
                  <Option value="6 month">6 month</Option>
                </Select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}>Discount on Price</Col>
              <Col md={8}>
                <Input
                  type="number"
                  placeholder="Discount"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}>Packages</Col>
              <Col md={8}>
                <div>
                  {inputFields.map((inputField, index) => (
                    <div key={index} className="mb-2">
                      <Input
                        type="text"
                        placeholder="Package"
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                        value={inputField.value}
                        onChange={(event) => handleInputChange(index, event)}
                        icon={
                          <XMarkIcon
                            className="cursor-pointer"
                            onClick={() => handleRemoveFields(index)}
                          />
                        }
                      />
                    </div>
                  ))}
                  <Button
                    className="flex items-center gap-2 mb-2"
                    size="sm"
                    variant="text"
                    onClick={handleAddFields}
                  >
                    <PlusIcon strokeWidth={2} className="h-5 w-5" /> Add more
                    packages
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="text"
            color="red"
            onClick={() => setShow(false)}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            className="bg-defaultBlue rounded-full"
            onClick={() => setShow(false)}
          >
            <span>Publish</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}
