import React, { useState } from 'react'
import { DatePicker, Form, Input, Modal, notification, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import DepartmentSelectorComponent from '../department/departmentSelectorComponent'
import { PiCloudArrowUpLight } from 'react-icons/pi'
import PhoneInput from "react-phone-number-input";
import ProfessionSelectorComponent from '../profession/professionSelectorComponent'
import BaseService from '../../../../helpers/baseServices'
import dayjs from 'dayjs';


interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
    dataToEdit: any
}

interface User {
    email: string;
    firstName: string;
    lastName: string;
    middleName: string;
    phone: string;
    profession: string;
    password: string;
    department: string;
    indentificationID: string;
    dateOfBirth: string;
    address: string;
    employmentDate: string;
    nationality: string;
}


const EditStaffModal = ({ isOpened, handleClose, handleReFetch, dataToEdit }: modalProps) => {

    const [form] = useForm()
    const [isBusy, setIsBusy] = useState(false)

    console.log("dataToEdit:", dataToEdit)

    //initialValues
    const initialValues = {
        email: dataToEdit?.email,
        firstName: dataToEdit?.firstName,
        lastName: dataToEdit?.lastName,
        password: dataToEdit?.password,
        middleName: dataToEdit?.middleName,
        phone: dataToEdit?.phone,
        profession: dataToEdit?.professionData?.[0]?.name,
        department: dataToEdit?.departmentData?.[0]?.name,
        indentificationID: dataToEdit?.indentificationID,
        address: dataToEdit?.address,
        dateOfBirth: dayjs(dataToEdit?.dateOfBirth),
        employmentDate: dayjs(dataToEdit?.employmentDate),
    }


    //handleSubmit
    const handleSubmit = (formValues: User) => {
        let cooked_data: any = {
            userid: dataToEdit?.userid,
            email: formValues?.email,
            password: formValues?.password,
            firstName: formValues?.firstName,
            lastName: formValues?.lastName,
            middleName: formValues?.middleName,
            phone: formValues?.phone,
            profession: formValues?.profession,
            department: formValues?.department,
            indentificationID: formValues?.indentificationID,
            dateOfBirth: new Date(formValues?.dateOfBirth).toISOString(),
            address: formValues?.address,
            employmentDate: new Date(formValues?.employmentDate).toISOString(),
            nationality: "Ghana"
        }

        if (!cooked_data?.middleName) {
            delete cooked_data?.middleName
        }

        console.log("data_to_update:", cooked_data)
        setIsBusy(true)

        BaseService.base_put_api(`/mingo-org/staff`, cooked_data)
            .then((response) => {
                console.log("staffUpdated:", response?.data)
                notification.success({ message: "Satff info has been updated successfully!" })
                form.resetFields()
                setIsBusy(false)
                handleClose()
                handleReFetch()
            }).catch((error: any) => {
                console.log("staffErr:", error)
                setIsBusy(false)
                notification.error({ message: error?.response?.data?.error || "An error occured while updating staff info! Please retry" })
            })
    }


    return (
        <>
            <Modal title="Update Staff Info" width={800} open={isOpened} onCancel={handleClose} footer={null} keyboard={false} maskClosable={false} closeIcon={!isBusy}>
                <hr />
                <Spin spinning={isBusy}>
                    <Form
                        form={form}
                        initialValues={initialValues}
                        layout='vertical'
                        onFinish={handleSubmit}
                    >
                        <div className="w-full grid grid-cols-2 gap-x-[20px] mt-[20px]">
                            <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: 'Please enter staff first name' }]}>
                                <Input className='h-[40px] w-full' placeholder='Ex: John' />
                            </Form.Item>

                            <Form.Item label="Middle Name" name="middleName">
                                <Input className='h-[40px] w-full' placeholder='Ex: Kofi' />
                            </Form.Item>

                            <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: 'Please enter staff last name' }]}>
                                <Input className='h-[40px] w-full' placeholder='Ex: Doe' />
                            </Form.Item>

                            <Form.Item label="Phone Number" name="phone" rules={[{ required: true, message: 'Please enter staff phone number' }]}>
                                <PhoneInput
                                    className="rounded-[5px] w-full border-[1px] border-gray-300 h-[40px] px-2 bg-white"
                                    defaultCountry="GH"
                                    inputStyle={{ width: "100%" }}
                                    name="phone"
                                    placeholder="0241112222"
                                    onChange={(phone) => console.log(phone)}
                                    numberInputProps={{
                                        className: "focus:outline-none",
                                    }}
                                />
                            </Form.Item>

                            <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please enter staff email' }]}>
                                <Input className='h-[40px] w-full' placeholder='Ex: john@mingoblox.com' />
                            </Form.Item>

                            <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please enter staff password' }]}>
                                <Input.Password className='h-[40px] w-full' placeholder='Ex: 123456' />
                            </Form.Item>

                            <Form.Item label="Department" name="department" rules={[{ required: true, message: 'Please choose a department' }]}>
                                <DepartmentSelectorComponent defaultValue={initialValues?.department} onChange={(selected) => console.log(selected)} />
                            </Form.Item>

                            <Form.Item label="Job Title" name="profession" rules={[{ required: true, message: 'Please choose a job title' }]}>
                                <ProfessionSelectorComponent defaultValue={initialValues?.profession} onChange={(selected) => console.log(selected)} />
                            </Form.Item>

                            <Form.Item label="ID Number" name="indentificationID" rules={[{ required: true, message: 'Please enter staff id number' }]}>
                                <Input className='h-[40px] w-full' placeholder='Ex: GHA-000000' />
                            </Form.Item>

                            <Form.Item label="Date of Birth" name="dateOfBirth" rules={[{ required: true, message: 'Please enter date of birth' }]}>
                                <DatePicker className='h-[40px] w-full' placeholder='Choose date' />
                            </Form.Item>

                            <Form.Item label="Address" name="address" rules={[{ required: true, message: 'Please enter staff address' }]}>
                                <Input className='h-[40px] w-full' placeholder='Ex: Greater Accra' />
                            </Form.Item>

                            <Form.Item label="Employment Date" name="employmentDate" rules={[{ required: true, message: 'Please enter employment date' }]}>
                                <DatePicker className='h-[40px] w-full' placeholder='Choose date' />
                            </Form.Item>
                        </div>

                        <Form.Item className='mt-[40px]'>
                            <button className='h-[40px] w-full rounded-[5px] bg-default_blue text-white flex gap-[5px] items-center justify-center'>
                                <PiCloudArrowUpLight className='h-[20px] w-[20px]' />
                                <p>Update Staff Info</p>
                            </button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal >
        </>
    )
}

export default EditStaffModal
