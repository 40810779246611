import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import PageLayout from "../../components/Layouts/page-layout";
import { IoIosTrendingUp } from "react-icons/io";
import { orderList } from "../../TestData/Test_Orders";
import { GoTasklist } from "react-icons/go";
import PerformanceChart from "./performanceChart";
import { Progress, Spin } from "antd";
import RecentRequests from "../all-requests/recentRequests";

const Dashboard = () => {
  //table
  const columns = [
    {
      name: "Order No.",
      selector: (row: any) => row.id,
    },
    {
      name: "Customer Name.",
      selector: (row: any) => row.customer_name,
    },
    {
      name: "Order Date.",
      selector: (row: any) => row.order_date,
    },
    {
      name: "Total (GH¢)",
      selector: (row: any) => row.total_amount,
    },
    {
      name: "Status",
      selector: (row: any) => (
        <>
          <p className="rounded-full text-tag_deep bg-tag_deep pl-3 pr-3 bg-opacity-20">
            {row.status}
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      <PageLayout>
        <div className="m-4 h-full">
          <h2 className="text-[32px] font-[700] text-text_deep">Dashboard</h2>

          {/* Statistics */}
          <div className="w-full lg:flex gap-[14px] mt-5">
            {/* sales */}
            <div className="w-[40%]">
              <div className="rounded-2xl bg-white bg-gradient-to-r from-[#6666EA] to-[#9494F5] py-[16px] px-[24px] shadow-sm">
                <div className="p-3 text-text_white">
                  <p className="text-[16px] font-[400]">Total Sales</p>

                  <div className="pt-2 flex justify-between">
                    <p className="text-[32px] font-[700]">$5,676,578</p>
                    <div className="h-10 w-10 rounded-[5px] flex justify-center items-center bg-default_blue bg-opacity-[30%] text-white">
                      <IoIosTrendingUp className="h-5 w-5" />
                    </div>
                  </div>

                  <div className="mt-2.5">
                    <p className="text-[14px] font-[400]">
                      <span className="text-secondary_green mr-2">
                        2.3% &uarr;
                      </span>{" "}
                      than last month
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* students */}
            <div className="w-[20%]">
              <div className="rounded-2xl py-[16px] px-[24px] bg-white shadow-sm">
                <div className="p-3 text-text_primary">
                  <p className="text-[16px] font-[400]">Total Students</p>

                  <div className="pt-2 flex justify-between">
                    <p className="text-[32px] font-[700]">346K</p>
                    <div className="h-10 w-10 rounded-[5px] flex justify-center items-center bg-secondary_green bg-opacity-[10%] text-secondary_green">
                      <GoTasklist className="h-5 w-5" />
                    </div>
                  </div>

                  <div className="mt-2.5">
                    <p className="text-[14px] font-[400]">
                      <span className="text-secondary_green mr-2">
                        8.5% &uarr;
                      </span>{" "}
                      last month
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* customers */}
            <div className="w-[20%]">
              <div className="rounded-2xl py-[16px] px-[24px] bg-white shadow-sm">
                <div className="p-3 text-text_primary">
                  <p className="text-[16px] font-[400]">Total Customers</p>

                  <div className="pt-2 flex justify-between">
                    <p className="text-[32px] font-[700]">124K</p>
                    <div className="h-10 w-10 rounded-[5px] flex justify-center items-center bg-secondary_green bg-opacity-[10%] text-secondary_green">
                      <GoTasklist className="h-5 w-5" />
                    </div>
                  </div>

                  <div className="mt-2.5">
                    <p className="text-[14px] font-[400]">
                      <span className="text-red-500 mr-2">2.5% &darr;</span>{" "}
                      yesterday
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* products */}
            <div className="w-[20%]">
              <div className="rounded-2xl py-[16px] px-[24px] bg-white shadow-sm">
                <div className="p-3 text-text_primary">
                  <p className="text-[16px] font-[400]">Total Products</p>

                  <div className="pt-2 flex justify-between">
                    <p className="text-[32px] font-[700]">122</p>
                    <div className="h-10 w-10 rounded-[5px] flex justify-center items-center bg-secondary_blue bg-opacity-[10%] text-secondary_blue">
                      <GoTasklist className="h-5 w-5" />
                    </div>
                  </div>

                  <div className="mt-2.5">
                    <p className="text-[14px] font-[400]">
                      <span className="text-secondary_green mr-2">
                        8.5% &uarr;
                      </span>{" "}
                      today
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Chart */}
          <div className="mt-5">
            <PerformanceChart />
          </div>

          {/* Orders */}
          <div className="mt-5 text-text_primary">
            <Row>
              <Col className="col-md-8 mt-2">
                <div className="rounded-2xl shadow-sm px-[32px] py-4 bg-white">
                  <div className="flex justify-between items-center">
                    <p className="text-[21px] font-[600] text-text_deep">
                      Orders
                    </p>
                    <Link
                      className="text-default_blue hover:text-default_blue font-[600] text-[16px]"
                      to="/orders"
                    >
                      See all &#8594;
                    </Link>
                  </div>

                  <div>
                    <DataTable
                      className="mt-3"
                      columns={columns}
                      data={orderList}
                      progressComponent={<Spin />}
                    />
                  </div>
                </div>
              </Col>

              <Col className="col-md-4 mt-2">
                <div className="rounded-2xl shadow-sm px-[32px] py-4 bg-white">
                  <div className="flex justify-between items-center">
                    <p className="text-[21px] font-[600] text-text_deep">
                      Best Selling
                    </p>
                    <Link
                      className="text-default_blue hover:text-default_blue font-[600] text-[16px]"
                      to="#"
                    >
                      See all &#8594;
                    </Link>
                  </div>

                  <div className="mt-[4px]">
                    {["", "", "", ""].map((item, i) => (
                      <div key={i} className="flex gap-4 items-center mb-[5px]">
                        <div>
                          <img
                            className="h-[44px] w-[54px] object-cover rounded-[5px]"
                            src="https://cordis.europa.eu/docs/news/images/2023-03/442991.jpg"
                            alt="..."
                          />
                        </div>

                        <div className="w-full">
                          <p>Rob - Servo Motors</p>

                          <Progress
                            percent={75}
                            showInfo={false}
                            size="small"
                            strokeColor="#6666EA"
                            trailColor="#CCCCF8"
                          />
                          <small className="text-default_ash">
                            $58,000 sales
                          </small>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="mt-5 text-text_primary pb-5 w-full">
            <div className="rounded-2xl shadow-sm px-[32px] py-4 bg-white">
              <p className="text-[21px] font-[600] text-text_deep">
                Recent Requests
              </p>

              <div className="">
                <RecentRequests />
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default Dashboard;
