import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Empty, Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import BaseService from "../../../../helpers/baseServices";
import { Container } from "react-bootstrap";
import { Pagination } from "@mui/material";
import AddNewStaffModal from "./addNewStaffModal";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import EditStaffModal from "./editstaffModal";

const AllStaffTable = () => {

  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(true);
  const [staffList, setStaffList] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0)
  const [reFetch, setReFetch] = useState(false)
  const [selected, setSelected] = useState<any>(null)

  //createModal
  const [openCreate, setOpenCreate] = useState(false)

  //editModal
  const [openEdit, setOpenEdit] = useState(false)
  const handleOpenEdit = (data: any) => {
    setSelected(data)
    setOpenEdit(true)
  }

  //onload
  useEffect(() => {
    const GetStaff = async () => {
      setIsFetching(true);
      let skip = (page - 1) * limit;

      try {
        const response = await BaseService.base_get_api(`/mingo-org/staff?limit=${limit}&skip=${skip}`);
        console.log("getStaffRes:", response?.data);
        setStaffList(response?.data?.payload)
        setTotal(response?.data?.total)
        setIsFetching(false);
      } catch (error) {
        console.log("getStaffErr:", error);
      }
    };

    GetStaff();
  }, [limit, page, reFetch]);

  //table columns
  const columns = [
    {
      name: <p className='text-[16px] text-text_primary font-[600]'>Name</p>,
      cell: (row: any) => (
        <p>
          {row?.firstName + " " + row?.lastName}
        </p>
      ),
    },
    {
      name: <p className='text-[16px] text-text_primary font-[600]'>Email</p>,
      cell: (row: any) => (
        <p>
          {row?.email}
        </p>
      ),
    },
    {
      name: <p className='text-[16px] text-text_primary font-[600]'>Department</p>,
      cell: (row: any) => (
        <p>
          {row?.departmentData.length >= 1 ? row?.departmentData?.[0]?.name : null}
        </p>
      ),
    },
    {
      name: <p className='text-[16px] text-text_primary font-[600]'>Job Title</p>,
      cell: (row: any) => (
        <p>
          {row?.professionData.length >= 1 ? row?.professionData?.[0]?.name : null}
        </p>
      ),
    },
    {
      name: '',
      cell: (row: any) => (
        <div className="flex gap-[15px] items-center">
          <Tooltip title='Edit'>
            <PencilSquareIcon className="h-[20px] w-[20px] text-default_blue" onClick={() => handleOpenEdit(row)} />
          </Tooltip>
        </div>
      ),
      width: '5%'
    },
  ];


  return (
    <>
      <div className="bg-white text-gray-700 w-full rounded-3xl focus:ring-opacity-50 shadow-md py-4 px-4 mt-4">
        <div className="flex justify-between items-center">
          <div className="">

          </div>

          <div>
            <button className="px-[20px] py-2 rounded-[30px] bg-default_blue text-white shadow-sm" onClick={() => setOpenCreate(true)}>
              Create Staff
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white p-4 drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[24px] mt-4">
        <DataTable
          columns={columns}
          data={staffList}
          progressPending={isFetching}
          progressComponent={<div className='flex justify-center items-center h-[70vh]'><Spin /></div>}
          pointerOnHover
          highlightOnHover
          noDataComponent={<div className='flex justify-center items-center h-[70vh]'><Empty description="No staff data found!" /></div>}
          // onRowClicked={(row) => navigate(`/manage-staff/${row?.companyID}`)}
          paginationServer
          pagination
          paginationTotalRows={total}
        />
      </div>



      {/* create staff modal */}
      {
        openCreate && <AddNewStaffModal isOpened={openCreate} handleClose={() => setOpenCreate(false)} handleReFetch={() => setReFetch(!reFetch)} />
      }


      {/* edit staff modal */}
      {
        openEdit && <EditStaffModal isOpened={openEdit} handleClose={() => setOpenEdit(false)} handleReFetch={() => setReFetch(!reFetch)} dataToEdit={selected} />
      }
    </>
  );
};

export default AllStaffTable;
