import React, { useState } from 'react'
import { Collapse, Empty, Tooltip } from 'antd'
import { PencilSquareIcon, PlusIcon } from '@heroicons/react/24/outline'
import AddContentTopic from './tabs/addContentTopic'
import { IoVideocamOutline } from "react-icons/io5";
import EditContentTopic from './tabs/editContentTopic';


interface theProps {
    unitInfo: any
    handleReFetch: () => void
}


const ContentTopicsComponent = ({ unitInfo, handleReFetch }: theProps) => {

    const [openAddTopic, setOpenAddTopic] = useState(false)
    const [openEditTopic, setOpenEditTopic] = useState(false)
    const [selectedTopic, setSelectedTopic] = useState<any>(null)

    //handleOpenEdit
    const handleOpenEdit = (data: any, index: number) => {
        console.log("selectedTopic:", data)
        setSelectedTopic({ ...data, "topicId": index })
        setOpenEditTopic(true)
    }

    return (
        <>
            <div className="mt-[20px] flex justify-between items-center">
                <p className='text-[18px] font-[600] text-text_primary'>
                    Topics in this Unit
                </p>

                <button className='px-[20px] py-[8px] rounded-[30px] bg-default_blue text-white flex gap-[5px] items-center' onClick={() => setOpenAddTopic(true)}>
                    <PlusIcon className='h-[20px] w-[20px]' />
                    <p>Add Topic</p>
                </button>
            </div>

            <div className='w-full mt-[30px]'>
                {
                    unitInfo?.unit.length === 0 ?
                        <Empty description="There are no topics added in this unit" />
                        :
                        <div>
                            {
                                unitInfo?.unit.map((singleTopic: any, i: number) => (
                                    <div key={i} className="w-full bg-white border-[1px] border-[#CCCCF8] rounded-[20px] text-text_primary mb-[20px]">
                                        <Collapse
                                            className="w-full rounded-[20px]"
                                            ghost
                                            defaultActiveKey={i}
                                            expandIconPosition="end"
                                            items={[
                                                {
                                                    label: <div className='w-full flex justify-between'>
                                                        <div className='flex gap-[10px]'>
                                                            <div>
                                                                <div className="h-[25px] w-[25px] mt-[2px] p-[2px] rounded-full bg-secondary_purple bg-opacity-[10%] text-secondary_purple border-1 border-secondary_purple flex justify-center items-center">
                                                                    <IoVideocamOutline />
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <p className='text-[16px] font-[600] text-text_primary my-0'>Lesson {i + 1}: {singleTopic?.title}</p>
                                                                <p className='text-[12px] text-[#9D9CC2] font-[500] mt-0'>{singleTopic?.duration / 60} min(s)</p>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <Tooltip title="Edit Topic">
                                                                <PencilSquareIcon className='h-[20px] w-[20px] text-default_blue' onClick={() => handleOpenEdit(singleTopic, i)} />
                                                            </Tooltip>
                                                        </div>
                                                    </div>,
                                                    children: <div className='w-full border-t-[1px] border-t-[#CCCCF8]'>
                                                        {/* content */}
                                                        <div className='mt-[20px]'>
                                                            <div className='flex gap-[20px]'>
                                                                <div className='w-[200px] rounded-[10px] text-text_primary h-[100px]'>
                                                                    <video className='h-full w-full rounded-[10px] object-contain' src={singleTopic?.educationalContent?.[0]?.url} controls />
                                                                </div>

                                                                {/* description */}
                                                                <div className='w-full'>
                                                                    <div className='mt-[10px] text-text_primary' dangerouslySetInnerHTML={{ __html: singleTopic?.description }} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                }
                                            ]}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                }
            </div>


            {/* open add topic modal */}
            {
                openAddTopic && <AddContentTopic isOpened={openAddTopic} unitInfo={unitInfo} handleClose={() => setOpenAddTopic(false)} handleReFetch={handleReFetch} />
            }


            {/* open edit topic modal */}
            {
                openEditTopic && <EditContentTopic isOpened={openEditTopic} selectedTopic={selectedTopic} unitInfo={unitInfo} handleClose={() => setOpenEditTopic(false)} handleReFetch={handleReFetch} />
            }
        </>
    )
}

export default ContentTopicsComponent
