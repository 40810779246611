import PinInput from "react-pin-input";
import ShowAlert from "../../components/Alerts/all_alerts";
import ShowToast from "../../components/Alerts/all_toasts";
import signin_img from "../../images/otp.png";
import { ROBOCENTRE_URL } from "../../helpers/constants";
import { StorageBox } from "../../helpers/storage";
import { notification } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ResetPwdVerify = () => {

  const navigate = useNavigate();

  const doVirify = async (value: any, index: any) => {
    if (value === "") {
      ShowToast.warning_toast("Please enter the OTP!");
    } else {
      ShowAlert.loading_alert("Please wait...");
      console.log(value);

      let data = JSON.stringify({
        "token": value
      });

      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${ROBOCENTRE_URL}/robocentre/auth/verify_account`,
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': StorageBox.getTempToken()
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          console.log(response?.data?.payload);
          notification.success({ message: 'Success', description: 'Account verified successfully' });
          StorageBox.saveAccessToken(response?.data?.token);
          setTimeout(() => {
            navigate('/resetpwd')
          }, 1000)
        })
        .catch((error: any) => {
          console.log(error);
          notification.error({
            message: "Error",
            description:
              error?.response?.data?.error ||
              "An error occurred. Please try again",
          });
        });

    }
  };

  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="h-full flex justify-center items-center flex-col px-[20px] lg:px-0">
          <p className="text-default_blue font-semibold text-3xl pt-3">
            Verify your email
          </p>

          <p className="text-text_primary text-center">
            We have sent a 5 digit pin to your email. Please enter the pin below
          </p>

          <img
            className="img-fluid block mr-auto ml-auto mt-4 w-[400px]"
            src={signin_img}
            alt="..."
          />


          <div className="text-center">
            <PinInput
              length={5}
              secret
              secretDelay={100}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px" }}
              inputStyle={{ borderColor: "#6666EA" }}
              inputFocusStyle={{ borderColor: "green" }}
              onComplete={doVirify}
              autoSelect={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPwdVerify;
