import React, { useState } from 'react'
import PageLayout from '../../../components/Layouts/page-layout'
import { IoArrowBackOutline } from 'react-icons/io5'
import CourseInformation from './tabs/courseInformation';
import { useNavigate } from 'react-router-dom';


const CreateNewCourse = () => {

    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState(0)

    //tabNames
    const tabNames = [
        "Course Information"
    ]

    //tabComponents
    const tabComponents = [
        <CourseInformation />
    ]

    return (
        <>
            <PageLayout>
                <div className="m-4">
                    <div className="flex gap-[20px] items-center">
                        <button className='px-[20px] py-[8px] rounded-[30px] border-[1px] border-default_blue text-default_blue flex gap-[5px] items-center' onClick={() => navigate("/courses")}>
                            <IoArrowBackOutline className='h-[20px] w-[20px]' />
                            <p>Back</p>
                        </button>

                        <p className="text-2xl my-4 text-text_primary font-semibold">
                            Create New Course
                        </p>
                    </div>

                    {/* content */}
                    <div className="mt-[20px] w-full grid grid-cols-4 gap-[20px]">
                        {/* left */}
                        <div className="w-full col-span-1 sticky-top h-[500px]">
                            <div className="w-full h-fit bg-white rounded-[20px] shadow-sm py-[35px]">
                                <p className='font-[600] text-text_primary ml-[23px]'>Course Creation</p>

                                <div className="mt-[5px]">
                                    {
                                        tabNames.map((tab, i) => (
                                            <div key={i} className={`px-[23px] py-[15px] cursor-pointer ${activeTab === i ? 'border-l-[7px] border-l-default_blue bg-[#F8F8F8]' : 'border-l-[7px] border-l-transparent'}`} onClick={() => setActiveTab(i)}>
                                                <p className='font-[400] text-text_primary'>{tab}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        {/* right */}
                        <div className='w-full col-span-3'>
                            {tabComponents[activeTab]}
                        </div>
                    </div>
                </div>
            </PageLayout>
        </>
    )
}

export default CreateNewCourse
