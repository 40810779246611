export const dataInfo = [
  {
    Type: "Pomotional",
    Announcement:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
    Created: "12/11/12",
    Expiry: "12/11/12",
    Status: "Running",
  },
  {
    Type: "Promo",
    Announcement:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
    Created: "12/11/12",
    Expiry: "12/11/12",
    Status: "Sent",
  },
  {
    Type: "Promo",
    Announcement:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.",
    Created: "12/11/12",
    Expiry: "12/11/12",
    Status: "Running",
  },
];
