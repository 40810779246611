import { useState } from "react";
import { Typography } from "@material-tailwind/react";

import { useParams } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { RxAvatar } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import PageLayout from "../../../../components/Layouts/page-layout";
import CustomButtomRounded from "../../../../components/shared/CustomButtomRounded";
import CustomButtomSquare from "../../../../components/shared/CustomButtomSquare";
import otp from "../../../../images/customer_img.png";
import PersonalInformation from "../../../customers/PersonalInformation";
import TextArea from "antd/es/input/TextArea";
import { Select } from "antd";
const BusinessesDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const handlerClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    navigate("/verificationRequest");
  };
  // console.log(id);

  return (
    <>
      <PageLayout>
        <div className="m-4  flex gap-8 ">
          <section className="w-[80%] ">
            <div className="flex items-center gap-4 ml-6 mb-14   ">
              <CustomButtomRounded
                icon={<FaArrowLeft />}
                value={"Back"}
                onClick={handlerClick}
              />
              <h2 className="text-4xl text-text_primary font-semibold">
                Businesses
              </h2>
            </div>
            <PersonalInformation />
          </section>
          <section className=" flex gap-4 flex-col   w-[25%] ">
            <div>
              <div>
                <TextArea
                  rows={4}
                  placeholder="Add note"
                  className="border-[1px] border-outline_light text-[#9494f5]"
                />
              </div>
            </div>
            <div>
              <p className="text-text_primary">Status</p>
              <Select
                defaultValue="lucy"
                style={{ width: "100%" }}
                // onChange={handleChange}
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "Yiminghe", label: "yiminghe" },
                  { value: "disabled", label: "Disabled", disabled: true },
                ]}
              />
            </div>
            <div>
              <div className="w-[80%] grid gap-2 grid-cols-2 grid-rows-3 ">
                <CustomButtomSquare
                  value="Send message"
                  icon={<RxAvatar size={"1.3em"} />}
                  myStyle={"col-span-2 text-[14px] font-semibold shadow-md"}
                />
                <CustomButtomSquare
                  value="Export"
                  icon={<RxAvatar size={"1.3em"} />}
                  myStyle={
                    "row-start-2  row-end-3 text-[14px] shadow-md font-semibold"
                  }
                  row-start-2
                />
                <CustomButtomSquare
                  myStyle={
                    "flex justify-center items-center text-[14px] shadow-md font-semibold"
                  }
                  icon={<IoEyeOutline size={"1.3em"} />}
                />

                <CustomButtomSquare
                  value="Deactivate School"
                  icon={<IoEyeOutline size={"1.3em"} />}
                  myStyle={
                    "col-span-2 text-[14px] border-1 border-solid border-red-200  text-red-200 shadow-md font-semibold"
                  }
                />
              </div>
            </div>
          </section>
        </div>
      </PageLayout>
    </>
  );
};

export default BusinessesDetails;
