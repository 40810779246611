import React, { useState } from 'react'
import CourseContentInformation from './tabs/courseContentInformation'
import CourseFaqsComponent from './courseFaqs/courseFaqsComponent'



const CourseContentPage = () => {

    const [activeTab, setActiveTab] = useState(0)

    //tabNames
    const tabNames = [
        "Course Content", "Course FAQs"
    ]

    //tabComponents
    const tabComponents = [
        <CourseContentInformation />,
        <CourseFaqsComponent />
    ]

    return (
        <>
            {/* content */}
            <div className="mt-[20px] w-full grid grid-cols-4 gap-[20px]">
                {/* left */}
                <div className="w-full col-span-1">
                    <div className="w-full h-fit bg-white rounded-[20px] shadow-sm py-[35px]">

                        <div className="mt-[5px]">
                            {
                                tabNames.map((tab, i) => (
                                    <div key={i} className={`w-full px-[23px] py-[15px] cursor-pointer ${activeTab === i ? 'border-l-[7px] border-l-default_blue bg-[#F8F8F8]' : 'border-l-[7px] border-l-transparent'}`}>
                                        <button className='w-full text-left disabled:cursor-not-allowed' onClick={() => setActiveTab(i)}>
                                            <p className='font-[400] text-text_primary'>{tab}</p>
                                        </button>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                {/* right */}
                <div className='w-full col-span-3'>
                    {tabComponents[activeTab]}
                </div>
            </div>
        </>
    )
}

export default CourseContentPage
