import React, { Fragment, useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import {
  Bars3Icon,
  ChatBubbleLeftEllipsisIcon,
} from "@heroicons/react/24/outline";
import logo from "../../images/logo.png";
import "./Layouts.css";
import { navLinks } from "../../router/nav-links";

const ProSideBar = () => {
  const location = useLocation();
  const path = location.pathname;
  const { collapseSidebar, collapsed } = useProSidebar();
  const [sidebarVisibility, setSidebarVisibility] = useState(
    localStorage.getItem("side_visibility") === "true" || false
  );

  const toggleSidebar = () => {
    const newVisibility = !sidebarVisibility;
    setSidebarVisibility(newVisibility);
    localStorage.setItem("side_visibility", newVisibility.toString());
  };

  useEffect(() => {
    const storedVisibility = localStorage.getItem("side_visibility");
    if (storedVisibility) {
      setSidebarVisibility(storedVisibility === "true");
    }
  }, []);

  // Helper function to render sections
  const renderSectionHeader = (sectionName: any) => {
    if (!collapsed) {
      return (
        <div className="text-[12px] text-[#757494] mx-3 mt-[15px]">
          <p className="ml-3 mb-1">{sectionName}</p>
        </div>
      );
    }
    return null;
  };

  // Helper function to render menu items
  const renderMenuItems = (navItem: any, index: number) => {
    const isActive = navItem.sublinks
      ? navItem.sublinks.some((subLink: any) => subLink.link === path)
      : navItem.link === path;

    return (
      <Fragment key={index}>
        {navItem.name === "Manage Coupon" && renderSectionHeader("GENERAL")}
        {navItem.name === "Orders" && renderSectionHeader("E-COMMERCE")}
        {navItem.name === "Manage Robocentre" && renderSectionHeader("ROBOCENTRE")}

        {navItem.sublinks ? (
          <SubMenu
            label={navItem.name}
            icon={<navItem.icon className="h-5 w-5 pb-1" />}
            className={`${navItem.name === "Administration" ? "admin-section" : ""} text-[#757494]`}
            active={isActive}
            defaultOpen={isActive}
          >
            {navItem.sublinks.map((subLink: any, subIndex: number) => {
              const subIsActive = subLink.link === path;

              return (
                <MenuItem
                  key={subIndex}
                  icon={<subLink.icon className="h-5 w-5 pb-1" />}
                  className={subIsActive ? "active-section" : ""}
                  component={<Link to={subLink.link} />}
                  active={subIsActive}
                >
                  {subLink.name}
                </MenuItem>
              );
            })}
          </SubMenu>
        ) : (
          <MenuItem
            icon={<navItem.icon className="h-[20px] text-[#A3A3F2] w-[20px] pb-1" />}
            className={`text-[#757494] ${navItem.name === "Administration" ? "admin-section" : ""}`}
            component={<Link to={navItem.link} />}
            active={isActive}
          >
            {navItem.name}
          </MenuItem>
        )}
      </Fragment>
    );
  };

  return (
    <Sidebar
      style={{ height: "100vh" }}
      backgroundColor="#fff"
      collapsed={collapsed}
    >
      <div className="no-scrollbar h-full overflow-y-auto relative">
        <Menu
          menuItemStyles={{
            button: {
              [`&.ps-active`]: {
                backgroundColor: "#F9F9FF",
                color: "#6c63ff",
              },
            },
          }}
        >
          <MenuItem
            className="mb-[20px]"
            icon={<Bars3Icon className="h-8 w-8 text-default_blue" />}
            onClick={() => {
              collapseSidebar();
              toggleSidebar();
            }}
          >
            <img className="ml-2" id="logo" src={logo} alt="..." />
          </MenuItem>

          {navLinks.map(renderMenuItems)}

          <MenuItem
            className="text-text_primary absolute bottom-0"
            style={{ fontSize: "16px", marginTop: "5rem" }}
            icon={
              <ChatBubbleLeftEllipsisIcon
                className="h-5 w-5 pb-1"
                style={{ color: "#F5B303" }}
              />
            }
          >
            Need Help?
          </MenuItem>
        </Menu>
      </div>
    </Sidebar>
  );
};

export default ProSideBar;
