import { useEffect, useState } from "react";
import PageLayout from "../../components/Layouts/page-layout";
import { IoMdMore } from "react-icons/io";
import useTableSyle from "../../components/shared/useTableSyle";
import DataTable from "react-data-table-component";
import type { MenuProps } from "antd";
import { Dropdown, Input, Spin } from "antd";
import BaseService from "../../helpers/baseServices";
import { CiEdit, CiSearch } from "react-icons/ci";
import moment from "moment";
import ToolTipComponent from "../../components/shared/ToolTipComponent";
import { FiRefreshCcw } from "react-icons/fi";
import {
  NumberSort,
  dateCompareFunction,
  dynamicStringCompare,
} from "../../components/shared/TableSortFunctions";
import AddTags from "./Modal/AddTags";
import EditTags from "./Modal/EditTags";
import { debounce } from "lodash";

const Tags = () => {
  const [open, setOpen] = useState(false);
  const [EditOpen, setEditOpen] = useState(false);
  const [customStyles] = useTableSyle();
  const [reFetch, setReFetch] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [AllGradeLevelData, setAllGradeLevelData] = useState<any>();
  const [selectedGrade, setSelectedGrade] = useState<string>();
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [total, setTotal] = useState(0);
  const [fieldName, setFieldName] = useState<string>();

  const showModal = () => {
    setOpen(true);
  };

  const showEditModal = () => {
    setEditOpen(true);
  };

  const handleMenuClick = (e: any, selected: any) => {
    console.log("what is e", e);
    if (e.key === "1") {
      setEditOpen(true);
      setSelectedGrade(selected);
    }
  };

  //fetchTags
  const fetchTags = async (lim: number, sk: number) => {
    setIsFetching(true);

    try {
      const filterConditions: Record<string, any>[] = [];

      if (fieldName) {
        filterConditions.push({ name: { $regex: fieldName, $options: "i" } });
      }

      const filter =
        filterConditions.length > 0 ? { $and: filterConditions } : {};
      const filterString = JSON.stringify(filter);

      const response: any = await BaseService.get_api(
        `/robocentre/tags?sort={"updatedAt":-1}&limit=${lim}&skip=${sk}&filter=${filterString}`
      );

      setAllGradeLevelData(response?.data?.payload || []);
      setTotal(response?.data?.total || 0);
      console.log(response?.data?.payload);
    } catch (error: any) {
      console.error(
        "Error fetching grade levels:",
        error?.response?.data?.error || error.message
      );
    } finally {
      setIsFetching(false);
    }
  };

  //on page load
  useEffect(() => {
    fetchTags(limit, skip);
  }, [reFetch, limit, skip, fieldName]);

  //table columns
  const columns: any = [
    {
      name: (
        <p className="p-2  text-lg font-semibold text-text_primary">Name</p>
      ),
      cell: (row: any) => <p className="w-full  text-md  ">{row.name}</p>,
      sortable: true,
      sortFunction: dynamicStringCompare("name"),
    },
    {
      name: (
        <p className="p-2 text-lg font-semibold text-text_primary">
          Created At
        </p>
      ),
      cell: (row: any) => (
        <p className="p-2  w-full  text-md">
          {moment(row?.createdAt).format("Do MMM YYYY - hh:mm A")}
        </p>
      ),
      sortable: true,
      sortFunction: dateCompareFunction("updated_at"),
    },
    {
      name: (
        <p className="p-2 text-lg font-semibold text-text_primary">
          Last Updated
        </p>
      ),
      cell: (row: any) => (
        <p className="p-2  w-full  text-md">
          {moment(row?.updatedAt).format("Do MMM YYYY - hh:mm A")}
        </p>
      ),
      sortable: true,
      sortFunction: dateCompareFunction("updated_at"),
    },
    {
      name: "",
      cell: (row: any) => {
        const menuItems: MenuProps["items"] = [
          {
            key: "1",
            label: <span className="text-default_ash text-sm">Edit Tags</span>,

            icon: <CiEdit size={"1.3em"} />,
            onClick: (e) => handleMenuClick(e, row),
          },
          // {
          //   key: "2",
          //   label: (
          //     <span className="text-default_ash text-sm">Delete School</span>
          //   ),
          //   icon: <MdOutlineDelete color="red" size={"1.3em"} />,
          //   onClick: (e) => handleMenuClick(e, row._id),
          // },
        ];
        return (
          <Dropdown
            placement="bottomLeft"
            menu={{ items: menuItems }}
            className="text-[14px]"
          >
            <IoMdMore size={"2em"} className="text-[#9494f5]" />
          </Dropdown>
        );
      },
      width: "5%",
    },
  ];

  // pagination start
  const handleRowChange = (a: any) => {
    setLimit(a);
  };

  const pageChange = (a: number) => {
    const newSkip = a === 1 ? 0 : (a - 1) * limit;
    setSkip(newSkip);
  };
  // pagination end

  //handle name input change
  const handlerNameChange = debounce((e: any) => {
    setFieldName(e.target.value);
  }, 500);

  return (
    <PageLayout>
      <div className="m-4 ">
        <h2 className="text-2xl my-4 text-text_primary font-semibold">Tags</h2>

        <div className="flex justify-between py-10 items-center drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] bg-white  rounded-[20px]">
          <div className="ml-4 w-[60%]  flex items-center  gap-4">
            <ToolTipComponent text={"Refresh"}>
              <div className="w-[40px] h-[40px] text-[#9494F5] hover:text-[#6666EA] hover:border-1 hover:border-[#6666EA] rounded-lg border-1 border-[#9494F5] flex items-center justify-center cursor-pointer  p-2">
                <FiRefreshCcw
                  onClick={() => setReFetch(!reFetch)}
                  size={"2em"}
                />
              </div>
            </ToolTipComponent>

            <div className="w-[50%]">
              <Input
                size="large"
                onChange={(e) => handlerNameChange(e)}
                placeholder="Search name..."
                className="rounded-[20px] border-[1px] border-[#9494f5] text-[#9494f5] "
                suffix={<CiSearch />}
              />
            </div>
          </div>

          <div className="flex gap-4 mr-4 items-center">
            <AddTags
              showModal={showModal}
              open={open}
              setOpen={setOpen}
              handleReFetch={() => setReFetch(!reFetch)}
            />
          </div>
        </div>

        <div className="bg-white p-4 drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] mt-6 rounded-[20px]">
          <DataTable
            className="mt-4"
            columns={columns}
            data={AllGradeLevelData}
            progressPending={isFetching}
            paginationServer
            progressComponent={<Spin />}
            paginationTotalRows={total}
            pagination
            pointerOnHover
            highlightOnHover
            customStyles={customStyles}
            onChangeRowsPerPage={handleRowChange}
            onChangePage={pageChange}
          />
        </div>
        {EditOpen && (
          <EditTags
            showModal={showEditModal}
            selectedGrade={selectedGrade}
            open={EditOpen}
            setOpen={setEditOpen}
            handleReFetch={() => setReFetch(!reFetch)}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default Tags;
