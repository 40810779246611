import { IoMdMore } from "react-icons/io";

const GLevelsComponent = ({
  grades,
  units,
  studentsquantity,
  courses,
}: {
  grades: string;
  units: number;
  studentsquantity: number;
  courses: number;
}) => {
  return (
    <section className="mt-2 border-1 hover:shadow-md hover:bg-super_light border-light_blue cursor-pointer text-text_primary py-2 px-4 rounded-[20px] w-[70%]">
      <div className="flex justify-between">
        <div className="flex gap-2 items-center ">
          <p className="w-[15px] h-[15px] border-1 border-light_blue rounded-full"></p>
          <p className="font-bold text-xl text-light_blue">{grades}</p>
        </div>
        <div className="flex gap-4 items-center">
          <p className="h-full w-[2px] border-1 border-light_blue rounded-lg"></p>
          <p className="flex gap-2 items-center bg-super_light rounded-lg px-3 py-1">
            <span className="text-green-300 font-bold text-xl">
              {studentsquantity}
            </span>
            <span>Students</span>
          </p>
          <p className="flex gap-2 items-center bg-super_light rounded-lg px-3 py-1">
            <span className="text-yellow-500 font-bold  text-xl">{units}</span>
            <span>Units</span>
          </p>
          <p className="flex gap-2 items-center bg-super_light rounded-lg px-3 py-1">
            <span className="text-red-300 font-bold text-xl">{courses}</span>
            <span>Courses</span>
          </p>
          <p>
            <IoMdMore size={"2em"} className="text-[#9494f5] " />
          </p>
        </div>
      </div>
    </section>
  );
};

export default GLevelsComponent;
