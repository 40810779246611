import {
  ChevronDownIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function RoboCenterSchoolMenuList({ school }: any) {
  const [openMenu, setOpenMenu] = React.useState(false);

  const triggers = {
    onMouseEnter: () => setOpenMenu(true),
    onMouseLeave: () => setOpenMenu(false),
  };
  const navigate = useNavigate();

  return (
    <>
      <Menu>
        <MenuHandler>
          <EllipsisVerticalIcon
            strokeWidth={2.5}
            className={`h-5 w-5 transition-transform cursor-pointer`}
          />
        </MenuHandler>
        <MenuList>
          <MenuItem
            onClick={() => navigate(`/robocenter-schools/${school?.code}`)}
          >
            View details
          </MenuItem>
          <MenuItem
            onClick={() => navigate(`/edit-robocenter-school/${school?.code}`)}
          >
            Edit School
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
