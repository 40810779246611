import React, { useEffect, useState } from "react";
import { Select, Space } from "antd";
import type { SelectProps } from "antd";

const options: SelectProps["options"] = [];

for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}

const MultipleSelect = ({
  onTageSelect,
  productId,
  tageSelector,
}: {
  onTageSelect: any;
  productId: any;
  tageSelector: any;
}) => {
  const [selectedTags, setSelectedTags] = useState<any>();

  useEffect(() => {
    if (tageSelector) {
      setSelectedTags(tageSelector);
    }
  }, [tageSelector]);

  const handleChange = (value: string[]) => {
    console.log(value);
    onTageSelect(value);
  };

  return (
    <Space
      style={{ width: "100%" }}
      direction="vertical"
      className="placeholder-outline_light"
    >
      <Select
        mode="multiple"
        allowClear
        className=" "
        style={{ width: "100%", height: "40px" }}
        placeholder="Product Tags"
        onChange={handleChange}
        options={options}
        value={selectedTags}
      />
    </Space>
  );
};

export default MultipleSelect;
