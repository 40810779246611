// const base = "https://mingo-organisation-test.herokuapp.com"; //test

const base = process.env.REACT_APP_MCM_URL;
const robocenter = process.env.REACT_APP_ROBOCENTER_URL;

const urls = {
  signin: `${base}/mingo-org/auth/signin`,
  verify: `${base}/mingo-org/auth/verify_account`,
  forgotPassword: `${base}/mingo-org/auth/forgetpassword`,
  resetPassword: `${base}/mingo-org/auth/resetpassword`,

  /**
   * Administration
   */
  department: `${base}/mingo-org/department`,
  professions: `${base}/mingo-org/profession`,
  roles: `${base}/mingo-org/roles`,
  roles_to_profs: `${base}/mingo-org/profession/roles`,
  users: `${base}/mingo-org/users`,
  staff: `${base}/mingo-org/staff`,
  clocking: `${base}/mingo-org/clocking`,
  upload: `${base}/mingo-org/file/upload`,

  /**Countries */
  country: `${base}/mingo-org/country`,

  /**Terms and conditions */
  terms_and_conditions: `${robocenter}/robocentre/terms-policy`,

  /**Robocenter business */
  documents: `${robocenter}/robocentre/business/init`,

  /**Courses */
  interest: `${robocenter}/robocentre/interest`,

  /**Robocenter schools */
  robocenter_schools: `${robocenter}/robocentre/robocentre-online-country`,
  robocenter_default_schools: `${robocenter}/robocentre/robocentre-online-default`,
  grade_levels: `${robocenter}/robocentre/age-level`,
  theme: `${robocenter}/robocentre/main-theme`,
  sub_theme: `${robocenter}/robocentre/sub-theme`,

  /**Product related */
  software_products: `${robocenter}/robocentre/software-product`,
  hardware_products: `${robocenter}/robocentre/product`,
  product_categories: `${robocenter}/robocentre/product-category`,
  file_upload: `${robocenter}/robocentre/file/upload?`,

  // courses
  course_landingPage_route: `${robocenter}/robocentre/course-landingpage`,
};

export default urls;
