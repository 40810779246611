import { Form, Input, message, Modal, Progress, Spin, Upload, UploadProps } from 'antd'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import DescriptionInputComponent from '../../components/descriptionInputComponent'
import LanguageSelectorComponent from '../../components/languageSelectorComponent'
import { AiOutlineVideoCameraAdd } from 'react-icons/ai'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import { ROBOCENTRE_URL } from '../../../../helpers/constants'
import { global_variables } from '../../../../helpers/globalVariables'
import { StorageBox } from '../../../../helpers/storage'
import { useForm } from 'antd/es/form/Form'
import BaseService from '../../../../helpers/baseServices'
import { PiCloudArrowUpLight } from 'react-icons/pi'
import { GenerateCode } from '../../../../functions/generateID'


interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
}


const AddNewContentModal = ({ isOpened, handleClose, handleReFetch }: modalProps) => {

    const { id } = useParams()
    const [form] = useForm()
    const [isBusy, setIsBusy] = useState(false)
    const [uploadingVideo, setUploadingVideo] = useState(false)
    const [progress, setProgress] = useState(0)
    const [videoURL, setVideoURL] = useState("")
    const [siteContentUrl, setSiteContentUrl] = useState("")
    const [uploadingContent, setUploadingContent] = useState(false)
    const [contentProgress, setContentProgress] = useState(0)



    //handleVideoUpload for each course info
    const handleVideoUpload: UploadProps = {
        action: "",
        async onChange(info) {
            const file = info.file.originFileObj;
            if (file) {
                setUploadingVideo(true);

                try {
                    const fileFormat = file.type.split("/")[1];
                    const uid = file.uid;

                    const formData = new FormData();
                    formData.append("file", file, "[PROXY]");

                    const vidRes: any = await axios.post(
                        `${ROBOCENTRE_URL}${global_variables.file_upload}?imagename=${uid}&storage=test&mimeType=${fileFormat}`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                "x-access-token": StorageBox.getAccessToken()
                            },
                            onUploadProgress: (progressEvent: any) => {
                                // Calculate the upload progress
                                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                setProgress(percentCompleted);
                            },
                        }
                    );

                    console.log("vidRes", vidRes);
                    setVideoURL(vidRes?.data?.url);
                    setUploadingVideo(false);

                } catch (error: any) {
                    console.log("file uploading", error);
                    message.error(error?.response?.data?.error || "Error occurred while uploading video. Please retry");
                    setUploadingVideo(false);
                }
            }
        },
    };

    //handleVideoUpload for site content
    const handleSiteContentUpload: UploadProps = {
        action: "",
        async onChange(info) {
            const file = info.file.originFileObj;
            if (file) {
                setUploadingContent(true);

                try {
                    const fileFormat = file.type.split("/")[1];
                    const uid = file.uid;

                    const formData = new FormData();
                    formData.append("file", file, "[PROXY]");

                    const vidRes: any = await axios.post(
                        `${ROBOCENTRE_URL}${global_variables.file_upload}?imagename=${uid}&storage=test&mimeType=${fileFormat}`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                "x-access-token": StorageBox.getAccessToken()
                            },
                            onUploadProgress: (progressEvent: any) => {
                                // Calculate the upload progress
                                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                setContentProgress(percentCompleted);
                            },
                        }
                    );

                    console.log("vidRes", vidRes);
                    setSiteContentUrl(vidRes?.data?.url);
                    setUploadingContent(false);

                } catch (error: any) {
                    console.log("file uploading", error);
                    message.error(error?.response?.data?.error || "Error occurred while site content. Please retry");
                    setUploadingContent(false);
                }
            }
        },
    };



    //handleOnSubmit
    const handleOnSubmit = () => {
        form.validateFields().then((formData) => {

            let cooked_data = {
                "courseId": id,
                "title": formData?.title,
                "educationalContent": [
                    {
                        "url": videoURL,
                        "languageId": formData?.languageId?.value,
                        "siteContent": siteContentUrl,
                    }
                ],
                "contentType": "VIDEO",
                "description": formData.description,
                "codec": GenerateCode(10, formData.title),
                "unit": []
            }
            console.log("cooked_data:", cooked_data)

            setIsBusy(true)

            BaseService.post_api(global_variables.course_content_route, cooked_data)
                .then((response) => {
                    console.log("contentRes:", response?.data)
                    message.success("New course content was added successfully!")
                    setIsBusy(false)
                    handleReFetch()
                    handleClose()
                }).catch((error: any) => {
                    console.log("contentErr:", error)
                    setIsBusy(false)
                    message.error(error?.response?.data?.error || "An unknown error occured. Please retry")
                })

        }).catch((err) => {
            console.log("____formErrors:", err)
        })
    }



    return (
        <>
            <Modal title="Create Course Content" width={800} open={isOpened} onCancel={handleClose} footer={null} keyboard={false} maskClosable={false} closeIcon={!isBusy}>
                <hr />
                <Spin spinning={isBusy}>
                    <Form
                        className='w-full'
                        form={form}
                    >

                        <div className="w-full mt-[20px]">
                            {/* title */}
                            <Form.Item label="Content Title" name="title" rules={[{ required: true, message: 'Please add content title' }]}>
                                <Input className='w-full h-[40px] bg-transparent' placeholder='Add unit title here' />
                            </Form.Item>
                        </div>

                        {/* video */}
                        <div className="w-full gap-[20px]">
                            <Form.Item label="Preview Video" name="educationalContent" rules={[{ required: true, message: 'Please upload the video for this content' }]}>
                                <div className='w-full'>
                                    <Upload.Dragger
                                        showUploadList={false}
                                        {...handleVideoUpload}
                                        className="w-full relative"
                                        style={{
                                            borderColor: "#E0E0FB",
                                            border: "none",
                                            color: "#5353C2",
                                        }}
                                    >
                                        <div className="w-full h-[160px] rounded-[5px] border-[2px] border-[#CCCCF8] border-dashed flex gap-2 items-center">
                                            {
                                                uploadingVideo ?
                                                    <div className='w-full px-[40px]'>
                                                        <Progress percent={progress} />
                                                        <p className='animate-pulse'>Uploading...</p>
                                                    </div>
                                                    :
                                                    videoURL ?
                                                        <div className='w-full flex gap-[20px]'>
                                                            <video className='h-[160px] w-full object-contain rounded-[5px]' src={videoURL} controls={true} />

                                                            {
                                                                videoURL &&
                                                                <div>
                                                                    <PencilSquareIcon className='h-[20px] w-[20px] cursor-pointer text-default_blue' />
                                                                </div>
                                                            }
                                                        </div>
                                                        :
                                                        <div className="w-full flex flex-col items-center justify-center text-text_primary">
                                                            <AiOutlineVideoCameraAdd className='h-[50px] w-[50px]' />
                                                            <p>Click to upload preview video</p>
                                                        </div>
                                            }
                                        </div>
                                    </Upload.Dragger>
                                </div>
                            </Form.Item>
                        </div>

                        {/* siteContent */}
                        <div className="w-full gap-[20px]">
                            <Form.Item label="Site Content" name="siteContent" rules={[{ required: true, message: 'Please upload the site content' }]}>
                                <div className='w-full'>
                                    <Upload.Dragger
                                        showUploadList={false}
                                        {...handleSiteContentUpload}
                                        className="w-full relative"
                                        style={{
                                            borderColor: "#E0E0FB",
                                            border: "none",
                                            color: "#5353C2",
                                        }}
                                    >
                                        <div className="w-full h-[160px] rounded-[5px] border-[2px] border-[#CCCCF8] border-dashed flex gap-2 items-center">
                                            {
                                                uploadingContent ?
                                                    <div className='w-full px-[40px]'>
                                                        <Progress percent={progress} />
                                                        <p className='animate-pulse'>Uploading...</p>
                                                    </div>
                                                    :
                                                    siteContentUrl ?
                                                        <div className='w-full flex gap-[20px]'>
                                                            <video className='h-[160px] w-full object-contain rounded-[5px]' src={siteContentUrl} controls={true} />

                                                            {
                                                                siteContentUrl &&
                                                                <div>
                                                                    <PencilSquareIcon className='h-[20px] w-[20px] cursor-pointer text-default_blue' />
                                                                </div>
                                                            }
                                                        </div>
                                                        :
                                                        <div className="w-full flex flex-col items-center justify-center text-text_primary">
                                                            <AiOutlineVideoCameraAdd className='h-[50px] w-[50px]' />
                                                            <p>Click to upload site content</p>
                                                        </div>
                                            }
                                        </div>
                                    </Upload.Dragger>
                                </div>
                            </Form.Item>
                        </div>

                        <Form.Item label="Media Language" name="languageId" rules={[{ required: true, message: 'Please select media language' }]}>
                            <LanguageSelectorComponent isMulti={false} onChange={(data) => console.log(data)} />
                        </Form.Item>

                        {/* description */}
                        <Form.Item className='mt-[20px]' label="Description" name="description" rules={[{ required: true, message: 'Please enter content desciption' }]}>
                            <DescriptionInputComponent onChange={(e) => console.log(e)} placeholder='Enter content description here...' />
                        </Form.Item>

                        <div className="flex justify-end">
                            <Form.Item>
                                <button className='px-[20px] py-[8px] rounded-[30px] bg-default_blue text-white flex gap-[5px] items-center' onClick={() => handleOnSubmit()}>
                                    <PiCloudArrowUpLight className='h-[20px] w-[20px]' />
                                    <p>Publish Content</p>
                                </button>
                            </Form.Item>
                        </div>
                    </Form>
                </Spin>
            </Modal >
        </>
    )
}

export default AddNewContentModal
