import { GoTasklist } from "react-icons/go";
import PerformanceChart from "../../../../dashboard/performanceChart";

const Overview = () => {
  return (
    <div className="flex flex-col mt-6   ">
      <section>
        <div className=" mb-6 gap-[14px] grid grid-cols-3 mt-3">
          <div>
            <div className="rounded-2xl py-[16px] px-[24px] bg-white border-1  border-[#CCCCF8]">
              <div className="p-3 text-text_primary">
                <p className="text-[16px] font-[400]">Total Sale </p>

                <div className="pt-2 flex justify-between">
                  <p className="text-[32px] font-[700]">346K</p>
                  <div className="h-10 w-10 rounded-[5px] flex justify-center items-center bg-secondary_green bg-opacity-[10%] text-secondary_green">
                    <GoTasklist className="h-5 w-5" />
                  </div>
                </div>

                <div className="mt-2.5">
                  <p className="text-[14px] font-[400]">
                    <span className="text-secondary_green mr-2">
                      8.5% &uarr;
                    </span>{" "}
                    than last week{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="rounded-2xl py-[16px] px-[24px] bg-white border-1 border-[#CCCCF8]">
              <div className="p-3 text-text_primary">
                <p className="text-[16px] font-[400]">Orders</p>

                <div className="pt-2 flex justify-between">
                  <p className="text-[32px] font-[700]">124K</p>
                  <div className="h-10 w-10 rounded-[5px] flex justify-center items-center bg-default_yellow bg-opacity-[10%] text-default_yellow">
                    <GoTasklist className="h-5 w-5" />
                  </div>
                </div>

                <div className="mt-2.5">
                  <p className="text-[14px] font-[400]">
                    <span className="text-red-500 mr-2">2.5% &darr;</span> than
                    last week
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="rounded-2xl py-[16px] px-[24px] bg-white border-1  border-[#CCCCF8]">
              <div className="p-3 text-text_primary">
                <p className="text-[16px] font-[400]">Canceled</p>

                <div className="pt-2 flex justify-between">
                  <p className="text-[32px] font-[700]">122</p>
                  <div className="h-10 w-10 rounded-[5px] flex justify-center items-center bg-red-200 bg-opacity-[10%] text-[#FF808B]">
                    <GoTasklist className="h-5 w-5" />
                  </div>
                </div>

                <div className="mt-2.5">
                  <p className="text-[14px] font-[400]">
                    <span className="text-secondary_green mr-2">
                      8.5% &uarr;
                    </span>{" "}
                    than last week
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <PerformanceChart />
      </section>
    </div>
  );
};

export default Overview;
