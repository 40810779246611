import React, { useState } from "react";
import { MainPerformanceChart } from "./mainChart";

const PerformanceChart = () => {
  const [activeTab, setActiveTab] = useState(2);
  const tabList = ["This Year", "This Month", "This Week"];

  return (
    <>
      <div className="w-full bg-white shadow-sm p-[30px] rounded-2xl flex gap-[50px]">
        <div className="w-[70%] text-text_deep">
          <p className="text-[21px] font-[600] mb-3">Sales Performance</p>

          <MainPerformanceChart />
        </div>

        {/* right */}
        <div className="w-[30%] text-text_deep relative">
          <div className="flex gap-5 items-center">
            {tabList.map((tab, i) => (
              <div
                key={i}
                className={`${
                  activeTab === i ? "border-b-[2px] border-b-default_blue" : ""
                } w-fit px-1 cursor-pointer`}
                onClick={() => setActiveTab(i)}
              >
                <p className="text-[16px] font-[400]">{tab}</p>
              </div>
            ))}
          </div>

          <div className="absolute bottom-0 w-full">
            <div className="mt-[90px]">
              <div className="flex gap-[8px] items-center">
                <div className="h-[8px] w-[8px] rounded-full bg-default_yellow"></div>
                <p className="text-[16px] font-[400]">Courses</p>
              </div>
              <div className="flex gap-[8px] items-center mt-[10px]">
                <div className="h-[8px] w-[8px] rounded-full bg-default_blue"></div>
                <p className="text-[16px] font-[400]">Product</p>
              </div>
              <div className="flex gap-[8px] items-center mt-[10px]">
                <div className="h-[8px] w-[8px] rounded-full bg-default_green"></div>
                <p className="text-[16px] font-[400]">Hardware</p>
              </div>
            </div>

            <div className="mt-[40px]">
              <button className="border-[1px] border-default_blue rounded-[5px] w-full h-[40px] shadow-sm bg-default_blue bg-opacity-[10%]">
                <p className="text-[16px] font-[400]">view all</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerformanceChart;
