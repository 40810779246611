import React, { useEffect, useState } from 'react'
import { Form, Input, Checkbox, Radio, Button, DatePicker, Steps, Modal, Spin, message } from 'antd';
import GradeLevelSelectorComponent from '../../components/gradeLevelSelectorComponent';
import DescriptionInputComponent from '../../components/descriptionInputComponent';
import BaseService from '../../../../helpers/baseServices';
import dayjs from 'dayjs';

const { Step } = Steps;
const { RangePicker } = DatePicker;


interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
    curriculumData: any
}


const EditRoboCurriculum = ({ isOpened, handleClose, handleReFetch, curriculumData }: modalProps) => {

    const [form] = Form.useForm();
    const [isBusy, setIsBusy] = useState(false)
    const [current, setCurrent] = useState(0);
    const [tempGradeLevels, setTempGradeLevels] = useState([])
    const [stepOneValues, setStepOneValues] = useState<any>(null)
    const [isSeasonal, setIsSeasonal] = useState<boolean>(false)

    //onload
    useEffect(() => {
        if (curriculumData?.seasonal) {
            setIsSeasonal(true)
            console.log(curriculumData)
        }
    }, [curriculumData])

    const onNext = () => {
        form.validateFields().then((values) => {
            console.log('Form Values: ', values);
            setStepOneValues(values)
            setCurrent(current + 1);
        }).catch((error) => {
            console.log('Form error: ', error)
        })
    };

    const onPrev = () => {
        setCurrent(current - 1);
    };


    //initialValues
    const initialValues = {
        "title": curriculumData?.title,
        "description": curriculumData?.description,
        "mainCuriculum": curriculumData?.mainCuriculum,
        "gradeLevels": curriculumData?.gradeLevelsData.map((item: any) => (
            { label: item?.name, value: item?._id }
        )),
        "seasonal": [
            curriculumData?.seasonal?.startCuriculum ? dayjs(curriculumData?.seasonal?.startCuriculum) : null,
            curriculumData?.seasonal?.endCuriculum ? dayjs(curriculumData?.seasonal?.endCuriculum) : null
        ],
        "site": curriculumData?.site,
        "pricing": curriculumData?.pricing?.type,
        "durationInDays": curriculumData?.durationInDays
    }



    //on form submit
    const onFinish = (values: any) => {
        const formValues = { ...stepOneValues, ...values }

        let cooked_data: any = {
            "_id": curriculumData?._id,
            "title": formValues?.title,
            "description": formValues?.description,
            "mainCuriculum": formValues?.mainCuriculum,
            "gradeLevels": formValues?.gradeLevels.map((item: any) => (item?.value)),
            "seasonal": {
                "startCuriculum": isSeasonal ? new Date(formValues?.seasonal?.[0]).toISOString() : '',
                "endCuriculum": isSeasonal ? new Date(formValues?.seasonal?.[1]).toISOString() : ''
            },
            "site": formValues?.site,
            "pricing": {
                "type": formValues?.pricing
            },
            "durationInDays": parseInt(formValues?.durationInDays)
        }

        if (!isSeasonal) {
            delete cooked_data?.seasonal
        }
        setCurrent(0)

        console.log('Form Values: ', cooked_data);

        setIsBusy(true)

        BaseService.put_api(`/robocentre/robo-curriculum`, cooked_data)
            .then((response: any) => {
                console.log("newRoboRes:", response?.data)
                message.success("Robo curriculum was updated successfully!")
                setIsBusy(false)
                form.resetFields()
                handleClose()
                handleReFetch()
            }).catch((error: any) => {
                console.log("newRoboErr:", error)
                message.error(error?.response?.data?.error || "An unknown error occured. Please try again")
                setIsBusy(false)
            })

    };

    //form steps
    const steps = [
        {
            title: 'Basic Details',
            content: (
                <Form form={form} layout="vertical" initialValues={initialValues} onFinish={onNext}>
                    <Form.Item
                        name="mainCuriculum"
                        valuePropName="checked"
                    >
                        <Checkbox defaultChecked={isSeasonal}>
                            <p>Main Curriculum</p>
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[{ required: true, message: 'Please input the title!' }]}
                    >
                        <Input className='h-[40px]' placeholder="Enter title" />
                    </Form.Item>

                    <Form.Item label="Description" name="description">
                        <DescriptionInputComponent onChange={(e) => console.log(e)} />
                    </Form.Item>

                    <Form.Item
                        label="Grade Levels"
                        name="gradeLevels"
                        rules={[{ required: true, message: 'Please select grade levels!' }]}
                    >
                        <GradeLevelSelectorComponent defaultValue={initialValues?.gradeLevels} onChange={(selected) => setTempGradeLevels(selected)} />
                    </Form.Item>
                </Form>
            ),
        },
        {
            title: 'Other Details',
            content: (
                <Form form={form} layout="vertical" initialValues={initialValues} onFinish={onFinish}>

                    <Checkbox className='my-[20px]' defaultChecked={isSeasonal} onChange={(e: any) => setIsSeasonal(e.target.checked)}>
                        <p>Seasonal</p>
                    </Checkbox>

                    {
                        isSeasonal &&
                        <Form.Item label="Curriculum Duration">
                            <Form.Item
                                name="seasonal"
                                rules={[{ required: true, message: 'Please select the curriculum start and end date!' }]}
                            >
                                <RangePicker className='h-[40px] w-full' defaultValue={[initialValues?.seasonal?.[0], initialValues?.seasonal?.[1]]} placeholder={['Start Date', 'End Date']} />
                            </Form.Item>
                        </Form.Item>
                    }

                    <Form.Item label="Site" name="site" rules={[{ required: true, message: 'Please select the site!' }]}>
                        <Radio.Group>
                            <Radio value="HYBRID">Hybrid</Radio>
                            <Radio value="FAST_PACED">Fast Paced</Radio>
                            <Radio value="SITE">On Site</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="Pricing Type"
                        name="pricing"
                        rules={[{ required: true, message: 'Please select the pricing type!' }]}
                    >
                        <Radio.Group>
                            <Radio value="CUMULATIVE">Cumulative</Radio>
                            <Radio value="DISCOUNT">Discount</Radio>
                            <Radio value="OVERRIDE">Override</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="Duration in Days"
                        name="durationInDays"
                        rules={[{ required: true, message: 'Please enter duration in days!' }]}
                    >
                        <Input className='h-[40px]' type="number" placeholder="Duration in days" />
                    </Form.Item>
                </Form>
            ),
        },
    ];



    return (
        <>
            <Modal title="Edit Robocurriculum" open={isOpened} onCancel={handleClose} footer={null} keyboard={false} maskClosable={false} closeIcon={!isBusy}>
                <hr className='mb-[20px]' />
                <div style={{ maxWidth: '600px', margin: '0 auto' }}>
                    <Spin spinning={isBusy}>
                        <Steps current={current}>
                            {steps.map((item) => (
                                <Step key={item.title} title={item.title} />
                            ))}
                        </Steps>

                        <div style={{ marginTop: 24 }}>{steps[current].content}</div>

                        <div className='mt-[30px] flex justify-end'>
                            {current > 0 && (
                                <Button style={{ margin: '0 8px' }} onClick={() => onPrev()}>
                                    Previous
                                </Button>
                            )}
                            {current < steps.length - 1 && (
                                <Button type="primary" onClick={onNext} style={{ backgroundColor: '#6666EA' }}>
                                    Next
                                </Button>
                            )}
                            {current === steps.length - 1 && (
                                <Button type="primary" onClick={() => form.submit()} style={{ backgroundColor: '#6666EA' }}>
                                    Submit
                                </Button>
                            )}
                        </div>
                    </Spin>
                </div>
            </Modal>
        </>
    )
}

export default EditRoboCurriculum
