import React, { useEffect, useState } from 'react'
import { PencilSquareIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import { Collapse, Spin, Tooltip } from 'antd'
import { global_variables } from '../../../../helpers/globalVariables'
import BaseService from '../../../../helpers/baseServices'
import { useParams } from 'react-router-dom'
import AddNewContentModal from './addNewContentModal'
import EditCourseContentModal from './editCourseContentModal'
import ContentTopicsComponent from '../contentTopicsComponent'




const CourseContentInformation = () => {

    const { id } = useParams()
    const [openNewModal, setOpenNewModal] = useState(false)
    const [isFetching, setIsFetching] = useState(true)
    const [reFetch, setReFetch] = useState(false)
    const [courseUnitInfo, setCourseUnitInfo] = useState([])
    const [openEditUnitModal, setOpenEditUnitModal] = useState(false)
    const [selectedUnit, setSelectedUnit] = useState<any>(null)

    //onload
    useEffect(() => {
        if (id) {
            const getCourseContent = async () => {
                setIsFetching(true)

                try {
                    const response = await BaseService.get_api(`${global_variables.course_content_route}/${id}`)
                    console.log("contentFetchRes:", response?.data)
                    setCourseUnitInfo(response?.data?.payload)
                    setIsFetching(false)
                } catch (error) {
                    console.log("contentFetchErr:", error)
                }
            }

            getCourseContent()
        }
    }, [id, reFetch])


    //handleOpenEdit
    const handleOpenEdit = (data: any) => {
        setSelectedUnit(data)
        setOpenEditUnitModal(true)
    }


    return (
        <>
            <div className="w-full bg-white rounded-[20px] shadow-sm px-[30px] py-[24px]">
                <Spin spinning={isFetching}>
                    <div className="flex justify-between items-center">
                        <p className='text-[21px] font-[600] text-text_primary'>Course Content</p>

                        <button className='px-[20px] py-[8px] rounded-[30px] bg-default_blue text-white flex gap-[5px] items-center' onClick={() => setOpenNewModal(true)}>
                            <PlusCircleIcon className='h-[20px] w-[20px]' />
                            <p>Add New Content</p>
                        </button>
                    </div>
                    <hr className='my-[10px] border-[#CCCCF8]' />

                    <div className="w-full mt-[30px]">
                        {/* unit component */}
                        {
                            courseUnitInfo.map((singleUnit: any, unitIndex: number) => (
                                <div key={unitIndex} className="w-full border-[1px] border-[#CCCCF8] bg-[#F9F9FF] rounded-[20px] text-text_primary mb-[20px]">
                                    <Collapse
                                        className="w-full rounded-[20px]"
                                        ghost
                                        defaultActiveKey={unitIndex}
                                        expandIconPosition="end"
                                        items={[
                                            {
                                                label: <div className='w-full flex justify-between items-center'>
                                                    <p className='text-[18px] font-[600] text-text_primary'>Unit {unitIndex + 1}: {singleUnit?.title}</p>

                                                    <div>
                                                        <Tooltip title="Edit Unit">
                                                            <PencilSquareIcon className='h-[20px] w-[20px] text-default_blue' onClick={() => handleOpenEdit(singleUnit)} />
                                                        </Tooltip>
                                                    </div>
                                                </div>,
                                                children: <div className='w-full border-t-[1px] border-t-[#CCCCF8]'>
                                                    {/* content */}
                                                    <div className="mt-[10px]">
                                                        <p className='text-[14px] font-[600] text-text_deep'>Codec: {singleUnit?.codec}</p>
                                                    </div>
                                                    <div className='mt-[20px]'>
                                                        <div className='flex justify-between items-center'>
                                                            <p className='text-[16px] font-[500] text-text_primary'>Content Preview Video</p>

                                                            <p className='text-[16px] font-[500] text-text_primary'>Language: English</p>
                                                        </div>
                                                        <div className='w-full mt-[10px] border-[1px] border-[#CCCCF8] bg-[#F9F9FF] border-dashed rounded-[10px] text-text_primary h-[160px]'>
                                                            <video className='h-[160px] w-full rounded-[10px] object-contain' src={singleUnit?.educationalContent?.[0]?.url} controls />
                                                        </div>
                                                    </div>

                                                    {/* description */}
                                                    <div className="mt-[20px]">
                                                        <p className='text-[16px] font-[500] text-text_primary'>Description</p>

                                                        <div className='mt-[10px] text-text_primary' dangerouslySetInnerHTML={{ __html: singleUnit?.description }} />
                                                    </div>

                                                    <div className='w-full mt-[20px] border-t-[1px] border-t-[#CCCCF8]'>
                                                        <ContentTopicsComponent unitInfo={singleUnit} handleReFetch={() => setReFetch(!reFetch)} />
                                                    </div>
                                                </div>
                                            }
                                        ]}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </Spin>
            </div>


            {/* open new content modal */}
            {
                openNewModal && <AddNewContentModal isOpened={openNewModal} handleClose={() => setOpenNewModal(false)} handleReFetch={() => setReFetch(!reFetch)} />
            }


            {/* open edit content modal */}
            {
                openEditUnitModal && <EditCourseContentModal isOpened={openEditUnitModal} selectedUnit={selectedUnit} handleClose={() => setOpenEditUnitModal(false)} handleReFetch={() => setReFetch(!reFetch)} />
            }
        </>
    )
}

export default CourseContentInformation
