import React, { useMemo, useState } from "react";
import { Upload } from "antd";
import BaseService from "../../helpers/baseServices";
import { global_variables } from "../../helpers/globalVariables";
import basicElectronics_img from "../../images/bigImg.png";
import arrow_3 from "../../images/arrow_3.png";
import { TrashIcon } from "@heroicons/react/24/outline";
import type { UploadProps } from "antd";
type ExtendedUploadProps = UploadProps & { action: string };

const SmallUploadOne = ({
  MegaState,
  id,
  prodName,
  dispatchFunction,
}: {
  id: any;
  MegaState: any;
  prodName: any;
  dispatchFunction: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState("");
  const [Filename, setFilename] = useState("");

  const props: ExtendedUploadProps = useMemo(
    () => ({
      action: "",
      async onChange(info) {
        const file = info.file.originFileObj;
        if (file) {
          setFilename(file.name);
          setLoading(true);
          try {
            // const mimeType = file.type;
            // const fileFormat = mimeType.split("/")[1];
            // const imgRes: any = await BaseService.file_upload(
            //   `${global_variables.file_upload}?imagename=${prodName}${randomNumber}&storage=test&mimeType=${fileFormat}`,
            //   file
            // );
            const fileFormat = file.type.split("/")[1];
            const uid = file.uid;

            const imgRes: any = await BaseService.file_upload(
              `${global_variables.file_upload}?imagename=${uid}&storage=test&mimeType=${fileFormat}`,
              file
            );
            console.log("imgRes", imgRes);

            setImg(imgRes?.data?.url);
            dispatchFunction({
              type: "littleOnes",
              payload: { url: imgRes?.data?.url, filename: file.name, id },
            });
          } catch (error: any) {
            info.file.status = "error";
            console.error("imgUploadErr:", error.message);
          } finally {
            setLoading(false);
          }
        }
      },
    }),
    [dispatchFunction, prodName]
  );

  const handleDeleteImg = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const updatedImageUrls = MegaState.imageUrls.filter(
      (item: any) => item.filename !== Filename
    );

    const updatedItems = MegaState.items.map((item: any) => {
      if (item.id === id) {
        return { ...item, url: "", filename: "" };
      }
      return item;
    });
    dispatchFunction({
      type: "editStated",
      payload: updatedImageUrls,
    });

    dispatchFunction({
      type: "updateItems",
      payload: updatedItems,
    });
    setImg("");
    setFilename("");
  };

  const currentItem = MegaState.items.find((item: any) => item.id === id);

  return (
    <div className="h-[60px]">
      <Upload.Dragger
        showUploadList={false}
        {...props}
        className="w-full h-full bg-super_light relative"
      >
        {loading ? (
          <p className="bg-green-300 rounded-lg">Uploading...</p>
        ) : currentItem && currentItem.url ? (
          <div className="flex gap-2 items-center">
            <div
              className=" p-2 rounded-sm"
              style={{
                backgroundImage: `url(${arrow_3})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                padding: "2px",
                width: "20px",
                height: "20px",
              }}
            ></div>
            <div className="w-[30px] h-[30px]">
              <img
                src={currentItem.url}
                alt="uploaded"
                style={{ width: "100%", height: "100%", borderRadius: "5px" }}
              />
            </div>
            <div className="flex items-center gap-2 mr-auto">
              {Filename || currentItem.filename}
            </div>
            <button
              className="hover:text-red-900 text-red-500"
              onClick={handleDeleteImg}
            >
              <TrashIcon className="h-5 w-5" />
            </button>
          </div>
        ) : (
          <div className="flex gap-2 items-center">
            <div className="w-6 h-6 ml-3">
              <img
                src={basicElectronics_img}
                alt="default"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="flex items-center gap-2 mr-auto">
              <p>Drag and drop image here or</p>
              <p className="text-outline_deep">click to browse</p>
            </div>
          </div>
        )}
      </Upload.Dragger>
    </div>
  );
};

export default SmallUploadOne;
