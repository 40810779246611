export const dynamicStringCompare =
  (key: string | number) =>
  (rowA: { [x: string]: string }, rowB: { [x: string]: string }) => {
    const a = rowA[key] ? rowA[key].toLowerCase() : "";
    const b = rowB[key] ? rowB[key].toLowerCase() : "";

    if (a > b) {
      return 1;
    }

    if (a < b) {
      return -1;
    }

    return 0;
  };
export const dateCompareFunction = (key: string) => (rowA: any, rowB: any) => {
  const a = new Date(rowA[key]);
  const b = new Date(rowB[key]);
  const timestampA = a.getTime();
  const timestampB = b.getTime();

  if (timestampA > timestampB) {
    return 1;
  }

  if (timestampA < timestampB) {
    return -1;
  }

  return 0;
};

export const NumberSort =
  (key: string) =>
  (rowA: { [x: string]: number }, rowB: { [x: string]: number }) => {
    const a = rowA[key];
    const b = rowB[key];

    if (a > b) {
      return 1;
    }

    if (a < b) {
      return -1;
    }

    return 0;
  };
