import React from "react";
import { Tooltip } from "antd";

const ToolTipComponent = ({
  children,
  text,
}: {
  children: any;
  text: string;
}) => (
  <Tooltip color="#a3a3f2" placement="bottom" title={text}>
    {children}
  </Tooltip>
);

export default ToolTipComponent;
