import { useState, useEffect } from "react";
import {
  Cog6ToothIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import urls from "../../../mixins/base.url";
import moment from "moment";
import baseService from "../../../mixins/baseServices";
import CreateHardwareProduct from "../modals/createHardwareProduct";
import useTableSyle from "../../../components/shared/useTableSyle";
import EditHardwareProduct from "../modals/EditHardwareProduct";
import ToolTipComponent from "../../../components/shared/ToolTipComponent";
import { Input, Spin } from "antd";
import Skeleton from "react-loading-skeleton";
import {
  NumberSort,
  dateCompareFunction,
  dynamicStringCompare,
} from "../../../components/shared/TableSortFunctions";
import ProductCategorySelector from "../../../components/shared/productCategorySelector";
import { FiRefreshCcw } from "react-icons/fi";
import { CiSearch } from "react-icons/ci";
import { MdKeyboardArrowUp } from "react-icons/md";
import { debounce } from "lodash";

const ArrowUpIcon: React.FC<{ isSorted: boolean }> = ({ isSorted }) => (
  <span style={{ marginLeft: 8 }}>
    {isSorted ? "▲" : <MdKeyboardArrowUp />}
  </span>
);

const HardwareProducts = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [hardwareProducts, setHardwareProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [reFetch, setReFetch] = useState(false);
  const [productId, setProductId] = useState<string>();
  const [customStyles] = useTableSyle();
  const [extraDispatch, setextraDispatch] = useState<any>();
  const [imageLoading, setImageLoading] = useState<any>({});
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [prodCat, setProdCat] = useState<any>(null);
  const [editCategoryName, setEditCategoryName] = useState<string>();
  const [fieldValue, setFieldValue] = useState<string>();

  const navigate = useNavigate();

  const [openCreate, setOpenCreate] = useState(false);
  const [EditProductModal, setEditProductModal] = useState(false);

  const handleOpenCreate = (_id?: string) => {
    setOpenCreate(true);
  };
  const handleOpenEditProduct = (_id?: string) => {
    setEditProductModal(true);
  };
  const handleCloseCreate = () => {
    setOpenCreate(false);
    setEditProductModal(false);
  };

  const DispatchImagesHandler = (dispatchFun: any) => {
    setextraDispatch(dispatchFun);
  };

  useEffect(() => {
    fetchHardwareProducts(limit, skip);
  }, [reFetch, limit, skip, prodCat, fieldValue]);

  const fetchHardwareProducts = async (lim: number, sk: number) => {
    setIsFetching(true);

    try {
      const filterConditions: any[] = [];

      if (prodCat) {
        filterConditions.push({ categoryId: { $regex: prodCat } });
      }

      if (fieldValue) {
        filterConditions.push({ name: { $regex: fieldValue, $options: "i" } });
      }

      const filter =
        filterConditions.length > 0 ? { $and: filterConditions } : {};
      const filterString = JSON.stringify(filter);
      const response: any = await baseService.get(
        `${urls.hardware_products}?sort={"updatedAt":-1}&limit=${lim}&skip=${sk}&filter=${filterString}`
      );
      setHardwareProducts(response?.data?.payload);
      console.log("All product", response?.data?.payload);
      setTotal(response?.data?.total);
      setIsFetching(false);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleImageLoad = (id: string) => {
    setImageLoading((prev: any) => ({ ...prev, [id]: false }));
  };
  const handleRowChange = (a: any) => {
    setLimit(a);
  };

  const pageChange = (a: number) => {
    const newSkip = a === 1 ? 0 : (a - 1) * limit;
    setSkip(newSkip);
  };

  const handlerChange = debounce((e: any) => {
    setFieldValue(e);
  }, 500);

  const columns: any = [
    {
      name: <p className="font-semibold text-[14px]">Name</p>,
      sortable: true,
      width: "25%",
      selector: (row: any) => {
        return (
          <section className="flex items-center gap-3 w-full">
            <div className="flex items-center gap-2">
              {imageLoading[row._id] ? (
                <Skeleton circle={true} height={36} width={36} />
              ) : (
                <img
                  src={row?.images[0]}
                  alt="img"
                  className="w-[36px] rounded-xl object-cover"
                  onLoad={() => handleImageLoad(row._id)}
                  style={{ display: imageLoading[row._id] ? "none" : "block" }}
                />
              )}
            </div>
            <div>{row?.name}</div>
          </section>
        );
      },
      sortFunction: dynamicStringCompare("name"),
    },
    {
      name: <p className="font-semibold text-[14px]">Weight</p>,
      sortable: true,
      cell: (row: any) => (
        <ToolTipComponent text={"Click to view Product Details "}>
          {row?.weight + row?.weightUnit}
        </ToolTipComponent>
      ),
      sortFunction: NumberSort("weightUnit"),
    },
    {
      name: <p className="font-semibold text-[14px]">Price</p>,
      sortable: true,
      cell: (row: any) => {
        const currency = row.countryData.country === "Ghana" ? "GHC" : "USD";
        return <p>{`${currency} ${row?.originalPrice}`}</p>;
      },
      sortFunction: NumberSort("originalPrice"),
    },
    {
      name: <p className="font-semibold text-[14px]">Category</p>,
      cell: (row: any) => {
        const getBackgroundColorWithOpacity = (
          color: string,
          opacity: number
        ) => {
          let rgbaColor = color;
          if (color.startsWith("#")) {
            const bigint = parseInt(color.slice(1), 16);
            const r = (bigint >> 16) & 255;
            const g = (bigint >> 8) & 255;
            const b = bigint & 255;
            rgbaColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;
          }
          return rgbaColor;
        };

        return (
          <div
            className="flex items-center justify-center px-3 rounded-[20px] text-md text-center"
            style={{
              background: getBackgroundColorWithOpacity(
                row?.categoryData?.color,
                0.1
              ),
              color: row?.categoryData?.color,
            }}
          >
            {row.categoryData.name}
          </div>
        );
      },
    },
    {
      name: <p className="font-semibold text-[14px]">Date Created</p>,
      sortable: true,
      cell: (row: any) => moment(row?.createdAt).format("Do MMM YYYY"),
      sortFunction: dateCompareFunction("createdAt"),
    },
    {
      name: <p className="font-semibold text-[14px]">Last Updated</p>,
      sortable: true,
      cell: (row: any) => moment(row?.updatedAt).format("Do MMM YYYY"),
      sortFunction: dateCompareFunction("updatedAt"),
    },
    {
      name: <p className="font-semibold text-[14px]"></p>,
      width: "6%",
      selector: (row: any) => (
        <div className="flex gap-2 text-default_ash">
          <div
            className="text-sm"
            onClick={() => {
              setProductId(row._id);
              handleOpenEditProduct(row._id);
            }}
          >
            <PencilSquareIcon className="w-5 h-5" />
          </div>
          <div className="text-sm hover:text-text_danger">
            <TrashIcon className="w-5 h-5" />
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    console.log("prodCat", prodCat);
    console.log("fieldValue", fieldValue);
  }, [prodCat, hardwareProducts]);
  return (
    <section className="drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)]">
      <div className="flex justify-between py-10 items-center bg-white  rounded-[20px]">
        <div className="ml-4 w-[60%]   flex items-center  gap-4">
          <ToolTipComponent text={"Refresh"}>
            <div className="w-[40px] h-[40px] text-[#9494F5] hover:text-[#6666EA] hover:border-1 hover:border-[#6666EA] rounded-lg border-1 border-[#9494F5] flex items-center justify-center cursor-pointer  p-2">
              <FiRefreshCcw onClick={() => setReFetch(!reFetch)} size={"2em"} />
            </div>
          </ToolTipComponent>

          <div className="w-[50%] ">
            <ProductCategorySelector
              productId={productId}
              editCategoryName={editCategoryName}
              onChange={(selected) => {
                setProdCat(selected);
              }}
            />
          </div>
          <div className="w-[50%]">
            <Input
              size="large"
              value={fieldValue}
              onChange={(e) => handlerChange(e.target.value)}
              placeholder="Search Name..."
              className="rounded-[20px] border-[1px] border-[#9494f5] text-[#9494f5] "
              suffix={<CiSearch />}
            />
          </div>
        </div>
        <div className="flex gap-4 mr-4 items-center">
          <Link
            className="border-[1px] border-default_blue py-2 px-3 text-default_blue rounded-full text-sm flex items-center gap-2"
            to="/product-category"
            style={{ padding: "5px 18px" }}
          >
            <Cog6ToothIcon className="w-5 h-5 " />
            Product Categories
          </Link>
          <Link
            className="text-text_white text-center py-2 px-3 bg-default_blue hover:text-text_white hover:bg-default_blue rounded-full w-fit"
            to="#"
            onClick={() => handleOpenCreate()}
          >
            + Add Product
          </Link>
        </div>
      </div>
      <div className="px-2 pt-2 pb-4 bg-white shadow-sm rounded-[20px] min-h-[400px] mt-3">
        <DataTable
          className="mt-4"
          columns={columns}
          data={hardwareProducts}
          paginationServer
          progressPending={isFetching}
          progressComponent={<Spin />}
          onChangeRowsPerPage={handleRowChange}
          onChangePage={pageChange}
          pagination
          paginationTotalRows={total}
          pointerOnHover
          highlightOnHover
          customStyles={customStyles}
          onRowClicked={(selected: any) =>
            navigate(`/products/${selected._id}`)
          }
        />
      </div>

      {/* create product modal */}
      {openCreate && (
        <CreateHardwareProduct
          DispatchImages={DispatchImagesHandler}
          isOpened={openCreate}
          productId={productId}
          handleClose={handleCloseCreate}
          handleReFetch={() => setReFetch(!reFetch)}
        />
      )}
      {EditProductModal && (
        <EditHardwareProduct
          DispatchImages={DispatchImagesHandler}
          isOpened={EditProductModal}
          productId={productId}
          handleClose={handleCloseCreate}
          handleReFetch={() => setReFetch(!reFetch)}
        />
      )}
    </section>
  );
};

export default HardwareProducts;
