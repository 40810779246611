export const sampleData = [
  {
    _id: "GX866hdjFFFG1",
    customer_name: "Juicy",
    signup_date: "2022-10-27",
    total_sales: "9,200",
    views: "5,124",
    total_orders: "124",
    picture: "https://m.media-amazon.com/images/I/71dhm1tcD+L._AC_SL1500_.jpg",
    status: "Active",
  },
  // {
  //   _id: "GX866hdjFFFG2",
  //   customer_name: "Juicy",
  //   signup_date: "2022-10-27",
  //   total_sales: "9,200",
  //   views: "5,124",
  //   total_orders: "124",
  //   picture: "https://m.media-amazon.com/images/I/71dhm1tcD+L._AC_SL1500_.jpg",
  //   status: "Active",
  // },
  // {
  //   _id: "GX866hdjFFFG3",
  //   customer_name: "Juicy",
  //   signup_date: "2022-10-27",
  //   total_sales: "9,200",
  //   views: "5,124",
  //   total_orders: "124",
  //   picture: "https://m.media-amazon.com/images/I/71dhm1tcD+L._AC_SL1500_.jpg",
  //   status: "Active",
  // },
  // {
  //   _id: "GX866hdjFFFG4",
  //   customer_name: "Juicy",
  //   signup_date: "2022-10-27",
  //   total_sales: "9,200",
  //   views: "5,124",
  //   total_orders: "124",
  //   picture: "https://m.media-amazon.com/images/I/71dhm1tcD+L._AC_SL1500_.jpg",
  //   status: "Active",
  // },
  // {
  //   _id: "GX866hdjFFFG5",
  //   customer_name: "Juicy",
  //   signup_date: "2022-10-27",
  //   total_sales: "9,200",
  //   views: "5,124",
  //   total_orders: "124",
  //   picture: "https://m.media-amazon.com/images/I/71dhm1tcD+L._AC_SL1500_.jpg",
  //   status: "Active",
  // },
];
