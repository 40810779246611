import { Form, Input, message, Select, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useEffect, useState } from 'react'
import { PiCloudArrowUpLight } from 'react-icons/pi'
import { CreateSingleTest, UpdateSingleTest } from '../../functions/allTestsFunctions'
import { UpdateSingleCourse } from '../../functions/updateSingleCourse'
import { useParams } from 'react-router-dom'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import DescriptionInputComponent from '../../components/descriptionInputComponent'
import { useMainContext } from '../../../../contexts/mainContext'



const AssessmentSettings = () => {

    const [form] = useForm()
    const { id } = useParams()
    const { fetchingAssessment, assessmentInfo, setAssessmentInfo, setSingleCourseInfo } = useMainContext()
    const [isBusy, setIsBusy] = useState(false)
    const [quizObjectives, setQuizObjectives] = useState<string[]>(['']);
    const [quizReqs, setQuizReqs] = useState<string[]>(['']);

    //onload
    useEffect(() => {

        if (assessmentInfo) {
            setQuizObjectives(assessmentInfo?.objectives || [''])
            setQuizReqs(assessmentInfo?.requirements || [''])
        }

    }, [assessmentInfo])

    //handleCreateQuiz
    const handleCreateQuiz = (data: any) => {
        console.log("creating....:", data)
        setIsBusy(true)

        CreateSingleTest(data)
            .then((response: any) => {
                console.log("newTesRes:", response?.data)
                setAssessmentInfo(response?.data?.payload)
                UpdateSingleCourse({ "_id": id, "assesmentTestId": response?.data?.payload?._id }).then((res: any) => {
                    console.log("updateCourseQuiz:", res?.data)
                    setSingleCourseInfo(res?.data?.payload)
                    message.success("Assessment quiz was created successfully!")
                    setIsBusy(false)
                }).catch((error: any) => {
                    console.log("updateCourseErr:", error)
                    message.error(error?.response?.data?.error || "Error1 Could not attach quiz to course")
                    setIsBusy(false)
                })
            }).catch((error: any) => {
                console.log("newTestErr:", error)
                message.error(error?.response?.data?.error || "Error while creating quiz. Please try again")
                setIsBusy(false)
            })
    }

    //handleUpdateQuiz
    const handleUpdateQuiz = (data: any) => {
        console.log("upating...:", data)
        setIsBusy(true)

        UpdateSingleTest(data)
            .then((response: any) => {
                console.log("updateTestRes:", response?.data)
                setAssessmentInfo(response?.data?.payload)
                message.success("Assessment quiz was updated successfully!")
                setIsBusy(false)
            }).catch((error: any) => {
                console.log("updateTestErr:", error)
                message.error(error?.response?.data?.error || "Error while updating quiz. Please try again")
                setIsBusy(false)
            })
    }

    //handleOnSubmit
    const handleOnSubmit = () => {
        form.validateFields().then((formData) => {
            console.log("validationData:", formData)

            //prep create data
            let createInfo = {
                "title": formData?.title,
                "type": formData?.type,
                "description": formData?.description,
                "requirements": formData?.requirements || [],
                "objectives": formData?.objectives || [],
                "numberOfQuestions": parseInt(formData?.numberOfQuestions),
                "duration": parseInt(formData?.duration) * 60
            }

            //prep uodate data
            let updateInfo = {
                "_id": assessmentInfo?._id,
                "title": formData?.title,
                "type": formData?.type,
                "description": formData?.description,
                "requirements": formData?.requirements || [],
                "objectives": formData?.objectives || [],
                "numberOfQuestions": parseInt(formData?.numberOfQuestions),
                "duration": parseInt(formData?.duration) * 60
            }

            if (assessmentInfo) {
                handleUpdateQuiz(updateInfo)
            } else {
                handleCreateQuiz(createInfo)
            }


        }).catch((err) => {
            console.log("____formErrors:", err)
        })
    }


    //initial form values
    const initialValues = {
        title: assessmentInfo?.title,
        type: assessmentInfo?.type,
        description: assessmentInfo?.description,
        numberOfQuestions: assessmentInfo?.numberOfQuestions,
        duration: assessmentInfo?.duration / 60,
        objectives: assessmentInfo?.objectives?.map((item: any) => (
            item
        )),
        requirements: assessmentInfo?.requirements?.map((item: any) => (
            item
        )),
    }

    // handleQuizObjective: Adds a new input field for objectives
    const handleQuizObjective = () => {
        setQuizObjectives([...quizObjectives, '']);
    };
    // handleObjectiveChange: Updates the specific objective in the array
    const handleObjectiveChange = (value: string, index: number) => {
        const newObjectives = [...quizObjectives];
        newObjectives[index] = value;
        setQuizObjectives(newObjectives);
    }
    // handleRemoveObjective: Removes an objective field
    const handleRemoveObjective = (index: number) => {
        const newObjectives = quizObjectives.filter((_, i) => i !== index);
        setQuizObjectives(newObjectives);
    };


    // handleQuizReqs: Adds a new input field for requirements
    const handleQuizReqs = () => {
        setQuizReqs([...quizReqs, '']);
    };
    // handleReqsChange: Updates the specific requirement in the array
    const handleReqsChange = (value: string, index: number) => {
        const newObjectives = [...quizReqs];
        newObjectives[index] = value;
        setQuizReqs(newObjectives);
    }
    // handleRemoveReq: Removes a requirement field
    const handleRemoveReq = (index: number) => {
        const newObjectives = quizReqs.filter((_, i) => i !== index);
        setQuizReqs(newObjectives);
    };


    return (
        <>
            <div className="w-full bg-white rounded-[20px] shadow-sm px-[30px] py-[24px]">
                <div className="flex justify-between items-center">
                    <p className='text-[21px] font-[600] text-text_primary'>Settings</p>

                    <button className='px-[20px] py-[8px] rounded-[30px] bg-default_blue text-white flex gap-[5px] items-center' onClick={() => handleOnSubmit()} disabled={isBusy || fetchingAssessment}>
                        <PiCloudArrowUpLight className='h-[20px] w-[20px]' />
                        <p>Save Changes</p>
                    </button>
                </div>
                <hr className='my-[10px] border-[#CCCCF8]' />

                <div className="w-full">
                    <Spin spinning={isBusy || fetchingAssessment}>
                        <Form
                            className='w-full'
                            form={form}
                            onFinish={handleOnSubmit}
                            initialValues={initialValues}
                        >
                            <div className="grid grid-cols-5">
                                <div className="col-span-1">
                                    <p><span className='text-red-700'>*</span> Course Title</p>
                                </div>

                                <div className="col-span-4">
                                    <Form.Item name="title" rules={[{ required: true, message: 'Please add quiz title' }]}>
                                        <Input className='h-[40px]' placeholder='Ex: Introductory Assessment' />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="grid grid-cols-5 mt-[10px]">
                                <div className="col-span-1">
                                    <p><span className='text-red-700'>*</span> Quiz Type</p>
                                </div>

                                <div className="col-span-4">
                                    <Form.Item name="type" rules={[{ required: true, message: 'Please select a quiz type' }]}>
                                        <Select className='h-[40px]' placeholder='Choose quiz type'
                                            options={[
                                                { label: 'Multiple Choice', value: 'MULTI_CHOICE' }
                                            ]}
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="grid grid-cols-5 mt-[10px]">
                                <div className="col-span-1">
                                    <p><span className='text-red-700'>*</span> Description</p>
                                </div>

                                <div className="col-span-4">
                                    <Form.Item name="description" rules={[{ required: true, message: 'Please add quiz description' }]}>
                                        {/* <Input.TextArea style={{ height: 100 }} placeholder='Describe the assessment or quiz' /> */}
                                        <DescriptionInputComponent onChange={(e) => console.log(e)} placeholder='Describe the assessment or quiz' />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="grid grid-cols-5 mt-[10px]">
                                <div className="col-span-1">
                                    <p><span className='text-red-700'>*</span> Number of questions</p>
                                </div>

                                <div className="col-span-4">
                                    <Form.Item name="numberOfQuestions" rules={[{ required: true, message: 'Add total number of questions' }]}>
                                        <Input type='number' min={1} className='h-[40px]' placeholder='Ex: 20' />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="grid grid-cols-5 mt-[10px]">
                                <div className="col-span-1">
                                    <p><span className='text-red-700'>*</span> Duration (Minutes)</p>
                                </div>

                                <div className="col-span-4">
                                    <Form.Item name="duration" rules={[{ required: true, message: 'Add total duration for the quiz' }]}>
                                        <Input type='number' min={1} className='h-[40px]' placeholder='Ex: 60' />
                                    </Form.Item>
                                </div>
                            </div>


                            {/* objectives */}
                            <div className="mt-[24px]">
                                <p className='text-[16px] font-[600] text-text_primary'>
                                    Quiz / Assessment Objectives
                                </p>
                                <p className='text-[16px] font-[400] text-text_primary mt-[15px]'>
                                    You must enter at least 1 learning objectives or outcomes that learners can expect to achieve
                                    after completing the quiz.
                                </p>

                                <div className="mt-[20px]">
                                    {quizObjectives.map((objective, index) => (
                                        <div key={index} className="flex items-center gap-[10px]">
                                            <Form.Item
                                                name={['objectives', index]}
                                                rules={[{ required: true, message: 'Please enter a quiz objective' }]}
                                                className="flex-1"
                                            >
                                                <Input
                                                    className="h-[40px]"
                                                    value={objective}
                                                    onChange={(e) => handleObjectiveChange(e.target.value, index)}
                                                />
                                            </Form.Item>
                                            {quizObjectives.length > 1 && (
                                                <TrashIcon
                                                    className="h-[20px] w-[20px] text-red-500 cursor-pointer"
                                                    onClick={() => handleRemoveObjective(index)}
                                                />
                                            )}
                                        </div>
                                    ))}
                                    <button type="button" onClick={handleQuizObjective} className='flex gap-[4px] items-center text-default_blue'>
                                        <PlusIcon className='h-[20px] w-[20px]' />
                                        <p>Add New</p>
                                    </button>
                                </div>
                            </div>


                            {/* objectives */}
                            <div className="mt-[24px]">
                                <p className='text-[16px] font-[600] text-text_primary'>
                                    Quiz / Assessment Requirements
                                </p>
                                <p className='text-[16px] font-[400] text-text_primary mt-[15px]'>
                                    You must enter at least 1 requirement for the quiz.
                                </p>

                                <div className="mt-[20px]">
                                    {quizReqs.map((req, index) => (
                                        <div key={index} className="flex items-center gap-[10px]">
                                            <Form.Item
                                                name={['requirements', index]}
                                                rules={[{ required: true, message: 'Please enter a quiz requirement' }]}
                                                className="flex-1"
                                            >
                                                <Input
                                                    className="h-[40px]"
                                                    value={req}
                                                    onChange={(e) => handleReqsChange(e.target.value, index)}
                                                />
                                            </Form.Item>
                                            {quizReqs.length > 1 && (
                                                <TrashIcon
                                                    className="h-[20px] w-[20px] text-red-500 cursor-pointer"
                                                    onClick={() => handleRemoveReq(index)}
                                                />
                                            )}
                                        </div>
                                    ))}
                                    <button type="button" onClick={handleQuizReqs} className='flex gap-[4px] items-center text-default_blue'>
                                        <PlusIcon className='h-[20px] w-[20px]' />
                                        <p>Add New</p>
                                    </button>
                                </div>
                            </div>

                        </Form>
                    </Spin>
                </div>
            </div>
        </>
    )
}

export default AssessmentSettings
