import React, { useState } from "react";
import PageLayout from "../../../components/Layouts/page-layout";
import CustomButtomRounded from "../../../components/shared/CustomButtomRounded";
import { FaArrowLeft } from "react-icons/fa6";
import CustomButtomSquare from "../../../components/shared/CustomButtomSquare";
import { RxAvatar } from "react-icons/rx";
import { IoEyeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Profile from "../../administration/sections/profile";
import Order from "../../customers/Tabs/order/order";
import otp from "../../../images/customer_img.png";
import OrderDetailsTable from "./OrderDetailsTable";
import MoreDetails from "./PersonalDetails";
import SelectComponent from "../../../components/shared/SelectComponent";

const OrderDetails = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const { id } = useParams();

  const handlerClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    navigate("/customers");
  };
  const tabNames = ["Profile", "Order", "Cart", "Wishlist"];
  const theTabs = [<Profile />, <Order />];
  console.log("activeTab", activeTab);
  return (
    <>
      <PageLayout>
        <div className="m-4 gap-8 ">
          <section>
            <div className="flex items-center gap-4 ml-6 mb-14   ">
              <CustomButtomRounded
                icon={<FaArrowLeft />}
                value={"Back"}
                onClick={handlerClick}
              />
              <h2 className="text-4xl text-text_primary font-semibold">
                IN332942
              </h2>
            </div>
            <div className="flex gap-4 relative w-full">
              <section className="w-[80%] ">
                <OrderDetailsTable />
                <MoreDetails />
              </section>
              <section className=" text-text_primary  flex flex-col gap-2 w-[25%]  sticky top-[100px] h-[600px] z-[999]  ">
                <div className=" rounded-[20px] bg-white shadow-md p-4 mb-2">
                  <div className="flex justify-between mb-4">
                    <p className="font-bold">Status</p>
                    <p className="bg-blue-50 rounded-3xl px-6 py-1">
                      <span className="text-[#118AE6]">En Route</span>
                    </p>
                  </div>
                  <div className="w-full">
                    <SelectComponent
                      options={[
                        { value: "lucy", label: "Update Status" },
                        { value: "lucy", label: "Update Status" },
                      ]}
                    />
                  </div>
                </div>
                <div className="rounded-[16px] bg-white shadow-md flex flex-col gap-4 p-4 mb-2">
                  <p className="text-[20px] font-semibold">Order Summery</p>
                  <div className="w-full flex flex-col gap-3">
                    <p className="flex justify-between ">
                      <span>Order Created</span> <span>23/03/2023</span>
                    </p>
                    <p className="flex justify-between ">
                      <span>Order Time </span> <span>2:46 PM</span>
                    </p>
                    <p className="flex justify-between ">
                      <span>Subtotal</span> <span>400</span>
                    </p>
                  </div>
                </div>
                <div className=" rounded-[16px] bg-white shadow-md p-2 px-3 mb-2">
                  <div>
                    <p className="flex text-[20px] font-semibold justify-between ">
                      <span>Total</span> <span>$3,298</span>
                    </p>
                  </div>
                </div>
                <div className=" rounded-[16px] bg-white flex flex-col gap-4 shadow-md p-4 mb-2">
                  <p className="text-[20px] font-semibold">Delivery Location</p>
                  <p>Lorem ipsum dolor sit amet, consetetur </p>
                </div>
              </section>{" "}
            </div>
          </section>
        </div>
      </PageLayout>
    </>
  );
};

export default OrderDetails;
