import React, { useState } from 'react'
import PageLayout from '../../../../components/Layouts/page-layout'
import { Container } from 'react-bootstrap'
import StaffAttendanceTable from './attendanceTable';

const StaffAttendance = () => {

    const [activeTab, setActiveTab] = useState(0);

    // tabComponents
    const tabComponents = [
        <StaffAttendanceTable />
    ]

    return (
        <>
            <PageLayout>
                <Container className="mt-4 mb-4">
                    <h2 className="text-2xl font-semibold text-text_primary">Staff Attendance</h2>

                    {/* tabs */}
                    <div className="flex space-x-3 my-3 ">
                        {["Attendance Log", "Performance"].map((tab, i) => {
                            return (
                                <span
                                    key={i}
                                    className={`${i === activeTab
                                        ? "bg-gradient-to-r from-light_blue to-default_blue text-white font-medium rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600"
                                        : "bg-white text-gray-700 font-medium rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-400"
                                        } focus:ring-opacity-50 shadow-md cursor-pointer px-3 py-2`}
                                    onClick={() => setActiveTab(i)}
                                >
                                    {tab}
                                </span>
                            );
                        })}
                    </div>

                    {/* attendance table */}
                    <div>
                        {
                            tabComponents[activeTab]
                        }
                    </div>

                </Container>
            </PageLayout>
        </>
    )
}

export default StaffAttendance
