import React, { useEffect, useState } from 'react'
import { useForm } from 'antd/es/form/Form'
import { Form, Input, message, Spin } from 'antd'
import { PiCloudArrowUpLight } from 'react-icons/pi';
import { UpdateSingleCourse } from '../../functions/updateSingleCourse';
import GradeLevelSelectorComponent from '../../components/gradeLevelSelectorComponent';
import { useParams } from 'react-router-dom';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useMainContext } from '../../../../contexts/mainContext';



const EditTargetLearners = () => {

    const [form] = useForm()
    const { id } = useParams()
    const { singleCourseInfo, setSingleCourseInfo } = useMainContext()
    const [isBusy, setIsBusy] = useState(false)
    const [courseObjectives, setCourseObjectives] = useState<string[]>(['']);
    const [coursePereqs, setCoursePereqs] = useState<string[]>(['']);

    //onload
    useEffect(() => {

        if (singleCourseInfo) {
            console.log('courseInformation:', singleCourseInfo)
            setCourseObjectives(singleCourseInfo?.objectives || [''])
            setCoursePereqs(singleCourseInfo?.prerequisites || [''])
        }

    }, [singleCourseInfo])

    //initialValues
    const initialValues = {
        gradeLevel: singleCourseInfo?.gradesData?.map((item: any) => (
            { label: item?.name, value: item?._id }
        )),
        objectives: singleCourseInfo?.objectives?.map((item: any) => (
            item
        )),
        prerequisites: singleCourseInfo?.prerequisites?.map((item: any) => (
            item
        )),
    }

    //handleOnSubmit
    const handleOnSubmit = () => {
        form.validateFields().then((formData) => {
            console.log("validationData:", formData)

            //prep data
            let updateInfo = {
                "_id": id,
                "gradeIds": formData?.gradeLevel.map((lang: any) => (lang?.value)),
                "objectives": formData?.objectives || [],
                "prerequisites": formData?.prerequisites || [],
            }

            console.log("data_to_send:", updateInfo)
            setIsBusy(true)

            UpdateSingleCourse(updateInfo)
                .then((response: any) => {
                    console.log("newCourseRes:", response?.data)
                    setSingleCourseInfo(response?.data?.payload)
                    message.success("Course information was upadted successfully!")
                    setIsBusy(false)
                }).catch((error: any) => {
                    console.log("newCourseErr:", error)
                    message.error(error?.response?.data?.error || "Error while updating course. Please try again")
                    setIsBusy(false)
                })
        }).catch((err) => {
            console.log("____formErrors:", err)
        })
    }

    // handleAddObjective: Adds a new input field for objectives
    const handleAddObjective = () => {
        setCourseObjectives([...courseObjectives, '']);
    };
    // handleObjectiveChange: Updates the specific objective in the array
    const handleObjectiveChange = (value: string, index: number) => {
        const newObjectives = [...courseObjectives];
        newObjectives[index] = value;
        setCourseObjectives(newObjectives);
    }
    // handleRemoveObjective: Removes an objective field
    const handleRemoveObjective = (index: number) => {
        const newObjectives = courseObjectives.filter((_, i) => i !== index);
        setCourseObjectives(newObjectives);
    };


    // handleAddPrerequisites: Adds a new input field for perequisites
    const handleAddPrerequisites = () => {
        setCoursePereqs([...coursePereqs, '']);
    };
    // handlePerequisitesChange: Updates the specific perequisites in the array
    const handlePerequisitesChange = (value: string, index: number) => {
        const newObjectives = [...coursePereqs];
        newObjectives[index] = value;
        setCoursePereqs(newObjectives);
    }
    // handleRemovePerequisites: Removes an perequisites field
    const handleRemovePerequisites = (index: number) => {
        const newObjectives = coursePereqs.filter((_, i) => i !== index);
        setCoursePereqs(newObjectives);
    };


    return (
        <>
            <div className="w-full bg-white rounded-[20px] shadow-sm px-[30px] py-[24px]">
                <div className="flex justify-between items-center">
                    <p className='text-[21px] font-[600] text-text_primary'>Target Learners</p>

                    <button className='px-[20px] py-[8px] rounded-[30px] bg-default_blue text-white flex gap-[5px] items-center' onClick={() => handleOnSubmit()} disabled={isBusy}>
                        <PiCloudArrowUpLight className='h-[20px] w-[20px]' />
                        <p>Save Changes</p>
                    </button>
                </div>
                <hr className='my-[10px] border-[#CCCCF8]' />

                <div className="w-full">
                    <Spin spinning={isBusy}>
                        <Form
                            className='w-full'
                            form={form}
                            onFinish={handleOnSubmit}
                            initialValues={initialValues}
                        >
                            <div>
                                <p className='font-[400] text-text_primary'>
                                    The following descriptions will be publicly visible on your Course Landing Page and will have a direct impact on your course performance. These descriptions will help learners decide if your course is right for them.
                                </p>
                            </div>

                            <div className="mt-[24px]">
                                <p className='text-[16px] font-[600] text-text_primary'>
                                    What will students learn in the course?
                                </p>
                                <p className='text-[16px] font-[400] text-text_primary mt-[15px]'>
                                    You must enter at least 1 learning objectives or outcomes that learners can expect to achieve
                                    after completing your course.
                                </p>

                                {/* objectives */}
                                <div className="mt-[20px]">
                                    {courseObjectives.map((objective, index) => (
                                        <div key={index} className="flex items-center gap-[10px]">
                                            <Form.Item
                                                name={['objectives', index]}
                                                rules={[{ required: true, message: 'Please enter a learning objective' }]}
                                                className="flex-1"
                                            >
                                                <Input
                                                    className="h-[40px]"
                                                    value={objective}
                                                    onChange={(e) => handleObjectiveChange(e.target.value, index)}
                                                />
                                            </Form.Item>
                                            {courseObjectives.length > 1 && (
                                                <TrashIcon
                                                    className="h-[20px] w-[20px] text-red-500 cursor-pointer"
                                                    onClick={() => handleRemoveObjective(index)}
                                                />
                                            )}
                                        </div>
                                    ))}
                                    <button type="button" onClick={handleAddObjective} className='flex gap-[4px] items-center text-default_blue'>
                                        <PlusIcon className='h-[20px] w-[20px]' />
                                        <p>Add more</p>
                                    </button>
                                </div>
                            </div>


                            <div className="mt-[24px]">
                                <p className='text-[16px] font-[600] text-text_primary'>
                                    What are the requirements or prerequisites for taking your course?
                                </p>
                                <p className='text-[16px] font-[400] text-text_primary mt-[15px]'>
                                    List the required skills, experience, tools or equipment learners should have prior to taking your course. If there are no requirements, use this space as an opportunity to lower the barrier for beginners.
                                </p>

                                {/* prerequisites */}
                                <div className="mt-[20px]">
                                    {coursePereqs.map((pereq, index) => (
                                        <div key={index} className="flex items-center gap-[10px]">
                                            <Form.Item
                                                name={['prerequisites', index]}
                                                rules={[{ required: true, message: 'Please enter a learning prerequisite' }]}
                                                className="flex-1"
                                            >
                                                <Input
                                                    className="h-[40px]"
                                                    value={pereq}
                                                    onChange={(e) => handlePerequisitesChange(e.target.value, index)}
                                                />
                                            </Form.Item>
                                            {coursePereqs.length > 1 && (
                                                <TrashIcon
                                                    className="h-[20px] w-[20px] text-red-500 cursor-pointer"
                                                    onClick={() => handleRemovePerequisites(index)}
                                                />
                                            )}
                                        </div>
                                    ))}
                                    <button type="button" onClick={handleAddPrerequisites} className='flex gap-[4px] items-center text-default_blue'>
                                        <PlusIcon className='h-[20px] w-[20px]' />
                                        <p>Add more</p>
                                    </button>
                                </div>
                            </div>

                            {/* grade Levels */}
                            <div className="w-full mt-[24px]">
                                <p className='text-[16px] font-[600] text-text_primary'>
                                    Specify Grade Levels
                                </p>

                                <Form.Item className='mt-[15px]' name="gradeLevel" rules={[{ required: true, message: 'Please select at least one grade level' }]}>
                                    <GradeLevelSelectorComponent defaultValue={initialValues?.gradeLevel} />
                                </Form.Item>
                            </div>
                        </Form>

                        <div className='w-full flex justify-end mt-[10px]'>
                            <button className='px-[20px] py-[8px] rounded-[30px] bg-default_blue text-white flex gap-[5px] items-center' onClick={() => handleOnSubmit()} disabled={isBusy}>
                                <PiCloudArrowUpLight className='h-[20px] w-[20px]' />
                                <p>Save Changes</p>
                            </button>
                        </div>
                    </Spin>
                </div>
            </div>
        </>
    )
}

export default EditTargetLearners
