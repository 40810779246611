import { Link } from "react-router-dom";
import { test_coupons } from "../../TestData/TestCoupons";
import { useState } from "react";
import CreateNewCoupon from "./CreateNewCoupon";
import SearchInput from "../../components/shared/SearchInput";
import SelectComponent from "../../components/shared/SelectComponent";
import CouponCardComponent from "./CouponCardComponents";
import { Pagination } from "@mui/material";
const CouponsTable = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const handleOpenCreate = () => {
    setOpenCreateModal(true);
  };
  const handleCloseCreate = () => {
    setOpenCreateModal(false);
  };

  return (
    <>
      <div className="flex justify-between py-10  items-center bg-white shadow-sm  rounded-[20px]">
        <div className="ml-4  w-[40%] flex items-center  justify-between gap-4">
          <div>
            <SearchInput />
          </div>
          <div className="h-[40px] w-[200px]">
            <SelectComponent
              options={[{ value: "Filter discount", label: "Filter discount" }]}
            />
          </div>
        </div>

        <div className="flex gap-4  mr-4 items-center">
          <Link
            className="text-text_white text-center py-2 px-3 bg-default_blue hover:text-text_white hover:bg-default_blue rounded-full w-fit "
            to="#"
            onClick={() => handleOpenCreate()}
          >
            + create coupon
          </Link>
        </div>
      </div>

      <div className="w-full grid grid-cols-2  gap-4 mt-8 ">
        <CouponCardComponent textColor={"FFC400"} />
        <CouponCardComponent textColor={"FFC400"} />
        <CouponCardComponent textColor={"FFC400"} />
        <CouponCardComponent textColor={"FFC400"} />
      </div>
      <div className="flex justify-end mt-6">
        <Pagination
          count={20}
          // onChange={handleChangePagination}
          sx={{
            "& .MuiPaginationItem-root": {
              color: "#6666EA",
              "&.Mui-selected": {
                color: "#FFFFFF",
                backgroundColor: "#6666EA",
              },
              "&:hover": {
                backgroundColor: "#6666EA88",
              },
            },
          }}
        />
      </div>
      {openCreateModal && (
        <CreateNewCoupon
          isOpened={openCreateModal}
          handleCloseModal={handleCloseCreate}
        />
      )}
    </>
  );
};

export default CouponsTable;
