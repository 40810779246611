import React from "react";
import { Line } from "react-chartjs-2";

const data = {
    labels: ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
    datasets: [
        {
            label: "Hardware",
            data: [38, 40, 65, 55, 70, 50, 45],
            fill: false,
            borderColor: "#63C477",
        },
        {
            label: "Courses",
            data: [43, 60, 40, 75, 50, 60, 55],
            fill: false,
            borderColor: "#FFC400"
        },
        {
            label: "Products",
            data: [40, 25, 85, 45, 105, 75, 120],
            fill: true,
            backgroundColor: "#F2F2FE",
            borderColor: "#6666EA"
        }
    ],
};

const config: any = {
    responsive: true,
    tension: 0.1,
    plugins: {
        legend: {
            display: false,
            position: "right",
            marginTop: "1rem",
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
            },
        },
        y: {
            grid: {
                display: false,
            },
        },
    },
};

export const MainPerformanceChart = () => {
    return (
        <div className="App">
            <Line data={data} options={config} style={{ maxHeight: "400px" }} />
        </div>
    );
};
