import {
  ArrowDownOnSquareStackIcon,
  CheckIcon,
  PencilSquareIcon,
  PlusIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  Card,
  CardBody,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from "@material-tailwind/react";
import { Fragment } from "react";

export default function RobocenterSchoolTermsAndConditions({ school_id }: any) {
  return (
    <Fragment>
      <Card>
        <CardBody>
          <Typography variant="h3">Privacy and Terms</Typography>
        </CardBody>
      </Card>
    </Fragment>
  );
}

export function TermsAndConditionSide() {
  return (
    <Fragment>
      <Button
        className="flex items-center gap-2 bg-default_blue mb-2"
        size="sm"
      >
        <PencilSquareIcon strokeWidth={2} className="h-5 w-5" /> Edit Policy
      </Button>
      <div className="flex justify-start gap-2">
        <Button
          className="flex items-center gap-2 mb-2"
          variant="outlined"
          size="sm"
        >
          <ArrowDownOnSquareStackIcon strokeWidth={2} className="h-5 w-5" />{" "}
          Export
        </Button>
        <Button className="mb-2" size="sm" variant="outlined">
          <PrinterIcon strokeWidth={2} className="h-5 w-5" />
        </Button>
      </div>
    </Fragment>
  );
}
