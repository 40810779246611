export const demo_transactions = [
    {
        _id: "hufbf84i948873",
        date: "10-11-2022",
        amount: "3500",
        tracking_id: "GHH4588875",
        order_number: "MG998773944TY",
        location: "Tema Community 4",
        isCompleted: true
    },
    {
        _id: "hufbf8ww4i948873",
        date: "10-11-2022",
        amount: "300",
        tracking_id: "GHH458805",
        order_number: "MG999873944TY",
        location: "Kongo Junction",
        isCompleted: true
    }
]