import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { GoPlus } from "react-icons/go";
import { Spinner } from "@material-tailwind/react";
import { IoMdMore } from "react-icons/io";
import useTableSyle from "../../../../../../../components/shared/useTableSyle";
import CheckBox from "../../../../../../../components/shared/CheckBoxs";
import SearchInput from "../../../../../../../components/shared/SearchInput";
import CustomButtomRounded from "../../../../../../../components/shared/CustomButtomRounded";
import { customer_list } from "../../../../../../../TestData/Cusomer_List";
import { Spin } from "antd";

const StudentCurriculum = () => {
  const [customStyles] = useTableSyle();
  const navigate = useNavigate();

  //Main Table columns
  const columns = [
    {
      name: <CheckBox />,
      cell: (row: any) => <CheckBox />,
      width: "5%",
    },
    {
      name: "Customer",
      cell: (row: any) => (
        <div className="flex justify-center items-center gap-2">
          <img
            className="rounded-md"
            src={row.picture}
            alt="..."
            height="35px"
            width="35px"
          />
          <p>{row.customer_name}</p>
        </div>
      ),
    },

    {
      name: "Sales",
      cell: (row: any) => row.signup_date,
    },
    {
      name: "Orders",
      cell: (row: any) => row.total_sales,
    },
  ];

  const handlerClick = (e: any) => {};

  return (
    <div className="bg-white w-[90%] mt-10 p-4 drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[20px]">
      <div className="flex  justify-between">
        <div>
          <SearchInput />
        </div>
        <CustomButtomRounded
          value={"Add Item"}
          icon={<GoPlus size={"1.5em"} />}
          onClick={handlerClick}
        />
      </div>
      <DataTable
        className="mt-4"
        columns={columns}
        data={customer_list}
        paginationServer
        // progressPending={isFetching}
        progressComponent={<Spin />}
        pagination
        // paginationTotalRows={total}
        pointerOnHover
        highlightOnHover
        customStyles={customStyles}
        onRowClicked={(selected: any) => navigate(`/customers/${selected._id}`)}
      />
    </div>
  );
};

export default StudentCurriculum;
