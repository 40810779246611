import { useReducer, useEffect } from "react";
import { Button, Input, Modal } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { FaChild } from "react-icons/fa";
import BaseService from "../../../helpers/baseServices";
import Agegroup from "../Agegroup";
import ColorChange from "../ColorChange";
import ShowToast from "../../../components/Alerts/all_toasts";

interface CreateGradeLevelProps {
  handleReFetch: any;
  showModal: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface State {
  confirmLoading: boolean;
  size: SizeType;
  isHovered: boolean;
  activeTab: number;
  grade: {
    name: string;
    color: string;
    ageGroup: number[];
    type: string;
  };
  isFormValid: boolean;
}

const initialState: State = {
  confirmLoading: false,
  size: "large",
  isHovered: false,
  activeTab: 0,
  grade: {
    name: "",
    color: "",
    ageGroup: [20, 50],
    type: "",
  },
  isFormValid: false,
};

type Action =
  | { type: "SET_CONFIRM_LOADING"; payload: boolean }
  | { type: "SET_HOVERED"; payload: boolean }
  | { type: "SET_ACTIVE_TAB"; payload: number }
  | { type: "SET_GRADE_NAME"; payload: string }
  | { type: "SET_GRADE_COLOR"; payload: string }
  | { type: "SET_GRADE_AGEGROUPS"; payload: number[] }
  | { type: "SET_GRADE_TYPE"; payload: string }
  | { type: "RESET_GRADE" }
  | { type: "SET_FORM_VALIDITY"; payload: boolean };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_CONFIRM_LOADING":
      return { ...state, confirmLoading: action.payload };
    case "SET_HOVERED":
      return { ...state, isHovered: action.payload };
    case "SET_ACTIVE_TAB":
      return { ...state, activeTab: action.payload };
    case "SET_GRADE_NAME":
      return { ...state, grade: { ...state.grade, name: action.payload } };
    case "SET_GRADE_COLOR":
      return { ...state, grade: { ...state.grade, color: action.payload } };
    case "SET_GRADE_AGEGROUPS":
      return { ...state, grade: { ...state.grade, ageGroup: action.payload } };
    case "SET_GRADE_TYPE":
      return { ...state, grade: { ...state.grade, type: action.payload } };
    case "RESET_GRADE":
      return initialState;
    case "SET_FORM_VALIDITY":
      return { ...state, isFormValid: action.payload };
    default:
      return state;
  }
};

const CreateGradeLevel = ({
  handleReFetch,
  showModal,
  open,
  setOpen,
}: CreateGradeLevelProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const { name, color, ageGroup, type } = state.grade;
    const isFormValid =
      name.trim() !== "" &&
      color.trim() !== "" &&
      ageGroup.length > 0 &&
      type.trim() !== "";
    dispatch({ type: "SET_FORM_VALIDITY", payload: isFormValid });
  }, [state.grade]);

  const handleOk = async () => {
    dispatch({ type: "SET_CONFIRM_LOADING", payload: true });
    try {
      const payload = {
        color: state.grade.color,
        name: state.grade.name,
        type: state.grade.type,
        ageGroup: state.grade.ageGroup,
      };
      console.log("Sending payload:", payload);
      const results = await BaseService.post_api(
        "/robocentre/age-level",
        payload
      );
      handleReFetch();
      console.log("API results:", results);
      setTimeout(() => setOpen(false), 1000);
      ShowToast.success_toast("Created Successfully");
    } catch (error: any) {
      console.log("Error is ", error?.response?.data?.error);
      console.log(error);
      ShowToast.info_toast(
        error?.response?.data?.error || "something went wrong"
      );
    } finally {
      dispatch({ type: "SET_CONFIRM_LOADING", payload: false });
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleGradeTypeChange = (type: string) => {
    dispatch({ type: "SET_GRADE_TYPE", payload: type });
  };

  const handleGradeNameChange = (name: string) => {
    dispatch({ type: "SET_GRADE_NAME", payload: name });
  };

  const handleGradeColorChange = (color: string) => {
    dispatch({ type: "SET_GRADE_COLOR", payload: color });
  };

  const handleAgeGroupChange = (ageGroup: number[]) => {
    dispatch({ type: "SET_GRADE_AGEGROUPS", payload: ageGroup });
  };

  const buttonStyle = {
    backgroundColor: state.isHovered ? "#9494F5" : "#6666EA",
    color: "white",
    border: "1px solid #9494f5",
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      <Button
        type="default"
        // shape="round"
        icon={<FaChild size={"1.5em"} style={{ marginRight: 8 }} />}
        className="flex items-center rounded-[20px]"
        size={state.size}
        onClick={showModal}
        onMouseEnter={() => dispatch({ type: "SET_HOVERED", payload: true })}
        onMouseLeave={() => dispatch({ type: "SET_HOVERED", payload: false })}
        style={buttonStyle}
      >
        <div className="flex items-center justify-center">
          <p>Create Grade Levels</p>
        </div>
      </Button>

      <Modal
        width={"50%"}
        open={open}
        onOk={handleOk}
        confirmLoading={state.confirmLoading}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            backgroundColor: "#6666EA",
            borderColor: "#6666EA",
            color: "#FFFFFF",
            boxShadow: "none",
          },
          disabled: !state.isFormValid,
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "40vh" } }}
      >
        <div className=" rounded-2xl px-4">
          <p className="text-2xl mt-1 font-body text-text_primary font-bold">
            Grade Levels
          </p>
          <hr className="my-3 text-default" />
          <div className=" rounded-lg p-2">
            <section className="">
              <p className="ml-2 mb-1">Grade Type</p>
              <div className="w-full">
                <Input
                  className="h-[40px] ml-1 border-1  bg-super_light border-outline_deep  text-md font-semibold"
                  placeholder="Example : JHS"
                  value={state.grade.type}
                  onChange={(e) => handleGradeTypeChange(e.target.value)}
                />
              </div>
            </section>
            <section className="flex flex-col gap-2 mt-2">
              <section className="flex items-center gap-2 justify-between w-full">
                <div className="w-[70%]">
                  <p className="mb-2">Grade Name</p>
                  <Input
                    className="h-[40px] border-1 border-outline_deep"
                    placeholder="Add New Grade"
                    value={state.grade.name}
                    onChange={(e) => handleGradeNameChange(e.target.value)}
                  />
                </div>
                <div className="w-[150px] ">
                  <p className="mb-2">Choose Color</p>
                  <ColorChange
                    selectedColor={state.grade.color}
                    onColorChange={handleGradeColorChange}
                  />
                </div>
              </section>
              <div className="mt-2">
                <p className="mb-2">Choose Age Group</p>
                <Agegroup
                  selectedAgeGroup={state.grade.ageGroup}
                  onAgeGroupChange={handleAgeGroupChange}
                />
              </div>
            </section>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateGradeLevel;
