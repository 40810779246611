import { useEffect, useState } from "react";
import PageLayout from "../../components/Layouts/page-layout";
import { Empty, Input, Pagination } from "antd";
import BaseService from "../../helpers/baseServices";
import { CiSearch } from "react-icons/ci";
import ToolTipComponent from "../../components/shared/ToolTipComponent";
import { FiRefreshCcw } from "react-icons/fi";
import SingleCourseCard from "./components/singleCourseCard";
import type { PaginationProps } from "antd";
import SingleCourseShimmer from "./components/singleCourseShimmer";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { useMainContext } from "../../contexts/mainContext";

const CoursesPage = () => {
  const navigate = useNavigate();
  const tempData = Array.from({ length: 10 });
  const [reFetch, setReFetch] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [allCourses, setAllCourses] = useState([]);
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [fieldName, setFieldName] = useState<string>("");
  const { setSingleCourseInfo } = useMainContext();

  //fetchCourses
  const fetchCourses = async (lim: number, sk: number) => {
    setIsFetching(true);

    try {
      const response: any = await BaseService.get_api(
        `/robocentre/course?sort={"updatedAt":-1}&limit=${lim}&skip=${sk}&filter={ "title": { "$regex": ${JSON.stringify(
          fieldName
        )}, "$options": "i" } }`
      );

      setAllCourses(response?.data?.payload || []);
      setTotal(response?.data?.total || 0);
      console.log("course", response?.data?.payload);
    } catch (error: any) {
      console.error(
        "Error fetching grade levels:",
        error?.response?.data?.error || error.message
      );
    } finally {
      setIsFetching(false);
    }
  };

  //onload
  useEffect(() => {
    fetchCourses(limit, skip);

    // eslint-disable-next-line
  }, [reFetch, limit, skip, fieldName]);

  //pagination start
  const pageChange = (page: number) => {
    setCurrent(page);
    const newSkip = (page - 1) * limit;
    setSkip(newSkip);
    setLimit((prev) => prev + limit);
  };

  const onPageChange: PaginationProps["onChange"] = (page) => {
    console.log(page);
    pageChange(page);
  };
  //pagination end

  //handleInputChange
  const handleInputChange = debounce((value) => {
    setFieldName(value);
  }, 500);

  //handleCourseClick
  const handleCourseClick = (data: any) => {
    setSingleCourseInfo(data);
    navigate(`/courses/${data?._id}`);
  };

  return (
    <>
      <PageLayout>
        <div className="m-4">
          <p className="text-2xl my-4 text-text_primary font-semibold">
            Courses
          </p>

          {/* content */}
          <div className="w-full">
            {/* header */}
            <div className="flex justify-between p-[20px] items-center drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] bg-white rounded-[20px]">
              <div className="w-[60%] flex items-center gap-4">
                <ToolTipComponent text={"Refresh"}>
                  <div className="w-[40px] h-[40px] text-[#9494F5] hover:text-[#6666EA] hover:border-1 hover:border-[#6666EA] rounded-lg border-1 border-[#9494F5] flex items-center justify-center cursor-pointer  p-2">
                    <FiRefreshCcw
                      onClick={() => setReFetch(!reFetch)}
                      size={"2em"}
                    />
                  </div>
                </ToolTipComponent>

                <div className="w-[50%]">
                  <Input
                    size="large"
                    onChange={(e) => handleInputChange(e.target.value)}
                    placeholder="Search By Course Title"
                    className="rounded-[5px] border-[1px] border-[#9494f5] text-[#9494f5] "
                    suffix={<CiSearch />}
                  />
                </div>
              </div>

              <div className="flex gap-[20px] items-center">
                <button
                  className="px-[20px] h-[40px] bg-default_blue text-white rounded-[30px] shadow-sm flex gap-[4px] items-center"
                  onClick={() => navigate("/courses/curriculum")}
                >
                  <p>Curriculum & Bundler</p>
                </button>

                <button
                  className="px-[20px] h-[40px] bg-default_blue text-white rounded-[30px] shadow-sm flex gap-[4px] items-center"
                  onClick={() => navigate("/courses/create")}
                >
                  <p>+ Add New Course</p>
                </button>
              </div>
            </div>

            {/* list */}
            <div className="mt-6">
              {isFetching ? (
                tempData.map((item: any, i: number) => (
                  <SingleCourseShimmer key={i} />
                ))
              ) : allCourses.length === 0 ? (
                <div className="h-full bg-white p-4 drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[20px] flex justify-center items-center">
                  <Empty description="No courses have been created" />
                </div>
              ) : (
                allCourses.map((course: any, i: number) => (
                  <SingleCourseCard key={i} courseInfo={course} />
                ))
              )}
            </div>

            {/* pagination */}
            <div className="mt-6 flex justify-end">
              <Pagination
                total={total}
                current={current}
                onChange={onPageChange}
                pageSize={limit}
              />
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default CoursesPage;
