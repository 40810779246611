import React, { useEffect, useState } from "react";
import { Select } from "antd";
import BaseService from "../../../helpers/baseServices";

interface theProps {
  productId?: string;
  userID?: string;
  onChange: (selected: any) => void;
}

const SelectUsers = ({ onChange, userID, productId }: theProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const getAllCategories = async () => {
      setIsFetching(true);
      try {
        const response: any = await BaseService.get_api(
          `/robocentre/users-plt`
        );

        if (response?.data) {
          console.log("users all:", response.data.payload);
          const arrangedResponse = response?.data?.payload.map((item: any) => ({
            label: item?.fullName,
            value: item?._id,
          }));

          setAllUsers(arrangedResponse);
          setIsFetching(false);
        }
      } catch (error) {
        console.log("prodCatErr:", error);
      }
    };

    getAllCategories();
  }, []);

  useEffect(() => {
    if (userID) {
      setSelectedUsers(userID);
    }
  }, [userID]);

  const handleChange = (value: string) => {
    setSelectedUsers(value);
    onChange(value);
  };

  return (
    <>
      <Select
        id="category"
        value={selectedUsers}
        className="h-[40px] w-full "
        options={allUsers}
        onChange={handleChange}
        disabled={isFetching}
        loading={isFetching}
        placeholder="Select user"
        allowClear
      />
    </>
  );
};

export default SelectUsers;
