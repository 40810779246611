/* eslint-disable react/jsx-pascal-case */
import {
  BellIcon,
  ChevronDownIcon,
  Cog6ToothIcon,
  InboxArrowDownIcon,
  PowerIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import {
  Avatar,
  Button,
  Collapse,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Navbar,
  Typography,
} from "@material-tailwind/react";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import user_logo from "../../images/logo.jpeg";
import { StorageBox } from "../../helpers/storage";
import { Badge } from "antd";
import { CgMenuGridR } from "react-icons/cg";

export default function MyNavBar() {
  const [openNav, setOpenNav] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  // handle logout
  const handleLogout = () => {
    StorageBox.clearStorage();
    navigate("/signin");
  };

  // profile menu component
  const profileMenuItems = [
    {
      label: "My Profile",
      icon: UserCircleIcon,
      onClick: () => navigate("/adminprofile"),
    },
    {
      label: "Sign Out",
      icon: PowerIcon,
      onClick: () => handleLogout(),
    },
  ];

  //ProfileMenu
  function ProfileMenu() {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const closeMenu = () => setIsMenuOpen(false);

    return (
      <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
        <MenuHandler>
          <Button
            variant="text"
            color="blue-gray"
            className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto"
          >
            <Avatar
              variant="circular"
              size="sm"
              alt="candice wu"
              className="border border-blue-500 p-0.5 user-tour"
              src={user_logo}
            />
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`h-3 w-3 transition-transform ${isMenuOpen ? "rotate-180" : ""
                }`}
            />
          </Button>
        </MenuHandler>

        <MenuList className="p-1">
          {profileMenuItems.map(({ label, icon, onClick }, key) => {
            const isLastItem = key === profileMenuItems.length - 1;
            return (
              <MenuItem
                key={label}
                className={`flex items-center gap-2 rounded ${isLastItem
                  ? "hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10"
                  : ""
                  }`}
              >
                {React.createElement(icon, {
                  className: `h-4 w-4 ${isLastItem ? "text-red-500" : ""}`,
                  strokeWidth: 2,
                })}
                <Typography
                  as="span"
                  variant="small"
                  className="font-normal"
                  color={isLastItem ? "red" : "inherit"}
                >
                  {label}
                </Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  }

  return (
    <Fragment>
      <Navbar className="sticky inset-0 z-10 h-max max-w-full rounded-none py-2 px-4 lg:px-8 shadow-sm border-0">
        <div className="flex items-center justify-between text-blue-gray-900">
          <p>{moment().format("lll")}</p>

          <div className="flex items-center gap-[20px]">
            <button className="hidden lg:block mt-2 mr-[5px]">
              <Badge count={3} className="flex items-center">
                <BellIcon className="h-5 w-5 text-default_blue" />
              </Badge>
            </button>

            {/* menus */}
            <button className="h-[35px] w-[35px] text-gray-600 hover:bg-gray-100 rounded flex justify-center items-center p-[2px]">
              <CgMenuGridR className="h-[30px] w-[30px]" />
            </button>

            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>

            <ProfileMenu />
          </div>
        </div>
        <Collapse open={openNav}>
          <Badge count={3}>
            <BellIcon className="h-5 w-5 text-default_blue" />
          </Badge>
        </Collapse>
      </Navbar>
    </Fragment>
  );
}
