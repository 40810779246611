import CurriculumComponent from "./CurriculumComponent";

const CurriculumList = [
  {
    title: "Grade 2",
    units: 10,
    tasks: 9,
    points: 5,
  },
  {
    title: "Grade 3",
    units: 30,
    tasks: 2,
    points: 3,
  },
];

const SpecializationCurriculum = () => {
  return (
    <div className="flex flex-col gap-2 mt-3">
      {CurriculumList.map((gradeObj, index) => (
        <CurriculumComponent
          key={index}
          title={gradeObj.title}
          points={gradeObj.points}
          units={gradeObj.units}
          tasks={gradeObj.tasks}
          // onClick={onClickHandler}
        />
      ))}
    </div>
  );
};

export default SpecializationCurriculum;
