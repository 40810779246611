import React from "react";
import basicElectronics_img from "../../images/bigImg.png";

const SimpleUploadComponent = ({ url }: { url: any }) => {
  return (
    <div className="avatar-uploader h-full">
      {url ? (
        <img src={url} alt="avatar" style={{ width: "100%", height: "100%" }} />
      ) : (
        <div className="text-center flex justify-center items-center h-full">
          <section className="">
            <div className="w-full flex justify-center items-center  ">
              <section className="w-[80px] h-[80px]">
                <img
                  src={basicElectronics_img}
                  alt="avatar"
                  style={{ width: "100%", height: "100%" }}
                />
              </section>
            </div>
            <p className="mt-4"> First Image to Appear on the page</p>
          </section>
        </div>
      )}
    </div>
  );
};

export default React.memo(SimpleUploadComponent);
