import { useReducer, useEffect } from "react";
import { Button, Input, Modal } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { FaChild } from "react-icons/fa";
import BaseService from "../../../helpers/baseServices";
import { SlSocialFacebook, SlSocialInstagram } from "react-icons/sl";
import { PiTiktokLogoLight, PiYoutubeLogoThin } from "react-icons/pi";
import { CiTwitter } from "react-icons/ci";
import { CiLinkedin } from "react-icons/ci";
import { MdSupportAgent } from "react-icons/md";

interface CreateGradeLevelProps {
  handleReFetch: any;
  showModal: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface State {
  confirmLoading: boolean;
  size: SizeType;
  isHovered: boolean;
  name: string;
  phone: string;
  facebook: string;
  youtube: string;
  twitter: string;
  instagram: string;
  tiktok: string;
  linkedin: string;
}

const initialState: State = {
  confirmLoading: false,
  size: "large",
  isHovered: false,
  name: "",
  phone: "",
  facebook: "",
  youtube: "",
  twitter: "",
  instagram: "",
  tiktok: "",
  linkedin: "",
};

type Action =
  | { type: "SET_CONFIRM_LOADING"; payload: boolean }
  | { type: "SET_HOVERED"; payload: boolean }
  | { type: "SET_NAME"; payload: string }
  | { type: "SET_PHONE"; payload: string }
  | { type: "SET_FACEBOOK"; payload: string }
  | { type: "SET_YOUTUBE"; payload: string }
  | { type: "SET_TWITTER"; payload: string }
  | { type: "SET_INSTAGRAM"; payload: string }
  | { type: "SET_TIKTOK"; payload: string }
  | { type: "SET_LINKEDIN"; payload: string };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_CONFIRM_LOADING":
      return { ...state, confirmLoading: action.payload };
    case "SET_HOVERED":
      return { ...state, isHovered: action.payload };
    case "SET_NAME":
      return { ...state, name: action.payload };
    case "SET_PHONE":
      return { ...state, phone: action.payload };
    case "SET_FACEBOOK":
      return { ...state, facebook: action.payload };
    case "SET_YOUTUBE":
      return { ...state, youtube: action.payload };
    case "SET_TWITTER":
      return { ...state, twitter: action.payload };
    case "SET_INSTAGRAM":
      return { ...state, instagram: action.payload };
    case "SET_TIKTOK":
      return { ...state, tiktok: action.payload };
    case "SET_LINKEDIN":
      return { ...state, linkedin: action.payload };
    default:
      return state;
  }
};

const CreateSupport = ({
  handleReFetch,
  showModal,
  open,
  setOpen,
}: CreateGradeLevelProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleOk = async () => {
    dispatch({ type: "SET_CONFIRM_LOADING", payload: true });
    try {
      const payload = {
        name: state.name,
        phone: state.phone,
        socials: {
          facebook: state.facebook,
          youtube: state.youtube,
          x: state.twitter,
          instagram: state.instagram,
          tiktok: state.tiktok,
          linkedIn: state.linkedin,
        },
      };
      console.log("Sending payload:", payload);
      const results = await BaseService.post_api(
        "/robocentre/setup/support",
        payload
      );
      handleReFetch();
      setOpen(false);
    } catch (error: any) {
      console.log("Error is ", error?.response?.data?.error);
      console.log(error);
    } finally {
      dispatch({ type: "SET_CONFIRM_LOADING", payload: false });
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleNameChange = (name: string) => {
    dispatch({ type: "SET_NAME", payload: name });
  };

  const handlePhoneChange = (phone: string) => {
    dispatch({ type: "SET_PHONE", payload: phone });
  };

  const handleFacebookChange = (facebook: string) => {
    dispatch({ type: "SET_FACEBOOK", payload: facebook });
  };

  const handleYoutubeChange = (youtube: string) => {
    dispatch({ type: "SET_YOUTUBE", payload: youtube });
  };

  const handleTwitterChange = (twitter: string) => {
    dispatch({ type: "SET_TWITTER", payload: twitter });
  };

  const handleInstagramChange = (instagram: string) => {
    dispatch({ type: "SET_INSTAGRAM", payload: instagram });
  };

  const handleTiktokChange = (tiktok: string) => {
    dispatch({ type: "SET_TIKTOK", payload: tiktok });
  };

  const handleLinkedInChange = (linkedin: string) => {
    dispatch({ type: "SET_LINKEDIN", payload: linkedin });
  };

  return (
    <>
      <Button
        type="default"
        shape="round"
        icon={<MdSupportAgent size={"1.5em"} style={{ marginRight: 8 }} />}
        className="flex items-center"
        size={state.size}
        onClick={showModal}
        onMouseEnter={() => dispatch({ type: "SET_HOVERED", payload: true })}
        onMouseLeave={() => dispatch({ type: "SET_HOVERED", payload: false })}
        style={{
          backgroundColor: state.isHovered ? "#9494F5" : "#6666EA",
          color: "white",
          border: "1px solid #9494f5",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="flex items-center justify-center">
          <p>Create Support</p>
        </div>
      </Button>

      <Modal
        width={"50%"}
        open={open}
        onOk={handleOk}
        confirmLoading={state.confirmLoading}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            backgroundColor: "#6666EA",
            borderColor: "#6666EA",
            color: "#FFFFFF",
            boxShadow: "none",
          },
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "40vh" } }}
      >
        <div className="rounded-2xl px-4">
          <p className="text-2xl mt-1 font-body text-text_primary font-bold">
            Create Support
          </p>
          <hr className="my-3 text-default" />
          <div className="rounded-lg p-2">
            <section className="flex gap-3 flex-col">
              <span className="text-lg font-semibold text-text_primary">
                Personal Information
              </span>
              <div className="w-full flex justify-center gap-2 items-center">
                <p className=" w-[100px] h-[40px] bg-super_light border flex justify-center items-center rounded-lg">
                  Name :
                </p>
                <Input
                  className="h-[40px] border-1 bg-super_light border-outline_deep text-md font-semibold"
                  placeholder="Enter Name..."
                  value={state.name}
                  onChange={(e) => handleNameChange(e.target.value)}
                />
              </div>

              <div className="w-full flex justify-center gap-2 items-center">
                <p className=" w-[100px] h-[40px] bg-super_light border flex justify-center items-center rounded-lg">
                  Phone :
                </p>
                <Input
                  className="h-[40px] border-1 bg-super_light border-outline_deep text-md font-semibold"
                  placeholder="Enter Phone..."
                  value={state.phone}
                  onChange={(e) => handlePhoneChange(e.target.value)}
                />
              </div>

              <span className="text-lg font-semibold text-text_primary">
                Socials
              </span>
              <div className="flex gap-2 items-center">
                <p className="border w-[40px] h-[40px] border-1 bg-super_light border-outline_deep flex justify-center items-center rounded-lg">
                  <SlSocialFacebook
                    className="text-text_primary"
                    size={"1.5em"}
                  />
                </p>
                <div className="w-full">
                  <Input
                    className="h-[40px] border-1 bg-super_light border-outline_deep text-md font-semibold"
                    placeholder="facebook url..."
                    value={state.facebook}
                    onChange={(e) => handleFacebookChange(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <p className="border w-[40px] h-[40px] border-1 bg-super_light border-outline_deep flex justify-center items-center rounded-lg">
                  <PiYoutubeLogoThin
                    className="text-text_primary"
                    size={"1.5em"}
                  />
                </p>
                <div className="w-full">
                  <Input
                    className="h-[40px] border-1 bg-super_light border-outline_deep text-md font-semibold"
                    placeholder="youtube url..."
                    value={state.youtube}
                    onChange={(e) => handleYoutubeChange(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <p className="border w-[40px] h-[40px] border-1 bg-super_light border-outline_deep flex justify-center items-center rounded-lg">
                  <CiTwitter className="text-text_primary" size={"1.5em"} />
                </p>
                <div className="w-full">
                  <Input
                    className="h-[40px] border-1 bg-super_light border-outline_deep text-md font-semibold"
                    placeholder="twitter url..."
                    value={state.twitter}
                    onChange={(e) => handleTwitterChange(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <p className="border w-[40px] h-[40px] border-1 bg-super_light border-outline_deep flex justify-center items-center rounded-lg">
                  <SlSocialInstagram
                    className="text-text_primary"
                    size={"1.5em"}
                  />
                </p>
                <div className="w-full">
                  <Input
                    className="h-[40px] border-1 bg-super_light border-outline_deep text-md font-semibold"
                    placeholder="instagram url..."
                    value={state.instagram}
                    onChange={(e) => handleInstagramChange(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <p className="border w-[40px] h-[40px] border-1 bg-super_light border-outline_deep flex justify-center items-center rounded-lg">
                  <PiTiktokLogoLight
                    className="text-text_primary"
                    size={"1.5em"}
                  />
                </p>
                <div className="w-full">
                  <Input
                    className="h-[40px] border-1 bg-super_light border-outline_deep text-md font-semibold"
                    placeholder="tiktok url..."
                    value={state.tiktok}
                    onChange={(e) => handleTiktokChange(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <p className="border w-[40px] h-[40px] border-1 bg-super_light border-outline_deep flex justify-center items-center rounded-lg">
                  <CiLinkedin className="text-text_primary" size={"1.5em"} />
                </p>
                <div className="w-full">
                  <Input
                    className="h-[40px] border-1 bg-super_light border-outline_deep text-md font-semibold"
                    placeholder="linkedin url..."
                    value={state.linkedin}
                    onChange={(e) => handleLinkedInChange(e.target.value)}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default CreateSupport;
