import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import BaseService from "../../helpers/baseServices";

const CurriculumNameCell = ({ curriculumId }: { curriculumId: string }) => {
  const [loading, setLoading] = useState(true);
  const [curriculumName, setCurriculumName] = useState("");

  useEffect(() => {
    const fetchCurriculumName = async () => {
      try {
        const results = await BaseService.get_api(
          `/robocentre/curriculum?filter={"_id": {"$$_id": ${JSON.stringify(
            curriculumId
          )}}}`
        );
        const { payload } = results.data;
        if (payload) {
          setCurriculumName(payload[0].name);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCurriculumName();
  }, [curriculumId]);

  return (
    <Spin spinning={loading}>
      <p className="  w-full text-md text-center">{curriculumName}</p>
    </Spin>
  );
};

export default CurriculumNameCell;
