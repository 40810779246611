import React from "react";
import PageLayout from "../../components/Layouts/page-layout";
import { Col, Row } from "react-bootstrap";
import { Cog8ToothIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { Avatar } from "@material-tailwind/react";
import { useState } from "react";
import Forums from "./tabs/forum";
import MostRecent from "./tabs/most-recent";
import ForumGroup from "./tabs/forum-group";

const ForumsMainPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabNames = ["Forums", "Most Recent", "Top Discussions", "Forum Groups"];

  const tabComponents = [
    <Forums />,
    <MostRecent />,
    "Top Discussions",
    <ForumGroup />,
  ];

  return (
    <>
      <PageLayout>
        <div className="overflow-x-hidden overflow-y-auto">
          <Row className="p-[20px]">
            <Col md={9}>
              <div>
                <p className="text-text_primary font-semibold text-2xl">
                  Forum Discussion
                </p>

                <div className="mt-3 flex items-center gap-2">
                  {tabNames.map((dd, i) => (
                    <div
                      key={i}
                      className={`${
                        activeTab === i
                          ? "bg-gradient-to-r from-default_blue to-light_blue text-white"
                          : "bg-white shadow-sm text-text_primary "
                      } px-3 py-2 w-fit rounded-[30px] cursor-pointer`}
                      onClick={() => setActiveTab(i)}
                    >
                      {dd}
                    </div>
                  ))}
                </div>

                {/* render tabs */}
                {tabComponents[activeTab]}
              </div>
            </Col>
            <Col md={3} className="">
              <div>
                <button className="flex gap-2 items-center bg-default_blue text-white px-3 py-2 rounded-[5px]">
                  <UserPlusIcon className="w-5 h-5" />
                  Create Topic
                </button>
                <button className="mt-3 flex gap-2 items-center bg-white text-default_blue border-[1px] border-outline_light px-3 py-2 rounded-[5px]">
                  <Cog8ToothIcon className="w-5 h-5" />
                  Forum Settings
                </button>

                <div className="mt-3 bg-white shadow-sm rounded-[20px] p-[10px]">
                  <p className="font-medium">Moderators</p>
                  <div className="flex items-center -space-x-4 mt-3">
                    <Avatar
                      size="sm"
                      variant="circular"
                      alt="user 1"
                      className="border-2 border-white hover:z-10 focus:z-10"
                      src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80"
                    />
                    <Avatar
                      size="sm"
                      variant="circular"
                      alt="user 2"
                      className="border-2 border-white hover:z-10 focus:z-10"
                      src="https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1061&q=80"
                    />
                    <Avatar
                      size="sm"
                      variant="circular"
                      alt="user 3"
                      className="border-2 border-white hover:z-10 focus:z-10"
                      src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1288&q=80"
                    />
                    <Avatar
                      size="sm"
                      variant="circular"
                      alt="user 4"
                      className="border-2 border-white hover:z-10 focus:z-10"
                      src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1287&q=80"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </PageLayout>
    </>
  );
};

export default ForumsMainPage;
