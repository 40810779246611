import React, { useState } from 'react'
import logoImg from "../../images/mingo-logo.png"
import { notification, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { StorageBox } from '../../helpers/storage';
import { useNavigate } from 'react-router-dom';
import PinInput from 'react-pin-input';
import { ROBOCENTRE_URL } from '../../helpers/constants';
import axios from 'axios';


const VerificationPage = () => {

    const navigate = useNavigate()
    const [isBusy, setIsBusy] = useState(false)
    const [theCode, setTheCode] = useState<any>("")

    //handleVerifySubmit
    const handleVerifySubmit = async () => {

        let data = JSON.stringify({
            "token": theCode
        });

        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${ROBOCENTRE_URL}/robocentre/auth/verify_account`,
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': StorageBox.getTempToken()
            },
            data: data
        };

        setIsBusy(true)

        try {

            const res = await axios.request(config);

            StorageBox.saveAccessToken(res?.data?.token);
            StorageBox.saveUserData(res?.data?.payload);
            setTimeout(() => {
                window.location.href = "/choose-domain"
            }, 1000)
            notification.success({ message: `Logged in as ${res?.data?.payload?.firstName || "Administrator"}` })

        } catch (error: any) {
            notification.error({ message: error?.response?.data?.error || "Error while verifying account. Please retry" })
            setIsBusy(false)
            navigate("/signin");
        }
    }


    return (
        <>
            <div className='h-screen w-full overflow-hidden bg-[#F9F9FF]'>
                <div className="flex justify-center items-center h-full p-5">
                    <div className="2xl:w-[380px] xl:w-[380px] lg:w-[380px] md:w-[380px]">
                        <img className='flex mx-auto' src={logoImg} alt="" />

                        <p className='mt-[20px] '>
                            We have sent a verification code to your email address. Please enter the code below and click verify to proceed
                        </p>

                        {/* pin input */}
                        <div className="mt-[40px]">
                            <div className="text-center">
                                <PinInput
                                    length={5}
                                    secret
                                    secretDelay={100}
                                    type="numeric"
                                    inputMode="number"
                                    style={{ padding: "10px" }}
                                    inputStyle={{ borderColor: "#6666EA", borderRadius: 10, height: 60, width: 60 }}
                                    inputFocusStyle={{ borderColor: "green" }}
                                    onComplete={() => { }}
                                    onChange={(val) => setTheCode(val)}
                                    autoSelect={true}
                                    disabled={isBusy}
                                />
                            </div>

                            <button className='h-[50px] w-full bg-default_blue text-white rounded-[40px] mt-[50px]' onClick={handleVerifySubmit}>
                                {
                                    isBusy ?
                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />} />
                                        :
                                        <p className='text-[16px] font-[600]'>Verify</p>
                                }
                            </button>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerificationPage
