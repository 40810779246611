import { useEffect, useState } from "react";
import { InputNumber, Slider } from "antd";
import { IoRemoveOutline } from "react-icons/io5";

const Agegroup = ({
  selectedAgeGroup,
  onAgeGroupChange,
}: {
  selectedAgeGroup: number[];
  onAgeGroupChange: (values: number[]) => void;
}) => {
  const [inputValue1, setInputValue1] = useState<number>(selectedAgeGroup[0]);
  const [inputValue2, setInputValue2] = useState<number>(selectedAgeGroup[1]);
  const [disabled, setDisabled] = useState(false);

  const onChangeSlider = (values: number[]) => {
    setInputValue1(values[0]);
    setInputValue2(values[1]);
    onAgeGroupChange(values);
  };

  const onChangeInput1 = (value: number | null) => {
    if (value !== null) {
      setInputValue1(value);
      onAgeGroupChange([value, inputValue2]);
    }
  };

  const onChangeInput2 = (value: number | null) => {
    if (value !== null) {
      setInputValue2(value);
      onAgeGroupChange([inputValue1, value]);
    }
  };

  useEffect(() => {
    setInputValue1(selectedAgeGroup[0]);
    setInputValue2(selectedAgeGroup[1]);
  }, [selectedAgeGroup]);

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div>
            <InputNumber
              min={1}
              max={100}
              value={inputValue1}
              onChange={onChangeInput1}
              style={{ width: "60px" }}
            />
          </div>
          <IoRemoveOutline size={"1.5em"} />
          <div>
            <InputNumber
              min={1}
              max={100}
              value={inputValue2}
              onChange={onChangeInput2}
              style={{ width: "60px" }}
            />
          </div>
        </div>
        <div className="w-full">
          <Slider
            range
            min={1}
            max={100}
            value={[inputValue1, inputValue2]}
            onChange={onChangeSlider}
            disabled={disabled}
            style={{
              boxShadow: "none",
            }}
            styles={{
              track: { backgroundColor: "#6666EA" },
              handle: { borderColor: "#6666EA", backgroundColor: "#6666EA" },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Agegroup;
