import { useReducer, useEffect, useState } from "react";
import { Modal, Spin } from "antd";

import BaseService from "../../../helpers/baseServices";
import ShowToast from "../../../components/Alerts/all_toasts";
import TextArea from "antd/es/input/TextArea";
import SelectUsers from "./SelectUsers";

interface CreateGradeLevelProps {
  handleReFetch: any;
  showModal: () => void;
  open: boolean;
  educatorID: string | undefined;
  setOpen: (open: boolean) => void;
}

interface State {
  userid: string;
  professionalSummary: string;
}

const initialState: State = {
  userid: "",
  professionalSummary: "",
};

type Action =
  | { type: "SET_USER_ID"; payload: string }
  | { type: "SET_SUMMERY"; payload: string }
  | { type: "SET_INIT"; payload: any };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_USER_ID":
      return { ...state, userid: action.payload };
    case "SET_SUMMERY":
      return { ...state, professionalSummary: action.payload };
    case "SET_INIT":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const EditEducators = ({
  handleReFetch,
  educatorID,
  showModal,
  open,
  setOpen,
}: CreateGradeLevelProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState<boolean>(false);
  const [isHovered, setisHovered] = useState<boolean>(false);
  const [isUpdating, setUpdating] = useState<boolean>(false);
  const updateEducator = async () => {
    if (!educatorID) return;
    setLoading(true);

    try {
      const results = await BaseService.get_api(
        `/robocentre/educator?filter={"_id": {"$$_id": ${JSON.stringify(
          educatorID
        )}}}`
      );
      const { payload } = results.data;
      console.log("payload", payload[0]);
      if (payload && payload.length > 0) {
        const { professionalSummary, userid } = payload[0];
        dispatch({
          type: "SET_INIT",
          payload: { professionalSummary, userid },
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    updateEducator();
  }, [educatorID]);

  const handleOk = async () => {
    setUpdating(true);
    try {
      const payload = {
        _id: educatorID,
        userid: state.userid,
        professionalSummary: state.professionalSummary,
      };
      // console.log("Sending payload:", payload);
      const results = await BaseService.put_api(
        "/robocentre/educator",
        payload
      );
      handleReFetch();
      console.log("API results:", results);
      ShowToast.success_toast("updated successfully");
      setTimeout(() => setOpen(false), 1000);
    } catch (error: any) {
      console.log("Error is ", error?.response?.data?.error);
      ShowToast.info_toast(error?.response?.data?.error);
      console.log(error);
      setUpdating(false);
    } finally {
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handlerProfessionalSummary = (value: any) => {
    dispatch({ type: "SET_SUMMERY", payload: value });
  };

  const buttonStyle = {
    backgroundColor: isHovered ? "#9494F5" : "#6666EA",
    color: "white",
    border: "1px solid #9494f5",
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={isUpdating}
        onCancel={handleCancel}
        okText="Update Educators"
        okButtonProps={{
          style: {
            backgroundColor: "#6666EA",
            borderColor: "#6666EA",
            color: "#FFFFFF",
            boxShadow: "none",
            borderRadius: "30px",
            height: '40px',
            width: '140px'
          },
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "40vh" } }}
      >
        <div className=" rounded-2xl px-4">
          <Spin spinning={loading}>
            <p className="text-2xl mt-1 font-body text-text_primary font-bold">
              Edit Educator
            </p>
            <hr className="my-3 text-default" />
            <div className=" rounded-lg p-2">

              <div className="w-full mb-4">
                <p className="ml-2 mb-1">Users</p>
                <SelectUsers
                  userID={state.userid}
                  onChange={(selected) => {
                    dispatch({ type: "SET_USER_ID", payload: selected });
                  }}
                />
              </div>

              <div className="mt-[20px]">
                <p className="">Professional Summary</p>
                <TextArea
                  placeholder="Enter professional Summary"
                  className="noplaceholder  w-full  border-1 border-solid border-[#ccccf8]  rounded-[10px]"
                  value={state.professionalSummary}
                  onChange={(e) => handlerProfessionalSummary(e.target.value)}
                />
              </div>
            </div>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default EditEducators;
