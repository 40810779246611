import { useState } from "react";
import { Button } from "antd";
import type { ConfigProviderProps } from "antd";

type SizeType = ConfigProviderProps["componentSize"];
const CustomButtomSquare = ({
  value,
  icon,
  width,
  myStyle,
  onClick,
}: {
  onClick?: any;
  value?: any;
  icon?: any;
  width?: any;
  myStyle?: any;
}) => {
  const [size, setSize] = useState<SizeType>("large"); // default is 'middle'
  return (
    <Button
      type="default"
      onClick={onClick}
      icon={icon}
      className={`flex bg-white shadow-sm  ${myStyle}  w-[${width}%] items-center border-[1px] border-outline_light text-[#9494f5]`}
      size={size}
    >
      <span>{value}</span>
    </Button>
  );
};

export default CustomButtomSquare;
