import ProSideBar from "./ProSideBar";
import MyNavBar from "./nav";

const PageLayout = ({ children }: any) => {
  return (
    <div style={{ padding: "0px", height: "100vh", overflow: "auto" }}>
      <div
        style={{
          display: "flex",
          height: "100vh",
          flexDirection: "row",
        }}
      >
        <ProSideBar />
        <div
          style={{
            flex: 1,
            minHeight: "100vh",
            maxHeight: "100vh",
            overflowY: "scroll",
          }}
        >
          {/* <Navbar /> */}
          <MyNavBar />
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
