import { useReducer, useEffect, useState } from "react";
import { Button, Input, Modal, Spin } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { FaChild } from "react-icons/fa";
import BaseService from "../../../helpers/baseServices";

interface CreateGradeLevelProps {
  handleReFetch: any;
  showModal: () => void;
  open: boolean;
  languageID: any;
  setOpen: (open: boolean) => void;
}

interface State {
  name: string;
}

const initialState: State = {
  name: "",
};

type Action = { type: "SET_language"; payload: string };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_language":
      return { ...state, name: action.payload };
    default:
      return state;
  }
};

const EditLanguage = ({
  handleReFetch,
  showModal,
  open,
  languageID,
  setOpen,
}: CreateGradeLevelProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isHovered, setHovered] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const getLanguage = async () => {
    setLoading(true);
    try {
      const results = await BaseService.get_api(
        `/robocentre/language?filter={"_id": {"$$_id": ${JSON.stringify(
          languageID
        )}}}`
      );
      console.log("single tage", results.data.payload);
      dispatch({ type: "SET_language", payload: results.data.payload[0].name });
    } catch (error: any) {
      console.log("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLanguage();
  }, [languageID]);

  const handleOk = async () => {
    try {
      const payload = {
        _id: languageID,
        name: state.name,
      };
      // console.log("Sending payload:", payload);
      const results = await BaseService.put_api(
        "/robocentre/language",
        payload
      );
      handleReFetch();
      console.log("API results:", results);
      setOpen(false);
    } catch (error: any) {
      console.log("error", error.message);
    } finally {
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleGradeNameChange = (name: string) => {
    dispatch({ type: "SET_language", payload: name });
  };

  const buttonStyle = {
    backgroundColor: isHovered ? "#9494F5" : "#6666EA",
    color: "white",
    border: "1px solid #9494f5",
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      <Modal
        width={"30%"}
        open={open}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            backgroundColor: "#6666EA",
            borderColor: "#6666EA",
            color: "#FFFFFF",
            boxShadow: "none",
          },
          // disabled: !state.isFormValid,
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "30vh" } }}
      >
        <div className=" rounded-2xl px-4">
          <p className="text-2xl mt-1 font-body text-text_primary font-bold">
            Edit Languages
          </p>
          <hr className="my-3 text-default" />
          <Spin spinning={loading}>
            <p className="mb-2">Name</p>
            <Input
              className="h-[40px] w-full border-1 border-outline_deep"
              placeholder="Add New tag"
              value={state.name}
              onChange={(e) => handleGradeNameChange(e.target.value)}
            />
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default EditLanguage;

// export default EditLanguage;
