import PageLayout from "../../../../components/Layouts/page-layout";
import AllDepartmentsTable from "./allDepartmentsTable";

const DepartmentsPage = () => {


    return (
        <>
            <PageLayout>
                <div className="m-4">
                    <h2 className="text-2xl text-text_primary font-semibold">All Departments</h2>

                    {/* main */}
                    <div className="my-3 w-full">
                        <AllDepartmentsTable />
                    </div>
                </div>
            </PageLayout>
        </>
    );
};

export default DepartmentsPage;