import {
  ArrowDownOnSquareStackIcon,
  ChatBubbleLeftIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import moment from "moment";
import { Fragment } from "react";
import DataTable from "react-data-table-component";
import { SearchInputField } from "../../../../components/form/search";
import { Spin } from "antd";

export default function RobocenterSchoolStudent({ school_id }: any) {
  const columns = [
    {
      name: <Typography variant="h6">Name</Typography>,
      cell: (row: any, index: number) => (
        <Typography variant="paragraph">Grade 1</Typography>
      ),
    },
    {
      name: <Typography variant="h6">Last seen</Typography>,
      cell: (row: any, index: number) => (
        <Typography variant="paragraph">{moment().format("lll")}</Typography>
      ),
    },
    {
      name: <Typography variant="h6">Student ID</Typography>,
      cell: (row: any, index: number) => (
        <Typography variant="paragraph">GSHSJ78</Typography>
      ),
    },
  ];
  return (
    <Fragment>
      <Card>
        <CardBody>
          <div className="flex justify-end">
            <SearchInputField />
          </div>
          <DataTable
            columns={columns}
            progressComponent={<Spin />}
            data={[""]}
            pagination
            paginationServer
            selectableRows
          />
        </CardBody>
      </Card>
    </Fragment>
  );
}

export function StudentSide() {
  return (
    <Fragment>
      <Button
        className="flex items-center gap-2 bg-default_blue mb-2"
        size="sm"
      >
        <ChatBubbleLeftIcon strokeWidth={2} className="h-5 w-5" /> Send Message
      </Button>
      <div className="flex justify-start gap-2">
        <Button
          className="flex items-center gap-2 mb-2"
          variant="outlined"
          size="sm"
        >
          <ArrowDownOnSquareStackIcon strokeWidth={2} className="h-5 w-5" />{" "}
          Export
        </Button>
        <Button className="mb-2" size="sm" variant="outlined">
          <PrinterIcon strokeWidth={2} className="h-5 w-5" />
        </Button>
      </div>
    </Fragment>
  );
}
