import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { Option, Select } from "@material-tailwind/react";
import { Image } from "react-bootstrap";

const Forums = () => {
  return (
    <>
      <div>
        {/* search bar */}
        <div className="mt-3 grid grid-cols-5 gap-2">
          <div className="w-full col-span-4 relative">
            <input
              type="text"
              placeholder="Search Topic"
              className="h-[40px] rounded-[30px] border-[1px] border-outline_light focus:border-outline_deep w-full px-3"
            />
            <button className="px-3 h-[40px] bg-default_blue text-white rounded-[30px] absolute right-0">
              Search Topic
            </button>
          </div>
          <div className="col-span-1">
            <button className="w-full px-3 h-[40px] border-[2px] border-default_blue bg-white text-default_blue rounded-[30px]">
              Create Post
            </button>
          </div>
        </div>

        {/* filter */}
        <div className="mt-3 flex gap-2 items-center lg:w-[300px]">
          <Select label="Filter By">
            <Option>All Grades</Option>
          </Select>
          <Select label="Filter By">
            <Option>All Courses</Option>
          </Select>
        </div>

        {/* cards */}

        <div className="mt-3">
          {["", "", ""].map((item, i) => (
            <div
              key={i}
              className="w-full p-[10px] bg-white shadow-md mt-3 rounded-[20px]"
            >
              <div className="flex justify-between">
                <div className="flex gap-3">
                  <Image
                    className="h-[100px] w-[100px] rounded-[10px] object-cover"
                    src="https://media.wired.com/photos/5cdef92d38916b321aa0c474/master/w_2560%2Cc_limit/Facebook-Robots-00.jpg"
                    alt="course"
                  />
                  <div>
                    <p className="text-default_blue font-medium">
                      Lorem ipsum dolor sit amet, consetetur
                    </p>
                    <p className="mt-2">Discuss and ask questions about...</p>
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-3">
                  <div className="text-text_primary flex flex-col justify-center text-center">
                    <p className="text-3xl font-semibold">8</p>
                    <p>Topics</p>
                  </div>
                  <div className="text-text_primary flex flex-col justify-center text-center">
                    <p className="text-3xl font-semibold">6</p>
                    <p>Posts</p>
                  </div>
                  <div className="text-text_primary flex flex-col justify-center text-center">
                    <p className="text-3xl font-semibold text-default_yellow">
                      6
                    </p>
                    <p>New</p>
                  </div>
                  <div className="flex flex-col justify-center text-center">
                    <EllipsisVerticalIcon className="text-text_primary font-semibold cursor-pointer w-7 h-7" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Forums;
