import React from 'react'
import DataTable from 'react-data-table-component'
import { orderList } from '../../TestData/Test_Orders';

const RecentRequests = () => {

    const tabLists = ["Student", "School", "Business"]

    //table
    const columns = [
        {
            name: "Order No.",
            selector: (row: any) => row?.id,
        },
        {
            name: "Customer Name.",
            selector: (row: any) => row?.customer_name,
        },
        {
            name: "Order Date.",
            selector: (row: any) => row?.order_date,
        },
        {
            name: "Total (GH¢)",
            selector: (row: any) => row?.total_amount,
        },
        {
            name: "Status",
            selector: (row: any) => (
                <>
                    <p className="rounded-full text-tag_deep bg-tag_deep pl-3 pr-3 bg-opacity-20">
                        {row?.status}
                    </p>
                </>
            ),
        },
    ];

    return (
        <>
            <div className="flex gap-[10px] items-center">
                {
                    tabLists.map((item, i) => (
                        <div key={i} className='px-[25px] py-[4px] rounded-[30px] bg-gradient-to-r'>
                            {item}
                        </div>
                    ))
                }
            </div>

            <DataTable
                className="mt-3"
                columns={columns}
                data={orderList}
            />
        </>
    )
}

export default RecentRequests
