import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

export function SearchInputField() {
  return (
    <div className="relative rounded-full overflow-hidden">
      <input
        type="text"
        className="py-2 pr-8 pl-4 block w-full appearance-none border-2 border-gray-200 rounded-full leading-tight focus:outline-none focus:border-blue-500"
        placeholder="Search"
      />
      <div className="absolute top-0 right-0 h-full w-4 mr-3 text-gray-400 flex items-center justify-center">
        <MagnifyingGlassIcon />
      </div>
    </div>
  );
}
