import "./App.css";
import "nprogress/nprogress.css";
import "./nprogress-custom.css";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import PageNotFound from "./pages/404/not-found";
import ResetPwdVerify from "./pages/Auth/reset-pass-verify";
import OTPRoute from "./HOC/verifyotp.route";
import PWDOTPRoute from "./HOC/verifyotp.pwd";
import Dashboard from "./pages/dashboard/dashboard";
import Orders from "./pages/orders/Orders";
import Products from "./pages/products/products";
import Customers from "./pages/customers/Customers";
import Coupons from "./pages/couponManager/Coupons";
import Performance from "./pages/Performance/Performance";
import Communication from "./pages/communication/Communication";
import RoboCenterBusinessPage from "./pages/robocenter/business/business-page";
import RoboCenterSchools from "./pages/robocenter/robocenter-schools/robocenter-schools";
import RoboCenterSchoolDetail from "./pages/robocenter/robocenter-schools/robocenter-school-detail";
import CreateRoboCenterSchool from "./pages/robocenter/robocenter-schools/create-robocenter-school";
import ForumsMainPage from "./pages/forums/forumpage";
import ProductDetails from "./pages/products/ProductDetails";
import CustomersDetails from "./pages/customers/CustomersDetails";
import OrderDetails from "./pages/orders/OrderDetails/OrderDetails";
import ManageRequest from "./pages/ManageRequest/ManageRequest";
import ManageRobocenter from "./pages/ManageRobocenter/ManageRobocenter";
import Schools from "./pages/Schools/Schools";
import Educators from "./pages/Educators/Educators";
import Parents from "./pages/Parents/Parents";
import Businesses from "./pages/Businesses/Businesses";
import VerificationRequest from "./pages/VerificationRequest/VerificationRequest";
import SignUpRequest from "./pages/SignUpRequest/SignUpRequest";
import LearnerparentDetails from "./pages/SignUpRequest/Tabs/Learnertoparent/LearnerparentDetails";
import BusinessesDetails from "./pages/SignUpRequest/Tabs/Businesses/BusinessesDetails";
import VEducatorsDetails from "./pages/VerificationRequest/Tabs/Educators/VEducatorsDetails";
import VBusinessDetails from "./pages/VerificationRequest/Tabs/Business/VBusinessDetails";
import VContentEducatorDetails from "./pages/VerificationRequest/Tabs/Content Educator/VContentEducatorDetails";
import ManageRobocenterDetails from "./pages/ManageRobocenter/DynamicComponent/ManageRobocenterDetails/ManageRobocenterDetails";
import CenterGradeDetails from "./pages/ManageRobocenter/DynamicComponent/ManageRobocenterDetails/Tabs/GLevels/GradeDetails";
import ProductCategory from "./pages/products/product-category";
import Gradelevel from "./pages/Gradelevel/Gradelevel";
import TermsConditons from "./pages/Terms and Condition/TermsConditons";
import Curriculum from "./pages/Curriculum/Curriculum";
import Support from "./pages/Support/Support";
import AdminProfile from "./pages/AdminProfile/AdminProfile";
import EditProfile from "./pages/EditProfile/EditProfile";
import Signout from "./pages/Signout/Signout";
import ProtectedRoute from "./HOC/ProtectedRoute";
import TitleUpdater from "./pages/Auth/TitleUpdater";
import { ProSidebarProvider } from "react-pro-sidebar";
import RequestSchoolsDetails from "./pages/SignUpRequest/Tabs/RequestSchools/RequestSchoolsDetails";
import SchoolDetails from "./pages/Schools/SchoolDetails/SchoolDetails";
import Tags from "./pages/Tags/Tags";
import Language from "./pages/Languag/Language";
import Courses from "./pages/courses/coursesPage";
import SigninPage from "./pages/Auth/signinPage";
import VerificationPage from "./pages/Auth/verificationPage";
import CreateNewCourse from "./pages/courses/create/createNewCourse";
import EditCourseMainPage from "./pages/courses/edit/editCourseMainPage";
import { MainContextProvider } from "./contexts/mainContext";
import StaffAttendance from "./pages/administration/staffManagement/staffAttendance/staffAttendance";
import StaffPage from "./pages/administration/staffManagement/allStaff/staffPage";
import ProfessionsPage from "./pages/administration/staffManagement/profession/professionsPage";
import DepartmentsPage from "./pages/administration/staffManagement/department/departmentsPage";
import CoursesCurriculum from "./pages/courses/courseBundler/roboCurriculum/coursesCurriculum";
import CourseBundlerPage from "./pages/courses/courseBundler/bundler/bundlerPage";
import AdminGateway from "./pages/gateway/adminGateway";
import ResetPassword from "./pages/Auth/reset-password";
import InstructorsPage from "./pages/instructors/instructorsPage";

function App() {
  return (
    <ProSidebarProvider>
      <MainContextProvider>
        <Router>
          {/* <TitleUpdater /> */}
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/signin" element={<SigninPage />} />
            <Route path="/*" element={<PageNotFound />} />
            <Route path="/verify" element={<VerificationPage />} />

            <Route element={<OTPRoute />}>
              <Route path="/resetpwd" element={<ResetPassword />} />
            </Route>

            <Route element={<PWDOTPRoute />}>
              <Route path="/resetpwdVerify" element={<ResetPwdVerify />} />
            </Route>

            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Outlet />
                </ProtectedRoute>
              }
            >
              <Route path="/choose-domain" element={<AdminGateway />} />
              <Route path="/dashboard" element={<Dashboard />} />

              <Route path="/tags" element={<Tags />} />
              <Route path="/language" element={<Language />} />

              <Route path="/coupons" element={<Coupons />} />
              <Route path="/performance" element={<Performance />} />
              <Route path="/communication" element={<Communication />} />
              <Route path="/manageRequest" element={<ManageRequest />} />
              <Route path="/signUpRequest" element={<SignUpRequest />} />
              <Route
                path="/learnerparent/:id"
                element={<LearnerparentDetails />}
              />
              <Route path="/school/:id" element={<SchoolDetails />} />
              <Route path="/Businesses/:id" element={<BusinessesDetails />} />
              <Route path="/vEducators/:id" element={<VEducatorsDetails />} />
              <Route
                path="/requestschool/:id"
                element={<RequestSchoolsDetails />}
              />
              <Route path="/vBusinesses/:id" element={<VBusinessDetails />} />
              <Route
                path="/vcontenteducator/:id"
                element={<VContentEducatorDetails />}
              />
              <Route
                path="/verificationRequest"
                element={<VerificationRequest />}
              />
              <Route path="/orders" element={<Orders />} />
              <Route path="/orders/:id" element={<OrderDetails />} />
              <Route path="/products" element={<Products />} />
              <Route path="/product-category" element={<ProductCategory />} />
              <Route path="/products/:id" element={<ProductDetails />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/customers/:id" element={<CustomersDetails />} />
              <Route path="/manageRobocenter" element={<ManageRobocenter />} />
              <Route
                path="/manageRobocenter/:id"
                element={<ManageRobocenterDetails />}
              />
              <Route
                path="/manageRobocenter/:id/:id"
                element={<CenterGradeDetails />}
              />
              <Route path="/courses" element={<Courses />} />
              <Route path="/courses/:id" element={<EditCourseMainPage />} />
              <Route path="/courses/create" element={<CreateNewCourse />} />
              <Route path="/courses/curriculum" element={<CoursesCurriculum />} />
              <Route path="/courses/bundler/:id" element={<CourseBundlerPage />} />
              <Route path="/adminprofile" element={<AdminProfile />} />
              <Route path="/editprofile" element={<EditProfile />} />
              <Route path="/signout" element={<Signout />} />
              <Route path="/support" element={<Support />} />
              <Route path="/Gradelevel" element={<Gradelevel />} />
              <Route path="/termsConditons" element={<TermsConditons />} />
              <Route path="/curriculum" element={<Curriculum />} />
              <Route path="/schools" element={<Schools />} />
              <Route path="/educators" element={<Educators />} />
              <Route path="/instructors" element={<InstructorsPage />} />
              <Route path="/parents" element={<Parents />} />

              <Route path="/businesses" element={<Businesses />} />

              <Route
                path="/robocenter-business"
                element={<RoboCenterBusinessPage />}
              />
              <Route path="/robocenter-schools" element={<RoboCenterSchools />} />
              <Route
                path="/robocenter-schools/:id"
                element={<RoboCenterSchoolDetail />}
              />
              <Route
                path="/create-robocenter-schools"
                element={<CreateRoboCenterSchool />}
              />
              <Route path="/forums" element={<ForumsMainPage />} />

              {/* attendance, staff, profession, department */}
              <Route path="/attendance" element={<StaffAttendance />} />
              <Route path="/manage-staff" element={<StaffPage />} />
              <Route path="/professions" element={<ProfessionsPage />} />
              <Route path="/departments" element={<DepartmentsPage />} />

            </Route>
          </Routes>
        </Router>
      </MainContextProvider>
    </ProSidebarProvider>
  );
}

export default App;
