import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";

import MoreDetails from "./MoreDetails";
import PageLayout from "../../../components/Layouts/page-layout";
import CustomButtomRounded from "../../../components/shared/CustomButtomRounded";
import BaseService from "../../../helpers/baseServices";
import { useEffect, useReducer, useState } from "react";
import { Spin } from "antd";

const initialState = {
  schoolDetails: [],
  confirmLoading: false,
  confirmSchoolDetail: false,
  remarks: "",
  isError: false,
  errorMessage: "",
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_SCHOOL_DETAILS":
      return { ...state, schoolDetails: action.payload };
    case "SET_CONFIRM_LOADING":
      return { ...state, confirmLoading: action.payload };
    case "SET_CONFIRM_SCHOOL_DETAIL":
      return { ...state, confirmSchoolDetail: action.payload };
    case "SET_REMARKS":
      return { ...state, remarks: action.payload };
    case "SET_ERROR":
      return {
        ...state,
        isError: action.payload,
        errorMessage: action.message,
      };
    default:
      return state;
  }
};

const SchoolDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [state, dispatch] = useReducer(reducer, initialState);

  const handlerClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    navigate("/schools");
  };
  const fetchSingleSchool = async () => {
    dispatch({ type: "SET_CONFIRM_SCHOOL_DETAIL", payload: true });

    try {
      const results = await BaseService.get_api(
        `/robocentre/roboschool/onboarding/school?filter={"_id": {"$$_id": ${JSON.stringify(
          id
        )}}}`
      );
      const { payload } = results.data;
      if (payload && payload.length > 0) {
        dispatch({ type: "SET_SCHOOL_DETAILS", payload });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch({ type: "SET_CONFIRM_SCHOOL_DETAIL", payload: false });
    }
  };

  useEffect(() => {
    if (id) {
      fetchSingleSchool();
    }
  }, [id]);

  return (
    <>
      <PageLayout>
        <Spin spinning={state.confirmSchoolDetail}>
          <div className="m-4  flex gap-8 ">
            <section className="w-[80%] ">
              <div className="flex items-center gap-4 ml-6 mb-14   ">
                <CustomButtomRounded
                  icon={<FaArrowLeft />}
                  value={"Back"}
                  onClick={handlerClick}
                />
                <h2 className="text-4xl text-text_primary font-semibold">
                  School Details
                </h2>
              </div>
              <MoreDetails schooData={state.schoolDetails[0]} />
            </section>
            <section className=" flex flex-col gap-2  w-[20%] ">
              <div className="w-full flex items-center gap-2 p-4 sticky top-[100px]   bg-white    rounded-lg justify-center flex-col  drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] ">
                {/* <div>
                <FileUploade />
              </div> */}
                <div className=" ">
                  <img
                    className=" w-[150px] rounded-full h-[150px]"
                    src={
                      "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                    }
                    alt="..."
                  />
                </div>
                <p className="text-text_deep text-center text-[16px]  w-full font-semibold">
                  {state.schoolDetails[0]?.schoolInformation?.schoolName}
                </p>
                <p className="text-light_blue">
                  {" "}
                  {state.schoolDetails[0]?.principal?.email}
                </p>
                <div className="flex  w-[90%] gap-2 justify-between items-center">
                  <p className="flex flex-col ">
                    <span className="text-xl text-text_primary font-semibold">
                      Students
                    </span>

                    <span className="text-center">
                      {
                        state.schoolDetails[0]?.schoolInformation
                          ?.leanerPopulation
                      }
                    </span>
                  </p>
                  <p className="w-[2px] h-[60px] border-1 border-outline_light"></p>
                  <p className="flex flex-col ">
                    <span className="text-xl text-text_primary font-semibold">
                      Classes
                    </span>

                    <span className="text-center">
                      {" "}
                      {state.schoolDetails[0]?.curriculumData?.length}
                    </span>
                  </p>
                </div>
              </div>

              <div>
                <div className="w-[80%] grid gap-2 grid-cols-2 grid-rows-3 "></div>
              </div>
            </section>
          </div>
        </Spin>
      </PageLayout>
    </>
  );
};

export default SchoolDetails;
