import {
  ArrowDownOnSquareStackIcon,
  PlusIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import { Fragment, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

export default function RoboCenterSchoolCalender({ school_id }: any) {
  const handleDateClick = (data: any) => {
    // bind with an arrow function
    console.log(data);
  };

  const renderEvent = ({ event }: any) => {
    console.log(event);
    const eventStyle = {
      backgroundColor: "red",
      color: "#fff",
      borderRadius: "4px",
      padding: "2px 4px",
    };

    return (
      <div>
        <div style={eventStyle}>{event.title}</div>
        <div>{event.time}</div>
      </div>
    );
  };

  return (
    <Fragment>
      <Card>
        <CardBody>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            footerToolbar={{
              start: "",
              center: "",
              end: "prevYear,nextYear",
            }}
            weekends={false}
            initialView="dayGridMonth"
            events={[
              {
                title: "Holiday",
                date: "2023-06-23",
                backgroundColor: "red",
                // allDay: false,
                start: "2023-06-23T10:00:00",
                end: "2023-06-23T12:00:00",
              },
              { title: "Today is Gideon's birthday", date: "2023-06-22" },
            ]}
            // dateClick={handleDateClick}
            selectable={true}
            // eventContent={renderEvent}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
}

export function CalenderSide() {
  return (
    <Fragment>
      <Button
        className="flex items-center gap-2 bg-default_blue mb-2"
        size="sm"
      >
        <PlusIcon strokeWidth={2} className="h-5 w-5" /> Add Event
      </Button>
      <div className="flex justify-start gap-2">
        <Button
          className="flex items-center gap-2 mb-2"
          variant="outlined"
          size="sm"
        >
          <ArrowDownOnSquareStackIcon strokeWidth={2} className="h-5 w-5" />{" "}
          Export
        </Button>
        <Button className="mb-2" size="sm" variant="outlined">
          <PrinterIcon strokeWidth={2} className="h-5 w-5" />
        </Button>
      </div>
      <Typography className="mt-4" variant="small">
        Filter Events by Category
      </Typography>
      <hr className="m-2" />
      <div className="flex">
        <span className="bg-default_blue text-white text-[10px] rounded-full px-1 cursor-pointer">
          Event
        </span>
      </div>
    </Fragment>
  );
}
