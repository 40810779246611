import {
  ArrowDownOnSquareStackIcon,
  PlusIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import moment from "moment";
import { Fragment } from "react";

export default function RobocenterSchoolBadge({ school_id }: any) {
  return (
    <Fragment>
      <div className="grid grid-cols-4 gap-5">
        {["", ""].map((_d, i) => {
          return (
            <Card
              key={i}
              className="cursor-pointer hover:border-2 hover:border-default_blue transition-[300]"
            >
              <CardBody>
                <div className="flex flex-col justify-center items-center">
                  <img
                    className="rounded-full h-28 w-28"
                    src="https://static.vecteezy.com/system/resources/previews/004/269/052/original/cute-robot-logo-vector.jpg"
                    alt="img-blur-shadow"
                  />
                  <Typography>Badge default</Typography>
                  <small>Created on : {moment().format("ll")}</small>
                </div>
              </CardBody>
            </Card>
          );
        })}
      </div>
    </Fragment>
  );
}

export function BadgeSide() {
  return (
    <Fragment>
      <Button
        className="flex items-center gap-2 bg-default_blue mb-2"
        size="sm"
        // onClick={() => setShow(true)}
      >
        <PlusIcon strokeWidth={2} className="h-5 w-5" /> Create Badge
      </Button>
      <div className="flex justify-start gap-2">
        <Button
          className="flex items-center gap-2 mb-2"
          variant="outlined"
          size="sm"
        >
          <ArrowDownOnSquareStackIcon strokeWidth={2} className="h-5 w-5" />{" "}
          Export
        </Button>
        <Button className="mb-2" size="sm" variant="outlined">
          <PrinterIcon strokeWidth={2} className="h-5 w-5" />
        </Button>
      </div>
    </Fragment>
  );
}
