import { Select } from 'antd'


interface TermSelectorProps {
    defaultValue?: any
    onChange?: (value: any) => void
}


const TermSelector = ({ defaultValue, onChange }: TermSelectorProps) => {
    return (
        <>
            <Select
                className='h-[40px] w-full'
                options={[
                    { label: 'Term 1', value: 'LEVEL1' },
                    { label: 'Term 2', value: 'LEVEL2' },
                    { label: 'Term 3', value: 'LEVEL3' }
                ]}
                defaultValue={defaultValue}
                onChange={onChange}
                placeholder='Select Term'
                allowClear
            />
        </>
    )
}

export default TermSelector
