import React from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { customer_list } from "../../../TestData/Cusomer_List";
import SearchInput from "../../../components/shared/SearchInput";
import CustomButtomRounded from "../../../components/shared/CustomButtomRounded";
import { GoPlus } from "react-icons/go";
import useTableSyle from "../../../components/shared/useTableSyle";
import { Spinner } from "@material-tailwind/react";
import CheckBox from "../../../components/shared/CheckBoxs";
import { IoMdMore } from "react-icons/io";
import { Spin } from "antd";

const OrderDetailsTable = () => {
  const [customStyles] = useTableSyle();
  const navigate = useNavigate();

  //Main Table columns
  const columns = [
    {
      name: "Email",
      cell: (row: any) => <p>{row.customer_name} &#128525;</p>,
    },
    {
      name: "Sales",
      cell: (row: any) => row.signup_date,
    },
    {
      name: "Orders",
      cell: (row: any) => row.total_sales,
    },
    {
      name: "Location",
      cell: (row: any) => row.total_orders,
    },
  ];

  const handlerClick = (e: any) => {};

  return (
    <div className="bg-white p-4 rounded-[20px]">
      <DataTable
        columns={columns}
        data={customer_list}
        paginationServer
        // progressPending={isFetching}
        progressComponent={<Spin />}
        pagination
        // paginationTotalRows={total}
        pointerOnHover
        highlightOnHover
        customStyles={customStyles}
        // onRowClicked={(selected: any) => navigate(`/orders/${selected._id}`)}
      />
    </div>
  );
};

export default OrderDetailsTable;

// export default OrderDetailsTable;
