import { useEffect, useState, useCallback, useReducer } from "react";
import { Input, message, Modal, Select, Spin } from "antd";
import ProductCategorySelector from "../../../components/shared/productCategorySelector";
import BaseService from "../../../helpers/baseServices";
import MultipleSelect from "../../../components/shared/MultipleSelect";
import SelectOnyCountry from "../../../components/shared/SelectOnyCountry";
import SmallUploadOne from "../smallUploadOne";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import UploadComponent from "../ImageBillBoard";

interface modalProps {
  isOpened: boolean;
  productId?: string;
  handleClose: () => void;
  handleReFetch: () => void;
  DispatchImages: any;
}

const initialValues = {
  imageUrls: [],
  items: [
    {
      id: "item-1",
      component: SmallUploadOne,
      url: "",
      filename: "",
    },
    {
      id: "item-2",
      component: SmallUploadOne,
      url: "",
      filename: "",
    },
    {
      id: "item-3",
      component: SmallUploadOne,
      url: "",
      filename: "",
    },
    {
      id: "item-4",
      component: SmallUploadOne,
      url: "",
      filename: "",
    },
  ],
};

const ReducerFunction = (state: any, action: any) => {
  switch (action.type) {
    case "littleOnes": {
      const { url, filename, id } = action.payload;
      const newImageUrls = [...state.imageUrls, { url, filename }];

      const urlMap = new Map(newImageUrls.map((item) => [item.url, item]));
      const uniqueImageUrls = Array.from(urlMap.values());

      const updatedItems = state.items.map((item: any) => {
        if (item.id === id) {
          return { ...item, url, filename };
        }
        return item;
      });

      return {
        ...state,
        imageUrls: uniqueImageUrls,
        items: updatedItems,
      };
    }

    case "editStated": {
      const filenameToRemove = action.payload;

      return {
        ...state,
        imageUrls: [...filenameToRemove],
      };
    }

    case "newItems": {
      const myItems = action.payload;
      return {
        ...state,
        items: [...myItems],
      };
    }

    case "updateItems": {
      const myItems = action.payload;
      return {
        ...state,
        items: [...myItems],
      };
    }

    case "dbImages": {
      const images = action.payload;
      const updatedItems = state.items.map((item: any, index: any) => {
        if (images[index]) {
          return { ...item, url: images[index] };
        }
        return item;
      });

      return {
        ...state,
        imageUrls: images,
        items: updatedItems,
      };
    }

    default:
      return state;
  }
};

const EditHardwareProduct = ({
  DispatchImages,
  productId,
  isOpened,
  handleClose,
  handleReFetch,
}: modalProps) => {
  const today = new Date();
  const [isBusy, setIsBusy] = useState(false);
  const [prodName, setProdName] = useState("");
  const [prodCat, setProdCat] = useState<any>(null);
  const [prodDesc, setProdDesc] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [discountPercent, setDiscountPercent] = useState("");
  const [prodWeight, setProdWeight] = useState("");
  const [weightUnit, setWeightUnit] = useState<any>(null);
  const [inStockQty, setInStockQty] = useState("");
  const [tageSelector, setTagSelector] = useState<Array<string>>([]);
  const [country, setCountry] = useState<string>("Ghana");
  const [editCategoryName, setEditCategoryName] = useState<string>();
  const [editFetching, setEditFetching] = useState<boolean>(true);

  const [State, dispatchFunction] = useReducer(ReducerFunction, initialValues);

  const TagSelectHandler = (tageArray: Array<string>) => {
    setTagSelector(tageArray);
  };

  const CountrySelectHandler = async (myCountry: string) => {
    try {
      console.log(myCountry);

      const results = await BaseService.get_api(
        `/robocentre/country?filter={"country":"${myCountry}"}`
      );

      const { payload } = results.data;

      console.log("payload is ", payload);
      if (payload.length > 0 && payload[0].country === myCountry) {
        setCountry("Ghana");
      } else {
        setCountry("Other");
      }
    } catch (error: any) {
      console.error("Error fetching country data:", error);
    }
  };

  // handleEditProduct
  const handleEditProduct = async () => {
    try {
      const prod_data = {
        _id: productId,
        name: prodName,
        images: State.items.map((objs: any) => objs.url),
        weight: parseFloat(prodWeight),
        weightUnit: weightUnit,
        categoryId: prodCat,
        description: prodDesc,
        productDate: today.toISOString(),
        stock: parseInt(inStockQty),
        tags: tageSelector,
        salePrice: parseFloat(salePrice),
        originalPrice: parseFloat(originalPrice),
        allowedDiscount: parseInt(discountPercent),
        country:
          country === "Ghana"
            ? "65d4b85e9ae65ff72c90900e"
            : "65d4b85e9ae65ff72c9091d6",
      };

      setIsBusy(true);

      const results = await BaseService.put_api(
        "/robocentre/product",
        prod_data
      );
      const response = await results.data;
      // console.log("The Edit response is ", response);
      setIsBusy(false);
      handleClose();
      handleReFetch();
      message.success("Product edited Successfully !!");
    } catch (error: any) {
      console.log("Error is ", error?.response?.data?.error);
      message.error(error?.response?.data?.error);
      setIsBusy(false);
    }
  };

  // Updating the product
  const updateProductInfo = useCallback(async () => {
    setEditFetching(true);
    try {
      const results = await BaseService.get_api(
        `/robocentre/product?filter={"_id": {"$$_id": ${JSON.stringify(
          productId
        )}}}`
      );
      const { payload } = await results.data;

      console.log("fetching the product", payload[0]);
      setEditCategoryName(payload[0].categoryData.name);
      setProdName(payload[0].name);
      setProdCat(payload[0].categoryData._id);
      setProdDesc(payload[0].description);
      setOriginalPrice(payload[0].originalPrice);
      setSalePrice(payload[0].salePrice);
      setDiscountPercent(payload[0].allowedDiscount);
      setProdWeight(payload[0].weight);
      setWeightUnit(payload[0].weightUnit);
      setInStockQty(payload[0].stock);
      setTagSelector(payload[0].tags);

      console.log("Editimage", payload[0].images);
      DispatchImages(
        dispatchFunction({ type: "dbImages", payload: payload[0].images })
      );
    } catch (error: any) {
      console.log("This is the error ", error);
    } finally {
      setEditFetching(false);
    }
  }, [productId]);

  useEffect(() => {
    if (productId) {
      updateProductInfo();
    }
  }, [productId]);

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(State.items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    dispatchFunction({ type: "updateItems", payload: reorderedItems });
  };

  // useEffect(() => {
  //   console.log("isState", State.items);
  // }, [State]);

  return (
    <>
      <Modal
        open={isOpened}
        onCancel={handleClose}
        footer={null}
        maskClosable={false}
        keyboard={false}
        closeIcon={true}
        centered
        width={"60%"}
      >
        <Spin spinning={editFetching}>
          <section className="px-8 text-text_deep">
            <div>
              <p className="text-[24px] mb-4">Update Product</p>
              <p className="text-[17px]">Add Image </p>
            </div>
            <div className="gap-4 flex  ">
              <section className="basis-3/5 border-[1px] rounded-md border-dashed border-[#d9d9d9] h-[300px]">
                <div className="w-full h-full">
                  <UploadComponent url={State.items[0].url} />
                </div>
              </section>
              <section className=" w-full h-[400px]  grid grid-rows-3 grid-cols-1 ">
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {State.items.map((item: any, index: any) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided) => (
                              <main
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  margin: "10px",
                                  ...provided.draggableProps.style,
                                }}
                              >
                                {
                                  <item.component
                                    id={item.id}
                                    dispatchFunction={dispatchFunction}
                                    MegaState={State}
                                    prodName={prodName}
                                    productId={productId}
                                  />
                                }
                              </main>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </section>
            </div>
            <div>
              <p className="mt-4">
                You need to add at least 4 image .Comply with the background
                colour standard. Image size should be{" "}
              </p>
              <p>1220px X 1220px</p>
            </div>
            <div>
              <div className="mt-[15px] flex    items-center w-full ">
                <div className="basis-1/2">Country</div>
                <div className="w-full">
                  <SelectOnyCountry
                    onCountrySelect={CountrySelectHandler}
                    country={country}
                  />
                </div>
              </div>
              <div className="mt-[20px] flex    items-center">
                <p className="basis-1/2">Product Name</p>
                <div className="w-full">
                  <Input
                    className="h-[40px] text-text_deep border-1 border-outline_deep placeholder-outline_light"
                    value={prodName}
                    onChange={(e) => setProdName(e.target.value)}
                    placeholder="Product name"
                  />
                </div>
              </div>
              <div className="mt-[15px] flex  items-center">
                <p className="basis-1/2">Origional Price</p>
                <div className="w-full flex gap-2 items-center">
                  <div>
                    <Select
                      className="h-[40px] w-full  placeholder-outline_light"
                      options={[
                        { label: "GHC", value: "Ghana" },
                        { label: "USD", value: "Other" },
                      ]}
                      onChange={(selected) => setCountry(selected)}
                      value={country}
                      placeholder="Currency"
                    />
                  </div>
                  <div>
                    <Input
                      className="h-[40px] border-1 border-outline_deep placeholder-outline_light"
                      value={originalPrice}
                      onChange={(e) => setOriginalPrice(e.target.value)}
                      placeholder="Original price"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-[15px] flex items-center ">
                <p className="basis-1/2">Sale Price</p>
                <div className="w-full flex gap-2 items-center">
                  <div>
                    <Select
                      className="h-[40px] w-full  placeholder-outline_light"
                      options={[
                        { label: "GHC", value: "Ghana" },
                        { label: "USD", value: "Other" },
                      ]}
                      onChange={(selected) => setCountry(selected)}
                      value={country}
                      placeholder="Currency"
                    />
                  </div>
                  <div>
                    <Input
                      className="h-[40px] border-1 border-outline_deep placeholder-outline_light"
                      value={salePrice}
                      onChange={(e) => setSalePrice(e.target.value)}
                      placeholder="Sale price"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-[15px] flex items-center ">
                <p className="basis-1/2">Discount Allowed</p>
                <div className="w-full">
                  <Input
                    className="h-[40px] border-1 border-outline_deep placeholder-outline_light"
                    value={discountPercent}
                    onChange={(e) => setDiscountPercent(e.target.value)}
                    placeholder="Discount allowed (%)"
                  />
                </div>
              </div>
              <div className=" flex items-center">
                <p className="basis-1/2">Weight</p>
                <section className="flex w-full gap-2 items-center">
                  <div className="mt-[15px]">
                    <Select
                      className="h-[40px] w-full "
                      options={[
                        { label: "Kilograms (Kg)", value: "kg" },
                        { label: "Grams (g)", value: "g" },
                      ]}
                      onChange={(selected) => setWeightUnit(selected)}
                      value={weightUnit}
                      placeholder="Weight unit"
                      allowClear
                    />
                  </div>
                  <div className="mt-[15px]">
                    <Input
                      className="h-[40px] border-1 border-outline_deep placeholder-outline_light"
                      value={prodWeight}
                      onChange={(e) => setProdWeight(e.target.value)}
                      placeholder="Product weight"
                    />
                  </div>
                </section>
              </div>
              <div className="mt-[15px] flex items-center ">
                <p className="basis-1/2">Category</p>
                <div className="w-full">
                  <ProductCategorySelector
                    productId={productId}
                    editCategoryName={editCategoryName}
                    onChange={(selected) => setProdCat(selected)}
                  />
                </div>
              </div>

              <div className="mt-[15px] flex items-center ">
                <p className="basis-1/2">Description</p>
                <div className="w-full">
                  <Input.TextArea
                    className="w-full border-1 border-outline_deep placeholder-outline_light"
                    value={prodDesc}
                    onChange={(e) => setProdDesc(e.target.value)}
                    placeholder="Product description"
                  />
                </div>
              </div>

              <div className="mt-[15px] flex  items-center">
                <p className="basis-1/2">In Stock</p>
                <div className="w-full">
                  <Input
                    className="h-[40px] border-1 border-outline_deep placeholder-outline_light"
                    value={inStockQty}
                    onChange={(e) => setInStockQty(e.target.value)}
                    placeholder="Stock qty"
                  />
                </div>
              </div>

              <div className="mt-[15px] flex items-center ">
                <p className="basis-1/2">Tags</p>
                <div className="w-full">
                  <MultipleSelect
                    productId={productId}
                    tageSelector={tageSelector}
                    onTageSelect={TagSelectHandler}
                  />
                </div>
              </div>
            </div>
            <div className="mt-[15px] flex justify-end gap-[30px] items-center">
              <button className="text-red-500" onClick={handleClose}>
                Cancel
              </button>

              <button
                className="px-5 py-2 h-[40px] rounded-[30px] bg-default_blue text-white shadow-sm"
                onClick={handleEditProduct}
              >
                {productId ? " Edit Product" : "Create Product"}
              </button>
            </div>
          </section>
        </Spin>
      </Modal>
    </>
  );
};

export default EditHardwareProduct;
