import {
  ArrowDownOnSquareStackIcon,
  BuildingLibraryIcon,
  PrinterIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  Card,
  CardBody,
  Option,
  Select,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import PageLayout from "../../../components/Layouts/page-layout";
import { SearchInputField } from "../../../components/form/search";
import urls from "../../../mixins/base.url";
import RoboCenterSchoolMenuList from "./components/robo-schools-menu-list";
import accessDeniedImg from "../../../images/accessDeniedImg.svg";
import baseService from "../../../mixins/baseServices";
import { Spin } from "antd";

export default function RoboCenterSchools() {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);

  const columns = [
    {
      name: <Typography variant="h6">Name</Typography>,
      cell: (row: any, index: number) => (
        <Typography variant="paragraph">
          {row?.name}
          {row?.default && (
            <span className="px-2 py-1 text-[12px] rounded-full text-[white] bg-[#FF808B] ">
              Main
            </span>
          )}
        </Typography>
      ),
    },
    {
      name: <Typography variant="h6">Country</Typography>,
      cell: (row: any) => (
        <Typography variant="paragraph">{row?.country}</Typography>
      ),
    },
    {
      name: <Typography variant="h6">Status</Typography>,
      cell: (row: any) => (
        <span className="bg-[#63C477] px-2 rounded-full text-white ">
          Active
        </span>
      ),
    },
    {
      cell: (row: any) => <RoboCenterSchoolMenuList school={row} />,
      width: "10%",
    },
  ];

  const [loading, setLoading] = useState(true);
  const [schools, setSchools] = useState<any>([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const get_schools = async () => {
      try {
        let skip = (page - 1) * limit;
        const res: any = await baseService.get(
          urls.robocenter_schools + `/all?limit=${limit}&skip=${skip}`
        );
        setSchools(res.data);
        setLoading(false);
      } catch (error) {
        setIsError(true);
      }
    };

    get_schools();
  }, [limit, page]);

  return (
    <PageLayout>
      <div className="m-5">
        <Row>
          <Col lg={9}>
            <h2 className="text-2xl font-normal text-text_primary">
              Robocenter Schools
            </h2>
            <div className="bg-white text-gray-700 w-full rounded-3xl focus:ring-opacity-50 shadow-md py-4 px-4  mt-5 flex justify-between">
              <SearchInputField />
              <div className="w-80">
                <Select label="Sort" className="">
                  <Option>All</Option>
                  <Option>Location</Option>
                </Select>
              </div>
            </div>

            <Card className="my-5">
              <CardBody>
                {isError ? (
                  <div className="flex flex-col justify-center items-center h-full mt-2">
                    <Image
                      className="w-[100px]"
                      src={accessDeniedImg}
                      alt="access"
                    />
                    <p className="mt-2">
                      You do not have access to view this data...
                    </p>
                  </div>
                ) : (
                  <DataTable
                    columns={columns}
                    data={schools.payload}
                    progressPending={loading}
                    progressComponent={<Spin />}
                    pointerOnHover
                    highlightOnHover
                    selectableRows
                    onRowClicked={(row) =>
                      navigate(`/robocenter-schools/${row?.code}`)
                    }
                    pagination
                    paginationServer
                    paginationTotalRows={schools?.total}
                    paginationPerPage={limit}
                    onChangePage={(page) => {
                      setLoading(true);
                      setPage(page);
                    }}
                    onChangeRowsPerPage={(rows) => {
                      setLoading(true);
                      setPage(1);
                      setLimit(rows);
                    }}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <Button
              className="flex items-center gap-2 bg-default_blue mb-2"
              size="sm"
              onClick={() => navigate("/create-robocenter-schools")}
            >
              <BuildingLibraryIcon strokeWidth={2} className="h-5 w-5" /> Create
              school
            </Button>
            <Button
              className="flex items-center gap-2 mb-2"
              variant="outlined"
              size="sm"
            >
              <UserPlusIcon strokeWidth={2} className="h-5 w-5" /> Add
              Attendance
            </Button>
            <div className="flex justify-start gap-2">
              <Button
                className="flex items-center gap-2 mb-2"
                variant="outlined"
                size="sm"
              >
                <ArrowDownOnSquareStackIcon
                  strokeWidth={2}
                  className="h-5 w-5"
                />{" "}
                Export
              </Button>
              <Button className="mb-2" size="sm" variant="outlined">
                <PrinterIcon strokeWidth={2} className="h-5 w-5" />
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
}
