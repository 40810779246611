import React from "react";
import PersonalInformation from "../../PersonalInformation";

const Profile = () => {
  return (
    <>
      <PersonalInformation />
      <PersonalInformation />
      <PersonalInformation />
    </>
  );
};

export default Profile;
