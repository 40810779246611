import { useEffect, useState } from "react";
import PageLayout from "../../components/Layouts/page-layout";
import { Col, Row } from "react-bootstrap";
import {
  PaperAirplaneIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import ShowToast from "../../components/Alerts/all_toasts";
import ShowAlert from "../../components/Alerts/all_alerts";
import urls from "../../mixins/base.url";
import { Textarea, Typography } from "@material-tailwind/react";
import { LinearProgress, Pagination } from "@mui/material";
import baseService from "../../mixins/baseServices";
import AddNewProductCategory from "./addNewProductCategory";

const ProductCategoryComponent = ({
  data,
  reload,
}: {
  data?: any;
  reload?: any;
}) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [color, setColor] = useState<any>(null);

  useEffect(() => {
    setName(data?.name);
    setDescription(data?.description);
    setColor(data.color ?? "data");
  }, [data.color, data?.description, data?.name]);

  const submit = async () => {
    try {
      if (name === "" || description === "") {
        ShowToast.warning_toast("Please fill out all fields!");
      } else {
        ShowAlert.loading_alert("updating...");
        await baseService.put(urls.product_categories, {
          _id: data?._id,
          name: name,
          description: description,
          color: color,
        });

        ShowToast.success_toast("Interest updated successfully");
        setOpen(false);
        reload();
      }
    } catch (error: any) {
      ShowToast.error_toast(
        error.response?.data?.error ??
          "There was an error updating product category"
      );
    }
  };

  return (
    <>
      <div className="border-b-[1px] border-b-outline_light p-[10px]  flex justify-between items-center">
        <div
          className="text-text_primary bg-opacity-[20%] w-fit px-3 py-1  rounded-[30px]"
          style={{ backgroundColor: color ?? "#CCCCF8" }}
        >
          <Typography variant="small">{data?.name}</Typography>
        </div>
        <div className="flex items-center gap-2">
          <PencilSquareIcon
            className="cursor-pointer text-default_blue w-5 h-5"
            onClick={() => {
              setOpen(!open);
            }}
          />
          <TrashIcon className="cursor-pointer text-default_blue w-5 h-5" />
        </div>
      </div>
      {open ? (
        <div className="border-[1px] border-outline_light p-[20px] mt-[20px] rounded-[10px]">
          <input
            className="h-[40px] rounded-[5px] border-[1px] border-outline_light mt-[10px] w-full shadow-none focus:border-outline_deep"
            type="text"
            placeholder="Robotics"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="mt-3">
            <Textarea
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <Typography
            variant="small"
            className="font-semibold text-text_primary mt-3"
          >
            Color
          </Typography>
          <input
            className="h-[40px] rounded-[5px] border-[1px] border-outline_light w-full shadow-none focus:border-outline_deep"
            type="color"
            placeholder="Color"
            value={color}
            onChange={(e: any) => setColor(e.target.value)}
          />
          <div className="flex items-center justify-end gap-3 mt-[20px]">
            <button
              className="text-red-500 text-sm"
              onClick={() => setOpen(!open)}
            >
              Cancel
            </button>
            <button
              className="bg-default_blue text-white px-3 py-1 text-sm rounded-[30px] flex items-center gap-2"
              onClick={submit}
            >
              <PaperAirplaneIcon className="h-4 w-4" />
              Update
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ProductCategoryComponent;
