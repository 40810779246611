import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import SearchInput from "../../../components/shared/SearchInput";
import useTableSyle from "../../../components/shared/useTableSyle";
import { Spinner } from "@material-tailwind/react";
import CheckBox from "../../../components/shared/CheckBoxs";
import { IoMdMore } from "react-icons/io";
import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import { MdOutlineDelete } from "react-icons/md";
import { BsQuestionSquare } from "react-icons/bs";
import { SiLevelsdotfyi } from "react-icons/si";
import { GoStop } from "react-icons/go";

const RoboCenterTable = ({
  data,
  dispatchFunction,
}: {
  data: any;
  dispatchFunction: any;
}) => {
  const [customStyles] = useTableSyle();
  const navigate = useNavigate();

  const handleMenuClick: any = (e: any, rowId: any) => {
    if (e.key === "1") {
      dispatchFunction({ type: "DELETE", payload: rowId });
    }
  };

  const columns = [
    {
      name: <CheckBox />,
      cell: (row: any) => <CheckBox />,
      width: "5%",
    },
    {
      name: "Customer",
      cell: (row: any) => (
        <div className="flex justify-center items-center gap-2">
          <img
            className="rounded-md"
            src={row.picture}
            alt="..."
            height="35px"
            width="35px"
          />
          <p>{row.customer_name}</p>
        </div>
      ),
    },
    {
      name: "Email",
      cell: (row: any) => <p>{row.customer_name} &#128525;</p>,
    },
    {
      name: "Sales",
      cell: (row: any) => row.signup_date,
    },
    {
      name: "Orders",
      cell: (row: any) => row.total_sales,
    },
    {
      name: "Location",
      cell: (row: any) => row.total_orders,
    },
    {
      name: "Status",
      cell: (row: any) => (
        <p className="bg-green-100 -ml-4 text-green-300 px-4 rounded-xl">
          {row.status}
        </p>
      ),
    },
    {
      name: "",
      cell: (row: any) => {
        const menuItems: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <span className="text-default_ash text-sm">Delete School</span>
            ),
            icon: <MdOutlineDelete color="red" size={"1.3em"} />,
            onClick: (e) => handleMenuClick(e, row._id),
          },
          {
            key: "2",
            label: <span className="text-default_ash text-sm">Add FAQ</span>,
            icon: <BsQuestionSquare color="#ff8d23" size={"1.3em"} />,
            onClick: (e) => handleMenuClick(e, row._id),
          },
          {
            key: "3",
            label: (
              <span className="text-default_ash text-sm">Add Grade Levels</span>
            ),
            icon: <SiLevelsdotfyi color="#63C477" size={"1.3em"} />,
            onClick: (e) => handleMenuClick(e, row._id),
          },
          {
            key: "4",
            label: (
              <span className="text-default_ash text-sm">
                Deactivate School
              </span>
            ),
            icon: <GoStop color="red" size={"1.3em"} />,
            onClick: (e) => handleMenuClick(e, row._id),
          },
        ];
        return (
          <Dropdown
            placement="bottomLeft"
            menu={{ items: menuItems }}
            className="text-[14px]"
          >
            <IoMdMore size={"2em"} className="text-[#9494f5] " />
          </Dropdown>
        );
      },
      width: "5%",
    },
  ];

  const handlerClick = (e: any) => {};

  return (
    <div className="bg-white p-4 rounded-xl min-h-[500px] drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)]">
      <div className="flex gap-4">
        <div>
          <SearchInput />
        </div>
        <div>
          <SearchInput />
        </div>
      </div>
      <DataTable
        className="mt-4"
        columns={columns}
        data={data}
        paginationServer
        // progressPending={isFetching}
        progressComponent={<Spinner />}
        pagination
        // paginationTotalRows={total}
        pointerOnHover
        highlightOnHover
        customStyles={customStyles}
        onRowClicked={(selected: any) =>
          navigate(`/manageRobocenter/${selected._id}`)
        }
      />
    </div>
  );
};

export default RoboCenterTable;
