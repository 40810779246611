import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import InforCard from "../../../../components/cards/infor-card";
import {
  ArrowDownOnSquareStackIcon,
  BuildingLibraryIcon,
  ListBulletIcon,
  PrinterIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import RevenueChart from "../components/revenue-chart";

export default function RodoSchoolOverview({ school_id }: any) {
  return (
    <Fragment>
      <Row>
        <Col lg={4}>
          <InforCard
            title={"Total students"}
            value={"122"}
            icon={<UserGroupIcon className="h-5 w-6" />}
            small={
              <>
                <span className="text-[green]">2.3%</span> than last month
              </>
            }
            color="#9999f1"
          />
        </Col>
        <Col lg={4}>
          <InforCard
            title={"Classes"}
            value={"15"}
            icon={<ListBulletIcon className="h-5 w-6" />}
            small={
              <>
                <span className="text-[green]">2.3%</span> than last month
              </>
            }
            color="#15c2a5"
          />
        </Col>
        <Col lg={4}>
          <InforCard
            title={"Total learming Hours"}
            value={"15"}
            icon={<ListBulletIcon className="h-5 w-6" />}
            small={
              <>
                <span className="text-[green]">2.3%</span> than last month
              </>
            }
            color="#ffc400"
          />
        </Col>
      </Row>
      <Card className="my-4">
        <CardBody>
          <div className="flex justify-between">
            <Typography variant="lead">Total Revenue</Typography>
          </div>
          <RevenueChart />
        </CardBody>
      </Card>
    </Fragment>
  );
}

export function OverViewSide() {
  return (
    <Fragment>
      <Button
        className="flex items-center gap-2 bg-default_blue mb-2"
        size="sm"
      >
        <BuildingLibraryIcon strokeWidth={2} className="h-5 w-5" /> Create
        school
      </Button>
      <div className="flex justify-start gap-2">
        <Button
          className="flex items-center gap-2 mb-2"
          variant="outlined"
          size="sm"
        >
          <ArrowDownOnSquareStackIcon strokeWidth={2} className="h-5 w-5" />{" "}
          Export
        </Button>
        <Button className="mb-2" size="sm" variant="outlined">
          <PrinterIcon strokeWidth={2} className="h-5 w-5" />
        </Button>
      </div>
    </Fragment>
  );
}
