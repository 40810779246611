import React, { useState } from "react";
import {
  Cog6ToothIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useEffect } from "react";
import urls from "../../../mixins/base.url";
import { Image } from "react-bootstrap";
import moment from "moment";
import { Spinner } from "@material-tailwind/react";
import accessDeniedImg from "../../../images/accessDeniedImg.svg";
import baseService from "../../../mixins/baseServices";
import CreateSoftWareProductModal from "./software-product-modal";
import EditSoftWareProductModal from "./edit-software-prod-modal";
import BaseService from "../../../helpers/baseServices";
import useTableSyle from "../../../components/shared/useTableSyle";
import { Spin } from "antd";

const SoftWareProducts = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [softWareProducts, setSoftWareProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [dataToEdit, setDataToEdit] = useState([]);
  const [isError, setIsError] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [customStyles] = useTableSyle();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchSoftwareProducts = async () => {
    setIsFetching(true);
    try {
      const response = await BaseService.get_api(
        `/robocentre/software-product`
      );
      const data = await response.data;
      setSoftWareProducts(data?.payload);
      setTotal(data?.total);
      console.log("Data is ", data);
    } catch (error: any) {
      console.log("error from software  : ", error.message);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchSoftwareProducts();
  }, []);
  const handleCloseEdit = () => setShowEdit(false);

  const handleEdit = (data: any) => {
    setDataToEdit(data);
    setShowEdit(true);
  };

  //Table data
  const columns: any = [
    {
      name: <p className="font-semibold text-[14px]">Product Image</p>,
      cell: (row: any) => (
        <div className="flex items-center px-6 gap-2">
          {/* {row?.images.map((_d: any, i: number) => ( */}
          <Image
            src={row?.images[0]}
            alt=""
            className="w-[50px] object-cover"
            fluid
          />
          {/* ))} */}
        </div>
      ),
    },
    {
      name: <p className="font-semibold text-[14px]">Product Name</p>,
      cell: (row: any) => row?.name,
    },
    {
      name: <p className="font-semibold text-[14px]">Description</p>,
      cell: (row: any) => row?.description,
    },
    {
      name: <p className="font-semibold text-[14px]">Product Link</p>,
      cell: (row: any) => row?.link,
    },
    {
      name: <p className="font-semibold text-[14px]">Date Created</p>,

      cell: (row: any) => moment(row?.createdAt).format("Do MMM YYYY"),
    },
    {
      name: <p className="font-semibold text-[14px]">Actions</p>,
      selector: (row: any) => (
        <>
          <div className="flex gap-2 text-default_ash">
            <Link className="text-sm" to="#" onClick={() => handleEdit(row)}>
              <PencilSquareIcon className="w-5 h-5" />
            </Link>

            <Link className="text-sm hover:text-text_danger" to="#">
              <TrashIcon className="w-5 h-5" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="flex justify-between py-10  items-center bg-white shadow-sm  rounded-[20px]">
        <div className="relative px-2 ">
          <input
            type="text"
            className="h-8 w-80 pr-8 pl-5 rounded-lg py-3 px-3 border-solid border-2 border-outline_light placeholder-outline_deep"
            placeholder="Search product..."
          />

          <div className="absolute top-1.5 right-5">
            <i className="fa fa-search text-outline_deep z-20"></i>
          </div>
        </div>

        <div className="flex gap-4  mr-4 items-center">
          <Link
            className="border-[1px] border-default_blue py-2 px-3 text-default_blue rounded-full text-sm flex items-center gap-2"
            to="/product-category"
            style={{ padding: "5px 18px" }}
          >
            <Cog6ToothIcon className="w-5 h-5 " />
            Product Categories
          </Link>
          <Link
            className="text-text_white text-center py-2 px-3 bg-default_blue hover:text-text_white hover:bg-default_blue rounded-full w-fit "
            to="#"
            onClick={() => handleShow()}
          >
            + Add software Product
          </Link>
        </div>
      </div>

      <div className="px-2 pt-2 pb-4 bg-white shadow-sm min-h-[400px] rounded-[20px] mt-3">
        {isError ? (
          <div className="flex flex-col justify-center items-center h-full mt-2">
            <Image className="w-[100px]" src={accessDeniedImg} alt="access" />
            <p className="mt-2">You do not have access to view this data...</p>
          </div>
        ) : (
          <DataTable
            className="mt-4"
            columns={columns}
            data={softWareProducts}
            paginationServer
            progressPending={isFetching}
            progressComponent={<Spin />}
            pagination
            paginationTotalRows={total}
            pointerOnHover
            highlightOnHover
            customStyles={customStyles}
          />
        )}
      </div>

      {/* create product modal */}
      {show ? (
        <CreateSoftWareProductModal
          showModal={show}
          handleCloseModal={handleClose}
        />
      ) : (
        <></>
      )}

      {/* edit product modal */}
      {showEdit ? (
        <EditSoftWareProductModal
          showEditModal={showEdit}
          handleEditCloseModal={handleCloseEdit}
          editData={dataToEdit}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SoftWareProducts;
