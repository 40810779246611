import React, { useEffect, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { Form, Input, message, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { PiCloudArrowUpLight } from 'react-icons/pi';
import { UpdateSingleCourse } from '../../functions/updateSingleCourse';
import DescriptionInputComponent from '../../components/descriptionInputComponent';
import { useMainContext } from '../../../../contexts/mainContext';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';

const CourseActivityThemes = () => {
    const [form] = useForm();
    const { id } = useParams();
    const { singleCourseInfo, setSingleCourseInfo } = useMainContext();
    const [isBusy, setIsBusy] = useState(false);
    const [activityThemes, setActivityThemes] = useState([{ title: '', description: '' }]);

    // Initialize form values when singleCourseInfo is loaded
    useEffect(() => {
        if (singleCourseInfo) {
            console.log("singleCourseInfo", singleCourseInfo)
            if (singleCourseInfo?.activityThemes.length === 0) {
                setActivityThemes([{ title: '', description: '' }]);
            } else {
                setActivityThemes(singleCourseInfo?.activityThemes);
            }
        } else {
            console.log('No course to edit');
        }
    }, [singleCourseInfo, form]);

    // Add a new activity theme input set
    const handleAddActivity = () => {
        setActivityThemes([...activityThemes, { title: '', description: '' }]);
    };

    // Update the specific activity in the array
    const handleActivityChange = (index: number, field: any, value: any) => {
        const newActivityThemes: any = [...activityThemes];
        newActivityThemes[index][field] = value;
        setActivityThemes(newActivityThemes);
    };

    // Remove an activity theme
    const handleRemoveActivity = (index: number) => {
        const newActivityThemes = activityThemes.filter((_, i) => i !== index);
        setActivityThemes(newActivityThemes);
    };

    //initialValues
    const initialValues = {
        activityThemes: singleCourseInfo.activityThemes.map((theme: any) => ({
            title: theme.title,
            description: theme.description
        }))
    }


    // Handle form submission
    const handleOnSubmit = () => {
        form.validateFields().then((formData) => {
            // Prepare the data to send
            const updateInfo = {
                _id: id,
                countryId: '65d4b85e9ae65ff72c90900e',
                activityThemes,
            };

            setIsBusy(true);
            UpdateSingleCourse(updateInfo)
                .then((response: any) => {
                    setSingleCourseInfo(response?.data?.payload);
                    message.success('Course information updated successfully!');
                    setIsBusy(false);
                })
                .catch((error) => {
                    message.error(
                        error?.response?.data?.error || 'Error while updating course. Please try again'
                    );
                    setIsBusy(false);
                });
        }).catch((err) => {
            console.log('Form validation errors:', err);
        });
    };


    return (
        <div className="w-full bg-white rounded-[20px] shadow-sm px-[30px] py-[24px]">
            <div className="flex justify-between items-center">
                <p className="text-[21px] font-[600] text-text_primary">Activity Themes</p>
                <button
                    className="px-[20px] py-[8px] rounded-[30px] bg-default_blue text-white flex gap-[5px] items-center"
                    onClick={handleOnSubmit}
                    disabled={isBusy}
                >
                    <PiCloudArrowUpLight className="h-[20px] w-[20px]" />
                    <p>Save Changes</p>
                </button>
            </div>
            <hr className="my-[10px] border-[#CCCCF8]" />
            <div className="w-full">
                <Spin spinning={isBusy}>
                    <Form form={form} className="w-full" initialValues={initialValues} onFinish={handleOnSubmit}>
                        {activityThemes.map((activity, index) => (
                            <div key={index}>
                                <div className="flex justify-end">
                                    {activityThemes.length > 1 && (
                                        <TrashIcon
                                            className="h-[20px] w-[20px] text-red-500 cursor-pointer"
                                            onClick={() => handleRemoveActivity(index)}
                                        />
                                    )}
                                </div>
                                <div className="grid grid-cols-5 mt-[20px]">
                                    <div className="col-span-1">
                                        <p><span className="text-red-700">*</span> Title</p>
                                    </div>
                                    <div className="col-span-4">
                                        <Form.Item
                                            name={['activityThemes', index, 'title']}
                                            rules={[{ required: true, message: 'Please add a title' }]}
                                        >
                                            <Input
                                                className="h-[40px]"
                                                onChange={(e) =>
                                                    handleActivityChange(index, 'title', e.target.value)
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="grid grid-cols-5 mt-[10px]">
                                    <div className="col-span-1">
                                        <p><span className="text-red-700">*</span> Description</p>
                                    </div>
                                    <div className="col-span-4">
                                        <Form.Item
                                            name={['activityThemes', index, 'description']}
                                            rules={[{ required: true, message: 'Please add a description' }]}
                                        >
                                            <DescriptionInputComponent
                                                value={initialValues?.activityThemes[index]?.description}
                                                onChange={(e) =>
                                                    handleActivityChange(index, 'description', e)
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <hr className="my-[10px] border-[#CCCCF8]" />
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={handleAddActivity}
                            className="flex gap-[4px] items-center text-default_blue"
                        >
                            <PlusIcon className="h-[20px] w-[20px]" />
                            <p>Add more</p>
                        </button>
                    </Form>
                    <div className="w-full flex justify-end mt-[10px]">
                        <button
                            className="px-[20px] py-[8px] rounded-[30px] bg-default_blue text-white flex gap-[5px] items-center"
                            onClick={handleOnSubmit}
                            disabled={isBusy}
                        >
                            <PiCloudArrowUpLight className="h-[20px] w-[20px]" />
                            <p>Save Changes</p>
                        </button>
                    </div>
                </Spin>
            </div>
        </div>
    );
};

export default CourseActivityThemes;
