import { useReducer, useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { FaChild } from "react-icons/fa";
import BaseService from "../../../helpers/baseServices";
import TextArea from "antd/es/input/TextArea";
import ShowToast from "../../../components/Alerts/all_toasts";
import SearchUserByEmail from "../../../components/shared/searchUserByEmail";

interface CreateGradeLevelProps {
  handleReFetch: any;
  showModal: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface State {
  userid: string;
  professionalSummary: string;
  isFormValid: boolean;
}

const initialState: State = {
  userid: "",
  professionalSummary: "",
  isFormValid: false,
};

type Action =
  | { type: "SET_USER_ID"; payload: string }
  | { type: "SET_SUMMERY"; payload: string }
  | { type: "SET_FORM_VALIDATION"; payload: boolean };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_USER_ID":
      return { ...state, userid: action.payload };
    case "SET_SUMMERY":
      return { ...state, professionalSummary: action.payload };
    case "SET_FORM_VALIDATION":
      return { ...state, isFormValid: action.payload };
    default:
      return state;
  }
};

const AddEducators = ({
  handleReFetch,
  showModal,
  open,
  setOpen,
}: CreateGradeLevelProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [isHovered, setisHovered] = useState<boolean>(false);

  //handleCreateEducator
  const handleCreateEducator = async () => {
    setConfirmLoading(true);
    try {
      const payload = {
        userid: state.userid,
        professionalSummary: state.professionalSummary,
      };
      console.log("Sending payload:", payload);
      const results = await BaseService.post_api(
        "/robocentre/educator",
        payload
      );
      handleReFetch();
      console.log("API results:", results);
      setOpen(false);
      ShowToast.success_toast("Created successfully");
    } catch (error: any) {
      console.log("Error is ", error?.response?.data?.error);
      ShowToast.info_toast(error?.response?.data?.error);
      console.log(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  useEffect(() => {
    const { userid, professionalSummary } = state;
    const isFormValid = !!userid?.trim() && professionalSummary.trim() !== "";
    dispatch({ type: "SET_FORM_VALIDATION", payload: isFormValid });
  }, [state.professionalSummary, state.userid]);

  const handleCancel = () => {
    setOpen(false);
  };

  const handlerProfessionalSummary = (name: string) => {
    dispatch({ type: "SET_SUMMERY", payload: name });
  };


  return (
    <>
      <button className="flex gap-[4px] justify-center rounded-full items-center h-[40px] bg-default_blue hover:bg-[#9494F5] text-white border border-[#9494F5] px-[20px] py-[10px]" onClick={showModal}>
        <FaChild size={"1.5em"} />
        <p>Create Educator</p>
      </button>

      <Modal
        open={open}
        onOk={handleCreateEducator}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="Create Educator"
        okButtonProps={{
          style: {
            backgroundColor: "#6666EA",
            borderColor: "#6666EA",
            color: "#FFFFFF",
            boxShadow: "none",
            borderRadius: "30px",
            height: '40px',
            width: '140px'
          },
          disabled: !state.isFormValid,
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "40vh" } }}
      >
        <div className=" rounded-2xl px-4">
          <p className="text-2xl mt-1 font-body text-text_primary font-bold">
            Add New Educator
          </p>
          <hr className="my-3 text-default" />
          <div className=" rounded-lg p-2">
            <div className="w-full mb-4">
              <p className="ml-2 mb-1">Users</p>
              <SearchUserByEmail
                onChange={(selected) => {
                  dispatch({ type: "SET_USER_ID", payload: selected?.value });
                }}
              />
            </div>

            <div className="mt-[20px]">
              <p className="">Professional Summary</p>
              <TextArea
                placeholder="Enter professional Summary"
                className="noplaceholder  w-full  border-1 border-solid border-[#ccccf8]  rounded-[10px]"
                value={state.professionalSummary}
                onChange={(e) => {
                  handlerProfessionalSummary(e.target.value);
                }}
                style={{ height: "120px" }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddEducators;
