import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Empty, notification, Spin, Tooltip } from "antd";
import BaseService from "../../../../helpers/baseServices";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import AddNewProfession from "./addNewProfession";
import EditProfessionModal from "./editProfessionModal";
import ShowAlert from "../../../../components/Alerts/all_alerts";


const AllProfessionsTable = () => {

  const [isFetching, setIsFetching] = useState(true);
  const [theList, setTheList] = useState([])
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0)
  const [total, setTotal] = useState(0)
  const [reFetch, setReFetch] = useState(false)
  const [selected, setSelected] = useState<any>(null)

  //createModal
  const [openCreate, setOpenCreate] = useState(false)
  //editModal
  const [openEdit, setOpenEdit] = useState(false)
  const handleOpenEdit = (data: any) => {
    setSelected(data)
    setOpenEdit(true)
  }

  //onload
  useEffect(() => {
    const GetProfession = async () => {
      setIsFetching(true);

      try {
        const response = await BaseService.base_get_api(`/mingo-org/profession?limit=${limit}&skip=${skip}`);
        console.log("getProfRes:", response?.data);
        setTheList(response?.data?.payload)
        setTotal(response?.data?.total)
        setIsFetching(false);
      } catch (error) {
        console.log("getProfErr:", error);
      }
    };

    GetProfession();
  }, [limit, skip, reFetch]);



  //handleDeleteProfession
  const handleDeleteProfession = (data: any) => {
    console.log("toDelete:", data)
    let data_to_delete = {
      "id": data?._id
    }

    ShowAlert.question_alert("Confirm", `Do you want to delete ${data?.name}? This action cannot be reversed.`, 'Delete')
      .then((result) => {
        if (result.isConfirmed) {
          BaseService.base_delete_api(`/mingo-org/profession`, data_to_delete)
            .then((response) => {
              console.log("delRes:", response)
              notification.success({ message: `${data?.name} has been deleted successfully!` })
              setReFetch(!reFetch)
            }).catch((error: any) => {
              notification.error({ message: error?.response?.data?.error || "An error occured while deleting profession! Please retry" })
            })
        }
      })

  }


  //table columns
  const columns = [
    {
      name: <p className='text-[16px] text-text_primary font-[600]'>Title</p>,
      cell: (row: any) => (
        <p>
          {row?.name}
        </p>
      ),
    },
    {
      name: <p className='text-[16px] text-text_primary font-[600]'>Description</p>,
      cell: (row: any) => (
        <p>
          {row?.description}
        </p>
      ),
    },
    {
      name: <p className='text-[16px] text-text_primary font-[600]'>Last Updated</p>,
      cell: (row: any) => (
        <p>
          {moment(row?.updatedAt).format("Do MMM YYYY - LT")}
        </p>
      ),
    },
    {
      name: <p className='text-[16px] text-text_primary font-[600]'>Actions</p>,
      cell: (row: any) => (
        <div className="flex gap-[15px] items-center">
          <Tooltip title='Edit'>
            <PencilSquareIcon className="h-[20px] w-[20px] text-default_blue" onClick={() => handleOpenEdit(row)} />
          </Tooltip>

          <Tooltip title='Delete'>
            <TrashIcon className="h-[20px] w-[20px] text-red-500" onClick={() => handleDeleteProfession(row)} />
          </Tooltip>
        </div>
      ),
    },
  ];


  return (
    <>
      <div className="bg-white text-gray-700 w-full rounded-3xl focus:ring-opacity-50 shadow-md py-4 px-4 mt-4">
        <div className="flex justify-between items-center">
          <div className="">

          </div>

          <div>
            <button className="px-[20px] py-2 rounded-[30px] bg-default_blue text-white shadow-sm" onClick={() => setOpenCreate(true)}>
              Create Profession
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white p-4 drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[24px] mt-4">
        <DataTable
          columns={columns}
          data={theList}
          progressPending={isFetching}
          progressComponent={<div className='flex justify-center items-center h-[70vh]'><Spin /></div>}
          pointerOnHover
          highlightOnHover
          noDataComponent={<div className='flex justify-center items-center h-[70vh]'><Empty description="No staff data found!" /></div>}
          // onRowClicked={(row) => navigate(`/manage-staff/${row?.companyID}`)}
          paginationServer
          pagination
          paginationTotalRows={total}
        />
      </div>



      {/* create profession modal */}
      {
        openCreate && <AddNewProfession isOpened={openCreate} handleClose={() => setOpenCreate(false)} handleReFetch={() => setReFetch(!reFetch)} />
      }


      {/* edit profession modal */}
      {
        openEdit && <EditProfessionModal isOpened={openEdit} handleClose={() => setOpenEdit(false)} handleReFetch={() => setReFetch(!reFetch)} dataToEdit={selected} />
      }

    </>
  );
};

export default AllProfessionsTable;
