import React, { useEffect, useState } from 'react'
import BaseService from '../../../../helpers/baseServices'
import { Select } from 'antd'


interface theProps {
    onChange: (data: any) => void
    placeholder?: string
    defaultValue?: any
}


const ProfessionSelectorComponent = ({ onChange, defaultValue, placeholder }: theProps) => {

    const [isFetching, setIsFetching] = useState(true)
    const [theList, setTheList] = useState([])

    //onload
    useEffect(() => {
        const GetProfessions = async () => {
            setIsFetching(true)

            try {
                const response = await BaseService.base_get_api(`/mingo-org/profession`)
                // console.log("getProfsRes:", response?.data)
                const mapped = response?.data?.payload.map((item: any) => (
                    { label: item?.name, value: item?._id }
                ))
                setTheList(mapped)
                setIsFetching(false)
            } catch (error) {
                console.log("getProfsErr:", error)
            }
        }

        GetProfessions()
    }, [])

    return (
        <>
            <Select
                className='h-[40px] w-full'
                options={theList}
                placeholder={placeholder || "Select job title"}
                defaultValue={defaultValue}
                onChange={onChange}
                disabled={isFetching}
                loading={isFetching}
                virtual
            />
        </>
    )
}

export default ProfessionSelectorComponent
