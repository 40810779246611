import { Select } from "antd";

const SelectOnyCountry = ({
  onCountrySelect,
  country,
}: {
  onCountrySelect: any;
  country: any;
}) => {
  const handleChange = (value: string) => {
    onCountrySelect(value);
  };
  return (
    <Select
      defaultValue="Ghana"
      placeholder="Select Country"
      allowClear
      value={country}
      onChange={handleChange}
      style={{ width: "100%", height: "40px" }}
      options={[
        { value: "Ghana", label: "Ghana" },
        { value: "Other", label: "Other" },
      ]}
    />
  );
};

export default SelectOnyCountry;
