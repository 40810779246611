import React, { useState } from 'react'
import EditCourseInformation from './tabs/editCourseInformation'
import EditTargetLearners from './tabs/editTargetLearners'
import CourseActivityThemes from './tabs/courseActivityThemes'



const EditCoursesPage = () => {

    const [activeTab, setActiveTab] = useState(0)

    //tabNames
    const tabNames = [
        "Course Information", "Target Learners", "Activity Themes"
    ]

    //tabComponents
    const tabComponents = [
        <EditCourseInformation />,
        <EditTargetLearners />,
        <CourseActivityThemes />
    ]

    return (
        <>
            {/* content */}
            <div className="mt-[20px] w-full grid grid-cols-4 gap-[20px]">
                {/* left */}
                <div className="w-full col-span-1 sticky-top h-[500px]">
                    <div className="w-full h-fit bg-white rounded-[20px] shadow-sm py-[35px]">

                        <div className="mt-[5px]">
                            {
                                tabNames.map((tab, i) => (
                                    <div key={i} className={`px-[23px] py-[15px] cursor-pointer ${activeTab === i ? 'border-l-[7px] border-l-default_blue bg-[#F8F8F8]' : 'border-l-[7px] border-l-transparent'}`} onClick={() => setActiveTab(i)}>
                                        <p className='font-[400] text-text_primary'>{tab}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                {/* right */}
                <div className='w-full col-span-3'>
                    {tabComponents[activeTab]}
                </div>
            </div>
        </>
    )
}

export default EditCoursesPage
