import { useState } from "react";
import { Button, Modal } from "antd";
import { IoCreateOutline } from "react-icons/io5";
import { SizeType } from "antd/es/config-provider/SizeContext";
import CustomButtomRounded from "../../../components/shared/CustomButtomRounded";
import { FaArrowLeft } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { GoCheckCircleFill } from "react-icons/go";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";
import SchoolInformation from "../Tabs/SchoolInformation";
const CreateSchool = ({
  showModal,
  open,
  setOpen,
}: {
  showModal: any;
  open: any;
  setOpen: any;
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const [size, setSize] = useState<SizeType>("large");
  const [isHovered, setIsHovered] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };
  const tabNames = ["School Info"];
  const newTabNames = [
    { text: "School Info", icon: GoCheckCircleFill },
    { text: "Grade Levels", icon: GoCheckCircleFill },
    { text: "Privacy and Terms", icon: RiCheckboxBlankCircleFill },
  ];

  const theTabs = [<SchoolInformation />];

  const buttonStyle = {
    backgroundColor: isHovered ? "#9494F5" : "#6666EA",
    color: "white",
    border: "1px solid #9494f5",
    display: "flex",
    alignItems: "center",
  };
  const handlerClick = (e: any) => {
    console.log(e);
  };

  return (
    <>
      <Button
        type="default"
        shape="round"
        icon={<IoCreateOutline size={"2em"} style={{ marginRight: 8 }} />}
        className="flex items-center"
        size={size}
        onClick={showModal}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={buttonStyle}
      >
        <div className="flex items-center justify-center">
          <p>Create Robocentre Main School</p>
        </div>{" "}
      </Button>

      <Modal
        // title="Send Message to "
        width={"80%"}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            backgroundColor: "#6666EA",
            borderColor: "#6666EA",
            color: "#FFFFFF",
            boxShadow: "none",
          },
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "70vh" } }}
      >
        <section className="flex justify-between p-6">
          <div className="flex mt-10 justify-center  gap-2 items-center">
            <div>
              <CustomButtomRounded
                icon={<FaArrowLeft />}
                value={"Back"}
                onClick={handlerClick}
              />
            </div>
            <p className="text-2xl mt-1 font-body text-text_primary font-bold">
              Create School
            </p>
          </div>
          <div className="flex mt-10 justify-center gap-2  items-center">
            <CustomButtomRounded
              icon={<FaPlus />}
              value={"Save as draft"}
              onClick={handlerClick}
            />
            <CustomButtomRounded
              icon={<FaPlus />}
              value={"Publish School"}
              onClick={handlerClick}
            />
          </div>
        </section>
        <section className="grid  grid-cols-10 gap-12 p-6">
          <div className="col-span-3   rounded-2xl   shadow-sm">
            <div className="flex flex-col  py-3 ">
              {newTabNames.map((d, i) => (
                <div
                  key={i}
                  className={`${
                    activeTab === i
                      ? "border-l-[5px]  border-default_blue bg-gray-200 text_primary"
                      : "bg-white text-text_primary"
                  }  py-2  cursor-pointer `}
                  onClick={() => setActiveTab(i)}
                >
                  <div className="flex items-center p-2 gap-2 ml-2">
                    <p className="text-default_green">
                      <d.icon size={"1.5em"} />
                    </p>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-7    rounded-2xl  p-4 shadow-sm">
            <div>{theTabs[activeTab]}</div>
          </div>
        </section>
      </Modal>
    </>
  );
};

export default CreateSchool;
