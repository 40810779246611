import { IoMdMore } from "react-icons/io";
import { PiVideoFill } from "react-icons/pi";
const CurriculumComponent = ({
  title,
  points,
  units,
  tasks,
}: {
  title: any;
  points: any;
  units: any;
  tasks: any;
}) => {
  return (
    <section
      //   onClick={(e: any) => onClick(e, grades)}
      className="drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] hover:drop-shadow-none    bg-white hover:bg-super_light  cursor-pointer text-text_primary   rounded-[20px] w-[90%]"
    >
      <div className="flex  justify-between">
        <div className="flex gap-2 p-2 items-center ">
          <div className="w-[90px] h-[90px] flex justify-center items-center  ">
            {/* <img
              src={otp}
              alt="avatar"
              className="rounded-xl"
              style={{ width: "100%", height: "100%" }}
            /> */}
            <PiVideoFill color={"tomato"} size="4em" />
          </div>
          <div>
            <p className="font-bold text-xl text-light_blue">{title}</p>
            <div className="flex text-white items-center gap-2 ">
              <p className="px-3 text-[12px] bg-red-400 rounded-xl">Live</p>
              <p className="px-3 text-[12px]  bg-default rounded-xl">Public</p>
            </div>
          </div>
        </div>
        <div className="flex gap-4  w-[50%] justify-between items-center">
          <p className="flex gap-2 items-center flex-col   rounded-lg ">
            <span className="text-green-300 font-bold text-4xl">{tasks}</span>
            <span>Tasks</span>
          </p>
          <p className="flex gap-2 items-center flex-col rounded-lg  ">
            <span className="text-yellow-500 font-bold  text-4xl">{units}</span>
            <span>Units</span>
          </p>
          <p className="flex gap-2 items-center  flex-col rounded-lg  ">
            <span className="text-violet-500 font-bold text-4xl">{points}</span>
            <span>Max Points</span>
          </p>
          <p>
            <IoMdMore size={"2em"} className="text-[#9494f5] " />
          </p>
        </div>
      </div>
    </section>
  );
};

export default CurriculumComponent;
