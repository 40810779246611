import React from "react";
import { CiEdit } from "react-icons/ci";

const CouponCardComponent = ({ textColor }: { textColor: any }) => {
  return (
    <section className="rounded-[20px] flex justify-between shadow-md hover:shadow-sm p-4 gap-3 bg-white">
      <div>
        <CiEdit size="1.5em" color="#6666EA" />
      </div>
      <div>
        <p className="w-[130px] h-[130px]    rounded-full flex justify-center items-center bg-[#D7F2ED]">
          <span className="text-[#15C2A5] text-[42px] font-bold">40%</span>
        </p>
      </div>
      <div className="flex items-center">
        <div className="flex flex-col gap-6">
          <p>Product Name </p>
          <p>Code</p>
          <p>Expiry Date</p>
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex flex-col gap-2">
          <section className="bg-[#F9F9FF] border border-outline_light px-8 py-2 rounded-md">
            Mingo Robot
          </section>
          <section className="bg-[#F9F9FF] border border-outline_light px-8 py-2 rounded-md">
            gw45y4u5
          </section>
          <section className="bg-[#F9F9FF] border border-outline_light px-8 py-2 rounded-md">
            23 Jun. 20234
          </section>
        </div>
      </div>
    </section>
  );
};

export default CouponCardComponent;
