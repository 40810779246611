import { useReducer, useRef, useState } from "react";
import { Button, Modal, Select, message } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { FaChild } from "react-icons/fa";
import Quill from "quill";
import RangeStatic from "quill";
import BaseService from "../../../helpers/baseServices";
import MyEditor from "../MyEditor";

const Delta = Quill.import("delta");

interface CreateTermsAndConditionProps {
  handleReFetch: any;
  showModal: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface State {
  confirmLoading: boolean;
  size: SizeType;
  isHovered: boolean;
  isFormValid: boolean;
  userType: string | null;
  type: string | null;
  content: string;
}

const initialState: State = {
  confirmLoading: false,
  size: "large",
  isHovered: false,
  isFormValid: false,
  userType: null,
  type: null,
  content: "",
};

type Action =
  | { type: "SET_CONFIRM_LOADING"; payload: boolean }
  | { type: "SET_HOVERED"; payload: boolean }
  | { type: "SET_FORM_VALIDITY"; payload: boolean }
  | { type: "SET_USER_TYPE"; payload: string }
  | { type: "SET_TYPE"; payload: string }
  | { type: "SET_CONTENT"; payload: string };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_CONFIRM_LOADING":
      return { ...state, confirmLoading: action.payload };
    case "SET_HOVERED":
      return { ...state, isHovered: action.payload };
    case "SET_FORM_VALIDITY":
      return { ...state, isFormValid: action.payload };
    case "SET_USER_TYPE":
      return { ...state, userType: action.payload };
    case "SET_TYPE":
      return { ...state, type: action.payload };
    case "SET_CONTENT":
      return { ...state, content: action.payload };
    default:
      return state;
  }
};

const CreateTermsandCondition = ({
  handleReFetch,
  showModal,
  open,
  setOpen,
}: CreateTermsAndConditionProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [range, setRange] = useState<RangeStatic | null>(null);
  const [lastChange, setLastChange] = useState<any>(null);
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const quillRef = useRef<Quill | null>(null);

  const handleOk = async () => {
    dispatch({ type: "SET_CONFIRM_LOADING", payload: true });

    const payload = {
      userType: state.userType,
      type: state.type,
      content: state.content,
    };

    console.log("payload", payload);

    try {
      const results = await BaseService.post_api(
        "/robocentre/terms-condition-policy",
        payload
      );
      console.log(results.data.payload);
      message.success("Terms and Conditions created successfully");
      handleReFetch();
      setOpen(false);
    } catch (error: any) {
      console.error("Error creating Terms and Conditions:", error);
      message.error(
        error?.response?.data?.error || "Failed to create Terms and Conditions"
      );
    } finally {
      dispatch({ type: "SET_CONFIRM_LOADING", payload: false });
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        type="default"
        // shape="round"
        icon={<FaChild size={"1.5em"} style={{ marginRight: 8 }} />}
        className="flex rounded-[20px] items-center"
        size={state.size}
        onClick={showModal}
        onMouseEnter={() => dispatch({ type: "SET_HOVERED", payload: true })}
        onMouseLeave={() => dispatch({ type: "SET_HOVERED", payload: false })}
        style={{
          backgroundColor: state.isHovered ? "#9494F5" : "#6666EA",
          color: "white",
          border: "1px solid #9494f5",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="flex items-center justify-center">
          <p>Create Terms and Conditions</p>
        </div>
      </Button>

      <Modal
        width={"80%"}
        open={open}
        onOk={handleOk}
        confirmLoading={state.confirmLoading}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            backgroundColor: "#6666EA",
            borderColor: "#6666EA",
            color: "#FFFFFF",
            boxShadow: "none",
          },
          disabled: !state.isFormValid,
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        // styles={{ body: { minHeight: "70vh" } }}
      >
        <div className="">
          <p className="text-2xl mt-1 font-body text-text_primary font-bold">
            Terms and Conditions
          </p>
          <hr className="my-3 text-default" />
          <div className="flex justify-between py-4 items-center bg-white shadow-sm rounded-[10px]">
            <div className="ml-4 w-[40%] flex items-center justify-between gap-4">
              <Select
                className="w-full"
                allowClear
                placeholder="Select User Type"
                onChange={(value) => {
                  dispatch({ type: "SET_USER_TYPE", payload: value });
                  dispatch({
                    type: "SET_FORM_VALIDITY",
                    payload: !!value && !!state.type && !!state.content,
                  });
                }}
                options={[
                  { value: "PARENT", label: "PARENT" },
                  { value: "USER", label: "GENERAL USER" },
                  { value: "ORGANISATION", label: "ORGANISATION" },
                  { value: "SCHOOL", label: "SCHOOL" },
                ]}
              />
              <Select
                className="w-full"
                placeholder="Select Type"
                allowClear
                onChange={(value) => {
                  dispatch({ type: "SET_TYPE", payload: value });
                  dispatch({
                    type: "SET_FORM_VALIDITY",
                    payload: !!value && !!state.userType && !!state.content,
                  });
                }}
                options={[
                  { value: "POLICY", label: "PRIVACY POLICY" },
                  { value: "TERMS_CONDITION", label: "TERMS AND CONDITION" },
                ]}
              />
            </div>
          </div>
          <div className="my-5">
            <MyEditor
              readOnly={readOnly}
              onTextChange={(delta, oldDelta, source) => {
                setLastChange({ delta, oldDelta, source });
                const content = quillRef.current?.root.innerHTML || "";
                dispatch({ type: "SET_CONTENT", payload: content });
                dispatch({
                  type: "SET_FORM_VALIDITY",
                  payload: !!state.userType && !!state.type && !!content,
                });
              }}
              onSelectionChange={(range, oldRange, source) => {
                setRange(range);
              }}
              ref={quillRef}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateTermsandCondition;
