import React from "react";
import { FaPhotoFilm } from "react-icons/fa6";
const LegalDetails = () => {
  return (
    <div className="p-4 text-text_deep shadow-md rounded-[25px] my-4 bg-white">
      <p className="text-[20px] text-text_deep mb-2 font-medium ">
        Legal Details{" "}
      </p>
      <hr />
      <div className="grid grid-cols-3 gap-3  mt-6">
        <section>
          <p className="text-[12px]">Product Date</p>
          <p className="mt-1 text-[16px]">Sept. 27, 2023</p>
        </section>
        <section>
          <p className="text-[12px]">Weight</p>
          <p className="mt-1 text-[16px]">120kg</p>
        </section>
        <section>
          <p className="text-[12px]">In Stock</p>
          <p className="mt-1 text-[16px]">23455</p>
        </section>
        <section>
          <p className="text-[12px]">Category</p>
          <p className="mt-1 text-[16px]">Robot kit</p>
        </section>
        <section>
          <p className="text-[12px]">In Stock</p>
          <p className="mt-1 text-[16px]">23455</p>
        </section>
        <section>
          <p className="text-[12px]">Category</p>
          <p className="mt-1 text-[16px]">Robot kit</p>
        </section>
      </div>

      <div className="grid grid-cols-2  relative  my-4 gap-5  w-[60%]">
        <section className="   rounded-md">
          <p className="text-[14px]">Incorporation Certificate</p>
          <div className="flex h-[170px] bg-super_light  border-1 border-[#CCCCF8] rounded-md border-dashed  justify-center items-center">
            <FaPhotoFilm size={"3em"} />
          </div>
        </section>
        <section className="rounded-md">
          <p className="text-[14px]">Accreditation Certificate</p>
          <div className="flex h-[170px]  bg-super_light   border-1 border-[#CCCCF8] rounded-md border-dashed  justify-center items-center">
            <FaPhotoFilm size={"3em"} />
          </div>
        </section>
      </div>
    </div>
  );
};

export default LegalDetails;
