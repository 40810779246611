import { useState } from "react";
import PageLayout from "../../components/Layouts/page-layout";
import { useParams } from "react-router-dom";
import CustomButtomRounded from "../../components/shared/CustomButtomRounded";
import CustomButtomSquare from "../../components/shared/CustomButtomSquare";
import { IoEyeOutline } from "react-icons/io5";
import { RxAvatar } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import otp from "../../images/customer_img.png";
import Profile from "./Tabs/profile/Profile";
import Order from "./Tabs/order/order";
import Cart from "./Tabs/Cart/Cart";
import WishList from "./Tabs/WishList/WishList";
const CustomersDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const handlerClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    navigate("/customers");
  };
  // console.log(id);
  const [activeTab, setActiveTab] = useState(0);

  const tabNames = ["Profile", "Order", "Cart", "Wishlist"];
  const theTabs = [<Profile />, <Order />, <Cart />, <WishList />];
  console.log("activeTab", activeTab);
  return (
    <>
      <PageLayout>
        <div className="m-4  flex gap-8 ">
          <section className="w-[80%] ">
            <div className="flex items-center gap-4 ml-6 mb-14   ">
              <CustomButtomRounded
                icon={<FaArrowLeft />}
                value={"Back"}
                onClick={handlerClick}
              />
              <h2 className="text-4xl text-text_primary font-semibold">
                John Doe
              </h2>
            </div>
            <div className="ml-6 mt-8">
              <div className="flex">
                {tabNames.map((d, i) => (
                  <div
                    key={i}
                    className={`${
                      activeTab === i
                        ? "bg-gradient-to-tr  from-default_blue  to-light_blue text-white"
                        : "bg-white text-text_primary"
                    } shadow-sm w-fit  py-3 rounded-[20px] px-14 mr-2 cursor-pointer`}
                    onClick={() => setActiveTab(i)}
                  >
                    <h2 className="font-semi-bold">{d}</h2>
                  </div>
                ))}
              </div>
              <div>{theTabs[activeTab]}</div>
            </div>
          </section>
          <section className=" flex flex-col gap-2  w-[25%] ">
            <div className=" rounded-[20px] bg-white shadow-md py-4 mb-8">
              <section className="flex justify-center   text-text_primary items-center flex-col">
                <div className="w-[140px] h-[140px] ">
                  <img
                    src={otp}
                    alt="avatar"
                    className="rounded-full"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <p>Joana Doe LLC</p>
                <p>Email@gmail.com</p>
                <p>MBX775398xcyyu87</p>
              </section>
              <section className="flex gap-8 mt-6  items-center text-text_primary justify-center">
                <p>
                  <p className="text-4xl font-bold">2001</p>
                  <p>Students</p>
                </p>
                <div>
                  <div className="flex justify-center">
                    <div className="h-[58px] border-l-2 border-text_primary"></div>
                  </div>
                </div>
                <p>
                  <p className="text-4xl font-bold">34</p>
                  <p>Classes</p>
                </p>
              </section>
            </div>
            <div>
              <div className="w-[80%] grid gap-2 grid-cols-2 grid-rows-3 ">
                <CustomButtomSquare
                  value="Send message"
                  icon={<RxAvatar size={"1.3em"} />}
                  myStyle={"col-span-2 text-[14px] font-semibold shadow-md"}
                />
                <CustomButtomSquare
                  value="Export"
                  icon={<RxAvatar size={"1.3em"} />}
                  myStyle={
                    "row-start-2  row-end-3 text-[14px] shadow-md font-semibold"
                  }
                  row-start-2
                />
                <CustomButtomSquare
                  myStyle={
                    "flex justify-center items-center text-[14px] shadow-md font-semibold"
                  }
                  icon={<IoEyeOutline size={"1.3em"} />}
                />

                <CustomButtomSquare
                  value="Suspend User"
                  icon={<IoEyeOutline size={"1.3em"} />}
                  myStyle={"col-span-2 text-[14px] shadow-md font-semibold"}
                />
              </div>
            </div>
          </section>
        </div>
      </PageLayout>
    </>
  );
};

export default CustomersDetails;
