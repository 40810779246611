import { XMarkIcon } from "@heroicons/react/24/outline";
import { DatePicker, Input, Modal } from "antd";

interface modalProps {
  isOpened: boolean
  handleCloseModal: () => void
}

const CreateNewCoupon = ({ isOpened, handleCloseModal }: modalProps) => {
  return (
    <>
      <Modal width={800} open={isOpened} closeIcon={false} footer={null}>
        <div className="p-[10px]">
          <div className="flex justify-between items-center">
            <p className="text-[20px] font-[700]">
              Create a coupon
            </p>

            <XMarkIcon className="h-[20px] w-[20px] cursor-pointer" onClick={handleCloseModal} />
          </div>

          <hr className="my-[10px]" />

          {/* form */}
          <div className="mt-[20px]">
            <div className="mt-2 flex w-full">
              <div className="w-[35%]">
                <p className="text-md">Product Name</p>
              </div>

              <div className="w-[65%]">
                <Input
                  className="h-[40px] w-full"
                  placeholder="Type here..."
                />
              </div>
            </div>

            <div className="mt-[10px] flex w-full">
              <div className="w-[35%]">
                <p className="text-md">Coupon Code</p>
              </div>

              <div className="w-[65%]">
                <Input
                  className="h-[40px] w-full"
                  placeholder="Type here..."
                />
              </div>
            </div>

            <div className="mt-[10px] flex w-full">
              <div className="w-[35%]">
                <p className="text-md">Running Date</p>
              </div>

              <div className="w-[65%]">
                <div className="flex gap-4">
                  <div className="w-full">
                    <DatePicker className="h-[40px] w-full" placeholder="Start date" />
                  </div>
                  <div className="w-full">
                    <DatePicker className="h-[40px] w-full" placeholder="Expiry date" />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[10px] flex w-full">
              <div className="w-[35%]">
                <p className="text-md">Percentage Discount</p>
              </div>

              <div className="w-[65%]">
                <Input
                  className="h-[40px] w-full"
                  placeholder="Type here..."
                />
              </div>
            </div>

            <div className="mt-[10px] flex w-full">
              <div className="w-[35%]">
                <p className="text-md">Use Count</p>
              </div>

              <div className="w-[65%]">
                <Input
                  className="h-[40px] w-full"
                  placeholder="Type here..."
                />
              </div>
            </div>

            <div className="mt-[10px] flex w-full">
              <div className="w-[35%]">
                <p className="text-md">Description</p>
              </div>

              <div className="w-[65%]">
                <Input.TextArea
                  className="w-full" style={{ height: "80px" }}
                  placeholder="Type here..."
                />
              </div>
            </div>
          </div>

          <div className="mt-[20px] flex justify-end">
            <button className="text-text_danger border-0 mr-8 hover:bg-text_white text-danger" onClick={handleCloseModal}>
              Cancel
            </button>

            <button className="text-text_white bg-default_blue rounded-full w-36 h-[40px] shadow-sm">
              Publish
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateNewCoupon;
