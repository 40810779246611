import React, { useEffect, useState } from 'react'
import { Collapse, Empty, Spin, Tooltip } from 'antd'
import { PencilSquareIcon, PlusCircleIcon, PlusIcon } from '@heroicons/react/24/outline'
import { IoVideocamOutline } from "react-icons/io5";
import BaseService from '../../../../helpers/baseServices';
import { global_variables } from '../../../../helpers/globalVariables';
import AddCourseFaq from './addCourseFaq';
import EditCourseFaq from './editCourseFaq';
import { useParams } from 'react-router-dom'




const CourseFaqsComponent = () => {

    const { id } = useParams()
    const [isFetching, setIsFetching] = useState(true)
    const [reFetch, setReFetch] = useState(false)
    const [allFaqs, setAllFaqs] = useState([])
    const [selectedFaq, setSelectedFaq] = useState<any>(null)

    //modal
    const [openAddModal, setOpenAddModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)

    //handleOpenEdit
    const handleOpenEdit = (data: any) => {
        setSelectedFaq(data)
        setOpenEditModal(true)
    }


    //onload
    useEffect(() => {

        const getAllFaqs = async () => {
            setIsFetching(true)

            try {
                const response = await BaseService.get_api(`${global_variables.course_faqs_route}/${id}`)
                console.log("allFaqsRes:", response?.data)
                setAllFaqs(response?.data?.payload)
                setIsFetching(false)
            } catch (error) {
                console.log("allFaqsErr:", error)
            }
        }

        getAllFaqs()
    }, [reFetch])


    return (
        <>
            <div className="w-full bg-white rounded-[20px] shadow-sm px-[30px] py-[24px]">
                <Spin spinning={false}>
                    <div className="flex justify-between items-center">
                        <p className='text-[21px] font-[600] text-text_primary'>Course FAQs</p>

                        <button className='px-[20px] py-[8px] rounded-[30px] bg-default_blue text-white flex gap-[5px] items-center' onClick={() => setOpenAddModal(true)}>
                            <PlusCircleIcon className='h-[20px] w-[20px]' />
                            <p>Add New FAQ</p>
                        </button>
                    </div>
                    <hr className='my-[10px] border-[#CCCCF8]' />

                    <div className="w-full mt-[20px]">
                        {
                            allFaqs?.length === 0 ?
                                <div className='mt-[30px]'>
                                    <Empty description="No FAQs have been created for this course!" />
                                </div>
                                :
                                <div>
                                    faqsssss
                                </div>
                        }
                    </div>
                </Spin>
            </div>


            {/* open add faq modal */}
            {
                openAddModal && <AddCourseFaq isOpened={openAddModal} handleClose={() => setOpenAddModal(false)} handleReFetch={() => setReFetch(!reFetch)} />
            }


            {/* open edit faq modal */}
            {
                openEditModal && <EditCourseFaq isOpened={openEditModal} selectedFaq={selectedFaq} handleClose={() => setOpenEditModal(false)} handleReFetch={() => setReFetch(!reFetch)} />
            }
        </>
    )
}

export default CourseFaqsComponent
