import { Form, Input, InputNumber, message, Modal, Progress, Spin, Upload, UploadProps } from 'antd'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import DescriptionInputComponent from '../../components/descriptionInputComponent'
import LanguageSelectorComponent from '../../components/languageSelectorComponent'
import { AiOutlineVideoCameraAdd } from 'react-icons/ai'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import { ROBOCENTRE_URL } from '../../../../helpers/constants'
import { global_variables } from '../../../../helpers/globalVariables'
import { StorageBox } from '../../../../helpers/storage'
import { useForm } from 'antd/es/form/Form'
import BaseService from '../../../../helpers/baseServices'
import { PiCloudArrowUpLight } from 'react-icons/pi'


interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
}


const AddCourseFaq = ({ isOpened, handleClose, handleReFetch }: modalProps) => {

    const { id } = useParams()
    const [form] = useForm()
    const [isBusy, setIsBusy] = useState(false)



    //handleOnSubmit
    const handleOnSubmit = () => {
        form.validateFields().then((formData) => {

            let cooked_data = {
                "question": formData?.question,
                "answer": formData?.answer,
                "courseId": id
            }

            console.log("cooked_data:", cooked_data)

            setIsBusy(true)

            BaseService.post_api(global_variables.course_faqs_route, cooked_data)
                .then((response) => {
                    console.log("contentFaqRes:", response?.data)
                    message.success("Course FAQ was created successfully!")
                    setIsBusy(false)
                    handleReFetch()
                    handleClose()
                }).catch((error: any) => {
                    console.log("contentFaqErr:", error)
                    setIsBusy(false)
                    message.error(error?.response?.data?.error || "An unknown error occured. Please retry")
                })

        }).catch((err) => {
            console.log("____formErrors:", err)
        })
    }



    return (
        <>
            <Modal title="Add Course FAQ" width={800} open={isOpened} onCancel={handleClose} footer={null} keyboard={false} maskClosable={false} closeIcon={!isBusy}>
                <hr />
                <Spin spinning={isBusy}>
                    <Form
                        className='w-full'
                        form={form}
                    >

                        <div className="w-full mt-[20px]">
                            {/* Question */}
                            <Form.Item label="Question" name="question" rules={[{ required: true, message: 'Please add a question' }]}>
                                <Input className='w-full h-[40px] bg-transparent' placeholder='Add title here' />
                            </Form.Item>
                        </div>

                        {/* Answer */}
                        <Form.Item className='mt-[20px]' label="Answer" name="answer" rules={[{ required: true, message: 'Please add an answer' }]}>
                            <DescriptionInputComponent onChange={(e) => console.log(e)} placeholder='Enter answer here...' />
                        </Form.Item>

                        <div className="flex justify-end">
                            <Form.Item>
                                <button className='px-[20px] py-[8px] rounded-[30px] bg-default_blue text-white flex gap-[5px] items-center' onClick={() => handleOnSubmit()}>
                                    <PiCloudArrowUpLight className='h-[20px] w-[20px]' />
                                    <p>Publish FAQ</p>
                                </button>
                            </Form.Item>
                        </div>
                    </Form>
                </Spin>
            </Modal >
        </>
    )
}

export default AddCourseFaq
