import { Fragment, useEffect, useState } from "react";
import PageLayout from "../../../components/Layouts/page-layout";
import { Col, Container, Row } from "react-bootstrap";
import {
  Button,
  Card,
  CardBody,
  Checkbox,
  Input,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import ReactQuill from "react-quill";
import ShowToast from "../../../components/Alerts/all_toasts";
import Select from "react-select";
import urls from "../../../mixins/base.url";
import baseService from "../../../mixins/baseServices";

export default function CreateRoboCenterSchool() {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["School info", "Privacy Policy", "Terms and conditions"];
  const [completedSections, setCompletedSections] = useState<any>([]);
  const [name, setName] = useState("");
  const [country, setCountry] = useState<any>([]);
  const [description, setDescription] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [terms, setTerms] = useState("");

  const panels = [
    <SchoolInfo
      name={name}
      setName={setName}
      country={country}
      setCountry={setCountry}
      description={description}
      setDescription={setDescription}
      setActiveTab={setActiveTab}
      setCompletedSections={setCompletedSections}
      completedSections={completedSections}
    />,
    <SchoolPrivacy
      privacy={privacy}
      setPrivacy={setPrivacy}
      setActiveTab={setActiveTab}
      setCompletedSections={setCompletedSections}
      completedSections={completedSections}
    />,
    <SchoolTermsAndConditions
      terms={terms}
      setTerms={setTerms}
      setActiveTab={setActiveTab}
      setCompletedSections={setCompletedSections}
      completedSections={completedSections}
    />,
  ];

  const submitSchool = async () => {
    if (name === "") {
      ShowToast.error_toast("Please enter school name");
      setActiveTab(0);
    } else if (!country.value) {
      ShowToast.error_toast("Please enter school's country");
      setActiveTab(0);
    } else if (privacy === "") {
      ShowToast.error_toast("Please enter the privacy policy");
      setActiveTab(1);
    } else if (terms === "") {
      ShowToast.error_toast("Please enter the terms and conditions");
      setActiveTab(2);
    } else {
      const data = {
        name: name,
        privacyPolicy: privacy,
        termsAndCondition: terms,
        country: country,
        description: description,
        default: false,
      };
      console.log(data);
    }
  };
  function generateRandomColor() {
    // Generate random values for red, green, and blue channels
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);

    // Create a CSS color string using the generated values
    const color = `rgb(${red}, ${green}, ${blue})`;

    return color;
  }

  return (
    <Fragment>
      <PageLayout>
        <div className="m-5">
          <div className="flex justify-between">
            <h2 className="text-2xl font-normal text-text_primary">
              Create Schools
            </h2>
            <Button size="sm" variant="outlined" onClick={submitSchool}>
              Publish school
            </Button>
          </div>
          <Row className="mt-4">
            <Col md={4}>
              <Card className="mb-4">
                <ul className="py-5">
                  {tabs.map((_d, i) => (
                    <li
                      key={i}
                      className={`ps-2 cursor-pointer hover:bg-[#6666EA] hover:text-[white] mb-1 ${
                        activeTab === i
                          ? "border-l-4 border-[#6666EA] py-1 bg-[#F8F8F8] "
                          : ""
                      }`}
                      onClick={() => setActiveTab(i)}
                    >
                      <Checkbox
                        color="green"
                        checked={completedSections.includes(_d)}
                        className="rounded-full"
                      />
                      {_d}
                    </li>
                  ))}
                </ul>
              </Card>
            </Col>
            <Col md={8}>
              <Card>
                <CardBody
                // style={{ backgroundColor: generateRandomColor() }}
                >
                  {panels[activeTab]}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </PageLayout>
    </Fragment>
  );
}

function SchoolInfo({
  name,
  setName,
  country,
  setCountry,
  description,
  setDescription,
  setActiveTab,
  completedSections,
  setCompletedSections,
}: any) {
  const [n_error, setN_error] = useState(false);
  const [success, setSuccess] = useState(false);

  const submitInfo = (e: any) => {
    e.preventDefault();
    if (name === "") setN_error(true);
    if (name !== "" && country.value !== "") {
      setSuccess(true);
      setActiveTab(1);
      setCompletedSections([...completedSections, "School info"]);
    }
  };

  useEffect(() => {
    if (name !== "" && country !== "") {
      setSuccess(true);
    } else {
      setSuccess(false);
      const newList = completedSections.filter((d: any) => d !== "School info");
      setCompletedSections(newList);
    }
  }, []);

  const [countryOptions, setCountryOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const get_data = async () => {
      try {
        const res: any = await baseService.get(urls.country + `?limit=10000`);
        const countryList = res.data?.payload;
        setCountryOptions(
          countryList.map((_d: any) => ({ label: _d.country, value: _d.code }))
        );
        setLoading(false);
      } catch (error) {
        // console.log(error);
      }
    };

    get_data();
  }, []);

  return (
    <Fragment>
      <Typography variant="h5">School Info</Typography>
      <hr />
      <form onSubmit={submitInfo}>
        <div className="mt-4">
          <Input
            label="School Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={n_error}
            success={success}
          />
        </div>
        <div className="mt-4">
          <Select
            defaultValue={country}
            onChange={(e) => setCountry(e)}
            options={countryOptions}
            isLoading={loading}
          />
        </div>
        <div className="mt-4">
          <Textarea
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="flex justify-end mt-4 ">
          <Button size="sm" className="rounded-full" type="submit">
            Save
          </Button>
        </div>
      </form>
    </Fragment>
  );
}

function SchoolPrivacy({
  privacy,
  setPrivacy,
  setActiveTab,
  setCompletedSections,
  completedSections,
}: any) {
  const submitPolity = () => {
    setCompletedSections([...completedSections, "Privacy Policy"]);
    setActiveTab(2);
  };

  return (
    <Fragment>
      <Typography variant="h5">Privacy Policy</Typography>
      <hr />
      <div className="mt-4">
        <ReactQuill value={privacy} onChange={(e) => setPrivacy(e)} />
      </div>
      <div className="flex justify-end mt-4 ">
        <Button size="sm" className="rounded-full" onClick={submitPolity}>
          Save
        </Button>
      </div>
    </Fragment>
  );
}

function SchoolTermsAndConditions({
  terms,
  setTerms,
  setActiveTab,
  setCompletedSections,
  completedSections,
}: any) {
  const submitPolity = () => {
    setCompletedSections([...completedSections, "Terms and conditions"]);
    setActiveTab(0);
  };
  return (
    <Fragment>
      <Typography variant="h5">Terms and conditions</Typography>
      <hr />
      <div className="mt-4">
        <ReactQuill value={terms} onChange={(e) => setTerms(e)} />
      </div>
      <div className="flex justify-end mt-4 ">
        <Button size="sm" className="rounded-full" onClick={submitPolity}>
          Save
        </Button>
      </div>
    </Fragment>
  );
}
