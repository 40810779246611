import { useState } from "react";
import PageLayout from "../../components/Layouts/page-layout";
import { Typography } from "@material-tailwind/react";
import SoftWareProducts from "./tabs/software-products";
import HardwareProducts from "./tabs/hardware-products";

const Products = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabNames = ["Hardware Products", "Software Products"];
  const theTabs = [<HardwareProducts />, <SoftWareProducts />];

  return (
    <>
      <PageLayout>
        <div className="m-4">
          <h2 className="text-2xl text-text_primary font-semibold">Products</h2>

          <div className="my-3   flex items-center gap-2">
            {tabNames.map((d, i) => (
              <div
                key={i}
                className={`${
                  activeTab === i
                    ? "bg-gradient-to-tr  from-default_blue to-light_blue text-white"
                    : "bg-white text-text_primary"
                } shadow-sm w-fit px-7 py-3 rounded-[30px] cursor-pointer`}
                onClick={() => setActiveTab(i)}
              >
                <Typography variant="small">{d}</Typography>
              </div>
            ))}
          </div>

          <div>{theTabs[activeTab]}</div>
        </div>
      </PageLayout>
    </>
  );
};

export default Products;
