import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import { debounce } from 'lodash'
import BaseService from '../../helpers/baseServices'
import Select from 'react-select'


interface selectProps {
    onChange?: (data: any) => void
}


const SearchUserByEmail = ({ onChange }: selectProps) => {

    const [isSearching, setIsSearching] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [foundUsers, setFoundUsers] = useState<{ label: string, value: string }[]>([])

    //findTargetUser
    const findTargetUser = async () => {
        setIsSearching(true)

        try {
            const response = await BaseService.get_api(`/robocentre/users-plt?filter={"email": { "$regex": ${JSON.stringify(searchValue)}, "$options": "i" }}`)
            console.log("findRes:", response?.data)
            if (response?.data?.payload) {
                const mapped = response?.data?.payload.map((user: any) => (
                    { label: user?.fullName, value: user?._id }
                ))
                setFoundUsers(mapped)
                setIsSearching(false)
            } else {
                setFoundUsers([])
                setIsSearching(false)
            }
        } catch (error: any) {
            console.log("findError:", error)
            message.error(error?.response?.data?.error || "Error occured while finding user! Please retry")
            setIsSearching(false)
        }
    }

    //onload
    useEffect(() => {

        if (searchValue) {
            findTargetUser()
        } else {
            setFoundUsers([])
        }

    }, [searchValue])

    //handleSearchInput
    const handleSearchInput = debounce((value: any) => {
        console.log("inputValue:", value)
        setSearchValue(value)
    }, 500)

    return (
        <>
            <Select
                className='h-[40px] w-full'
                placeholder="Search by user email"
                options={foundUsers}
                onChange={onChange}
                onInputChange={handleSearchInput}
                isLoading={isSearching}
                isClearable
            />
        </>
    )
}

export default SearchUserByEmail
