import React, { useState } from 'react'
import { Input, message, Modal, Spin } from 'antd'
import { CloudArrowUpIcon } from '@heroicons/react/24/outline'
import { IoMdClose } from 'react-icons/io'
import BaseService from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'

interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
}

const AddNewProductCategory = ({ isOpened, handleClose, handleReFetch }: modalProps) => {

    const [isBusy, setIsBusy] = useState(false)
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [color, setColor] = useState("#CCCCF8");

    //handleSubmit
    const handleSubmit = async () => {
        try {
            if (name === "" || description === "") {
                message.warning("Please fill out all fields!")
            } else {
                setIsBusy(true)
                await BaseService.post_api(global_variables.product_categories, {
                    name: name,
                    description: description,
                    color: color,
                });
                message.success("New category was created successfully");
                setName("");
                setDescription("");
                setColor("#CCCCF8");
                setIsBusy(false)
                handleClose()
                handleReFetch()
            }
        } catch (error: any) {
            message.error(error?.response?.data?.error || "Unknown error, please retry")
            setIsBusy(false)
        }
    };

    return (
        <>
            <Modal open={isOpened} centered onCancel={handleClose} footer={null} closeIcon={false} maskClosable={false} keyboard={false}>
                <Spin spinning={isBusy}>
                    <div className='flex justify-between items-center'>
                        <p className='font-semibold text-lg'>Add Product category</p>
                        <IoMdClose className='h-5 w-5 cursor-pointer hover:text-red-500' onClick={handleClose} />
                    </div>

                    <hr />

                    <div className='mt-[20px]'>
                        <label htmlFor="name" className='text-sm'>Category name</label>
                        <Input
                            className="h-[40px] rounded-[5px] border-[1px] border-outline_light w-full shadow-none focus:border-outline_deep"
                            type="text"
                            placeholder="Robotics"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            id="name"
                        />
                    </div>

                    <div className='mt-[15px]'>
                        <label className="text-sm">
                            Color
                        </label>
                        <input
                            className="h-[40px] rounded-[5px] border-[1px] border-outline_light w-full shadow-none focus:border-outline_deep cursor-pointer"
                            type="color"
                            placeholder="Color"
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                        />
                    </div>

                    <div className="mt-3">
                        <label className="text-sm" htmlFor='desc'>
                            Description
                        </label>
                        <Input.TextArea
                            placeholder="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            id="desc"
                        />
                    </div>

                    <div className="flex items-center justify-end gap-3 mt-[20px]">
                        <button
                            className="bg-default_blue text-white px-4 py-2 text-sm rounded-[30px] flex items-center gap-2"
                            onClick={handleSubmit}
                        >
                            <CloudArrowUpIcon className="h-4 w-4" />
                            Publish
                        </button>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}

export default AddNewProductCategory
