import { useState } from "react";
import { Button, Form, Input, message, Modal, Spin } from "antd";
import BaseService from "../../../helpers/baseServices";
import { IoPricetagsOutline } from "react-icons/io5";
import { useForm } from "antd/es/form/Form";

////
interface CreateGradeLevelProps {
  handleReFetch: any;
  showModal: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const AddTags = ({
  handleReFetch,
  showModal,
  open,
  setOpen,
}: CreateGradeLevelProps) => {
  const [form] = useForm();
  const [isHovered, setHovered] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  //handleOnSubmit
  const handleOnSubmit = async (values: any) => {
    setLoading(true);
    try {
      const payload = {
        name: values?.name,
      };
      console.log("Sending payload:", payload);
      const results = await BaseService.post_api("/robocentre/tags", payload);
      form.resetFields();
      handleReFetch();
      console.log("API results:", results);
      setOpen(false);
      message.success("New tag was created successfully!");
    } catch (error: any) {
      console.log(error.message);
      setLoading(false);
      message.error(
        error?.response?.data?.error || "Error while adding tag! Please retry"
      );
    } finally {
      setLoading(false);
    }
  };

  const buttonStyle = {
    backgroundColor: isHovered ? "#9494F5" : "#6666EA",
    color: "white",
    border: "1px solid #9494f5",
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      <Button
        type="default"
        shape="round"
        icon={<IoPricetagsOutline size={"1.5em"} style={{ marginRight: 4 }} />}
        className="flex rounded-[20px] items-center h-[40px]"
        // size={state.size}
        onClick={showModal}
        onMouseEnter={() => setHovered((prev) => !prev)}
        onMouseLeave={() => setHovered((prev) => !prev)}
        style={buttonStyle}
      >
        <div className="flex items-center justify-center">
          <p>Create Tag</p>
        </div>
      </Button>

      <Modal
        width={"30%"}
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
      >
        <div className=" rounded-2xl px-4">
          <p className="text-2xl mt-1 font-body text-text_primary font-bold">
            Create Tags
          </p>
          <hr className="my-3 text-default" />
          <Spin spinning={loading}>
            <div className="rounded-lg p-2">
              <Form form={form} layout="vertical" onFinish={handleOnSubmit}>
                <Form.Item
                  name="name"
                  label="Tag Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a tag name!",
                    },
                  ]}
                >
                  <Input
                    className="h-[40px] border-1 border-outline_deep"
                    placeholder="Eg: Sensors"
                  />
                </Form.Item>

                <Form.Item className="flex justify-end">
                  <button className="px-[30px] py-[8px] bg-default_blue text-white rounded-[30px]">
                    <p>Submit</p>
                  </button>
                </Form.Item>
              </Form>
            </div>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default AddTags;
