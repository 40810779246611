import React from 'react'
import { Skeleton } from 'antd'


const SingleCourseShimmer = () => {
    return (
        <>
            <div className="border-[1px] border-[#CCCCF8] bg-white p-[10px] rounded-[20px] shadow-sm mb-[20px] grid grid-cols-6 gap-[20px]">
                {/* image */}
                <div className='col-span-1'>
                    <Skeleton.Image active style={{ height: 86, width: 120 }} />
                </div>

                {/* details */}
                <div className='w-full col-span-3 flex justify-between gap-[20px]'>
                    <div className='w-full'>
                        <Skeleton active paragraph={{ rows: 2 }} />
                    </div>
                </div>

                {/* dates */}
                <div className="w-full col-span-2">
                    <Skeleton active paragraph={{ rows: 2 }} />
                </div>
            </div>
        </>
    )
}

export default SingleCourseShimmer
