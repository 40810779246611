import { useEffect, useState } from "react";
import PageLayout from "../../components/Layouts/page-layout";
import { IoMdMore } from "react-icons/io";
import useTableSyle from "../../components/shared/useTableSyle";
import DataTable from "react-data-table-component";
import type { MenuProps } from "antd";
import { Dropdown, Input, Spin } from "antd";
import BaseService from "../../helpers/baseServices";
import { CiSearch } from "react-icons/ci";
import moment from "moment";
import ToolTipComponent from "../../components/shared/ToolTipComponent";
import { FiRefreshCcw } from "react-icons/fi";
import {
  dateCompareFunction,
  dynamicStringCompare,
} from "../../components/shared/TableSortFunctions";
import { debounce } from "lodash";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";

const InstructorsPage = () => {
  const [customStyles] = useTableSyle();
  const [reFetch, setReFetch] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [allInstrcutorsData, setAllInstrcutorsData] = useState<any>();
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [total, setTotal] = useState(0);
  const [fieldName, setFieldName] = useState<string>();


  const handleMenuClick = (selected: any) => {
    console.log("selected:", selected);
  };

  //fetchAllInstructors
  const fetchAllInstructors = async (lim: number, sk: number) => {
    setIsFetching(true);

    try {
      const filterConditions: any[] = [];

      if (fieldName) {
        filterConditions.push({ "userData.fullname": { $regex: fieldName, $options: "i" } });
      }

      const response = await BaseService.get_api(`/robocentre/roboinstructor/onboarding/instructor?limit=${lim}&skip=${sk}`);
      console.log("___instructors:", response?.data?.payload);
      setAllInstrcutorsData(response?.data?.payload);
      setTotal(response?.data?.total);
      setIsFetching(false);
    } catch (error) {
      console.log(error)
    }

  };

  useEffect(() => {
    fetchAllInstructors(limit, skip);

    // eslint-disable-next-line
  }, [reFetch, limit, skip, fieldName]);

  //table columns
  const columns: any = [
    {
      name: (
        <p className="p-2 text-lg font-semibold text-text_primary">Fullname</p>
      ),
      cell: (row: any) => row?.userData?.fullName,
    },
    {
      name: (
        <p className="p-2 text-lg font-semibold text-text_primary">Email</p>
      ),
      cell: (row: any) => (
        <p className="p-2  text-md">{row?.userData?.email}</p>
      ),
      sortable: true,
      sortFunction: dynamicStringCompare("type"),
    },
    {
      name: (
        <p className="p-2  text-lg font-semibold text-text_primary">Phone</p>
      ),
      cell: (row: any) => (
        <p className="w-full  text-md  ">{row?.userData?.phone}</p>
      ),
      sortable: true,
      sortFunction: dynamicStringCompare("name"),
    },

    {
      name: (
        <p className="p-2  text-lg font-semibold text-text_primary">
          Created On
        </p>
      ),
      cell: (row: any) => (
        <p className="p-2  w-full  text-md">
          {" "}
          {moment(row?.createdAt).format("Do MMM YYYY - hh:mm A")}
        </p>
      ),
      sortable: true,
      sortFunction: dateCompareFunction("createdAt"),
    },
    {
      name: (
        <p className="p-2 text-lg font-semibold text-text_primary">
          Last Updated
        </p>
      ),
      cell: (row: any) => (
        <p className="p-2  w-full  text-md">
          {moment(row?.updatedAt).format("Do MMM YYYY - hh:mm A")}
        </p>
      ),
      sortable: true,
      sortFunction: dateCompareFunction("updatedAt"),
    },
    {
      name: "",
      cell: (row: any) => {
        const menuItems: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <span className="text-default_ash text-sm">View Details</span>
            ),

            icon: <ClipboardDocumentIcon className="h-[20px] w-[20px]" />,
            onClick: (e) => handleMenuClick(row),
          },
        ];
        return (
          <Dropdown
            placement="bottomLeft"
            menu={{ items: menuItems }}
            className="text-[14px]"
          >
            <IoMdMore size={"2em"} className="text-[#9494f5]" />
          </Dropdown>
        );
      },
      width: "5%",
    },
  ];

  /* pagination start */
  const handleRowChange = (a: any) => {
    setLimit(a);
  };

  const pageChange = (a: number) => {
    const newSkip = a === 1 ? 0 : (a - 1) * limit;
    setSkip(newSkip);
  };
  /* pagination start */

  //handlerNameChange
  const handlerNameChange = debounce((e: any) => {
    setFieldName(e);
  }, 500);


  return (
    <PageLayout>
      <div className="m-4 ">
        <h2 className="text-2xl my-4 text-text_primary font-semibold">
          All Instructors
        </h2>

        <div className="flex justify-between py-10 items-center drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] bg-white  rounded-[20px]">
          <div className="ml-4 w-[60%]  flex items-center  gap-4">
            <ToolTipComponent text={"Refresh"}>
              <div className="w-[40px] h-[40px] text-[#9494F5] hover:text-[#6666EA] hover:border-1 hover:border-[#6666EA] rounded-lg border-1 border-[#9494F5] flex items-center justify-center cursor-pointer  p-2">
                <FiRefreshCcw
                  onClick={() => setReFetch(!reFetch)}
                  size={"2em"}
                />
              </div>
            </ToolTipComponent>

            <div className="w-[50%]">
              <Input
                size="large"
                onChange={(e) => handlerNameChange(e.target.value)}
                placeholder="Search name...eg:Samuel"
                className="rounded-[20px] border-[1px] border-[#9494f5] text-[#9494f5] "
                suffix={<CiSearch />}
              />
            </div>
          </div>

          <div>
            ...
          </div>
        </div>

        <div className="bg-white p-4 drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] mt-6 rounded-[20px]">
          <DataTable
            className="mt-4"
            columns={columns}
            data={allInstrcutorsData}
            progressPending={isFetching}
            paginationServer
            progressComponent={<Spin />}
            paginationTotalRows={total}
            pagination
            // pointerOnHover
            // highlightOnHover
            customStyles={customStyles}
            onChangeRowsPerPage={handleRowChange}
            onChangePage={pageChange}
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default InstructorsPage;
