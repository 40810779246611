import { useEffect, useState } from "react";
import PageLayout from "../../components/Layouts/page-layout";
import { Col, Row } from "react-bootstrap";

import urls from "../../mixins/base.url";
import { LinearProgress, Pagination } from "@mui/material";
import baseService from "../../mixins/baseServices";
import AddNewProductCategory from "./addNewProductCategory";
import ProductCategoryComponent from "./ProductCategoryComponent";
import CustomButtomRounded from "../../components/shared/CustomButtomRounded";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

export default function ProductCategory() {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<any>([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [reFetch, setReFetch] = useState(false);
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [TotalCategory, setTotalCategory] = useState<number>(0);
  const [Count, setCount] = useState<number>(0);
  const navigate = useNavigate();

  const get_product_categories = async (lim: number, skp: number) => {
    try {
      const response: any = await baseService.get(
        `${urls.product_categories}?limit=${lim}&skip=${skp}`
      );
      const { total, payload } = await response.data;
      const isCount = Math.ceil(total / limit);
      setTotalCategory(total);
      setCount(isCount);
      setCategories(payload);
      setLoading(false);
      console.log("payload : ", payload);
    } catch (error: any) {
      console.log("error from product category ", error.message);
    }
  };
  const handleOpenAddCategory = () => {
    setOpenAddCategory(true);
  };
  const handleCloseAddCategory = () => {
    setOpenAddCategory(false);
  };

  const handlerClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    navigate("/products");
  };
  useEffect(() => {
    get_product_categories(limit, page);
  }, [limit, page, reFetch]);

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    const newSkip = value === 0 ? 1 : (value - 1) * limit;
    setLoading(true);
    setPage(newSkip);
  };
  return (
    <>
      <PageLayout>
        <div className="m-4">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl text-text_primary font-semibold">
              Product Category
            </h2>

            <button
              className="px-3 py-2 rounded-[30px] bg-default_blue text-white shadow-sm"
              onClick={handleOpenAddCategory}
            >
              + Add New
            </button>
          </div>
          <CustomButtomRounded
            icon={<FaArrowLeft />}
            value={"Back"}
            onClick={handlerClick}
          />
          <Row className="my-4">
            <Col md={8}>
              <div className="bg-white shadow-sm rounded-[20px] p-[30px]">
                <Spin spinning={loading}>
                  {categories?.map((d: any, i: number) => (
                    <ProductCategoryComponent key={i} data={d} />
                  ))}
                </Spin>

                <div className="my-3  flex justify-end mt-10">
                  {TotalCategory >= 10 && (
                    <Pagination
                      count={Count}
                      onChange={handleChangePagination}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </PageLayout>

      {openAddCategory && (
        <AddNewProductCategory
          isOpened={openAddCategory}
          handleClose={handleCloseAddCategory}
          handleReFetch={() => setReFetch(!reFetch)}
        />
      )}
    </>
  );
}
