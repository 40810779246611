export const global_variables = {
  product_categories: "/robocentre/product-category",
  file_upload: "/robocentre/file/upload",
  all_languages: "/robocentre/language",
  all_instructors: "/robocentre/educator",
  all_tags: "/robocentre/tags",
  all_courses: "/robocentre/course",
  all_course_category: "/robocentre/product-category",
  add_new_course: "/robocentre/course",
  all_grade_levels: "/robocentre/age-level",
  test_route: "/robocentre/test",
  course_content_route: "/robocentre/course-content",
  course_faqs_route: "/robocentre/course-faqs",
};
