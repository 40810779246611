import React from "react";
import { Select, Space } from "antd";

const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};

const SelectComponent = ({ options }: { options: any }) => (
  <Select
    defaultValue="All"
    allowClear
    className="roundedSelect w-full h-full  rounded-[20px] "
    options={options}
  />
);

export default SelectComponent;
