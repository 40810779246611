import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface QuillTextAreaProps {
    value?: string;
    onChange: (value: string) => void;
    placeholder?: string;
}

const DescriptionInputComponent: React.FC<QuillTextAreaProps> = ({
    value = "",
    onChange,
    placeholder = "Enter course description...",
}) => {
    const [text, setText] = useState<string>(value);

    const handleChange = (content: string) => {
        setText(content);
        onChange(content);
    };

    return (
        <ReactQuill
            theme="snow"
            className="h-[100px] mb-[30px]"
            value={text}
            onChange={handleChange}
            placeholder={placeholder}
            modules={{
                toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", "strike"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image"],
                    ["clean"],
                ],
            }}
        />
    );
};

export default DescriptionInputComponent;
