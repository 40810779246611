import React from "react";

import PageLayout from "../../components/Layouts/page-layout";
import CustomButtomRounded from "../../components/shared/CustomButtomRounded";
import CustomButtomSquare from "../../components/shared/CustomButtomSquare";
import { IoEyeOutline } from "react-icons/io5";
import { RxAvatar } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import otp from "../../images/customer_img.png";

import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { CiEdit } from "react-icons/ci";

const EditProfile = () => {
  const navigate = useNavigate();

  const handlerClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    navigate("/customers");
  };

  return (
    <>
      <PageLayout>
        <div className="m-4  flex gap-8 ">
          <section className="w-[80%] ">
            <div className="flex items-center gap-4 ml-6 mb-14   ">
              <CustomButtomRounded
                icon={<FaArrowLeft />}
                value={"Back"}
                onClick={handlerClick}
              />
              <h2 className="text-4xl text-text_primary font-semibold">
                Admin Profile
              </h2>
            </div>
            <div className="ml-6 mt-8">
              <div className="bg-white text-text_primary w-full rounded-3xl focus:ring-opacity-50 shadow-md py-4 px-4">
                <div className="flex justify-between">
                  <h2>Personal Details</h2>
                  <button className="bg-white text-default_blue  mb-3">
                    <PencilSquareIcon className="h-5 w-5 inline mr-1" />
                    Edit
                  </button>
                </div>
                <hr className="mt-0 mb-4" />
                <div className="flex justify-between">
                  <div>
                    <small>Date of Birth</small>
                    <h2>Date of Birth</h2>
                  </div>
                  <div>
                    <small>Place of Birth</small>
                    <h2></h2>
                  </div>
                  <div>
                    <small>Natitonality</small>
                    <h2>Natitonality</h2>
                  </div>
                </div>
              </div>
              <div className="bg-white w-full text-text_primary rounded-3xl focus:ring-opacity-50 shadow-md py-4 px-4 mt-2">
                <h2>Work</h2>
                <hr className="mt-2 mb-4" />
                <div className="flex justify-between">
                  <div>
                    <small>Department</small>
                    <h2>Department</h2>
                  </div>
                  <div>
                    <small>Job Title</small>
                    <h2>Job Title</h2>
                  </div>
                  <div>
                    <small>Work ID</small>
                    <h2>Email</h2>
                  </div>
                </div>
              </div>
              <div className="bg-white text-text_primary w-full rounded-3xl focus:ring-opacity-50 shadow-md py-4 px-4 mt-2">
                <h2>Contact Detail</h2>
                <hr className="mt-2 mb-4" />
                <div className="flex justify-between">
                  <div>
                    <small>Email</small>
                    <h2>Email</h2>
                  </div>
                  <div>
                    <small>Contact</small>
                    <h2>Contact</h2>
                  </div>
                  <div>
                    <small>Residential Address</small>
                    <h2>Residential Address</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className=" flex flex-col gap-2  w-[25%] ">
            <div className=" rounded-[20px] bg-white shadow-md py-4 mb-8">
              <section className="flex justify-center   text-text_primary items-center flex-col">
                <div className="w-[140px] h-[140px] ">
                  <img
                    src={otp}
                    alt="avatar"
                    className="rounded-full"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <p>Joana Doe LLC</p>
                <p>Email@gmail.com</p>
                <p>MBX775398xcyyu87</p>
              </section>
            </div>
            <div>
              <div className="w-[80%] grid gap-2 grid-cols-2 grid-rows-3 ">
                <CustomButtomSquare
                  value="Edit details "
                  icon={<CiEdit size={"1.3em"} />}
                  myStyle={"col-span-2 text-[14px] shadow-md font-semibold"}
                />
              </div>
            </div>
          </section>
        </div>
      </PageLayout>
    </>
  );
};

export default EditProfile;
