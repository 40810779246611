import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Modal,
  Row,
} from "react-bootstrap";
import { Typography } from "@mui/material";
import {
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon,
  ExclamationCircleIcon,
  MapIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Empty, Spin, Tooltip } from "antd";
import ClockingMapComponent from "./clockingMapComponent";
import BaseService from "../../../../helpers/baseServices";

const StaffAttendanceTable = () => {
  //today default date filter
  const today = {
    $and: [
      {
        createdAt: {
          $gte: `${moment().startOf("day").format()}`,
        },
      },
      {
        createdAt: {
          $lte: `${moment().endOf("day").format()}`,
        },
      },
    ],
  };

  const navigate = useNavigate();
  //general states
  const [isFetching, setIsFetching] = useState(true);
  const [clockingPayload, setClockingPayload] = useState([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [totalClockingPages, setTotalClockingPages] = useState(0);
  const [clockingCurrentPage, setClockingCurrentPage] = useState(1);
  const [clockingReport, setClockingReport] = useState<any>(null);
  const [selectedClocking, setSelectedClocking] = useState(null);

  //filter state management
  const filters = ["Today", "Week", "Month", "Year"];
  const [filter, setFilter] = useState(filters[0]);
  const [clockingFilter, setClockingFilter] = useState<any>(
    JSON.stringify(today)
  );

  //onLoad
  useEffect(() => {
    const GetClocking = async () => {
      setIsFetching(true);

      try {
        const response = await BaseService.base_get_api(
          `/mingo-org/clocking?sort={"updatedAt":-1}&limit=${limit}&skip=${skip}&filter=${clockingFilter}`
        );
        console.log("clockingRes:", response?.data);
        const total_data = response.data?.total;
        const pages = total_data / limit;
        setTotalClockingPages(Math.round(pages) === 0 ? 1 : Math.round(pages));
        setClockingPayload(response?.data?.payload);
        setIsFetching(false);
      } catch (error) {
        console.log("getClockingErr:", error);
      }
    };

    GetClocking();
  }, [skip, clockingFilter]);

  //handleChageFilter
  const handleChageFilter = (filt: any) => {
    setFilter(filt);
    switch (filt) {
      case "Today":
        setClockingFilter(
          JSON.stringify({
            $and: [
              {
                createdAt: {
                  $gte: `${moment().startOf("day").format()}`,
                },
              },
              {
                createdAt: {
                  $lte: `${moment().endOf("day").format()}`,
                },
              },
            ],
          })
        );
        break;
      case "Week":
        setClockingFilter(
          JSON.stringify({
            $and: [
              {
                createdAt: {
                  $gte: `${moment().startOf("week").format()}`,
                },
              },
              {
                createdAt: {
                  $lte: `${moment().endOf("week").format()}`,
                },
              },
            ],
          })
        );
        break;
      case "Month":
        setClockingFilter(
          JSON.stringify({
            $and: [
              {
                createdAt: {
                  $gte: `${moment().startOf("month").format()}`,
                },
              },
              {
                createdAt: {
                  $lte: `${moment().endOf("month").format()}`,
                },
              },
            ],
          })
        );
        break;
      case "Year":
        setClockingFilter(
          JSON.stringify({
            $and: [
              {
                createdAt: {
                  $gte: `${moment().startOf("year").format()}`,
                },
              },
              {
                createdAt: {
                  $lte: `${moment().endOf("year").format()}`,
                },
              },
            ],
          })
        );
        break;
      default:
        setClockingFilter(null);
        break;
    }
  };

  //table columns
  const columns = [
    {
      name: <p className="text-[16px] text-text_primary font-[600]">Date</p>,
      cell: (row: any) => (
        <p>{moment(row?.createdAt).format("Do MMM YYYY")} </p>
      ),
    },
    {
      name: <p className="text-[16px] text-text_primary font-[600]">Name</p>,
      cell: (row: any) => <p>{row?.user?.fullName}</p>,
    },
    {
      name: (
        <p className="text-[16px] text-text_primary font-[600]">Clocking</p>
      ),
      cell: (row: any) => (
        <div className="flex flex-col my-1 space-y-1">
          <span className="bg-[#D7F9F3] text-green-500 rounded-full px-4 mb-1">
            In
          </span>
          {row?.out !== null ? (
            <div className="flex">
              <span className="bg-[#FFE6E8] text-red-500 rounded-full px-4">
                Out
              </span>
              {row?.out?.report && (
                <ExclamationCircleIcon
                  className="h-5 w-5 text-[#FFC400] cursor-pointer"
                  onClick={() => setClockingReport(row)}
                />
              )}
            </div>
          ) : null}
        </div>
      ),
    },
    {
      name: (
        <p className="text-[16px] text-text_primary font-[600]">Timestamp</p>
      ),
      cell: (row: any) => (
        <>
          <div className="flex flex-col my-1 space-y-2">
            <p>
              {row?.createdAt
                ? moment(row?.createdAt).format("Do MMM YYYY - h:mm A")
                : "N/A"}
            </p>
            {row?.out && (
              <p>{moment(row?.updatedAt).format("Do MMM YYYY - h:mm A")}</p>
            )}
          </div>
        </>
      ),
    },
    {
      name: (
        <p className="text-[16px] text-text_primary font-[600]">Location</p>
      ),
      cell: (row: any) => (
        <>
          <div className="flex flex-col my-1 space-y-2">
            <p>{row?.in?.location}</p>
            {row?.out !== null ? <p>{row?.in?.location}</p> : null}
          </div>
        </>
      ),
    },
    {
      name: <p className="text-[16px] text-text_primary font-[600]">Action</p>,
      cell: (row: any) => (
        <Tooltip title="View Map">
          <MapIcon
            className="h-5 w-5"
            onClick={() => setSelectedClocking(row)}
          />
        </Tooltip>
      ),
    },
  ];

  // pagination start
  const nextPage = () => {
    if (clockingCurrentPage === totalClockingPages) {
    } else {
      setIsFetching(true);
      setSkip(skip + limit);
      setClockingCurrentPage(clockingCurrentPage + 1);
    }
  };

  const prevPage = () => {
    if (clockingCurrentPage === 1) {
    } else {
      setIsFetching(true);
      setSkip(skip - limit);
      setClockingCurrentPage(clockingCurrentPage - 1);
    }
  };
  // pagination end

  return (
    <>
      <div className="bg-white text-gray-700 w-full rounded-3xl focus:ring-opacity-50 shadow-md py-4 px-4 mt-4">
        <div className="flex justify-end">
          <ButtonGroup>
            {filters.map((_d, i) => (
              <Button
                className={`${
                  filter === _d
                    ? "bg-default_blue text-white"
                    : "hover:text-white hover:bg-default_blue"
                } text-default_blue font-semibold`}
                variant="primary"
                key={i}
                onClick={() => handleChageFilter(_d)}
              >
                {_d}
              </Button>
            ))}
          </ButtonGroup>
        </div>
      </div>

      <div
        className="shadow-md bg-white rounded-3xl p-[10px] mt-[20px]"
        style={{ minHeight: "70vh" }}
      >
        <DataTable
          columns={columns}
          data={clockingPayload}
          progressPending={isFetching}
          progressComponent={
            <div className="flex justify-center items-center h-[70vh]">
              <Spin />
            </div>
          }
          pointerOnHover
          highlightOnHover
          noDataComponent={
            <div className="flex justify-center items-center h-[70vh]">
              <Empty description="No clocking data found!" />
            </div>
          }
        />
      </div>

      {/* pagination component */}
      <Container>
        <nav
          className="fa-pull-right mt-4 mb-3"
          aria-label="Page navigation example"
          id="paginationDiv"
        >
          <ul className="inline-flex items-center -space-x-px text-3xs md:text-xs lg:text-sm xl:text-md">
            <li>
              <Link
                to="#"
                className={`block py-2 px-2 mr-4 text-text_white rounded-lg hover:text-text_white ${
                  clockingCurrentPage === 1
                    ? "bg-[grey] cursor-not-allowed"
                    : "bg-default_blue"
                }`}
                onClick={prevPage}
              >
                <span className="sr-only">Previous</span>
                <ArrowSmallLeftIcon className="w-5 h-5" />
              </Link>
            </li>
            <li>
              <p className="py-2 px-3 text-text_primary">
                {clockingCurrentPage}/{totalClockingPages} Pages
              </p>
            </li>
            <li>
              <Link
                to="#"
                className={`block py-2 px-2 ml-4 text-text_white rounded-lg hover:text-text_white ${
                  clockingCurrentPage === totalClockingPages
                    ? "bg-[grey] cursor-not-allowed"
                    : "bg-default_blue"
                }`}
                onClick={nextPage}
              >
                <span className="sr-only">Next</span>
                <ArrowSmallRightIcon className="w-5 h-5" />
              </Link>
            </li>
          </ul>
        </nav>
      </Container>

      {/* clocking report modal */}
      <Modal
        show={clockingReport !== null}
        onHide={() => setClockingReport(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Clocking Out Report</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <div className="flex space-x-3">
            <img
              className="inline-block h-10 w-10 rounded-full ring-2 ring-white"
              src={
                clockingReport?.user?.image || "https://via.placeholder.com/150"
              }
              alt=""
            />
            <p className="text-xl mt-1">{clockingReport?.user?.fullName}</p>
          </div>
          <Row className="mt-3">
            <Col md={6}>
              {[
                {
                  name: "Report Date/Time",
                  time: moment(clockingReport?.createdAt).format(
                    "Do MMM YYYY LT"
                  ),
                },
                {
                  name: "Overlay Date/Time",
                  time: moment(clockingReport?.out?.overlayTime).format(
                    "Do MMM YYYY LT"
                  ),
                },
              ].map((_d, i) => (
                <Fragment key={i}>
                  <small className="m-0 p-0 text-[11px]">{_d.name}</small>
                  <p className="m-0 p-0 text-md">{_d.time}</p>
                </Fragment>
              ))}
            </Col>
            <Col md={6}>
              {[
                { name: "Report Location", loc: clockingReport?.in?.location },
                { name: "Report Location", loc: clockingReport?.out?.location },
              ].map((_d, i) => (
                <Fragment key={i}>
                  <small className="m-0 p-0 text-[11px]">{_d.name}</small>
                  <p className="m-0 p-0 text-md text-default_blue">{_d.loc}</p>
                </Fragment>
              ))}
            </Col>
            <Col md={12} className="mt-3">
              <small className="m-0 p-0 text-[11px]">Report</small>
              <p className="m-0 p-0 text-md">{clockingReport?.out?.report}</p>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* clocking map modal */}
      <Modal
        show={selectedClocking !== null}
        onHide={() => setSelectedClocking(null)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Clocking Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedClocking === null ? null : (
            <ClockingMapComponent details={selectedClocking} />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StaffAttendanceTable;
