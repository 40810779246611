import { useReducer } from "react";
import PageLayout from "../../components/Layouts/page-layout";
import RoboCenterFrontPage from "./DynamicComponent/FrontPage";
import IconsPage from "./DynamicComponent/IconsPage";
import RoboCenterTable from "./DynamicComponent/RoboCenterTable";
import { sampleData } from "./DynamicComponent/sampleData";

const RoboReducerFunction = (state: any, action: any) => {
  switch (action.type) {
    case "DELETE":
      const tableId = action.payload;
      return {
        ...state,
        tableData: state.tableData.filter(
          (tableField: any) => tableId !== tableField._id
        ),
      };
    default:
      return state;
  }
};
const initialRoboState = {
  tableData: [...sampleData],
};

const ManageRobocenter = () => {
  const [RoboState, dispatchFunction] = useReducer(
    RoboReducerFunction,
    initialRoboState
  );

  return (
    <>
      <PageLayout>
        <div className="m-4 ">
          <div className="flex justify-between">
            <h2 className="text-4xl mb-10 text-text_primary font-semibold">
              Manage Robocentre
            </h2>
            {RoboState.tableData.length ? <IconsPage /> : <p></p>}
          </div>
          {RoboState.tableData.length ? (
            <RoboCenterTable
              data={RoboState.tableData}
              dispatchFunction={dispatchFunction}
            />
          ) : (
            <RoboCenterFrontPage />
          )}
        </div>
      </PageLayout>
    </>
  );
};

export default ManageRobocenter;
