import React from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import { useState } from "react";
import moment from "moment";
import { MAP_KEY } from "../../../../helpers/constants";


interface theProps {
  details: any
}


const containerStyle = {
  width: "100%",
  height: "450px",
};

function ClockingMapComponent({ details }: theProps) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: MAP_KEY,
  });

  const center = {
    lat: details?.in?.latitude,
    lng: details?.in?.longitude,
  };

  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(0);

  const onLoad = React.useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setTimeout(() => {
      setZoom(18);
      console.log(details);
    }, 2000);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const [infoWindowContent, setInfoWindowContent] = useState<any>(null);
  const handleMarkerClick = (content: any, time: any) => {
    content.time = time;
    setInfoWindowContent(content);
  };

  const handleMarkerCloseClick = () => {
    setInfoWindowContent(null);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {details?.in && (
        <Marker
          position={{ lat: details?.in?.latitude, lng: details?.in?.longitude }}
          icon={{
            url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
          }}
          label=""
          onClick={() => handleMarkerClick(details?.in, details?.createdAt)}
        />
      )}
      {details?.out && (
        <Marker
          position={{ lat: details?.out?.latitude, lng: details?.out?.longitude }}
          icon={{
            url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
          }}
          label=""
          onClick={() => handleMarkerClick(details?.out, details?.updatedAt)}
        />
      )}

      {infoWindowContent && (
        <InfoWindow
          position={{
            lat: infoWindowContent?.latitude,
            lng: infoWindowContent?.longitude,
          }}
          onCloseClick={handleMarkerCloseClick}
        >
          <div>
            <h2>Location : {infoWindowContent?.location}</h2>
            <p>Longitude: {infoWindowContent?.longitude}</p>
            <p>Latitude: {infoWindowContent?.latitude}</p>
            <p>Time: {moment(infoWindowContent?.time).format("lll")}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(ClockingMapComponent);
