import robotImg from "../../../images/roboImg.png";
import { useState } from "react";
import CreateSchool from "../Modal/CreateSchool";

const FrontPage = () => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };
  const onclickHandler = (e: any) => {
    console.log(e);
    setOpen(true);
  };

  return (
    <div className=" flex w-[80%] justify-center bg-white items-center flex-col  gap-4 drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] hover:drop-shadow-[0_20px_20px_rgba(102,102,234,0.1)] rounded-xl h-[60vh]">
      <div>
        <img src={robotImg} alt="" />
      </div>
      <p>
        To manage robocentre you eed to create a Robocentre Main School first
      </p>

      <CreateSchool showModal={showModal} open={open} setOpen={setOpen} />
    </div>
  );
};

export default FrontPage;
