import { Fragment, useState } from "react";
import { Container } from "react-bootstrap";
import BusinessDocumnetTable from "./components/business-document-table";
import PageLayout from "../../../components/Layouts/page-layout";
import { SearchInputField } from "../../../components/form/search";

export default function RoboCenterBusinessPage() {
  const [show, setShow] = useState(false);

  return (
    <PageLayout>
      <div className="m-4">
        <h2 className="text-2xl font-normal text-text_primary">Business</h2>
        <Fragment>
          <div className="bg-white text-gray-700 w-full rounded-3xl focus:ring-opacity-50 shadow-md py-4 px-4  mt-5 flex justify-end">
            <SearchInputField />
          </div>
        </Fragment>

        <Fragment>
          <div className="my-5">
            <BusinessDocumnetTable />
          </div>
        </Fragment>
      </div>
    </PageLayout>
  );
}
