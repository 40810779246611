import BaseService from "../../../helpers/baseServices";
import { global_variables } from "../../../helpers/globalVariables";

export const CreateSingleTest = async (post_data: any) => {
  try {
    const response = await BaseService.post_api(
      global_variables.test_route,
      post_data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const UpdateSingleTest = async (update_data: any) => {
  try {
    const response = await BaseService.put_api(
      global_variables.test_route,
      update_data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const GetSingleTest = async (testId: any) => {
  try {
    const response = await BaseService.get_api(
      `${global_variables.test_route}/${testId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const AddTestQuestions = async (questions_payload: any) => {
  try {
    const response = await BaseService.post_api(
      "/robocentre/questions",
      questions_payload
    );
    return response;
  } catch (error) {
    return error;
  }
};
