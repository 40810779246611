import {
  ArrowUpTrayIcon,
  BackspaceIcon,
  PhotoIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { Col, Image, Modal, Row } from "react-bootstrap";
import ShowToast from "../../../components/Alerts/all_toasts";
import ShowAlert from "../../../components/Alerts/all_alerts";
import urls from "../../../mixins/base.url";
import { Spinner } from "@material-tailwind/react";
import baseService from "../../../mixins/baseServices";

interface modalProps {
  showModal: boolean;
  handleCloseModal: () => void;
}

const CreateSoftWareProductModal = ({
  showModal,
  handleCloseModal,
}: modalProps) => {
  const [title, setTitle] = useState("");
  const [prodUrl, setProdUrl] = useState("");
  const [prodDesc, setProdDesc] = useState("");
  const [imgUploading, setImgUploading] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [mimeType, setMimeType] = useState("");
  const [displayFile, setDisplayFile] = useState("");
  const [upLoadedImages, setUpLoadedImages] = useState<any>([]);
  // const upLoadedImages = [];

  //handle image select
  const handleImageChange = (event: any) => {
    const selectedImage = event.target.files[0];
    const mimetype = selectedImage.type;

    setMimeType(mimetype.split("/")[1]);
    if (selectedImage) {
      const objectUrl = URL.createObjectURL(selectedImage);
      setDisplayFile(objectUrl);
      setFile(selectedImage);
    } else {
      setDisplayFile("");
      setMimeType("");
      setFile(null);
    }
  };

  //handle actual image upload
  const handleImgUpload = async () => {
    var formData = new FormData();
    formData.append("file", file);
    // console.log(formData);
    if (displayFile) {
      if (title) {
        const postUrl = `${urls.file_upload}imagename=${
          title + upLoadedImages.length
        }&storage=test&mimeType=${mimeType}`;
        console.log(postUrl);
        try {
          setImgUploading(true);
          await baseService.post(postUrl, formData).then((res: any) => {
            // console.log(res);
            //after successful upload set the url to the array on top cos we can upload multiple images
            setUpLoadedImages([...upLoadedImages, res.data?.url]);
            console.log([...upLoadedImages, res.data?.url]);
            console.log(upLoadedImages);
            setImgUploading(false);
            setMimeType("");
            setDisplayFile("");
          });
          setImgUploading(false);
        } catch (error) {
          setImgUploading(false);
          console.log(error);
        }
      } else {
        ShowToast.warning_toast("Enter product title");
      }
    } else {
      ShowToast.warning_toast("Select an image");
    }
  };

  //delete actual uploaded image
  const doDeleteUrl = (img: any) => {
    console.log(img);
    setUpLoadedImages((prevImages: any) =>
      prevImages.filter((url: any) => url !== img)
    );
  };

  //handle create
  const doCreateProduct = () => {
    if (title === "" || prodUrl === "" || prodDesc === "") {
      ShowToast.warning_toast("Please fill out all fields!");
    } else if (upLoadedImages.length === 0) {
      ShowToast.warning_toast("Please upload at least one product image!");
    } else {
      const postData = {
        name: title,
        link: prodUrl,
        description: prodDesc,
        images: upLoadedImages,
      };
      handleCloseModal();
      ShowAlert.loading_alert("Creating New Product...");
      try {
        const productUrl = urls.software_products;
        baseService.post(productUrl, postData).then((res) => {
          ShowAlert.success_alert(
            "Success",
            "Product created successfully!",
            "Ok"
          ).then((done) => {
            if (done.isConfirmed) {
              window.location.reload();
            }
          });
          console.log(res);
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Modal size="lg" show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4 className="font-semibold text-text_primary">
            Create Software Product
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-3">
          <Row>
            <Col md={4}>
              {/* file select */}
              <div>
                <label htmlFor="courseVid">
                  {displayFile === "" ? (
                    <div className="cursor-pointer bg-super_light border-dashed border-[1.5px] border-outline_deep rounded-[5px] h-[150px] w-[200px] flex justify-center items-center">
                      <PhotoIcon className="w-10 h-10 text-outline_deep" />
                    </div>
                  ) : (
                    <div className="cursor-pointer bg-super_light border-dashed border-[1.5px] border-outline_deep rounded-[5px] h-[150px] w-[200px]">
                      <Image
                        className="w-full h-full object-cover"
                        src={displayFile}
                        alt="preview"
                        fluid
                      />
                    </div>
                  )}
                </label>
                <input
                  className="hidden"
                  type="file"
                  id="courseVid"
                  onChange={handleImageChange}
                />
              </div>
            </Col>
            <Col md={8}>
              <div>
                <p className="text-md font-normal">Add Image</p>
                <p className="text-sm font-normal text-gray-500 mt-2">
                  School logo will appear on the login and dashboard pages. The
                  logo width should be less than 800 pixels.
                </p>
                <p className="text-sm font-normal text-gray-500 mt-2">
                  The recommended file formats are: jpg, jpeg, png.
                </p>

                <div className="flex items-center gap-3 mt-2">
                  <button
                    className="px-3 py-2 rounded-[5px] border-[1px] border-red-500 flex items-center gap-2 text-red-500"
                    onClick={() => setDisplayFile("")}
                  >
                    <BackspaceIcon className="w-5 h-5" />
                    Clear
                  </button>
                  <button
                    className="px-3 py-2 rounded-[5px] border-[1px] border-default_blue flex items-center gap-2 text-default_blue"
                    onClick={() => handleImgUpload()}
                  >
                    {imgUploading ? (
                      <Spinner />
                    ) : (
                      <>
                        <ArrowUpTrayIcon className="w-5 h-5" />
                        Upload
                      </>
                    )}
                  </button>
                </div>
              </div>

              <div className="mt-2 p-2 bg-default_blue bg-opacity-[10%] rounded-[10px] h-fit w-full flex items-center flex-wrap gap-3">
                {upLoadedImages.length === 0 ? (
                  <p className="text-sm text-gray-600">
                    Uploaded product images will show here...
                  </p>
                ) : (
                  upLoadedImages.map((img: any, i: any) => (
                    <div
                      key={i}
                      className="flex flex-col gap-2 items-center justify-center"
                    >
                      <Image
                        className="h-[40px] w-[80px] object-cover rounded-[5px]"
                        src={img}
                        alt={i}
                        fluid
                      />
                      <TrashIcon
                        className="text-red-500 cursor-pointer w-4 h-4"
                        onClick={() => doDeleteUrl(img)}
                      />
                    </div>
                  ))
                )}
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={4} className="flex items-center">
              <p className="text-md text-text_primary">Title</p>
            </Col>
            <Col md={8}>
              <input
                className="w-full mt-2 h-[40px] border-[1px] border-outline_light rounded-[5px] focus-outline-[1px] focus:outline-outline_deep focus:border-none shadow-none"
                type="text"
                placeholder="Enter product title here"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={4} className="flex items-center">
              <p className="text-md text-text_primary">Product URL</p>
            </Col>
            <Col md={8}>
              <input
                className="w-full mt-2 h-[40px] border-[1px] border-outline_light rounded-[5px] focus-outline-[1px] focus:outline-outline_deep focus:border-none shadow-none"
                type="text"
                placeholder="Enter product url here"
                value={prodUrl}
                onChange={(e) => setProdUrl(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={4}>
              <p className="text-md text-text_primary">Product Description</p>
            </Col>
            <Col md={8}>
              <textarea
                className="w-full mt-2 h-[120px] border-[1px] border-outline_light rounded-[5px] focus-outline-[1px] focus:outline-outline_deep focus:border-none shadow-none"
                placeholder="Enter product url here"
                value={prodDesc}
                onChange={(e) => setProdDesc(e.target.value)}
              />
            </Col>
          </Row>

          <div className="mt-3 flex gap-3 items-center justify-end">
            <button className="text-red-500" onClick={() => handleCloseModal()}>
              Cancel
            </button>
            <button
              className="px-3 py-2 bg-default_blue text-white rounded-[30px]"
              onClick={() => doCreateProduct()}
            >
              Add Product
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateSoftWareProductModal;
