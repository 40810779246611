import React from "react";
import OrderTable from "./orderTable";
import OrderCard from "./OrderCards";
const Order = () => {
  return (
    <div>
      <OrderCard />
      <OrderTable />
    </div>
  );
};

export default Order;
