import { useReducer, useEffect } from "react";
import { Button, Input, Modal, Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { FaChild } from "react-icons/fa";
import BaseService from "../../../helpers/baseServices";
import ColorChange from "../../Gradelevel/ColorChange";
import Agegroup from "../../Gradelevel/Agegroup";
import { SiLevelsdotfyi } from "react-icons/si";

interface CreateGradeLevelProps {
  handleReFetch: any;
  curriculumData: any;
  showModal: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface State {
  confirmLoading: boolean;
  size: SizeType;
  isHovered: boolean;
  activeTab: number;
  grade: {
    name: string;
    color: string;
    ageGroup: number[];
    type: string;
    curriculumId: string | undefined;
  };
  isFormValid: boolean;
}

const initialState: State = {
  confirmLoading: false,
  size: "large",
  isHovered: false,
  activeTab: 0,
  grade: {
    name: "",
    color: "",
    ageGroup: [20, 50],
    type: "",
    curriculumId: undefined,
  },
  isFormValid: false,
};

type Action =
  | { type: "SET_CONFIRM_LOADING"; payload: boolean }
  | { type: "SET_HOVERED"; payload: boolean }
  | { type: "SET_ACTIVE_TAB"; payload: number }
  | { type: "SET_GRADE_NAME"; payload: string }
  | { type: "SET_GRADE_COLOR"; payload: string }
  | { type: "SET_GRADE_AGEGROUPS"; payload: number[] }
  | { type: "SET_GRADE_TYPE"; payload: string }
  | { type: "SET_CURRICULUM_ID"; payload: string }
  | { type: "RESET_GRADE" }
  | { type: "SET_FORM_VALIDITY"; payload: boolean };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_CONFIRM_LOADING":
      return { ...state, confirmLoading: action.payload };
    case "SET_HOVERED":
      return { ...state, isHovered: action.payload };
    case "SET_ACTIVE_TAB":
      return { ...state, activeTab: action.payload };
    case "SET_GRADE_NAME":
      return { ...state, grade: { ...state.grade, name: action.payload } };
    case "SET_GRADE_COLOR":
      return { ...state, grade: { ...state.grade, color: action.payload } };
    case "SET_GRADE_AGEGROUPS":
      return { ...state, grade: { ...state.grade, ageGroup: action.payload } };
    case "SET_GRADE_TYPE":
      return { ...state, grade: { ...state.grade, type: action.payload } };
    case "SET_CURRICULUM_ID":
      return {
        ...state,
        grade: { ...state.grade, curriculumId: action.payload },
      };
    case "RESET_GRADE":
      return initialState;
    case "SET_FORM_VALIDITY":
      return { ...state, isFormValid: action.payload };
    default:
      return state;
  }
};

const CreatecurricullumLevel = ({
  handleReFetch,
  showModal,
  open,
  setOpen,
  curriculumData,
}: CreateGradeLevelProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const { name, color, ageGroup, type, curriculumId } = state.grade;
    const isFormValid =
      color.trim() !== "" &&
      ageGroup.length > 0 &&
      type.trim() !== "" &&
      curriculumId !== undefined;
    dispatch({ type: "SET_FORM_VALIDITY", payload: isFormValid });
  }, [state.grade]);

  const handleOk = async () => {
    dispatch({ type: "SET_CONFIRM_LOADING", payload: true });
    try {
      const payload = {
        color: state.grade.color,
        name: state.grade.type,
        ageGroup: state.grade.ageGroup,
        curriculumId: state.grade.curriculumId,
      };
      console.log("Sending payload:", payload);
      const results = await BaseService.post_api(
        "/robocentre/curriculum-level",
        payload
      );
      handleReFetch();
      console.log("API results:", results);
      setOpen(false);
    } catch (error: any) {
      console.log("Error is ", error?.response?.data?.error);
      console.log(error);
    } finally {
      dispatch({ type: "SET_CONFIRM_LOADING", payload: false });
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleGradeTypeChange = (type: string) => {
    dispatch({ type: "SET_GRADE_TYPE", payload: type });
  };

  const handleGradeNameChange = (name: string) => {
    dispatch({ type: "SET_GRADE_NAME", payload: name });
  };

  const handleGradeColorChange = (color: string) => {
    dispatch({ type: "SET_GRADE_COLOR", payload: color });
  };

  const handleAgeGroupChange = (ageGroup: number[]) => {
    dispatch({ type: "SET_GRADE_AGEGROUPS", payload: ageGroup });
  };

  const handleCurriculumChange = (curriculumId: string) => {
    dispatch({ type: "SET_CURRICULUM_ID", payload: curriculumId });
  };

  const buttonStyle = {
    backgroundColor: state.isHovered ? "#9494F5" : "#6666EA",
    color: "white",
    border: "1px solid #9494f5",
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      <Button
        type="default"
        // shape="round"
        icon={<SiLevelsdotfyi size={"1.5em"} style={{ marginRight: 8 }} />}
        className="flex rounded-[20px] items-center"
        size={state.size}
        onClick={showModal}
        onMouseEnter={() => dispatch({ type: "SET_HOVERED", payload: true })}
        onMouseLeave={() => dispatch({ type: "SET_HOVERED", payload: false })}
        style={buttonStyle}
      >
        <div className="flex items-center justify-center">
          <p>Create Curriculum Level</p>
        </div>
      </Button>

      <Modal
        width={"50%"}
        open={open}
        onOk={handleOk}
        confirmLoading={state.confirmLoading}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            backgroundColor: "#6666EA",
            borderColor: "#6666EA",
            color: "#FFFFFF",
            boxShadow: "none",
          },
          disabled: !state.isFormValid,
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "40vh" } }}
      >
        <div className="  rounded-2xl px-4">
          <p className="text-2xl mt-1 font-body text-text_primary font-bold">
            Create Curriculum Level
          </p>
          <hr className="my-3 text-default" />
          <div className=" rounded-lg p-2">
            <p className="mb-1 ml-2"> Level Name</p>

            <section className="flex justify-between">
              <div className="w-full">
                <Input
                  className="h-[40px] ml-1 border-1   border-light_blue   font-semibold"
                  placeholder="level name..."
                  value={state.grade.type}
                  onChange={(e) => handleGradeTypeChange(e.target.value)}
                />
              </div>
            </section>
            <section className="flex flex-col gap-2 mt-2">
              <section className="flex items-center gap-2 justify-between w-full">
                <div className="flex-auto">
                  <p className="mb-2">Curriculum Name</p>
                  <Select
                    showSearch
                    className="w-full"
                    placeholder="Select Curriculum"
                    value={state.grade.curriculumId}
                    onChange={handleCurriculumChange}
                  >
                    {curriculumData.map((curriculum: any) => (
                      <Select.Option
                        key={curriculum._id}
                        value={curriculum._id}
                      >
                        {curriculum.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="w-[150px] ">
                  <p className="mb-2">Choose Color</p>
                  <ColorChange
                    selectedColor={state.grade.color}
                    onColorChange={handleGradeColorChange}
                  />
                </div>
              </section>
              <div className="mt-2">
                <p className="mb-2">Choose Age Group</p>
                <Agegroup
                  selectedAgeGroup={state.grade.ageGroup}
                  onAgeGroupChange={handleAgeGroupChange}
                />
              </div>
            </section>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreatecurricullumLevel;
