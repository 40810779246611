import { useEffect, useReducer, useRef, useState } from "react";
import { Button, Modal, Select, Spin, message } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { FaChild } from "react-icons/fa";
import Quill from "quill";
import BaseService from "../../../helpers/baseServices";
import MyEditor from "../MyEditor";

const Delta = Quill.import("delta");

interface CreateTermsAndConditionProps {
  handleReFetch: any;
  termsID: string | undefined;
  showModal: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface State {
  confirmLoading: boolean;
  size: SizeType;
  isHovered: boolean;
  isFormValid: boolean;
  userType: string | null;
  type: string | null;
  content: string;
}

const initialState: State = {
  confirmLoading: false,
  size: "large",
  isHovered: false,
  isFormValid: false,
  userType: null,
  type: null,
  content: "",
};

type Action =
  | { type: "SET_CONFIRM_LOADING"; payload: boolean }
  | { type: "SET_HOVERED"; payload: boolean }
  | { type: "SET_FORM_VALIDITY"; payload: boolean }
  | { type: "SET_USER_TYPE"; payload: string }
  | { type: "SET_TYPE"; payload: string }
  | { type: "SET_CONTENT"; payload: string }
  | {
      type: "SET_TERMS";
      payload: { userType: string; type: string; content: string };
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_CONFIRM_LOADING":
      return { ...state, confirmLoading: action.payload };
    case "SET_HOVERED":
      return { ...state, isHovered: action.payload };
    case "SET_FORM_VALIDITY":
      return { ...state, isFormValid: action.payload };
    case "SET_USER_TYPE":
      return { ...state, userType: action.payload };
    case "SET_TYPE":
      return { ...state, type: action.payload };
    case "SET_CONTENT":
      return { ...state, content: action.payload };
    case "SET_TERMS":
      return {
        ...state,
        userType: action.payload.userType,
        type: action.payload.type,
        content: action.payload.content,
      };
    default:
      return state;
  }
};

const EditTermsandConditions = ({
  handleReFetch,
  termsID,
  showModal,
  open,
  setOpen,
}: CreateTermsAndConditionProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState<boolean>(false);
  const [range, setRange] = useState<any>(null);
  const [lastChange, setLastChange] = useState<any>(null);
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const quillRef = useRef<Quill | null>(null);

  const updateTermsandConditions = async () => {
    if (!termsID) return;
    setLoading(true);
    try {
      const results = await BaseService.get_api(
        `/robocentre/terms-condition-policy?filter={"_id": {"$$_id": ${JSON.stringify(
          termsID
        )}}}`
      );
      const { payload } = results.data;
      if (payload && payload.length > 0) {
        const { content, type, userType } = payload[0];
        dispatch({
          type: "SET_TERMS",
          payload: { content, type, userType },
        });
        if (quillRef.current) {
          quillRef.current.clipboard.dangerouslyPasteHTML(content);
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      updateTermsandConditions();
    }
  }, [open, termsID]);

  const handleOk = async () => {
    dispatch({ type: "SET_CONFIRM_LOADING", payload: true });

    const payload = {
      _id: termsID,
      userType: state.userType,
      type: state.type,
      content: quillRef.current
        ? quillRef.current.root.innerHTML
        : state.content,
    };

    try {
      if (termsID) {
        const results = await BaseService.put_api(
          "/robocentre/terms-condition-policy",
          payload
        );
        message.success("Terms and Conditions updated successfully");
      }
      handleReFetch();
      setOpen(false);
    } catch (error: any) {
      console.error("Error creating/updating Terms and Conditions:", error);
      message.error(
        error?.response?.data?.error ||
          "Failed to create/update Terms and Conditions"
      );
    } finally {
      dispatch({ type: "SET_CONFIRM_LOADING", payload: false });
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        width={"80%"}
        open={open}
        onOk={handleOk}
        confirmLoading={state.confirmLoading}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            backgroundColor: "#6666EA",
            borderColor: "#6666EA",
            color: "#FFFFFF",
            boxShadow: "none",
          },
          disabled: !state.isFormValid,
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
      >
        <div className="">
          <p className="text-2xl mt-1 font-body text-text_primary font-bold">
            Terms and Conditions
          </p>
          <hr className="my-3 text-default" />
          <div className="flex justify-between py-4 items-center bg-white shadow-sm rounded-[10px]">
            <div className="ml-4 w-[40%] flex items-center justify-between gap-4">
              <Select
                className="w-full"
                allowClear
                placeholder="Select User Type"
                value={state.userType}
                onChange={(value) => {
                  dispatch({ type: "SET_USER_TYPE", payload: value });
                  dispatch({
                    type: "SET_FORM_VALIDITY",
                    payload: !!value && !!state.type && !!state.content,
                  });
                }}
                options={[
                  { value: "PARENT", label: "PARENT" },
                  { value: "USER", label: "GENERAL USER" },
                  { value: "ORGANISATION", label: "ORGANISATION" },
                  { value: "SCHOOL", label: "SCHOOL" },
                ]}
              />
              <Select
                className="w-full"
                placeholder="Select Type"
                allowClear
                value={state.type}
                onChange={(value) => {
                  dispatch({ type: "SET_TYPE", payload: value });
                  dispatch({
                    type: "SET_FORM_VALIDITY",
                    payload: !!value && !!state.userType && !!state.content,
                  });
                }}
                options={[
                  { value: "POLICY", label: "POLICY" },
                  { value: "TERMS_CONDITION", label: "TERMS AND CONDITION" },
                ]}
              />
            </div>
          </div>
          <Spin spinning={loading}>
            <div className="my-5">
              <MyEditor
                readOnly={readOnly}
                onTextChange={(delta, oldDelta, source) => {
                  setLastChange({ delta, oldDelta, source });
                  dispatch({
                    type: "SET_CONTENT",
                    payload: quillRef.current
                      ? quillRef.current.root.innerHTML
                      : "",
                  });
                  dispatch({
                    type: "SET_FORM_VALIDITY",
                    payload: !!state.userType && !!state.type,
                  });
                }}
                onSelectionChange={(range, oldRange, source) => {
                  setRange(range);
                }}
                ref={quillRef}
              />
            </div>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default EditTermsandConditions;
