import { Card, CardBody, Typography } from "@material-tailwind/react";

interface cardProps {
  title: string;
  value: any;
  icon: any;
  small: any;
  className?: any;
  color: any;
}

export default function InforCard({
  title,
  value,
  icon,
  small,
  className,
  color,
}: cardProps) {
  return (
    <Card className={className}>
      <CardBody>
        <Typography variant="h6" className="mb-2">
          {title}
        </Typography>
        <div className="flex mt-auto justify-between">
          <Typography variant="h4" className="mb-2">
            {value}
          </Typography>
          <span
            className="p-2 rounded-lg bg-opacity-50 text-[white]"
            style={{ backgroundColor: color ? color : `#6666ea` }}
          >
            {icon}
          </span>
        </div>
        <small>{small}</small>
      </CardBody>
    </Card>
  );
}
