import { Select } from 'antd'


interface theProps {
    defaultValue?: any
    onChange?: (value: any) => void
}


const CourseTypeSelector = ({ defaultValue, onChange }: theProps) => {
    return (
        <>
            <Select
                className='h-[40px] w-full'
                options={[
                    { label: 'SPECIALISED', value: 'SPECIALISED' },
                    { label: 'BUNDLER', value: 'BUNDLER' },
                ]}
                defaultValue={defaultValue}
                onChange={onChange}
                placeholder='Select course type'
                allowClear
            />
        </>
    )
}

export default CourseTypeSelector
