import { useEffect, useState } from "react";
import PageLayout from "../../components/Layouts/page-layout";
import SearchInput from "../../components/shared/SearchInput";
import { IoMdMore } from "react-icons/io";
import useTableSyle from "../../components/shared/useTableSyle";
import DataTable from "react-data-table-component";
import type { MenuProps } from "antd";
import { Dropdown, Spin } from "antd";
import BaseService from "../../helpers/baseServices";
import { CiEdit } from "react-icons/ci";
import { SlSocialFacebook } from "react-icons/sl";
import { PiYoutubeLogoThin } from "react-icons/pi";
import { CiTwitter } from "react-icons/ci";
import { SlSocialInstagram } from "react-icons/sl";
import { PiTiktokLogoLight } from "react-icons/pi";
import moment from "moment";
import CreateSupport from "./Modal/CreateSupport";
import EditSupport from "./Modal/EditSupport";
import { CiLinkedin } from "react-icons/ci";
import ToolTipComponent from "../../components/shared/ToolTipComponent";
import { FiRefreshCcw } from "react-icons/fi";
import {
  NumberSort,
  dateCompareFunction,
  dynamicStringCompare,
} from "../../components/shared/TableSortFunctions";
const Support = () => {
  const [open, setOpen] = useState(false);
  const [EditOpen, setEditOpen] = useState(false);
  const [customStyles] = useTableSyle();
  const [reFetch, setReFetch] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [AllSupportData, setAllSupportData] = useState<any>();
  const [SupportID, setSupportID] = useState<string>();
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [total, setTotal] = useState(0);

  const showModal = () => {
    setOpen(true);
  };

  const showEditModal = () => {
    setEditOpen(true);
  };

  const handleMenuClick = (e: any, rowId: any) => {
    if (e.key === "1") {
      setEditOpen(true);
      setSupportID(rowId);
    }
  };

  const fetchGradeLevels = async (lim: number, sk: number) => {
    setIsFetching(true);
    try {
      const respopnse: any = await BaseService.get_api(
        `/robocentre/setup/support?sort={"updatedAt":-1}&limit=${lim}&skip=${sk}`
      );
      setAllSupportData(respopnse?.data?.payload);
      setTotal(respopnse?.data?.total);
      console.log(respopnse);
      setIsFetching(false);
    } catch (error: any) {
      console.log("Error is ", error?.response?.data?.error);
    }
  };

  useEffect(() => {
    fetchGradeLevels(limit, skip);
  }, [reFetch]);

  const columns: any = [
    {
      name: <p className="p-2 text-lg font-semibold text-text_primary">Name</p>,
      cell: (row: any) => (
        <div>
          <div className="py-1  text-center ">{row.name}</div>
        </div>
      ),
      sortable: true,
      sortFunction: dynamicStringCompare("name"),
    },
    {
      name: (
        <p className="p-2 text-lg font-semibold text-text_primary">Phone</p>
      ),
      cell: (row: any) => <p className="p-2  text-md">{row.phone}</p>,
      sortable: true,
      sortFunction: NumberSort("phone"),
    },
    {
      name: (
        <p className="p-2 text-lg font-semibold text-text_primary">Socials</p>
      ),
      width: "30%",
      sortable: true,

      cell: (row: any) => {
        return (
          <div className=" w-[80%]  flex flex-col gap-3 mt-2 justify-center">
            <section>
              <div className="flex  items-center">
                <SlSocialFacebook
                  className="text-text_primary"
                  size={"1.5em"}
                />
                <p className="overflow-hidden whitespace-nowrap italic ml-4 text-ellipsis">
                  {row?.socials?.facebook}
                </p>
              </div>
            </section>
            <section>
              <div className="flex  items-center">
                <PiYoutubeLogoThin
                  className="text-text_primary"
                  size={"1.5em"}
                />
                <p className="overflow-hidden whitespace-nowrap ml-4 italic text-ellipsis">
                  {row?.socials?.youtube}
                </p>
              </div>
            </section>
            <section>
              <div className="flex  items-center">
                <CiTwitter className="text-text_primary" size={"1.5em"} />
                <p className="overflow-hidden whitespace-nowrap ml-4 italic text-ellipsis">
                  {row?.socials?.x}
                </p>
              </div>
            </section>
            <section>
              <div className="flex  items-center">
                <SlSocialInstagram
                  className="text-text_primary"
                  size={"1.5em"}
                />
                <p className="overflow-hidden whitespace-nowrap ml-4 italic  text-ellipsis">
                  {row?.socials?.instagram}
                  {}{" "}
                </p>
              </div>
            </section>
            <section>
              <div className="flex  items-center">
                <PiTiktokLogoLight
                  className="text-text_primary"
                  size={"1.5em"}
                />
                <p className="overflow-hidden whitespace-nowrap ml-4 italic text-ellipsis">
                  {row?.socials?.tiktok}
                </p>
              </div>
            </section>
            <section>
              <div className="flex  items-center">
                <CiLinkedin className="text-text_primary" size={"1.5em"} />
                <p className="overflow-hidden whitespace-nowrap ml-4 italic text-ellipsis">
                  {row?.socials?.linkedIn}
                </p>
              </div>
            </section>
          </div>
        );
      },
    },
    {
      name: (
        <p className="p-2  text-lg font-semibold text-text_primary">
          Created On
        </p>
      ),
      sortable: true,
      sortFunction: dateCompareFunction("createdAt"),

      cell: (row: any) => (
        <p className="p-2   text-md">
          {" "}
          {moment(row?.createdAt).format("Do MMM YYYY - hh:mm A")}
        </p>
      ),
    },
    {
      name: (
        <p className="p-2 text-lg font-semibold text-text_primary">
          Last Updated
        </p>
      ),
      sortable: true,

      cell: (row: any) => (
        <p className="p-2   text-md">
          {" "}
          {moment(row?.updated_at).format("Do MMM YYYY - hh:mm A")}
        </p>
      ),
      sortFunction: dateCompareFunction("updated_at"),
    },

    {
      name: "",
      cell: (row: any) => {
        const menuItems: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <span className="text-default_ash text-sm">Edit Support</span>
            ),

            icon: <CiEdit size={"1.3em"} />,
            onClick: (e) => handleMenuClick(e, row._id),
          },
          // {
          //   key: "2",
          //   label: (
          //     <span className="text-default_ash text-sm">Delete School</span>
          //   ),
          //   icon: <MdOutlineDelete color="red" size={"1.3em"} />,
          //   onClick: (e) => handleMenuClick(e, row?._id),
          // },
        ];
        return (
          <Dropdown
            placement="bottomLeft"
            menu={{ items: menuItems }}
            className="text-[14px]"
          >
            <IoMdMore size={"2em"} className="text-[#9494f5]" />
          </Dropdown>
        );
      },
      width: "5%",
    },
  ];
  const handleRowChange = (a: any) => {
    setLimit(a);
  };

  const pageChange = (a: number) => {
    const newSkip = a === 1 ? 0 : (a - 1) * limit;
    setSkip(newSkip);
  };
  return (
    <PageLayout>
      <div className="m-4 ">
        <h2 className="text-2xl text-text_primary mb-4 font-semibold">
          Support
        </h2>

        <div className="flex justify-between py-10 items-center bg-white drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[20px]">
          <div className="ml-4 w-[40%] flex items-center justify-between gap-4">
            <ToolTipComponent text={"Refresh"}>
              <div className="w-[40px] h-[40px] text-[#9494F5] hover:text-[#6666EA] hover:border-1 hover:border-[#6666EA] rounded-lg border-1 border-[#9494F5] flex items-center justify-center cursor-pointer  p-2">
                <FiRefreshCcw
                  onClick={() => setReFetch(!reFetch)}
                  size={"2em"}
                />
              </div>
            </ToolTipComponent>
            <SearchInput />
          </div>

          <div className="flex gap-4 mr-4 items-center">
            <CreateSupport
              showModal={showModal}
              open={open}
              setOpen={setOpen}
              handleReFetch={() => setReFetch(!reFetch)}
            />
          </div>
        </div>

        <div className="bg-white p-4 drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] mt-6 rounded-[20px]">
          <DataTable
            className="mt-4"
            columns={columns}
            data={AllSupportData}
            progressPending={isFetching}
            paginationServer
            progressComponent={<Spin />}
            pagination
            pointerOnHover
            highlightOnHover
            customStyles={customStyles}
          />
        </div>
        {EditOpen && (
          <EditSupport
            showModal={showEditModal}
            SupportID={SupportID}
            open={EditOpen}
            setOpen={setEditOpen}
            handleReFetch={() => setReFetch(!reFetch)}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default Support;
