import { Fragment, useEffect, useState } from "react";
import urls from "../../../../mixins/base.url";
import DataTable from "react-data-table-component";
import { Typography } from "@mui/material";
import moment from "moment";
import { Image } from "react-bootstrap";
import { Spinner } from "@material-tailwind/react";
import accessDeniedImg from "../../../../images/accessDeniedImg.svg";
import baseService from "../../../../mixins/baseServices";
import { Spin } from "antd";

export default function BusinessDocumnetTable() {
  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState<any>([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const get_data = async () => {
      try {
        let skip = (page - 1) * limit;
        const res: any = await baseService.get(
          urls.documents + `?limit=${limit}&skip=${skip}`
        );
        setRequest(res.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setIsError(true);
      }
    };

    get_data();
  }, [limit, page]);

  const columns = [
    {
      name: "Name",
      cell: (row: any) => (
        <Typography variant="subtitle1">{`${row.firstName} ${row.lastName}`}</Typography>
      ),
    },
    {
      name: "Job Title",
      cell: (row: any) => (
        <Typography variant="subtitle1">{row.jobTitle}</Typography>
      ),
    },
    {
      name: "Business name",
      cell: (row: any) => (
        <Typography variant="subtitle1">{row.businessName}</Typography>
      ),
    },
    {
      name: "Business email",
      cell: (row: any) => (
        <Typography variant="subtitle1">{row.businessEmail}</Typography>
      ),
    },
    {
      name: "Phone",
      cell: (row: any) => (
        <Typography variant="subtitle1">{row.phone}</Typography>
      ),
    },
    {
      name: "Date",
      cell: (row: any) => (
        <Typography variant="subtitle1">
          {moment(row.updatedAt).format("lll")}
        </Typography>
      ),
    },
  ];

  return (
    <Fragment>
      <div className="px-2 pt-2 pb-4 bg-white shadow-sm rounded-[20px]">
        {isError ? (
          <div className="flex flex-col justify-center items-center h-full mt-2">
            <Image className="w-[100px]" src={accessDeniedImg} alt="access" />
            <p className="mt-2">You do not have access to view this data...</p>
          </div>
        ) : (
          <DataTable
            data={request?.payload}
            columns={columns}
            progressPending={loading}
            progressComponent={<Spin />}
            pagination
            paginationServer
            paginationTotalRows={request?.total}
            paginationPerPage={limit}
            onChangePage={(page) => {
              setLoading(true);
              setPage(page);
            }}
            onChangeRowsPerPage={(rows) => {
              setLoading(true);
              setPage(1);
              setLimit(rows);
            }}
          />
        )}
      </div>
    </Fragment>
  );
}
