import { Link } from 'react-router-dom'
import { HiOutlineBuildingOffice2, HiPower } from "react-icons/hi2";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { HiOutlineCube } from "react-icons/hi2";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { HiOutlineCog } from "react-icons/hi2";
import { Avatar, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { StorageBox } from '../../helpers/storage';


const AdminGateway = () => {

    //adminRoutes
    const adminRoutes = [
        {
            title: 'MingoBlox Administration',
            code: 'admin',
            description: 'View and manage all your data in one place',
            link: '/dashboard',
            icon: <HiOutlineBuildingOffice2 className='h-[24px] w-[24px] text-[#15C2A5]' />,
            color: '#15C2A5'
        },
        {
            title: 'E-Commerce',
            code: 'ecommerce',
            description: 'View and manage all your e-commerce data',
            link: '/orders',
            icon: <HiOutlineShoppingBag className='h-[24px] w-[24px] text-[#F88E2C]' />,
            color: '#F88E2C'
        },
        {
            title: 'Robocentre',
            code: 'robocentre',
            description: 'View and manage all your robocentre data',
            link: '/courses',
            icon: <HiOutlineUserGroup className='h-[24px] w-[24px] text-[#FF365D]' />,
            color: '#FF365D'
        },
        {
            title: 'Evengo',
            code: 'events',
            description: 'View and manage all your event data',
            link: '#',
            icon: <HiOutlineCube className='h-[24px] w-[24px] text-[#FFC400]' />,
            color: '#FFC400'
        },
        {
            title: 'Settings',
            code: 'settings',
            description: 'View and manage all your settings',
            link: '#',
            icon: <HiOutlineCog className='h-[24px] w-[24px] text-[#FFC400]' />,
            color: '#FFC400'
        },
    ]

    //handleLogout
    const handleLogout = () => {
        StorageBox.clearStorage();
        notification.success({ message: 'Success', description: 'You have been logged out successfully' });
        setTimeout(() => {
            window.location.href = '/signin';
        }, 1000);
    }


    //handleOnSectionClick
    const handleOnSectionClick = (selected: any) => {
        localStorage.setItem('gateway', selected?.code);
        window.location.href = `${selected?.link}`;
    }


    return (
        <>
            <div className="h-screen w-full overflow-hidden bg-[#F9F9FF]">
                <div className="w-full h-full flex flex-col justify-center items-center 2xl:px-[120px] xl:px-[120px] lg:px-[100px] md:px-[40px] px-[20px] relative overflow-y-auto">

                    <div className="w-full mb-10">
                        <p className='text-[42px] text-center font-[600] text-text_deep'>
                            MingoBlox Dashboard
                        </p>

                        <p className='text-[16px] font-[400] text-center text-text_deep'>
                            Welcome to the MingoBlox admin dashboard. Here you can view and manage all your data in one place.
                            Choose a section below to get started.
                        </p>

                        <div className="mt-[50px] w-full">
                            {/* a double grid */}
                            <div className="w-full grid grid-cols-2 gap-[20px]">
                                {adminRoutes.map((route, index) => (
                                    <div key={index} className='w-full h-fit bg-white rounded-lg shadow-md hover:shadow-xl flex justify-between items-center gap-[20px] p-[20px] cursor-pointer' onClick={() => handleOnSectionClick(route)}>
                                        <div className='flex gap-[20px] items-center'>
                                            <div className={`w-[70px] h-[70px] rounded-full flex justify-center items-center`} style={{ backgroundColor: `${route.color}1A` }}>
                                                {route.icon}
                                            </div>

                                            <div>
                                                <p className='text-[21px] font-[600] text-text_deep'>
                                                    {route.title}
                                                </p>

                                                <p className='text-[16px] font-[400] text-text_deep'>
                                                    {route.description}
                                                </p>
                                            </div>
                                        </div>

                                        <div>
                                            <Avatar.Group size="large" maxCount={4}>
                                                <Avatar style={{ backgroundColor: '#6666ea' }}>B</Avatar>
                                                <Avatar style={{ backgroundColor: '#252363' }}>J</Avatar>
                                                <Avatar style={{ backgroundColor: '#f56a00' }}>S</Avatar>
                                                <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                                <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                                <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                            </Avatar.Group>
                                        </div>
                                    </div>
                                ))}

                                {/* sign out */}
                                <Link to="#" className='w-full h-fit bg-red-50 rounded-lg shadow-md hover:shadow-xl p-[20px]' onClick={handleLogout}>
                                    <div className='flex gap-[20px] items-center'>
                                        <div className={`w-[70px] h-[70px] rounded-full flex justify-center items-center bg-red-500 bg-opacity-[10%]`}>
                                            <HiPower className='h-[24px] w-[24px] text-red-500' />
                                        </div>

                                        <div>
                                            <p className='text-[21px] font-[600] text-text_deep'>
                                                Sign Out
                                            </p>

                                            <p className='text-[16px] font-[400] text-text_deep'>
                                                Sign out of your account
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* footer */}
                    <div className="w-full absolute bottom-0 2xl:px-[120px] xl:px-[120px] lg:px-[100px] md:px-[40px] px-[20px]">
                        <div className="w-full flex justify-between items-center py-[30px]">
                            <p className='text-[16px] font-[400] text-text_deep'>
                                © 2024 Mingo blox. All rights reserved.
                            </p>

                            <Link to='#' className='text-[16px] font-[400] text-default_blue'>
                                Contact Support
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AdminGateway
