import { useState } from "react";
import { Typography } from "@material-tailwind/react";
import PageLayout from "../../../../components/Layouts/page-layout";
import AllStaffTable from "./allStaffTable";

const StaffPage = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabNames = ["All", "Marketing", "Software", "Education"];
  const theTabs = [
    <AllStaffTable />
  ];

  return (
    <>
      <PageLayout>
        <div className="m-4">
          <h2 className="text-2xl text-text_primary font-semibold">Manage Staff</h2>

          {/* tabs */}
          <div className="my-3   flex items-center gap-2">
            {tabNames.map((d, i) => (
              <div
                key={i}
                className={`${activeTab === i
                  ? "bg-gradient-to-tr  from-default_blue to-light_blue text-white"
                  : "bg-white text-text_primary"
                  } shadow-sm w-fit px-5 py-2 rounded-[30px] cursor-pointer`}
                onClick={() => setActiveTab(i)}
              >
                <Typography variant="small">{d}</Typography>
              </div>
            ))}
          </div>

          <div>{theTabs[activeTab]}</div>
        </div>
      </PageLayout>
    </>
  );
};

export default StaffPage;
