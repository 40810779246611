import { Navigate, Outlet } from "react-router-dom";
import { StorageBox } from "../helpers/storage";

const ProtectedRoute = ({
  redirectPath = "/signin",
  children,
}: {
  redirectPath?: string;
  children?: any;
}) => {
  const isAuthenticated = !!StorageBox.getAccessToken();

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
