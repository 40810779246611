import { useEffect, useState } from "react";
import PageLayout from "../../components/Layouts/page-layout";
import { IoMdMore } from "react-icons/io";
import useTableSyle from "../../components/shared/useTableSyle";
import DataTable from "react-data-table-component";
import type { MenuProps } from "antd";
import { Dropdown, Input, Spin } from "antd";
import BaseService from "../../helpers/baseServices";
import { CiEdit, CiSearch } from "react-icons/ci";
import CreateTermsandConditions from "./Modal/CreateTermsandCondition";
import EditTermsandConditions from "./Modal/EditTermsandConditions";
import moment from "moment";
import ToolTipComponent from "../../components/shared/ToolTipComponent";
import { FiRefreshCcw } from "react-icons/fi";
import {
  dateCompareFunction,
  dynamicStringCompare,
} from "../../components/shared/TableSortFunctions";
import { debounce } from "lodash";

const TermsConditons = () => {
  const [open, setOpen] = useState(false);
  const [EditOpen, setEditOpen] = useState(false);
  const [customStyles] = useTableSyle();
  const [reFetch, setReFetch] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [AllTermsConditionData, setAllTermsConditionData] = useState<any>();
  const [TermsConditionData, setTermsConditionDataId] = useState<string>();
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [fieldName, setFieldName] = useState<string>();

  const showModal = () => {
    setOpen(true);
  };

  const showEditModal = () => {
    setEditOpen(true);
  };

  const handleMenuClick = (e: any, rowId: any) => {
    if (e.key === "1") {
      setEditOpen(true);
      setTermsConditionDataId(rowId);
    }
  };

  const fetchTermsConditionData = async (lim: number, sk: number) => {
    setIsFetching(true);
    try {
      const filterConditions: Record<string, any>[] = [];

      if (fieldName) {
        filterConditions.push({ type: { $regex: fieldName, $options: "i" } });
      }

      const filter =
        filterConditions.length > 0 ? { $and: filterConditions } : {};
      const filterString = JSON.stringify(filter);

      const respopnse: any = await BaseService.get_api(
        `/robocentre/terms-condition-policy?sort={"updatedAt":-1}&limit=${lim}&skip=${sk}&filter=${filterString}`
      );
      setAllTermsConditionData(respopnse?.data?.payload);
      setTotal(respopnse?.data?.total);
      // console.log(respopnse);
      setIsFetching(false);
    } catch (error: any) {
      console.log("Error is ", error?.response?.data?.error);
    }
  };

  useEffect(() => {
    fetchTermsConditionData(limit, skip);
  }, [reFetch, fieldName, limit, skip]);

  const columns = [
    {
      name: (
        <p className=" text-lg font-semibold   text-text_primary">User Type</p>
      ),
      cell: (row: any) => {
        const newUsers =
          row.userType === "USER" ? "GENERAL USERS" : row.userType;

        return <p className="  w-full  text-md">{newUsers}</p>;
      },
      sortable: true,
      sortFunction: dynamicStringCompare("userType"),
    },
    {
      name: <p className=" text-lg font-semibold text-text_primary">Type</p>,
      cell: (row: any) => {
        const newType =
          row.type === "TERMS_CONDITION" ? "TERMS AND CONDITION" : row.type;

        return <p className=" w-full   text-md text-left ">{newType}</p>;
      },
      sortable: true,
      sortFunction: dynamicStringCompare("type"),
    },

    {
      name: (
        <p className=" text-lg  font-semibold text-text_primary">Created on</p>
      ),
      cell: (row: any) => (
        <p className="w-full  text-md text-left ">
          {" "}
          {moment(row?.createdAt).format("Do MMM YYYY - hh:mm A")}
        </p>
      ),
      sortable: true,
      sortFunction: dateCompareFunction("createdAt"),
    },
    {
      name: (
        <p className=" text-lg  font-semibold text-text_primary">
          Updated Last
        </p>
      ),
      cell: (row: any) => (
        <p className="w-full  text-md text-left ">
          {" "}
          {moment(row?.updatedAt).format("Do MMM YYYY - hh:mm A")}
        </p>
      ),
      sortable: true,
      sortFunction: dateCompareFunction("updatedAt"),
    },

    {
      name: "",
      cell: (row: any) => {
        const menuItems: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <span className="text-default_ash text-sm">
                Edit Terms and Conditions
              </span>
            ),

            icon: <CiEdit size={"1.3em"} />,
            onClick: (e) => handleMenuClick(e, row._id),
          },
          // {
          //   key: "2",
          //   label: (
          //     <span className="text-default_ash text-sm">
          //       Delete Terms and Conditions
          //     </span>
          //   ),
          //   icon: <MdOutlineDelete color="red" size={"1.3em"} />,
          //   onClick: (e) => handleMenuClick(e, row._id),
          // },
        ];
        return (
          <Dropdown
            placement="bottomLeft"
            menu={{ items: menuItems }}
            className="text-[14px]"
          >
            <IoMdMore size={"2em"} className="text-[#9494f5]" />
          </Dropdown>
        );
      },
      width: "5%",
    },
  ];
  const handleRowChange = (a: any) => {
    setLimit(a);
  };

  const pageChange = (a: number) => {
    const newSkip = a === 1 ? 0 : (a - 1) * limit;
    setSkip(newSkip);
  };
  const handlerSearchChange = debounce((e: any) => {
    setFieldName(e.target.value);
  }, 500);
  return (
    <PageLayout>
      <div className="m-4 ">
        <h2 className="text-2xl my-4 text-text_primary font-semibold">
          Terms and Conditions
        </h2>

        <div className="flex justify-between py-10 items-center bg-white drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[20px]">
          <div className="ml-4 w-[40%] flex items-center justify-between gap-4">
            <ToolTipComponent text={"Refresh"}>
              <div className="w-[40px] h-[40px] text-[#9494F5] hover:text-[#6666EA] hover:border-1 hover:border-[#6666EA] rounded-lg border-1 border-[#9494F5] flex items-center justify-center cursor-pointer  p-2">
                <FiRefreshCcw
                  onClick={() => setReFetch(!reFetch)}
                  size={"2em"}
                />
              </div>
            </ToolTipComponent>
            <Input
              size="large"
              placeholder="Search for type...eg:Policy"
              className="rounded-[20px] border-[1px] border-[#9494f5] text-[#9494f5] "
              suffix={<CiSearch />}
              onChange={(e) => handlerSearchChange(e)}
            />
          </div>

          <div className="flex gap-4 mr-4 items-center">
            <CreateTermsandConditions
              showModal={showModal}
              open={open}
              setOpen={setOpen}
              handleReFetch={() => setReFetch(!reFetch)}
            />
          </div>
        </div>

        <div className="bg-white p-4 drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] mt-6 rounded-[20px]">
          <DataTable
            className="mt-4"
            columns={columns}
            data={AllTermsConditionData}
            progressPending={isFetching}
            paginationServer
            paginationTotalRows={total}
            progressComponent={<Spin />}
            pagination
            pointerOnHover
            highlightOnHover
            onChangeRowsPerPage={handleRowChange}
            onChangePage={pageChange}
            customStyles={customStyles}
          />
        </div>
        {EditOpen && (
          <EditTermsandConditions
            showModal={showEditModal}
            termsID={TermsConditionData}
            open={EditOpen}
            setOpen={setEditOpen}
            handleReFetch={() => setReFetch(!reFetch)}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default TermsConditons;
