import React, { useState } from 'react'
import logoImg from "../../images/mingo-logo.png"
import { Form, Input, notification, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import BaseService from '../../helpers/baseServices';
import { StorageBox } from '../../helpers/storage';
import { Link } from 'react-router-dom';
import ForgotPwdModal from './forgot-pwd-modal';

interface formValues {
    email: string
    password: string
}

const SigninPage = () => {

    const [form] = useForm()
    const [isBusy, setIsBusy] = useState(false)
    const [openForgotModal, setOpenForgotModal] = useState(false);


    //handleFormSubmit
    const handleFormSubmit = async (values: formValues) => {
        setIsBusy(true)

        try {
            const { email, password } = values;
            const res = await BaseService.post_api("/robocentre/auth/signin", {
                email,
                password,
            });

            if (res?.data?.payload && Object.keys(res?.data?.payload).length > 0) {
                StorageBox.saveAccessToken(res?.data?.token);
                StorageBox.saveUserData(res?.data?.payload);
                setTimeout(() => {
                    window.location.href = "/choose-domain"
                }, 1000)
                notification.success({ message: `Logged in as ${res?.data?.payload?.firstName || "Administrator"}` })
            } else {
                StorageBox.saveTempToken(res?.data?.token);
                setTimeout(() => {
                    window.location.href = "/verify"
                }, 1000)
            }
        } catch (error: any) {
            notification.error({ message: error?.response?.data?.error || "Error while signing in. Please retry" })
            setIsBusy(false)
        }
    }


    return (
        <>
            <div className='h-screen w-full overflow-hidden bg-[#F9F9FF]'>
                <div className="flex justify-center items-center h-full p-5">
                    <div className="2xl:w-[380px] xl:w-[380px] lg:w-[380px] md:w-[380px]">
                        <img className='flex mx-auto' src={logoImg} alt="" />

                        {/* form */}
                        <div className="mt-[60px]">
                            <Form
                                form={form}
                                onFinish={handleFormSubmit}
                                layout='vertical'
                                initialValues={{ remember: true }}
                                disabled={isBusy}
                            >
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Email!',
                                        },
                                        {
                                            type: 'email',
                                            message: 'Please input a valid Email!',
                                        },
                                    ]}
                                >
                                    <Input
                                        className='h-[40px]'
                                        prefix={<UserOutlined className="text-default_blue" />}
                                        placeholder="Email"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Password!',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        className='h-[40px]'
                                        prefix={<LockOutlined className="text-default_blue" />}
                                        placeholder="Password"
                                    />
                                </Form.Item>

                                <Form.Item className='mt-[50px]'>
                                    <button className='h-[50px] w-full bg-default_blue text-white rounded-[40px]'>
                                        {
                                            isBusy ?
                                                <Spin indicator={<LoadingOutlined style={{ fontSize: 28, color: 'white' }} spin />} />
                                                :
                                                <p className='text-[16px] font-[600]'>Sign In</p>
                                        }
                                    </button>
                                </Form.Item>
                            </Form>

                            <div className="flex justify-center items-center gap-2 mt-[20px]">
                                <p className='text-[16px] font-[400] text-[#606060]'>Forgot Password?</p>
                                <Link
                                    className="text-default_blue font-[400] text-[16px]"
                                    to="#"
                                    onClick={() => setOpenForgotModal(true)}
                                >
                                    Reset Password
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* forgot password modal */}
            {openForgotModal && (
                <ForgotPwdModal
                    openPwdModal={openForgotModal}
                    handleClosePwdModal={() => setOpenForgotModal(false)}
                />
            )}
        </>
    )
}

export default SigninPage
