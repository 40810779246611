import { useState } from "react";
import { Button } from "antd";
import type { ConfigProviderProps } from "antd";

type SizeType = ConfigProviderProps["componentSize"];

const CustomButtonRounded = ({
  onClick,
  icon,
  value,
  children,
}: {
  onClick?: any;
  icon?: any;
  value?: string;
  children?: any;
}) => {
  const [size, setSize] = useState<SizeType>("large"); // default is 'middle'
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    backgroundColor: isHovered ? "#9494F5" : "#6666EA",
    color: "white",
    border: "1px solid #9494f5",
    display: "flex",
    alignItems: "center",
  };

  return (
    <Button
      type="default"
      shape="round"
      icon={icon}
      className="flex items-center"
      size={size}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={buttonStyle}
    >
      <span>{value}</span>
    </Button>
  );
};

export default CustomButtonRounded;
