import React, { useState } from "react";
import { BsXLg } from "react-icons/bs";
import otp from "../../../images/customer_img.png";
import CustomButtomSquare from "../../../components/shared/CustomButtomSquare";
import { IoEyeOutline } from "react-icons/io5";
import SendMessage from "../Modal/SendMessage";

const MoreDetails = () => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };
  const onClickHandler = (e: any) => {
    console.log("button clicked", e);
  };
  return (
    <>
      <div className="p-4 text-text_deep shadow-md rounded-[25px] my-4 bg-white">
        <p className="text-[20px] text-text_deep mb-2 font-medium ">
          Customer and order details{" "}
        </p>
        <hr />
        <section className="flex items-center">
          <div className="w-[140px] h-[140px] mt-4 ">
            <img
              src={otp}
              alt="avatar"
              className="rounded-full"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div className=" w-[80%] flex flex-col gap-4  mt-6">
            <div className=" gap-4 flex  justify-around">
              <section>
                <p className="text-[12px]">Product Date</p>
                <p className="mt-1 text-[16px]">Sept. 27, 2023</p>
              </section>
              <section>
                <p className="text-[12px]">Weight</p>
                <p className="mt-1 text-[16px]">120kg</p>
              </section>
              <section>
                <p className="text-[12px]">In Stock</p>
                <p className="mt-1 text-[16px]">23455</p>
              </section>
            </div>
            <div className=" flex justify-around gap-4">
              <section>
                <p className="text-[12px]">Category</p>
                <p className="mt-1 text-[16px]">Robot kit</p>
              </section>
              <section>
                <p className="text-[12px]">Product Date</p>
                <p className="mt-1 text-[16px]">Sept. 27, 2023</p>
              </section>
              <section>
                <p className="text-[12px]">Weight</p>
                <p className="mt-1 text-[16px]">120kg</p>
              </section>
            </div>
          </div>
        </section>
        <div className="w-full flex justify-end">
          <SendMessage showModal={showModal} open={open} setOpen={setOpen} />
        </div>
      </div>
    </>
  );
};

export default MoreDetails;

// export default MoreDetails;
