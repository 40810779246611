import React from "react";
import PersonalInformation from "../../../customers/PersonalInformation";
import LegalDetails from "./LegalDetails";

const MoreDetails = () => {
  return (
    <div>
      <PersonalInformation />
      <PersonalInformation />
      <PersonalInformation />
      <LegalDetails />
    </div>
  );
};

export default MoreDetails;
