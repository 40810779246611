export const orderList = [
    {
        id: "GX8466hdjFFFG",
        customer_name: "Beetlejuice",
        order_date: "2022-10-27",
        total_amount: "920",
        status: "Processing"
    },
    {
        id: "GX866hdjFFFG",
        customer_name: "Beetlejuice",
        order_date: "2022-10-27",
        total_amount: "92",
        status: "Ready to Ship"
    },
    {
        id: "GX84hh66hdjFF",
        customer_name: "John AMG",
        order_date: "2022-10-27",
        total_amount: "2000",
        status: "Shipped"
    },
    {
        id: "GX846djFFFG",
        customer_name: "Beetle Juice",
        order_date: "2022-10-25",
        total_amount: "9200",
        status: "En Route"
    },
    {
        id: "GX8466hdjFF",
        customer_name: "John AMG",
        order_date: "2022-10-27",
        total_amount: "2000",
        status: "Delivered"
    }
]