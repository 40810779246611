import { useState } from "react";
import { Typography } from "@material-tailwind/react";

import { useParams } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { RxAvatar } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import PageLayout from "../../../../components/Layouts/page-layout";
import CustomButtomRounded from "../../../../components/shared/CustomButtomRounded";
import CustomButtomSquare from "../../../../components/shared/CustomButtomSquare";
import otp from "../../../../images/customer_img.png";
import PersonalInformation from "../../../customers/PersonalInformation";
import TextArea from "antd/es/input/TextArea";
import FileUploade from "../Educators/FileUploade";
import MoreDetails from "./BMoreDetails";
const VBusinessDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const handlerClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    navigate("/verificationRequest");
  };
  // console.log(id);

  return (
    <>
      <PageLayout>
        <div className="m-4  flex gap-8 ">
          <section className="w-[80%] ">
            <div className="flex items-center gap-4 ml-6 mb-14   ">
              <CustomButtomRounded
                icon={<FaArrowLeft />}
                value={"Back"}
                onClick={handlerClick}
              />
              <h2 className="text-4xl text-text_primary font-semibold">
                Business Details
              </h2>
            </div>
            <MoreDetails />
          </section>
          <section className=" flex flex-col gap-2  w-[25%] ">
            <div className="w-full flex items-center h-[250px] border-1 border-solid  border-outline_light rounded-lg justify-center flex-col ">
              <div>
                <FileUploade />
              </div>
              <p className="text-text_deep text-center text-[20px] whitespace-nowrap overflow-hidden text-ellipsis w-full font-semibold">
                School Name Here{" "}
              </p>
              <p className="text-light_blue">Email@gmail.com</p>
            </div>
            <div>
              <TextArea
                rows={4}
                placeholder="Add note"
                className="border-[1px] border-outline_light text-[#9494f5]"
              />
            </div>
            <div>
              <div className="w-[80%] grid gap-2 grid-cols-2 grid-rows-3 ">
                <CustomButtomSquare
                  value="Send message"
                  icon={<RxAvatar size={"1.3em"} />}
                  myStyle={"col-span-2 text-[14px] font-semibold shadow-md"}
                />
                <CustomButtomSquare
                  value="Export"
                  icon={<RxAvatar size={"1.3em"} />}
                  myStyle={
                    "row-start-2  row-end-3 text-[14px] shadow-md font-semibold"
                  }
                  row-start-2
                />
                <CustomButtomSquare
                  myStyle={
                    "flex justify-center items-center text-[14px] shadow-md font-semibold"
                  }
                  icon={<IoEyeOutline size={"1.3em"} />}
                />

                <CustomButtomSquare
                  value="Suspend User"
                  icon={<IoEyeOutline size={"1.3em"} />}
                  myStyle={"col-span-2 text-[14px] shadow-md font-semibold"}
                />
              </div>
            </div>
          </section>
        </div>
      </PageLayout>
    </>
  );
};

export default VBusinessDetails;

// export default VBusinessDetails;
