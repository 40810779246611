import CustomButtomSquare from "../../../components/shared/CustomButtomSquare";
import { IoSchoolOutline } from "react-icons/io5";
import { PiExportThin } from "react-icons/pi";
import { IoPrintOutline } from "react-icons/io5";
const IconsPage = () => {
  return (
    <>
      <div className=" flex flex-col gap-2  w-[20%]  ">
        <div className="w-full grid gap-2 grid-cols-2 grid-rows-3 ">
          <CustomButtomSquare
            value="Create School"
            icon={<IoSchoolOutline size={"1.5em"} />}
            myStyle={
              "col-span-2 text-[14px] font-semibold text-center shadow-md"
            }
          />
          <CustomButtomSquare
            value="Export"
            icon={<PiExportThin size={"1.5em"} />}
            myStyle={
              "row-start-2  row-end-3 text-[14px] shadow-md font-semibold"
            }
            row-start-2
          />
          <CustomButtomSquare
            myStyle={
              "flex justify-center items-center text-[14px] shadow-md font-semibold"
            }
            icon={<IoPrintOutline size={"1.6em"} />}
          />
        </div>
      </div>
    </>
  );
};

export default IconsPage;
