import { useEffect, useState } from "react";
import PageLayout from "../../components/Layouts/page-layout";
import useTableSyle from "../../components/shared/useTableSyle";
import DataTable from "react-data-table-component";
import { Input, Spin } from "antd";
import BaseService from "../../helpers/baseServices";
import moment from "moment";
import ToolTipComponent from "../../components/shared/ToolTipComponent";
import { FiRefreshCcw } from "react-icons/fi";
import {
  NumberSort,
  dateCompareFunction,
  dynamicStringCompare,
} from "../../components/shared/TableSortFunctions";
import ViewSchool from "./Modal/ViewSchool";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { debounce } from "lodash";

const Schools = () => {
  const [EditOpen, setEditOpen] = useState(false);
  const [customStyles] = useTableSyle();
  const [reFetch, setReFetch] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [AllSchools, setAllSchools] = useState<any>();
  const [SupportID, setSupportID] = useState<string>();
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [total, setTotal] = useState(0);
  const [fieldName, setFieldName] = useState<string>();

  const navigate = useNavigate();

  const showEditModal = () => {
    setEditOpen(true);
  };

  const handleMenuClick = (e: any, rowId: any) => {
    if (e.key === "1") {
      setEditOpen(true);
      setSupportID(rowId);
    }
  };

  //fetchAllSchools
  const fetchAllSchools = async (lim: number, sk: number) => {
    setIsFetching(true);

    try {
      const filterConditions: Record<string, any>[] = [];
      if (fieldName) {
        filterConditions.push({
          approved: true,
          "schoolInformation.schoolName": {
            $regex: `${fieldName}`,
            $options: "i",
          },
        });
      }

      const filter =
        filterConditions.length > 0
          ? { $and: filterConditions }
          : { approved: true };

      const filterString = JSON.stringify(filter);

      const response: any = await BaseService.get_api(
        `/robocentre/roboschool/onboarding/school?sort={"updatedAt":-1}&limit=${lim}&skip=${sk}&filter=${filterString}`
      );

      console.log("response?.data?.payload", response?.data?.payload);
      console.log("filterString", filterString);

      setAllSchools(response?.data?.payload);
      setTotal(response?.data?.total);
    } catch (error: any) {
      console.log("Error is ", error?.response?.data?.error || error.message);
    } finally {
      setIsFetching(false);
    }
  };

  //on load
  useEffect(() => {
    fetchAllSchools(limit, skip);
  }, [reFetch, fieldName, limit, skip]);

  //table columns
  const columns: any = [
    {
      name: (
        <p className="p-2 text-[12px] font-semibold text-text_primary">
          School Name
        </p>
      ),
      cell: (row: any) => (
        <div className="flex justify-center items-center gap-2">
          <img
            className="rounded-md"
            src={
              row.picture ||
              "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
            }
            alt="..."
            height="35px"
            width="35px"
          />
          <p>{row?.schoolInformation?.schoolName}</p>
        </div>
      ),
      sortable: true,
      sortFunction: dynamicStringCompare("name"),
      width: "20%",
    },
    {
      name: (
        <p className="p-2 text-md font-semibold text-text_primary">
          Principal Name
        </p>
      ),
      cell: (row: any) => {
        const firstname = row?.principal?.firstName || "";
        const middlename = row?.principal?.middleName || "";
        const lastNamename = row?.principal?.lastName || "";

        return (
          <p className="p-2  text-md">{`${firstname} ${middlename} ${lastNamename}`}</p>
        );
      },
      sortable: true,
      sortFunction: NumberSort("phone"),
      width: "15%",
    },
    {
      name: (
        <p className="p-2 text-md font-semibold text-text_primary">
          Principal Email
        </p>
      ),
      cell: (row: any) => (
        <p className="p-2  text-md">{row?.principal.email}</p>
      ),
      sortable: true,
      sortFunction: NumberSort("phone"),
      width: "15%",
    },

    {
      name: (
        <p className="p-2 text-md font-semibold text-text_primary">Phone</p>
      ),
      cell: (row: any) => (
        <p className="p-2  text-md">{row?.principal?.phone}</p>
      ),
      sortable: true,
      sortFunction: NumberSort("phone"),
      width: "15%",
    },
    {
      name: "Status",
      selector: (row: { approved: any }) => {
        const isStatus = row?.approved ? "Approved" : "not Approved";
        const backgroundColor =
          isStatus === "Approved"
            ? "rgba(119, 209, 164, 0.1)"
            : "rgba(255, 99, 141, 0.1)";
        const textColor = isStatus === "Approved" ? "#77D1A4" : "#FF638D";

        return (
          <div
            className=" flex justify-center items-center  w-auto rounded-[30px] h-[30px] px-[16px] pb-[3px]"
            style={{ backgroundColor, color: textColor }}
          >
            {isStatus}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: (
        <p className=" text-md text-center w-full font-semibold text-text_primary">
          Students
        </p>
      ),
      cell: (row: any) => (
        <p className="p-2 w-full text-center text-md">
          {row?.schoolInformation?.leanerPopulation}
        </p>
      ),
      sortable: true,
      sortFunction: NumberSort("phone"),
    },

    {
      name: (
        <p className="p-2 text-md  font-semibold text-text_primary">
          Created On
        </p>
      ),
      sortable: true,
      sortFunction: dateCompareFunction("createdAt"),

      cell: (row: any) => (
        <p className="p-2   text-md">
          {" "}
          {moment(row?.createdAt).format("Do MMM YYYY - hh:mm A")}
        </p>
      ),
    },
  ];
  const handleRowChange = (a: any) => {
    setLimit(a);
  };

  const pageChange = (a: number) => {
    const newSkip = a === 1 ? 0 : (a - 1) * limit;
    setSkip(newSkip);
  };

  // handleNameChange
  const handleNameChange = debounce((e: any) => {
    setFieldName(e);
  }, 500);


  return (
    <PageLayout>
      <div className="m-4 ">
        <h2 className="text-2xl text-text_primary mb-4 font-semibold">
          Schools
        </h2>

        <div className="flex justify-between py-10 items-center bg-white drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[20px]">
          <div className="ml-4 w-[40%] flex items-center  gap-4">
            <ToolTipComponent text={"Refresh"}>
              <div className="w-[40px] h-[40px] text-[#9494F5] hover:text-[#6666EA] hover:border-1 hover:border-[#6666EA] rounded-lg border-1 border-[#9494F5] flex items-center justify-center cursor-pointer  p-2">
                <FiRefreshCcw
                  onClick={() => setReFetch(!reFetch)}
                  size={"2em"}
                />
              </div>
            </ToolTipComponent>
            <div className="w-[50%]">
              <Input
                size="large"
                onChange={(e) => handleNameChange(e.target.value)}
                placeholder="Search name..."
                className="rounded-[20px] border-[1px] border-[#9494f5] text-[#9494f5] "
                suffix={<CiSearch />}
              />
            </div>
          </div>

          {/* <div className="flex gap-4 mr-4 items-center">
            <CreateSupport
              showModal={showModal}
              open={open}
              setOpen={setOpen}
              handleReFetch={() => setReFetch(!reFetch)}
            />
          </div> */}
        </div>

        <div className="bg-white p-4 drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] mt-6 rounded-[20px]">
          <DataTable
            className="mt-4"
            columns={columns}
            data={AllSchools}
            progressPending={isFetching}
            paginationServer
            progressComponent={<Spin />}
            onChangeRowsPerPage={handleRowChange}
            onChangePage={pageChange}
            paginationTotalRows={total}
            pagination
            pointerOnHover
            highlightOnHover
            customStyles={customStyles}
            onRowClicked={(selected: any) =>
              navigate(`/school/${selected._id}`)
            }
          />
        </div>
        {EditOpen && (
          <ViewSchool
            showModal={showEditModal}
            SupportID={SupportID}
            open={EditOpen}
            setOpen={setEditOpen}
            handleReFetch={() => setReFetch(!reFetch)}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default Schools;
