import React, { useEffect, useState } from "react";
import { ColorPicker, Space } from "antd";
import type { ColorPickerProps, GetProp } from "antd";

type Color = GetProp<ColorPickerProps, "value">;
type Format = GetProp<ColorPickerProps, "format">;

interface ColorChangeProps {
  onColorChange: (color: string) => void;
  selectedColor: Color;
}

const ColorChange: React.FC<ColorChangeProps> = ({
  onColorChange,
  selectedColor,
}) => {
  const [formatHex, setFormatHex] = useState<Format | undefined>("hex");

  const handleChange = (color: Color) => {
    if (typeof color === "string") {
      onColorChange(color);
    } else if (color) {
      onColorChange(color.toHexString());
    }
  };

  return (
    <Space direction="vertical">
      <ColorPicker
        value={selectedColor}
        size="large"
        onFormatChange={setFormatHex}
        format={formatHex}
        showText
        onChange={handleChange}
      />
    </Space>
  );
};

export default ColorChange;
