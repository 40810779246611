import { useState } from "react";
import AllCurriculum from "./AllCurriculum";
import CoreCurriculum from "./CoreCurriculum";
import ElectiveCurriculum from "./ElectiveCurriculum";
import SpecializationCurriculum from "./SpecializationCurriculum";

const Curriculum = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabNames = ["All", "Core", "Elective", "Specialization"];
  const theTabs = [
    <AllCurriculum />,
    <CoreCurriculum />,
    <ElectiveCurriculum />,
    <SpecializationCurriculum />,
  ];

  return (
    <div>
      <div className="flex items-center mt-4 gap-2">
        {tabNames.map((d, i) => (
          <div
            key={i}
            className={`${
              activeTab === i
                ? "border-b-[2px] border-default_blue text_default_blue border-solid "
                : "text_primary"
            }  w-fit px-7 py-1 text-text_primary  cursor-pointer`}
            onClick={() => setActiveTab(i)}
          >
            <span>{d}</span>
          </div>
        ))}
      </div>
      <div>{theTabs[activeTab]}</div>
    </div>
  );
};

export default Curriculum;
