import React, { useState } from 'react'
import { Form, InputNumber, message, Modal, Spin } from 'antd';
import GradeLevelSelectorComponent from '../../components/gradeLevelSelectorComponent';
import RoboCurriculumSelector from '../roboCurriculum/roboCurriculumSelector';
import { PiCloudArrowUpLight } from 'react-icons/pi';
import CourseSelectorComponent from '../../../../components/shared/courseSelectorComponent';
import BaseService from '../../../../helpers/baseServices';
import { useSearchParams } from 'react-router-dom';



interface modalProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
}


const CreateNewRoboBundler = ({ isOpened, handleClose, handleReFetch }: modalProps) => {

    const [searchParams] = useSearchParams();
    const priceType = searchParams.get('type');
    const [form] = Form.useForm();
    const [isBusy, setIsBusy] = useState(false)


    //on form submit
    const onFinish = () => {

        form.validateFields().then((formValues: any) => {

            let cooked_data: any = {
                "electives": formValues?.electives.map((item: any) => (item?.value)),
                "peripherals": formValues?.peripherals.map((item: any) => (item?.value)),
                "peripheralsNumber": formValues?.peripherals.length,
                "countryId": "65d4b85e9ae65ff72c90900e",
                "gradeLevelId": formValues?.gradeLevelId?.value,
                "roboCuriculumId": formValues?.roboCuriculumId?.value,
                "overrideCost": parseInt(formValues?.overrideCost),
                "discounted": parseInt(formValues?.discounted),
                "durationInDays": parseInt(formValues?.durationInDays)
            }
            if (!cooked_data?.discounted) {
                delete cooked_data?.discounted
            }
            if (!cooked_data?.overrideCost) {
                delete cooked_data?.overrideCost
            }

            console.log("formValues:", cooked_data)

            setIsBusy(true)

            BaseService.post_api(`/robocentre/robo-bundler`, cooked_data)
                .then((response: any) => {
                    console.log("newRoboRes:", response?.data)
                    message.success("Robo bundler was created successfully!")
                    setIsBusy(false)
                    form.resetFields()
                    handleClose()
                    handleReFetch()
                }).catch((error: any) => {
                    console.log("newRoboErr:", error)
                    message.error(error?.response?.data?.error || "An unknown error occured. Please try again")
                    setIsBusy(false)
                })

        }).catch((error) => {
            console.log("formErrors:", error)
        })


    };



    return (
        <>
            <Modal title="Create New Bundler" open={isOpened} onCancel={handleClose} footer={null} keyboard={false} maskClosable={false} closeIcon={!isBusy}>
                <hr className='mb-[20px]' />
                <Spin spinning={isBusy}>
                    <Form form={form} layout="vertical" onFinish={onFinish}>
                        <Form.Item
                            label="Robo Curriculum"
                            name="roboCuriculumId"
                            rules={[{ required: true, message: 'Please select a curriculum!' }]}
                        >
                            <RoboCurriculumSelector isMulti={false} />
                        </Form.Item>

                        <Form.Item
                            label="Elective Courses"
                            name="electives"
                            rules={[{ required: true, message: 'Please select an elective course!' }]}
                        >
                            <CourseSelectorComponent />
                        </Form.Item>

                        <Form.Item
                            label="Addon Courses"
                            name="peripherals"
                            rules={[{ required: true, message: 'Please select an addon course!' }]}
                        >
                            <CourseSelectorComponent />
                        </Form.Item>

                        <Form.Item
                            label="Grade Level"
                            name="gradeLevelId"
                            rules={[{ required: true, message: 'Please select a grade level!' }]}
                        >
                            <GradeLevelSelectorComponent isMulti={false} />
                        </Form.Item>

                        {
                            priceType === "DISCOUNT" &&
                            <Form.Item
                                label="Discount (%)"
                                name="discounted"
                                rules={[{ required: true, message: 'Please enter discount value!' }]}
                            >
                                <InputNumber className='h-[40px] w-full' placeholder='Ex: 10' />
                            </Form.Item>
                        }

                        {
                            priceType === "OVERRIDE" &&
                            <Form.Item
                                label="Cost"
                                name="overrideCost"
                                rules={[{ required: true, message: 'Please enter cost!' }]}
                            >
                                <InputNumber className='h-[40px] w-full' placeholder='Ex: 450' />
                            </Form.Item>
                        }

                        <Form.Item
                            label="Duration in days"
                            name="durationInDays"
                            rules={[{ required: true, message: 'Please enter duration!' }]}
                        >
                            <InputNumber className='h-[40px] w-full' placeholder='Ex: 450' />
                        </Form.Item>

                        <Form.Item>
                            <button className='w-full h-[50px] rounded-[5px] bg-default_blue text-white flex gap-[5px] items-center justify-center'>
                                <PiCloudArrowUpLight className='h-[20px] w-[20px]' />
                                <p>Publish Bundler</p>
                            </button>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
}

export default CreateNewRoboBundler
