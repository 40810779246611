import React, { useState } from 'react'
import AssessmentSettings from './tabs/assessmentSettings'
import AssessmentQuestions from './tabs/assessmentQuestions'
import { useMainContext } from '../../../contexts/mainContext'



const AssessmentExamsPage = () => {

    const [activeTab, setActiveTab] = useState(0)
    const { assessmentInfo } = useMainContext()

    //tabNames
    const tabNames = [
        "Settings", "Questions"
    ]

    //tabComponents
    const tabComponents = [
        <AssessmentSettings />,
        <AssessmentQuestions />
    ]

    return (
        <>
            {/* content */}
            <div className="mt-[20px] w-full grid grid-cols-4 gap-[20px]">
                {/* left */}
                <div className="w-full col-span-1 sticky-top h-[500px]">
                    <div className="w-full h-fit bg-white rounded-[20px] shadow-sm py-[35px]">

                        <div className="mt-[5px]">
                            {
                                tabNames.map((tab, i) => (
                                    <div key={i} className={`w-full px-[23px] py-[15px] cursor-pointer ${activeTab === i ? 'border-l-[7px] border-l-default_blue bg-[#F8F8F8]' : 'border-l-[7px] border-l-transparent'}`}>
                                        <button className='w-full text-left disabled:cursor-not-allowed' onClick={() => setActiveTab(i)} disabled={!assessmentInfo?.title}>
                                            <p className='font-[400] text-text_primary'>{tab}</p>
                                        </button>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                {/* right */}
                <div className='w-full col-span-3'>
                    {tabComponents[activeTab]}
                </div>
            </div>
        </>
    )
}

export default AssessmentExamsPage
