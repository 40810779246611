import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import BaseService from '../../../helpers/baseServices'
import { global_variables } from '../../../helpers/globalVariables'

interface selectProps {
    onChange?: (data: any) => void
    defaultValue?: any
}



const CourseCategorySelectorComponent = ({ defaultValue, onChange }: selectProps) => {

    const [isBusy, setIsBusy] = useState(true)
    const [dataArray, setDataArray] = useState([])

    //onload
    useEffect(() => {
        const fetchTheData = async () => {
            setIsBusy(true)

            try {
                const response = await BaseService.get_api(global_variables.all_course_category)
                // console.log(response)
                const mapped = response?.data?.payload.map((item: any) => (
                    { label: item?.name, value: item?._id }
                ))
                setDataArray(mapped)
                setIsBusy(false)
            } catch (error) {
                console.log(error)
            }
        }

        fetchTheData()
    }, [])


    return (
        <>
            <Select
                className='h-[40px] w-full'
                placeholder='Choose category...'
                options={dataArray}
                onChange={onChange}
                defaultValue={defaultValue}
                isLoading={isBusy}
                isDisabled={isBusy}
                isClearable
                isMulti
            />
        </>
    )
}

export default CourseCategorySelectorComponent
