import {
  ArrowDownOnSquareStackIcon,
  EllipsisVerticalIcon,
  PlusIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  Card,
  CardBody,
  Input,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Textarea,
  Typography,
} from "@material-tailwind/react";
import { LinearProgress, Pagination } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ShowToast from "../../../../components/Alerts/all_toasts";
import ShowAlert from "../../../../components/Alerts/all_alerts";
import baseService from "../../../../mixins/baseServices";
import urls from "../../../../mixins/base.url";

export default function RobocenterSchoolTheme({
  school_id,
  reload,
  setReload,
}: any) {
  const [loading, setLoading] = useState(true);
  const [themes, setThemes] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    const get_theme = async () => {
      try {
        let skip = (page - 1) * limit;

        const response: any = await baseService.get(
          urls.theme + `?limit=${limit}&skip=${skip}`
        );

        // console.log(response.data);
        setThemes(response.data);
        setLoading(false);
      } catch (error) {}
    };

    get_theme();
  }, [limit, page, reload]);

  const navigate = useNavigate();
  const [editTheme, setEditTheme] = useState(null);
  return (
    <Fragment>
      <div className="mb-5">
        {loading ? (
          <LinearProgress />
        ) : (
          themes?.payload?.map((_d: any, i: number) => {
            return (
              <Card key={i} className=" my-3">
                <CardBody>
                  <div className="grid grid-cols-1  lg:grid-cols-7">
                    {/* <div
                      className="col-span-2"
                      onClick={() =>
                        navigate(`/robocenter-schools/${school_id}/${_d.name}`)
                      }
                    >
                      <img
                        className="w-24 h-24 object-cover rounded-lg cursor-pointer "
                        src="https://c4.wallpaperflare.com/wallpaper/987/319/586/windows-11-dark-theme-silk-hd-wallpaper-preview.jpg"
                        alt="thumbnail"
                      />
                    </div> */}
                    <div
                      className="col-span-6 cursor-pointer"
                      onClick={() =>
                        navigate(`/robocenter-schools/${school_id}/${_d.name}`)
                      }
                    >
                      <Typography variant="lead">{_d?.name}</Typography>
                      <Typography variant="small">{_d?.description}</Typography>
                    </div>
                    {/* <div className="col-span-3 flex flex-col justify-center items-center">
                      <Typography variant="h1" style={{ color: _d?.color }}>
                        8
                      </Typography>
                      <Typography variant="small">Sub themes</Typography>
                    </div> */}
                    <div className="flex flex-col justify-center items-center">
                      <Menu>
                        <MenuHandler>
                          <EllipsisVerticalIcon
                            strokeWidth={2.5}
                            className={`h-5 w-5 transition-transform cursor-pointer`}
                          />
                        </MenuHandler>
                        <MenuList>
                          <MenuItem
                            onClick={() =>
                              navigate(
                                `/robocenter-schools/${school_id}/${_d.name}?add-new=true`
                              )
                            }
                          >
                            Add sub theme
                          </MenuItem>
                          <MenuItem onClick={() => setEditTheme(_d)}>
                            Edit theme
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </div>
                  </div>
                </CardBody>
              </Card>
            );
          })
        )}
        <div className="flex justify-end">
          {loading ? null : themes.total < limit ? null : (
            <Pagination
              count={Math.ceil(themes.total / limit)}
              page={page}
              onChange={(e, p) => {
                setLoading(true);
                setPage(p);
              }}
            />
          )}
        </div>
      </div>

      {editTheme !== null ? (
        <EditTheme
          theme={editTheme}
          show={editTheme !== null}
          setShow={setEditTheme}
          reload={reload}
          setReload={setReload}
        />
      ) : null}
    </Fragment>
  );
}

function EditTheme({ theme, show, setShow, reload, setReload }: any) {
  const [name, setName] = useState(theme.name);
  const [description, setDescription] = useState(theme.description);
  const [color, setColor] = useState(theme.color);

  const update_theme = async () => {
    try {
      if (name === "") {
        ShowToast.error_toast("Please enter a name for your theme");
      } else if (description === "") {
        ShowToast.error_toast(
          "Please leave a brief description for your theme"
        );
      } else {
        ShowAlert.loading_alert("updating theme. . .");
        await baseService.put(urls.theme, {
          name: name,
          description: description,
          color: color,
          _id: theme._id,
        });
        setShow(null);
        ShowToast.success_toast("Theme updated successfully");
        setName("");
        setDescription("");
        setColor("#6666ea");
        setReload(!reload);
        setShow(null);
      }
    } catch (error: any) {
      console.log(error);
      ShowToast.error_toast(
        error?.response?.data?.error ?? "There was an error creating account"
      );
    }
  };

  return (
    <Modal show={show} onHide={() => setShow(null)} size="lg">
      <Modal.Header>
        <Modal.Title>Update theme</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Row className="mb-3">
            <Col md={4}>Name</Col>
            <Col md={8}>
              <Input
                type="text"
                placeholder="Name"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>Description</Col>
            <Col md={8}>
              <Textarea
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>Color</Col>
            <Col md={8}>
              <Input
                type="color"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                value={color}
                onChange={(e) => setColor(e.target.value)}
              />
            </Col>
          </Row>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="text"
          color="red"
          onClick={() => setShow(null)}
          className="mr-1"
        >
          <span>Cancel</span>
        </Button>
        <Button className="rounded-full" onClick={update_theme}>
          <span>Publish</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function ThemeSide({ reload, setReload }: any) {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [color, setColor] = useState("#6666ea");
  // const navigate = useNavigate();

  const publish_theme = async () => {
    try {
      if (name === "") {
        ShowToast.error_toast("Please enter a name for your theme");
      } else if (description === "") {
        ShowToast.error_toast(
          "Please leave a brief description for your theme"
        );
      } else {
        ShowAlert.loading_alert("creating theme");
        await baseService.post(urls.theme, {
          name: name,
          description: description,
          color: color,
        });

        ShowToast.success_toast("Theme created successfully");
        // navigate(
        //   `/robocenter-schools/${school_id}/${response.data?.payload?.name}`
        // );
        setReload(!reload);
        setName("");
        setDescription("");
        setColor("#6666ea");
        setShow(false);
      }
    } catch (error: any) {
      console.log(error);
      ShowToast.error_toast(
        error?.response?.data?.error ?? "There was an error creating account"
      );
    }
  };

  return (
    <Fragment>
      <Button
        className="flex items-center gap-2 bg-default_blue mb-2"
        size="sm"
        onClick={() => setShow(true)}
      >
        <PlusIcon strokeWidth={2} className="h-5 w-5" /> Create Theme
      </Button>
      <div className="flex justify-start gap-2">
        <Button
          className="flex items-center gap-2 mb-2"
          variant="outlined"
          size="sm"
        >
          <ArrowDownOnSquareStackIcon strokeWidth={2} className="h-5 w-5" />{" "}
          Export
        </Button>
        <Button className="mb-2" size="sm" variant="outlined">
          <PrinterIcon strokeWidth={2} className="h-5 w-5" />
        </Button>
      </div>

      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header>
          <Modal.Title>Create new theme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Row className="mb-3">
              <Col md={4}>Name</Col>
              <Col md={8}>
                <Input
                  type="text"
                  placeholder="Name"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}>Description</Col>
              <Col md={8}>
                <Textarea
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={4}>Color</Col>
              <Col md={8}>
                <Input
                  type="color"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                />
              </Col>
            </Row>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="text"
            color="red"
            onClick={() => setShow(false)}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button className="rounded-full" onClick={publish_theme}>
            <span>Publish</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}
