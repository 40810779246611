import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Collapse, Form, Input, message, Radio, Spin, Tooltip } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useState } from 'react'
import { PiFloppyDisk } from 'react-icons/pi';
import { TiDocumentText } from "react-icons/ti";
import { useMainContext } from '../../../../contexts/mainContext';
import { AddTestQuestions } from '../../functions/allTestsFunctions';



const AssessmentQuestions = () => {

    const [form] = useForm()
    const [isBusy, setIsBusy] = useState(false)
    const { assessmentInfo, setAssessmentInfo } = useMainContext()
    const [quizQuestions, setQuizQuestions] = useState<object[]>([{ question: '', options: [{ code: 'A', text: '' }], answer: 'A' }]);

    // handleAddNewQuestion: Adds a new question with default values
    const handleAddNewQuestion = () => {
        const newQuestion = {
            question: '',
            options: [{ code: 'A', text: '' }], // Initialize with an empty options array or with default options
            answer: 'A'
        };

        setQuizQuestions([...quizQuestions, newQuestion]);
    };

    // handleQuestionChange: Updates the specific question in the array
    const handleQuestionChange = (value: string, index: number) => {
        const newQuestions: any = [...quizQuestions];
        newQuestions[index]['question'] = value;
        setQuizQuestions(newQuestions);
    }
    // handleRemoveQuestion: Removes a requirement field
    const handleRemoveQuestion = (index: number) => {
        const newQuestions = quizQuestions.filter((_, i) => i !== index);
        setQuizQuestions(newQuestions);
    };

    // handleAddOption: Adds a new option to the specific question with a code assigned (A, B, C, D, etc.)
    const handleAddOption = (questionIndex: number) => {
        // Clone the current state of quizQuestions
        const newQuestions: any = [...quizQuestions];

        // Calculate the number of options currently in the question
        const optionCount = newQuestions[questionIndex]?.options.length || 0;

        // Assign code based on the current number of options
        const newOption = {
            code: String.fromCharCode(65 + optionCount), // 65 is the ASCII code for 'A'
            text: ''
        };

        // Add the new option to the question
        newQuestions[questionIndex].options.push(newOption);
        setQuizQuestions(newQuestions);
    };



    // handleOptionChange: Updates the specific option in the specific question
    const handleOptionChange = (value: string, questionIndex: number, optionIndex: number, field: 'code' | 'text') => {
        const newQuestions: any = [...quizQuestions];
        newQuestions[questionIndex].options[optionIndex][field] = value;
        setQuizQuestions(newQuestions);
    };

    // handleRemoveOption: Removes a specific option from a specific question
    const handleRemoveOption = (questionIndex: number, optionIndex: number) => {
        const newQuestions: any = [...quizQuestions];
        newQuestions[questionIndex].options = newQuestions[questionIndex].options.filter((_: any, i: number) => i !== optionIndex);
        setQuizQuestions(newQuestions);
    };

    // handleSelectCorrectOption: Selects the correct option for the question by setting the answer code
    const handleSelectCorrectOption = (questionIndex: number, selectedCode: string) => {
        const newQuestions: any = [...quizQuestions];
        newQuestions[questionIndex].answer = selectedCode; // Update the answer code
        setQuizQuestions(newQuestions);
    };


    //handleOnSubmit this is to create the question and save to backend
    const handleOnSubmit = () => {
        form.validateFields().then((formData) => {
            let question_payload = quizQuestions.map((item: any) => {
                return {
                    "question": item?.question,
                    "options": item?.options,
                    "answer": item?.answer,
                    "testId": assessmentInfo?._id
                }
            })
            console.log("___readyData:", question_payload)
            setIsBusy(true)
            AddTestQuestions(question_payload).then((response: any) => {
                console.log("questionsAddRes:", response?.data)
                // setAssessmentInfo(response?.data?.payload)
                setIsBusy(false)
            }).catch((error: any) => {
                console.log("questionAddErr:")
                message.error(error?.response?.data?.error || "Error while adding quiz questions. Please try again")
                setIsBusy(false)
            })

        }).catch((err) => {
            console.log("____formErrors:", err)
        })
    }


    return (
        <>
            <div className="w-full">
                {/* content */}
                <div className="w-full rounded-[20px] shadow-sm bg-white text-text_primary px-[30px] py-[27px]">
                    <div className="flex gap-[20px]">
                        <div>
                            <div className="h-[30px] w-[30px] rounded-full bg-pink-700 bg-opacity-[20%] text-pink-700 border-[1px] border-pink-700 flex justify-center items-center">
                                <TiDocumentText className='h-[20px] w-[20px]' />
                            </div>
                        </div>

                        <div className='w-full flex justify-between gap-[40px]'>
                            <div>
                                <p className='text-[21px] font-[600]'>{assessmentInfo?.title}</p>
                                <div dangerouslySetInnerHTML={{ __html: assessmentInfo?.description }} />
                            </div>

                            <div>
                                <p className='text-[16px] font-[400] mt-[10px]'>{assessmentInfo?.duration / 60} Minute(s)</p>
                            </div>
                        </div>
                    </div>
                </div>

                <Spin spinning={isBusy}>
                    {/* quetion component */}
                    <Form
                        className='w-full'
                        form={form}
                        onFinish={handleOnSubmit}
                    //   initialValues={initialValues}
                    >
                        {
                            quizQuestions.map((singleItem: any, questionIndex: number) => (
                                <div key={questionIndex} className="mt-[20px]">
                                    <Collapse
                                        className="rounded-[30px] bg-white border-[1px] border-[#C2C2F7] shadow-sm px-[10px]"
                                        ghost
                                        defaultActiveKey={questionIndex}
                                        expandIconPosition="end"
                                        items={[
                                            {
                                                label: <div className="flex gap-[20px] items-center">
                                                    <p className="text-[16px] font-[400] text-text_primary">
                                                        <span className="text-[21px] font-[600]">Q{questionIndex + 1}.</span> {singleItem?.question || "Click here to add question"}
                                                    </p>
                                                    {quizQuestions.length > 1 && (
                                                        <Tooltip title="Delete Question">
                                                            <TrashIcon
                                                                className="h-[20px] w-[20px] text-default_blue cursor-pointer mt-1"
                                                                onClick={() => handleRemoveQuestion(questionIndex)}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </div>,
                                                children: <div>
                                                    {/* Question */}
                                                    <div className="grid grid-cols-5">
                                                        <div className="col-span-1">
                                                            <p className="font-[500]"><span className="text-red-700">*</span> Question</p>
                                                        </div>
                                                        <div className="col-span-4">
                                                            <Form.Item name={['question', questionIndex]} rules={[{ required: true, message: 'Please add a question' }]}>
                                                                <Input
                                                                    className="h-[40px]"
                                                                    value={singleItem?.question}
                                                                    onChange={(e) => handleQuestionChange(e.target.value, questionIndex)}
                                                                    placeholder="Ex: Which of these is not true?"
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>

                                                    {/* Options */}
                                                    <div className="mt-[5px] mb-5">
                                                        {
                                                            singleItem?.options.map((singleOpt: any, optionIndex: number) => (
                                                                <div key={optionIndex} className="grid grid-cols-5 mb-2 items-center">
                                                                    <div className="col-span-1">
                                                                        <p>Option {optionIndex + 1}</p>
                                                                    </div>
                                                                    <div className="col-span-4 flex gap-2 items-center">
                                                                        <Input
                                                                            className="h-[40px] w-full"
                                                                            value={singleOpt?.text}
                                                                            onChange={(e) => handleOptionChange(e.target.value, questionIndex, optionIndex, 'text')}
                                                                            placeholder="Ex: The sky is blue"
                                                                        />
                                                                        {/* Select Correct Option Button */}
                                                                        <Radio
                                                                            checked={singleItem?.answer === singleOpt?.code}
                                                                            onChange={() => handleSelectCorrectOption(questionIndex, singleOpt.code)}
                                                                        >
                                                                            Correct
                                                                        </Radio>
                                                                        {/* Remove option button */}
                                                                        {singleItem.options.length > 1 && (
                                                                            <Tooltip title="Delete Option">
                                                                                <TrashIcon
                                                                                    className="h-[20px] w-[20px] text-red-500 cursor-pointer"
                                                                                    onClick={() => handleRemoveOption(questionIndex, optionIndex)}
                                                                                />
                                                                            </Tooltip>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>

                                                    {/* Add New Option Button */}
                                                    <div className="mt-2 mb-3 flex justify-end">
                                                        <button
                                                            className="text-default_blue text-sm"
                                                            type="button"
                                                            onClick={() => handleAddOption(questionIndex)}
                                                        >
                                                            + Add Option
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        ]}
                                    />
                                </div>
                            ))
                        }

                        {/* buttons */}
                        <div className="flex justify-end mx-[40px]">
                            <div className="w-fit px-[20px] py-[10px] bg-default_blue rounded-b-[10px] flex gap-[20px] items-center">
                                <Tooltip title="New Question">
                                    <button className='h-[30px] w-[30px] p-[2px] rounded-full text-white hover:bg-white hover:bg-opacity-[10%] flex justify-center items-center' type='button' onClick={handleAddNewQuestion}>
                                        <PlusCircleIcon className='h-[25px] w-[25px]' />
                                    </button>
                                </Tooltip>

                                <Tooltip title="Save Questions">
                                    <button className='h-[30px] w-[30px] p-[2px] rounded-full text-white hover:bg-white hover:bg-opacity-[10%] flex justify-center items-center'>
                                        <PiFloppyDisk className='h-[25px] w-[25px]' />
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                    </Form>
                </Spin>
            </div>
        </>
    )
}

export default AssessmentQuestions
