import { useState } from "react";
import PageLayout from "../../components/Layouts/page-layout";
import CouponsTable from "./CouponsTable";
import AllOrderTable from "../orders/Tabs/AllOrdersTable";
import SoftWareProducts from "../products/tabs/software-products";
import { Typography } from "@material-tailwind/react";

const Coupons = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabNames = ["All ", "Courses", "Product"];
  const theTabs = [<AllOrderTable />, <SoftWareProducts />];

  return (
    <>
      <PageLayout>
        <div className="m-4">
          <h2 className="text-[32px] font-[700] text-text_primary">Coupons</h2>
          <div className="my-3   flex items-center gap-2">
            {tabNames.map((d, i) => (
              <div
                key={i}
                className={`${
                  activeTab === i
                    ? "bg-gradient-to-tr  from-default_blue to-light_blue text-white"
                    : "bg-white text-text_primary"
                } shadow-sm w-fit px-8 py-3 rounded-[30px] cursor-pointer`}
                onClick={() => setActiveTab(i)}
              >
                <Typography variant="small">{d}</Typography>
              </div>
            ))}
          </div>
          <CouponsTable />
        </div>
      </PageLayout>
    </>
  );
};

export default Coupons;
