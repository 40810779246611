import {
  FaTachometerAlt,
  FaGift,
  FaChartLine,
  FaComments,
  FaClipboardCheck,
  FaShoppingCart,
  FaStore,
  FaUsers,
  FaUniversity,
  FaChild,
  FaClipboardList,
  FaTags,
  FaLanguage,
  FaVideo,
  FaSchool,
  FaChalkboardTeacher,
  FaUserShield,
  FaUserTie,
  FaBriefcase,
  FaCog,
} from "react-icons/fa";

const gateway = localStorage.getItem("gateway");
console.log("gateway", gateway);

export const navLinks =
  gateway === "admin"
    ? [
        {
          name: "Dashboard",
          icon: FaTachometerAlt,
          link: "/dashboard",
        },
        {
          name: "Administration",
          icon: FaUserTie,
          sublinks: [
            {
              name: "Manage Staff",
              icon: FaUsers,
              link: "/manage-staff",
            },
            {
              name: "Attendance",
              icon: FaClipboardCheck,
              link: "/attendance",
            },
            {
              name: "Departments",
              icon: FaBriefcase,
              link: "/departments",
            },
            {
              name: "Professions",
              icon: FaBriefcase,
              link: "/professions",
            },
            {
              name: "Roles & Permissions",
              icon: FaClipboardCheck,
              link: "/roles-permissions",
            },
          ],
        },
        {
          name: "Manage Coupon",
          icon: FaGift,
          link: "/coupons",
        },
        {
          name: "Performance",
          icon: FaChartLine,
          link: "/performance",
        },
        {
          name: "Communication",
          icon: FaComments,
          link: "/communication",
        },
        {
          name: "Manage Requests",
          icon: FaClipboardCheck,
          link: "/manageRequest",
          sublinks: [
            {
              name: "Sign up Request",
              icon: FaUserShield,
              link: "/signUpRequest",
            },
            {
              name: "Verification Request",
              icon: FaUserTie,
              link: "/VerificationRequest",
            },
          ],
        },
        {
          name: "Orders",
          icon: FaShoppingCart,
          link: "/orders",
        },
        {
          name: "Products",
          icon: FaStore,
          link: "/products",
        },
        {
          name: "Customers",
          icon: FaUsers,
          link: "/customers",
        },
        {
          name: "Manage Robocentre",
          icon: FaUniversity,
          sublinks: [
            {
              name: "Grade Level",
              icon: FaChild,
              link: "/Gradelevel",
            },
            {
              name: "Terms and Conditions",
              icon: FaClipboardList,
              link: "/termsConditons",
            },
            {
              name: "Curriculum",
              icon: FaClipboardList,
              link: "/curriculum",
            },
            {
              name: "Tags",
              icon: FaTags,
              link: "/tags",
            },
            {
              name: "Language",
              icon: FaLanguage,
              link: "/language",
            },
            {
              name: "Courses",
              icon: FaVideo,
              link: "/courses",
            },
            {
              name: "Schools",
              icon: FaSchool,
              link: "/schools",
            },
            {
              name: "Educators",
              icon: FaChalkboardTeacher,
              link: "/educators",
            },
            {
              name: "Instructors",
              icon: FaChalkboardTeacher,
              link: "/instructors",
            },
          ],
        },
        {
          name: "Support",
          icon: FaCog,
          link: "/support",
        },
      ]
    : gateway === "robocentre"
    ? [
        {
          name: "Courses",
          icon: FaVideo,
          link: "/courses",
        },
        {
          name: "Schools",
          icon: FaSchool,
          link: "/schools",
        },
        {
          name: "Grade Level",
          icon: FaChild,
          link: "/Gradelevel",
        },
        {
          name: "Terms and Conditions",
          icon: FaClipboardList,
          link: "/termsConditons",
        },
        {
          name: "Curriculum",
          icon: FaClipboardList,
          link: "/curriculum",
        },
        {
          name: "Tags",
          icon: FaTags,
          link: "/tags",
        },
        {
          name: "Language",
          icon: FaLanguage,
          link: "/language",
        },
        {
          name: "Educators",
          icon: FaChalkboardTeacher,
          link: "/educators",
        },
        {
          name: "Instructors",
          icon: FaChalkboardTeacher,
          link: "/instructors",
        },
      ]
    : gateway === "ecommerce"
    ? [
        {
          name: "Orders",
          icon: FaShoppingCart,
          link: "/orders",
        },
        {
          name: "Products",
          icon: FaStore,
          link: "/products",
        },
        {
          name: "Customers",
          icon: FaUsers,
          link: "/customers",
        },
      ]
    : gateway === "events"
    ? []
    : [];
