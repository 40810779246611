import { useState } from "react";
import PageLayout from "../../components/Layouts/page-layout";
import { Typography } from "@material-tailwind/react";
import AllOrderTable from "./Tabs/AllOrdersTable";
import CustomButtomSquare from "../../components/shared/CustomButtomSquare";

const Orders = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabNames = ["All Orders", "Completed", "Cancelled"];
  const theTabs = [
    <AllOrderTable />
  ];

  return (
    <>
      <PageLayout>
        <div className="m-4">
          <div className="flex justify-between">
            <h2 className="text-2xl text-text_primary font-semibold">Orders</h2>
            <CustomButtomSquare value="Export CSV" width={"10"} />
          </div>
          {/* tabs */}
          <div className="my-3   flex items-center gap-2">
            {tabNames.map((d, i) => (
              <div
                key={i}
                className={`${activeTab === i
                  ? "bg-gradient-to-tr  from-default_blue to-light_blue text-white"
                  : "bg-white text-text_primary"
                  } shadow-sm w-fit px-7 py-3 rounded-[30px] cursor-pointer`}
                onClick={() => setActiveTab(i)}
              >
                <Typography variant="small">{d}</Typography>
              </div>
            ))}
          </div>

          <div>{theTabs[activeTab]}</div>
        </div>
      </PageLayout>
    </>
  );
};

export default Orders;
