import { Link } from "react-router-dom";
import PageLayout from "../../../../components/Layouts/page-layout";
import { BiArrowBack } from "react-icons/bi";
import CreateNewRoboCurriculum from "./createNewRoboCurriculum";
import RoboCurriculumCard from "./roboCurriculumCard";
import { Input, Skeleton } from "antd";
import ToolTipComponent from "../../../../components/shared/ToolTipComponent";
import { useEffect, useState } from "react";
import BaseService from "../../../../helpers/baseServices";
import { FiRefreshCcw } from "react-icons/fi";
import { CiSearch } from "react-icons/ci";
import { debounce } from "lodash";



const CoursesCurriculum = () => {

  const tempList = Array.from({ length: 6 })
  const [reFetch, setReFetch] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)
  const [allLists, setAllLists] = useState([])
  const [isFetching, setIsFetching] = useState(true)
  const [fieldName, setFieldName] = useState<string>("");

  //onload
  useEffect(() => {
    const fetchRoboCurriculums = async () => {
      setIsFetching(true)

      try {
        const response = await BaseService.get_api(`/robocentre/robo-curriculum?filter={ "title": { "$regex": ${JSON.stringify(
          fieldName
        )}, "$options": "i" } }`)
        console.log("roboCurrRes:", response?.data)
        setAllLists(response?.data?.payload)
        setIsFetching(false)
      } catch (error) {
        console.log("roboCurrErr:", error)
      }
    }

    fetchRoboCurriculums()
  }, [reFetch, fieldName])


  //handleInputChange
  const handleInputChange = debounce((value) => {
    setFieldName(value);
  }, 500);



  return (
    <>
      <PageLayout>
        <div className="m-4">
          <div className="flex gap-[20px] items-center my-4">
            <Link to="/courses">
              <BiArrowBack className="h-5 w-5" />
            </Link>

            <p className="text-2xl text-text_primary font-semibold">
              Course Curriculum
            </p>
          </div>

          {/* content */}
          <div className="h-full">
            {/* top */}
            <div className="flex justify-between p-[20px] items-center drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] bg-white rounded-[20px]">
              <div className="w-[60%] flex items-center gap-4">
                <ToolTipComponent text={"Refresh"}>
                  <div className="w-[40px] h-[40px] text-[#9494F5] hover:text-[#6666EA] hover:border-1 hover:border-[#6666EA] rounded-lg border-1 border-[#9494F5] flex items-center justify-center cursor-pointer  p-2">
                    <FiRefreshCcw
                      onClick={() => setReFetch(!reFetch)}
                      size={"2em"}
                    />
                  </div>
                </ToolTipComponent>

                <div className="w-[50%]">
                  <Input
                    size="large"
                    onChange={(e) => handleInputChange(e.target.value)}
                    placeholder="Search By Title"
                    className="rounded-[5px] border-[1px] border-[#9494f5] text-[#9494f5] "
                    suffix={<CiSearch />}
                  />
                </div>
              </div>

              <button
                className="px-[20px] h-[40px] bg-default_blue text-white rounded-[30px] shadow-sm flex gap-[4px] items-center"
                onClick={() => setOpenCreate(true)}
              >
                <p>+ Add New</p>
              </button>
            </div>

            {/* cards */}
            <div className='mt-[20px]'>
              {
                isFetching ?
                  <div className="w-full">
                    {
                      tempList.map((item, i) => (
                        <div key={i} className='w-full mb-[20px] bg-white shadow-sm border border-gray-300 rounded-[20px] p-[20px]'>
                          <Skeleton paragraph={{ rows: 4 }} active />
                        </div>
                      ))
                    }
                  </div>
                  :
                  <div className="w-full">
                    {
                      allLists.map((item: any, i: number) => (
                        <div key={i} className='mb-[20px]'>
                          <RoboCurriculumCard theData={item} handleReFetch={() => setReFetch(!reFetch)} />
                        </div>
                      ))
                    }
                  </div>
              }
            </div>
          </div>


        </div>
      </PageLayout>


      {/* create Modal */}
      {
        openCreate && <CreateNewRoboCurriculum isOpened={openCreate} handleClose={() => setOpenCreate(false)} handleReFetch={() => setReFetch(!reFetch)} />
      }
    </>
  );
};

export default CoursesCurriculum;
