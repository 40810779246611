import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { GetSingleTest } from '../pages/courses/functions/allTestsFunctions';

// Create the context
const MainContext = createContext<any>(null);

// Create a custom hook for easy access
export const useMainContext = () => useContext(MainContext);

interface ApiProviderProps {
    children: ReactNode;
}

export const MainContextProvider: React.FC<ApiProviderProps> = ({ children }) => {

    const [courseId, setCourseId] = useState("")
    const [fetchingCourse, setFetchingCourse] = useState(true)
    const [singleCourseInfo, setSingleCourseInfo] = useState<any>(null)
    const [fetchingAssessment, setFetchingAssessment] = useState(true)
    const [assessmentInfo, setAssessmentInfo] = useState<any>(null)

    //onload
    useEffect(() => {
        if (singleCourseInfo) {
            console.log("we found course :)", singleCourseInfo)
            if (singleCourseInfo?.assesmentTestId) {
                console.log("assessment found")
                setFetchingAssessment(true)

                GetSingleTest(singleCourseInfo?.assesmentTestId).then((response: any) => {
                    console.log("getQuizRes:", response?.data)
                    setAssessmentInfo(response?.data?.payload)
                    setFetchingAssessment(false)
                }).catch((error: any) => {
                    console.log("getQuizErr:", error)
                })
            } else {
                setAssessmentInfo(null)
                setFetchingAssessment(false)
            }
        } else {
            console.log("no course found :(")
        }
    }, [singleCourseInfo])


    return (
        <MainContext.Provider value={{ courseId, setCourseId, fetchingCourse, setFetchingCourse, singleCourseInfo, setSingleCourseInfo, fetchingAssessment, setFetchingAssessment, assessmentInfo, setAssessmentInfo }}>
            {children}
        </MainContext.Provider>
    );
};
