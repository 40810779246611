import { Col, Container, Row } from "react-bootstrap";
import PageLayout from "../../../components/Layouts/page-layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Button } from "@material-tailwind/react";
import { useState } from "react";
import RodoSchoolOverview, {
  OverViewSide,
} from "./sections/robo-school-overview";
import RoboCenterSchoolCalender, {
  CalenderSide,
} from "./sections/robo-school-calender";
import RobocenterSchoolGradeLevels, {
  GradeLevelsSide,
} from "./sections/robo-school-grade-levels";
import RobocenterSchoolStudent, {
  StudentSide,
} from "./sections/robo-school-students";
import RobocenterSchoolPayment, {
  PaymentSide,
} from "./sections/robo-school-payment";
import RobocenterSchoolPricing, {
  PricingSide,
} from "./sections/robo-center-pricing";
import RobocenterSchoolTermsAndConditions, {
  TermsAndConditionSide,
} from "./sections/robo-center-terms-and-conditions";
import RobocenterSchoolBadge, {
  BadgeSide,
} from "./sections/robo-center-bagdes";
import { useEffect } from "react";
import urls from "../../../mixins/base.url";
import { LinearProgress } from "@mui/material";
import { Fragment } from "react";
import RobocenterSchoolTheme, { ThemeSide } from "./sections/robo-center-theme";
import baseService from "../../../mixins/baseServices";

export default function RoboCenterSchoolDetail() {
  const { school_id } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [schoolName, setSchoolName] = useState("");
  const [defaultSchool, setDefaultSchool] = useState(false);
  const [schoolId, setSchoolId] = useState("");
  const [reload, setReload] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get("c-tab");
  useEffect(() => {
    if (tab) {
      const gottenTab = parseInt(tab);
      console.log(isNaN(gottenTab) ? 0 : gottenTab);
      setActiveTab(isNaN(gottenTab) ? 0 : gottenTab);
    }
  }, [tab]);

  useEffect(() => {
    const get_school = async () => {
      try {
        const res: any = await baseService.get(
          urls.robocenter_schools +
            `/all?filter=${JSON.stringify({ code: school_id })}`
        );
        const payload = res.data.payload?.[0];
        if (!payload) {
          navigate(-1);
        }
        setSchoolName(payload?.name);
        setDefaultSchool(payload?.default);
        setSchoolId(payload?._id);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    get_school();
  }, [navigate, school_id]);

  let tabs = [
    "Overview",
    "Calender",
    "Grade Levels",
    "Students",
    "Payments",
    "Pricing",
    "Privacy and Terms",
    "Badges",
    // "Forums",
  ];

  if (defaultSchool) {
    tabs = [
      "Overview",
      "Calender",
      "Grade Levels",
      "Students",
      "Payments",
      "Pricing",
      "Privacy and Terms",
      "Badges",
      "theme",
    ];
  }

  const panels = [
    <RodoSchoolOverview school_id={school_id} />,
    <RoboCenterSchoolCalender school_id={school_id} />,
    <RobocenterSchoolGradeLevels school_id={schoolId} />,
    <RobocenterSchoolStudent school_id={school_id} />,
    <RobocenterSchoolPayment school_id={school_id} />,
    <RobocenterSchoolPricing school_id={school_id} />,
    <RobocenterSchoolTermsAndConditions school_id={school_id} />,
    <RobocenterSchoolBadge school_id={school_id} />,
    <RobocenterSchoolTheme
      school_id={school_id}
      reload={reload}
      setReload={setReload}
    />,
  ];
  const side_panel = [
    <OverViewSide />,
    <CalenderSide />,
    <GradeLevelsSide school_id={schoolId} />,
    <StudentSide />,
    <PaymentSide />,
    <PricingSide />,
    <TermsAndConditionSide />,
    <BadgeSide />,
    <ThemeSide reload={reload} setReload={setReload} />,
  ];

  return (
    <PageLayout>
      {loading ? (
        <LinearProgress />
      ) : (
        <Fragment>
          <div className="m-5">
            <Row>
              <Col lg={9}>
                <h2 className="text-2xl font-normal text-text_primary">
                  {schoolName} {defaultSchool ? `(MAIN)` : null}
                </h2>

                <div className="grid grid-cols-4 lg:grid-cols-12 gap-2">
                  {tabs.map((_d, i) => {
                    return (
                      <Button
                        key={i}
                        onClick={() => setActiveTab(i)}
                        variant={activeTab === i ? "filled" : "outlined"}
                        className={`rounded-full col-span-3`}
                        size="sm"
                      >
                        {_d}
                      </Button>
                    );
                  })}
                </div>
                <div className="mt-4">{panels[activeTab]}</div>
              </Col>
              <Col lg={3}>{side_panel[activeTab]}</Col>
            </Row>
          </div>
        </Fragment>
      )}
    </PageLayout>
  );
}
