import React from "react";
import { Checkbox } from "antd";
import type { CheckboxProps } from "antd";

const onChange: CheckboxProps["onChange"] = (e) => {
  console.log(`checked = ${e.target.checked}`);
};

const CheckBox = () => <Checkbox checked onChange={onChange}></Checkbox>;

export default CheckBox;
