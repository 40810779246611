import React from 'react'
import { Tag, Dropdown } from 'antd'
import { getAgeRange } from '../../../functions/getAgeRanges'
import { HiOutlineDotsVertical } from "react-icons/hi";
import moment from 'moment';
import type { MenuProps } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useMainContext } from '../../../contexts/mainContext';

interface cardProps {
    courseInfo: any
    onClick?: (data: any) => void
}

const SingleCourseCard = ({ courseInfo, onClick }: cardProps) => {


    const navigate = useNavigate()
    const { setSingleCourseInfo } = useMainContext();

    //handleCourseClick
    const handleCourseClick = () => {
        setSingleCourseInfo(courseInfo);
    };


    //dropdown options
    const items: MenuProps['items'] = [
        {
            label: <Link to={`/courses/${courseInfo?._id}`} onClick={handleCourseClick} className='flex gap-[8px] items-center'>
                <PencilSquareIcon className='h-5 w-5' />
                <p>Edit Course</p>
            </Link>,
            key: '0',
        },
    ];


    return (
        <>
            <div className="border-[1px] border-[#CCCCF8] bg-white p-[10px] rounded-[20px] shadow-sm mb-[20px] grid grid-cols-6 gap-[20px] cursor-pointer hover:shadow-lg" onClick={onClick}>
                {/* image and details */}
                <div className='col-span-3 flex gap-[20px] items-center'>
                    <div>
                        <img className='h-[86px] w-[120px] object-cover rounded-[20px]' src={courseInfo?.banner || ""} alt="" />
                    </div>

                    <div className='w-full flex justify-between gap-[20px]'>
                        <div>
                            <p className='text-[18px] font-[600] text-text_primary capitalize line-clamp-1'>{courseInfo?.title}</p>
                            {courseInfo?.subtitle &&
                                <p className='text-[14px] font-[400] text-text_primary capitalize line-clamp-1'>{courseInfo?.subtitle}</p>
                            }

                            {/* tags */}
                            <div className="mt-[10px] flex gap-[5px] items-center">
                                {courseInfo?.gradesData.length !== 0 &&
                                    <Tag className='rounded-[20px] capitalize' color='#ff808B'>{getAgeRange(courseInfo?.gradesData?.[0]?.ageGroup)}</Tag>
                                }

                                {courseInfo?.categoryData.length !== 0 &&
                                    courseInfo?.categoryData.map((cat: any, i: number) => (
                                        <Tag key={i} className='rounded-[20px] capitalize' color={cat?.color || 'gold'}>
                                            {cat?.name}
                                        </Tag>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>


                {/* date created */}
                <div className="col-span-1">
                    <p className='text-[14px] font-[500] text-text_primary'>Date Created</p>
                    <p className='text-[12px] font-[400] text-text_primary'>{moment(courseInfo?.createdAt).format("Do MMM YYYY - h:mm A")}</p>
                </div>

                {/* date updated */}
                <div className="col-span-1">
                    <p className='text-[14px] font-[500] text-text_primary'>Last Updated</p>
                    <p className='text-[12px] font-[400] text-text_primary'>{moment(courseInfo?.updatedAt).format("Do MMM YYYY - h:mm A")}</p>
                </div>

                {/* dropdown */}
                <div className='col-span-1 flex justify-end'>
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <button className='h-5 w-5 text-default_blue'>
                            <HiOutlineDotsVertical />
                        </button>
                    </Dropdown>
                </div>
            </div>
        </>
    )
}

export default SingleCourseCard
