import signin_img from "../../images/signin_img.png";
import logo from "../../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import ShowToast from "../../components/Alerts/all_toasts";
import ShowAlert from "../../components/Alerts/all_alerts";
import { EyeIcon } from "@heroicons/react/24/outline";
import { StorageBox } from "../../helpers/storage";
import BaseService from "../../helpers/baseServices";
import { notification } from "antd";

const ResetPassword = () => {

  const navigate = useNavigate()
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");

  //Show Password
  const [values, setValues] = useState({ showPassword: false });

  const ViewPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  //Signin function
  const doSignIn = async (e: any) => {
    e.preventDefault();

    if (password === "" || cPassword === "") {
      ShowToast.warning_toast("Please enter your new password!");
    } else if (password !== cPassword) {
      ShowToast.warning_toast("Passwords do not match!");
    } else {
      ShowAlert.loading_alert("Resetting Password...");

      try {
        const response = await BaseService.put_api(
          `/robocentre/auth/resetpassword`,
          { password: password }
        );

        StorageBox.saveAccessToken(response?.data?.token);
        notification.success({
          message: "Success",
          description: "Password reset successfully! Please login"
        });
        setTimeout(() => {
          navigate("/signin");
        }, 1000);
      } catch (error: any) {
        console.log(error);
        notification.error({
          message: "Error",
          description:
            error?.response?.data?.error ||
            "An error occurred. Please try again",
        });
      }
    }
  };

  return (
    <>
      <div className="h-screen overflow-hidden bg-default_blue bg-signInBg bg-no-repeat">
        <div className="h-full lg:grid lg:grid-cols-2">
          {/* right */}
          <div className="hidden lg:block bg-white h-full w-full rounded-r-[100px]">
            <div className="flex flex-col justify-center items-center h-full">
              <p className="text-default_blue font-semibold text-3xl pt-3">
                Password Reset
              </p>

              <p className="text-text_primary">Enter your new password</p>

              <img
                className="img-fluid block mr-auto ml-auto mt-4"
                width="300px"
                src={signin_img}
                alt="..."
              />
            </div>
          </div>

          {/* left */}
          <div className="flex flex-col items-center justify-center h-full px-[20px] lg:px-[200px]">
            <div className="p-[40px] bg-white w-full rounded-[20px]">
              <img
                className="pb-4 block mr-auto ml-auto"
                src={logo}
                alt="..."
              />
              <form onSubmit={doSignIn}>
                <div className="form-group pt-3">
                  <div className="input-group mb-3">
                    <input
                      className="form-control shadow-none border-outline_light"
                      type={values.showPassword ? "text" : "password"}
                      id="admin_password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter Password"
                      aria-describedby="basic-addon1"
                      required
                    />
                    <Link
                      to="#"
                      className="input-group-text"
                      id="admin_password"
                      onClick={ViewPassword}
                    >
                      <EyeIcon className="w-4 h-4" />
                    </Link>
                  </div>
                </div>

                <div className="form-group pt-1">
                  <div className="input-group mb-3">
                    <input
                      className="form-control shadow-none border-outline_light"
                      type={values.showPassword ? "text" : "password"}
                      id="admin_password2"
                      value={cPassword}
                      onChange={(e) => setCPassword(e.target.value)}
                      placeholder="Confrim Password"
                      aria-describedby="basic-addon1"
                      required
                    />
                  </div>
                </div>

                <div className="form-group mt-4 text-center">
                  <button
                    className="bg-default_blue text-text_white shadow-md rounded-full form-control active:bg-default_blue"
                    type="submit"
                    onClick={doSignIn}
                    id="signin-btn"
                  >
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
