import React from "react";
import { GoTasklist } from "react-icons/go";
import { IoIosTrendingUp } from "react-icons/io";
import RateComponent from "../../../../components/shared/RateComponents";

const Cart = () => {
  return (
    <div className="w-full  gap-[14px] grid grid-cols-1 mt-4">
      <div>
        <div className="rounded-2xl py-[16px]   px-[24px] bg-white shadow-sm">
          <div className="p-3 text-text_primary   ">
            <div className="pt-2 flex  justify-between">
              <div>
                <p className="text-[24px] font-bold w-[70%]">
                  SMD LED - RGB APA102C -505 (Strip of 5000)
                </p>
              </div>
              <div className=" rounded-[5px] flex flex-col justify-center items-center  ">
                <p className="text-[20px] font-semibold">GHS 350.00</p>
                <p className="text-red-400 bg-red-100 rounded-lg px-2">
                  8.5% off
                </p>{" "}
                <div className=" my-2">
                  <RateComponent />
                </div>
              </div>
            </div>

            <div className="mt-2.5">
              <p className="text-[14px] font-[400]">MBXS-3678</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="rounded-2xl py-[16px]   px-[24px] bg-white shadow-sm">
          <div className="p-3 text-text_primary   ">
            <div className="pt-2 flex  justify-between">
              <div>
                <p className="text-[24px] font-bold w-[70%]">
                  SMD LED - RGB APA102C -505 (Strip of 5000)
                </p>
              </div>
              <div className=" rounded-[5px] flex flex-col justify-center items-center  ">
                <p className="text-[20px] font-semibold">GHS 350.00</p>
                <p className="text-red-400 bg-red-100 rounded-lg px-2">
                  8.5% off
                </p>{" "}
                <div className=" my-2">
                  <RateComponent />
                </div>{" "}
              </div>
            </div>

            <div className="mt-2.5">
              <p className="text-[14px] font-[400]">MBXS-3678</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="rounded-2xl py-[16px]   px-[24px] bg-white shadow-sm">
          <div className="p-3 text-text_primary   ">
            <div className="pt-2 flex  justify-between">
              <div>
                <p className="text-[24px] font-bold w-[70%]">
                  SMD LED - RGB APA102C -505 (Strip of 5000)
                </p>
              </div>
              <div className=" rounded-[5px] flex flex-col justify-center items-center  ">
                <p className="text-[20px] font-semibold">GHS 350.00</p>
                <p className="text-red-400 bg-red-100 rounded-lg px-2">
                  8.5% off
                </p>{" "}
                <div className=" my-2">
                  <RateComponent />
                </div>
              </div>
            </div>

            <div className="mt-2.5">
              <p className="text-[14px] font-[400]">MBXS-3678</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
