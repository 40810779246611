export function getAgeRange(ages: any) {
    if (!Array.isArray(ages) || ages.length === 0) {
        return '0';
    }

    // Sort the array to ensure the first and last elements are the min and max
    ages.sort((a, b) => a - b);

    // Get the first and last elements of the sorted array
    const minAge = ages[0];
    const maxAge = ages[ages.length - 1];

    return `Ages ${minAge}-${maxAge}`;
}

