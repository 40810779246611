import PageLayout from "../../components/Layouts/page-layout";
import { useParams } from "react-router-dom";
import CustomButtomRounded from "../../components/shared/CustomButtomRounded";
import CustomButtomSquare from "../../components/shared/CustomButtomSquare";
import { IoEyeOutline } from "react-icons/io5";
import { RxAvatar } from "react-icons/rx";
import RateComponent from "../../components/shared/RateComponents";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import BaseService from "../../helpers/baseServices";
import { useEffect, useState } from "react";
import moment from "moment";
import { Spin } from "antd";

const ProductDetails = () => {
  const [Loading, setLoading] = useState<boolean>(true);
  const [cateLoading, setcateLoading] = useState<boolean>(true);
  const [ImageArray, setImageArray] = useState<any>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [salePrice, setSalePrice] = useState<number>(0);
  const [originalPrice, setOriginalPrice] = useState<number>(0);
  const [productDate, setProductDate] = useState<string>("");
  const [stock, setStock] = useState<number>(0);
  const [tags, setTags] = useState<any>([]);
  const [weight, setWeight] = useState<number>(0);
  const [categoryId, setCategoryId] = useState<string>("");
  const [updatedAt, setUpdatedAt] = useState<string>("");
  const [WeightUnit, setweightUnit] = useState<string>("");
  const [cateName, setcateName] = useState<string>("");
  const [cateColor, setcolor] = useState<string>("");

  const navigate = useNavigate();
  const { id } = useParams();

  const handlerClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    navigate("/products");
  };

  const getAllCategory = async () => {
    setcateLoading(true);
    try {
      const results = await BaseService.get_api(
        `/robocentre/product-category?filter={"_id": {"$$_id": ${JSON.stringify(
          categoryId
        )}}}`
      );
      const { payload } = results.data;
      if (payload && payload.length > 0) {
        const { color, name } = payload[0];
        setcateName(name);
        setcolor(color);
      }
    } catch (error: any) {
      console.log("error", error.message);
    } finally {
      setcateLoading(false);
    }
  };

  const productDeatilsPage = async () => {
    if (!id) return;
    setLoading(true);

    try {
      const results = await BaseService.get_api(
        `/robocentre/product?filter={"_id": {"$$_id": ${JSON.stringify(id)}}}`
      );
      const { payload } = results.data;
      if (payload && payload.length > 0) {
        const {
          images = [],
          name = "",
          description = "",
          salePrice = 0,
          originalPrice = 0,
          productDate = "",
          stock = 0,
          tags = [],
          weight = 0,
          categoryId = "",
          updatedAt = "",
          weightUnit = "",
        } = payload[0];
        setImageArray(images);
        setName(name);
        setDescription(description);
        setSalePrice(salePrice);
        setOriginalPrice(originalPrice);
        setProductDate(productDate);
        setStock(stock);
        setTags(tags);
        setWeight(weight);
        setCategoryId(categoryId);
        setUpdatedAt(updatedAt);
        setweightUnit(weightUnit);
        setCurrentImageIndex(0);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClicked = (index: number) => {
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    getAllCategory();
  }, [categoryId]);

  useEffect(() => {
    console.log("ImageArray", ImageArray[currentImageIndex]);
    productDeatilsPage();
  }, [id]);

  return (
    <>
      <PageLayout>
        <div className="m-4  flex ">
          <section className="basis-4/5 ">
            <div className="flex items-center gap-4 m-2">
              <CustomButtomRounded
                icon={<FaArrowLeft />}
                value={"Back"}
                onClick={handlerClick}
              />
              <h2 className="text-2xl text-text_primary font-semibold">
                Products Details
              </h2>
            </div>
            <Spin spinning={Loading}>
              <div className=" flex   p-4 m-4 rounded-[20px] bg-white gap-6 drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)]">
                <section className=" w-[40%]   ">
                  <div
                    style={{
                      width: "300px",
                      marginBottom: "8px",
                      height: "300px",
                      backgroundImage: `url(${ImageArray[currentImageIndex]})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center top",
                      borderRadius: "20px",
                    }}
                  />
                  <div className="grid grid-cols-4  py-2">
                    {ImageArray.map((images: string, index: number) => (
                      <section
                        key={index}
                        onClick={() => handleClicked(index)}
                        className={`w-[65px] cursor-pointer h-[65px] ${
                          currentImageIndex === index
                            ? "border-2 border-blue-500"
                            : ""
                        }`}
                        style={{
                          backgroundImage: `url(${images})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderRadius: "10px",
                        }}
                      />
                    ))}
                  </div>
                </section>
                <section className=" w-[60%] flex flex-col gap-6 border-red-100">
                  <h2 className="text-[30px] text-default_blue">{name}</h2>
                  <div className="flex items-center gap-2">
                    <RateComponent />
                    <p>4.5</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <p className="text-[40px] text-default_blue font-bold">
                      {`GHC ${salePrice}`}
                    </p>
                    <p className="line-through text-red-500">
                      {`GHC ${originalPrice}`}
                    </p>{" "}
                  </div>
                  <div>
                    <h2 className="mb-2 text-[20px] text-text_deep font-medium">
                      Description
                    </h2>
                    <div className="text-[18px] text-[#0D0F45]">
                      {description}
                    </div>
                  </div>
                </section>
              </div>
              <div className="p-4 text-text_deep shadow-md rounded-[20px] drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] m-4 bg-white">
                <p className="text-[20px] text-text_deep font-medium ">
                  Product information
                </p>
                <hr />
                <div className="grid grid-cols-4 gap-4 mt-6">
                  <section>
                    <p className="text-[12px]">Created Date</p>
                    <p className="mt-1 text-[16px]">
                      {" "}
                      {moment(productDate).format("Do MMM YYYY - hh:mm A")}
                    </p>
                  </section>
                  <section>
                    <p className="text-[12px]">Updated Date</p>
                    <p className="mt-1 text-[16px]">
                      {" "}
                      {moment(updatedAt).format("Do MMM YYYY - hh:mm A")}
                    </p>
                  </section>
                  <section>
                    <p className="text-[12px]">Weight</p>
                    <p className="mt-1 text-[16px]">{`${weight}${WeightUnit}`}</p>
                  </section>
                  <section>
                    <p className="text-[12px]">In Stock</p>
                    <p className="mt-1 text-[16px]">{stock}</p>
                  </section>
                  <Spin spinning={cateLoading}>
                    <section>
                      <p className="text-[12px]">Category</p>
                      <p
                        className="mt-1 text-[16px] text-center text-white rounded-[20px]"
                        style={{ background: `${cateColor}` }}
                      >
                        {cateName}
                      </p>
                    </section>
                  </Spin>
                </div>
                <div className="mt-10">
                  <p className="mb-2">Tags</p>
                  <div className="flex items-center gap-3">
                    {tags.map((tagData: string, index: any) => (
                      <div
                        key={index}
                        className=" rounded-[20px]  flex  items-center px-4 [&>p]:mx-1 bg-[#CCCCF8] "
                      >
                        <p className="text-[14px]">{tagData}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Spin>
          </section>
          <section className=" flex flex-col gap-2  basis-1/5 ">
            <CustomButtomSquare
              value="Edit Product"
              icon={<RxAvatar size={"1.5em"} />}
              width={"80"}
            />
            <CustomButtomSquare
              value="View Performance"
              icon={<IoEyeOutline size={"1.5em"} />}
            />
            <CustomButtomSquare
              value="Generate Coupon"
              icon={<IoEyeOutline size={"1.5em"} />}
            />
          </section>
        </div>
      </PageLayout>
    </>
  );
};

export default ProductDetails;
