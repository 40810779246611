import { useState } from "react";
import { Form, Input, message, Modal, Spin } from "antd";
import BaseService from "../../../helpers/baseServices";
import { useForm } from "antd/es/form/Form";

interface CreateGradeLevelProps {
  handleReFetch: any;
  showModal: () => void;
  open: boolean;
  selectedGrade: any;
  setOpen: (open: boolean) => void;
}


const EditTags = ({
  handleReFetch,
  showModal,
  open,
  selectedGrade,
  setOpen,
}: CreateGradeLevelProps) => {

  const [form] = useForm()
  const [isBusy, setIsBusy] = useState<boolean>(false);


  //handleOnSubmit
  const handleOnSubmit = async (values: any) => {
    setIsBusy(true)

    try {
      const payload = {
        _id: selectedGrade?._id,
        name: values?.name,
      };
      // console.log("Sending payload:", payload);
      const results = await BaseService.put_api("/robocentre/tags", payload);
      handleReFetch();
      setIsBusy(false)
      console.log("API results:", results);
      setOpen(false);
      message.success("Tag was updated successfully!")
    } catch (error: any) {
      console.log("error", error.message);
      setIsBusy(false)
      message.error(error?.response?.data?.error || "Error while updating tag! Please retry")
    } finally {
    }
  };


  return (
    <>
      <Modal
        width={"30%"}
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
      >
        <div className=" rounded-2xl px-4">
          <p className="text-2xl mt-1 font-body text-text_primary font-bold">
            Edit Tag
          </p>
          <hr className="my-3 text-default" />
          <Spin spinning={isBusy}>
            <Form
              form={form}
              layout='vertical'
              onFinish={handleOnSubmit}
              initialValues={{ name: selectedGrade?.name }}
            >
              <Form.Item name="name" label="Tag Name" rules={[
                {
                  required: true,
                  message: 'Please enter a tag name!',
                },
              ]}>
                <Input
                  className="h-[40px] border-1 border-outline_deep"
                  placeholder="Eg: Sensors"
                />
              </Form.Item>

              <Form.Item className="flex justify-end">
                <button className="px-[30px] py-[8px] bg-default_blue text-white rounded-[30px]">
                  <p>Submit</p>
                </button>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default EditTags;
