import {
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import { Avatar, Option, Select } from "@material-tailwind/react";

const MostRecent = () => {
  return (
    <div>
      <div className="mt-3 flex gap-2 items-center lg:w-[300px]">
        <Select label="Filter By">
          <Option>All Grades</Option>
        </Select>
        <Select label="Filter By">
          <Option>All Courses</Option>
        </Select>
      </div>

      <div className="mt-4">
        {["", ""].map((dd, i) => (
          <div
            key={i}
            className="w-full py-[10px] px-[20px] bg-white shadow-md mt-3 rounded-[20px] text-text_primary"
          >
            <p className="font-semibold">Topic: Introduce Yourself</p>
            <p className="text-sm mt-2">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem
            </p>

            <div className="mt-3 flex justify-between items-center">
              <div className="flex gap-4 items-center">
                <button className="flex gap-2 text-default_green items-center text-sm">
                  <HandThumbUpIcon className="w-5 h-5" />
                  Approve
                </button>
                <button className="flex gap-2 text-red-500 items-center text-sm">
                  <HandThumbDownIcon className="w-5 h-5" />
                  Disapprove
                </button>
              </div>
              <div className="flex gap-4 items-center">
                <div className="flex gap-2 items-center">
                  <Avatar
                    src="https://ui.tradeshift.com/v10/dist/components/userimages/assets/jim.png"
                    size="sm"
                  />
                  <p className="text-sm">Lorem ipsum dolor</p>
                </div>
                <p className="text-gray-500 text-sm">15 mins ago</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MostRecent;
