import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { CiSearch } from "react-icons/ci";

const SearchInput = () => (
  <>
    <Input
      size="large"
      placeholder="Search"
      className="rounded-[5px] border-[1px] border-[#9494f5] text-[#9494f5] "
      suffix={<CiSearch />}
    />
    <br />
  </>
);

export default SearchInput;
