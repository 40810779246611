import PageLayout from "../../../../components/Layouts/page-layout";
import AllProfessionsTable from "./allProfessionsTable";

const ProfessionsPage = () => {


    return (
        <>
            <PageLayout>
                <div className="m-4">
                    <h2 className="text-2xl text-text_primary font-semibold">All Professions</h2>

                    {/* main */}
                    <div className="my-3 w-full">
                        <AllProfessionsTable />
                    </div>
                </div>
            </PageLayout>
        </>
    );
};

export default ProfessionsPage;