import React from "react";
import { BsXLg } from "react-icons/bs";

const PersonalInformation = () => {
  return (
    <div className="p-4 text-text_deep shadow-md rounded-[25px] my-4 bg-white">
      <p className="text-[20px] text-text_deep mb-2 font-medium ">
        Product information
      </p>
      <hr />
      <div className="grid grid-cols-4 mt-6">
        <section>
          <p className="text-[12px]">Product Date</p>
          <p className="mt-1 text-[16px]">Sept. 27, 2023</p>
        </section>
        <section>
          <p className="text-[12px]">Weight</p>
          <p className="mt-1 text-[16px]">120kg</p>
        </section>
        <section>
          <p className="text-[12px]">In Stock</p>
          <p className="mt-1 text-[16px]">23455</p>
        </section>
        <section>
          <p className="text-[12px]">Category</p>
          <p className="mt-1 text-[16px]">Robot kit</p>
        </section>
      </div>
      <div className="mt-10">
        <p className="mb-2">Tags</p>
        <div className="flex items-center gap-3">
          <div className=" rounded-[20px]  flex  items-center px-4 [&>p]:mx-1 bg-[#CCCCF8] ">
            <p className="mr-auto">DIY</p>{" "}
            <p>
              <BsXLg size={"12px"} />{" "}
            </p>
          </div>
          <div className=" rounded-[20px]  flex  items-center px-4 [&>p]:mx-1 bg-[#CCCCF8] ">
            <p className="mr-auto">Coding</p>{" "}
            <p>
              <BsXLg size={"12px"} />{" "}
            </p>
          </div>
          <div className=" rounded-[20px]  flex  items-center px-4 [&>p]:mx-1 bg-[#CCCCF8] ">
            <p className="mr-auto">Robotics</p>{" "}
            <p>
              <BsXLg size={"12px"} />{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
