import BaseService from "../../../helpers/baseServices";
import { global_variables } from "../../../helpers/globalVariables";

export const UpdateSingleCourse = async (update_data: any) => {
  try {
    const response = await BaseService.put_api(
      global_variables.all_courses,
      update_data
    );
    return response;
  } catch (error) {
    return error;
  }
};
