import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { StorageBox } from "../../helpers/storage";
import ShowToast from "../../components/Alerts/all_toasts";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import BaseService from "../../helpers/baseServices";


interface modalProps {
  openPwdModal: boolean;
  handleClosePwdModal: () => void;
}


const ForgotPwdModal = ({ openPwdModal, handleClosePwdModal }: modalProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [theEmail, setTheEmail] = useState("");

  // send code to email
  const handleSendCode = async () => {
    if (theEmail === "") {
      ShowToast.warning_toast("Please enter your email!");
    } else {
      console.log(theEmail);
      setLoading(true);
      let data = JSON.stringify({
        email: theEmail,
      });

      try {
        const response = await BaseService.put_api(
          `/robocentre/auth/forgetpassword`,
          data
        );
        const temp_token = response?.data?.token;
        StorageBox.saveTempToken(temp_token);
        setTimeout(() => {
          navigate("/resetpwdVerify");
        }, 1000);
      } catch (error: any) {
        console.log(error);
        setLoading(false);
        notification.error({
          message: "Error",
          description:
            error?.response?.data?.error ||
            "An error occurred. Please try again",
        });
      }
    }
  };

  return (
    <>
      <Modal
        show={openPwdModal}
        onHide={handleClosePwdModal}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Enter your email in the field provided. We will send you a
            verification code to help you reset your password.
          </p>
          <div className="mt-2">
            <input
              className="h-[40px] w-full border-[1px] border-outline_light rounded-[5px] px-3"
              type="email"
              placeholder="Enter your email"
              value={theEmail}
              onChange={(e) => setTheEmail(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="text-red-500 mr-3" onClick={handleClosePwdModal}>
            Close
          </button>
          <button
            className="px-3 py-2 bg-default_blue text-white rounded-[20px]"
            onClick={() => handleSendCode()}
            disabled={loading}
          >
            {loading ? <Spinner /> : "Send Code"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ForgotPwdModal;
