import React, { useState } from "react";
import { Button, Modal } from "antd";
import ReactQuill from "react-quill";
import SelectComponent from "../../../components/shared/SelectComponent";
import { FaRegMessage } from "react-icons/fa6";
import RadioButtons from "../../../components/shared/RadioButtons";

const SendMessage = ({
  showModal,
  open,
  setOpen,
}: {
  showModal: any;
  open: any;
  setOpen: any;
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const [textareaValue, setTextareaValue] = useState("");
  const [value, setValue] = useState("");
  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        style={{
          backgroundColor: "#6666EA",
          border: "none",
          boxShadow: "none",
        }}
      >
        <div className="flex items-center justify-center">
          <FaRegMessage size={"1.2em"} style={{ marginRight: 8 }} />
          <p>Send message</p>
        </div>
      </Button>
      <Modal
        title="Send Message to "
        width={"60%"}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            backgroundColor: "#6666EA",
            borderColor: "#6666EA",
            color: "#FFFFFF",
            boxShadow: "none",
          },
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
      >
        <hr />

        <section className="flex w-full  justify-between items-center">
          <p className="w-[30%] ">Title</p>
          <div className="my-4 w-[70%]">
            <SelectComponent
              options={[
                { value: "lucy", label: "Update Status" },
                { value: "lucy", label: "Update Status" },
              ]}
            />
          </div>
        </section>
        <section className="flex items-center justify-between">
          <p className="w-[30%] ">Description</p>
          <div className="w-[70%]">{/* <Privacy /> */}</div>
        </section>
        <section className="flex  mt-6">
          <p className="w-[30%] ">Send as </p>
          <section className="flex ">
            <div>
              <RadioButtons name={"Email"} />
            </div>
            <div>
              <RadioButtons name={"SMS"} />
            </div>
          </section>
        </section>
        <section>
          <div></div>
          <div></div>
        </section>
      </Modal>
    </>
  );
};

export default SendMessage;
