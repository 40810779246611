import {
  ArrowDownOnSquareStackIcon,
  EllipsisVerticalIcon,
  PlusIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  Card,
  CardBody,
  Checkbox,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { SearchInputField } from "../../../../components/form/search";
import urls from "../../../../mixins/base.url";
import moment from "moment";
import { Col, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Select from "react-select";
import ShowToast from "../../../../components/Alerts/all_toasts";
import ShowAlert from "../../../../components/Alerts/all_alerts";
import baseService from "../../../../mixins/baseServices";
import { Spin } from "antd";

const ages: any = [
  { label: `1 years`, value: 1 },
  { label: `2 years`, value: 2 },
  { label: `3 years`, value: 3 },
  { label: `4 years`, value: 4 },
  { label: `5 years`, value: 5 },
  { label: `6 years`, value: 6 },
  { label: `7 years`, value: 7 },
  { label: `8 years`, value: 8 },
  { label: `9 years`, value: 9 },
  { label: `10 years`, value: 10 },
  { label: `11 years`, value: 11 },
  { label: `12 years`, value: 12 },
  { label: `13 years`, value: 13 },
];
const types = [{ label: "Primary/Elementary", value: "Primary/Elementary" }];

export default function RobocenterSchoolGradeLevels({ school_id }: any) {
  const [loading, setLoading] = useState(true);
  const [gradeLevels, setGradeLevels] = useState<any>([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  useEffect(() => {
    const get_schools = async () => {
      try {
        let skip = (page - 1) * limit;
        const res: any = await baseService.get(
          urls.grade_levels +
            `?limit=${limit}&skip=${skip}&filter=${JSON.stringify({
              schoolId: school_id,
            })}`
        );
        setGradeLevels(res.data);
        setLoading(false);
        // console.log(res.data);
      } catch (error) {}
    };

    get_schools();
  }, [limit, page, school_id]);

  const columns = [
    {
      name: <Typography variant="h6">Name</Typography>,
      cell: (row: any) => (
        <Typography variant="paragraph">{row?.name}</Typography>
      ),
      sort: true,
    },
    {
      name: <Typography variant="h6">Type</Typography>,
      cell: (row: any) => (
        <Typography variant="paragraph">{row?.type}</Typography>
      ),
    },
    {
      name: <Typography variant="h6">Date created</Typography>,
      cell: (row: any) => (
        <Typography variant="paragraph">
          {moment(row?.createdAt).format("ll")}
        </Typography>
      ),
    },
    {
      cell: (row: any) => (
        <Menu>
          <MenuHandler>
            <EllipsisVerticalIcon
              strokeWidth={2.5}
              className={`h-5 w-5 transition-transform cursor-pointer`}
            />
          </MenuHandler>
          <MenuList>
            <MenuItem onClick={() => trigger_update(row)}>Edit</MenuItem>
            <MenuItem>Delete</MenuItem>
          </MenuList>
        </Menu>
      ),
      width: "10%",
    },
  ];

  const [editData, setEditData] = useState<any>(null);
  const [name, setName] = useState("");
  const [ageGroup, setAgeGroup] = useState<any>([]);
  const [ageType, setAgeType] = useState<any>("");

  const trigger_update = (row: any) => {
    setEditData(row);
    setName(row.name);
    setAgeGroup(
      row.ageGroup?.map((_d: any) => ({ value: _d, label: _d + " years" }))
    );
    setAgeType({ label: row.type, value: row.type });
  };

  async function update() {
    try {
      ShowAlert.loading_alert("Creating Grade level");
      await baseService.put(urls.grade_levels, {
        schoolId: school_id,
        _id: editData._id,
        name: name,
        type: ageType,
        ageGroup: ageGroup,
      });
      ShowToast.success_toast("Grade level successfully");
      setEditData(null);
    } catch (error: any) {
      ShowToast.error_toast(
        error?.response?.data?.error ?? "Error creating age groups"
      );
    }
  }

  return (
    <Fragment>
      <Card>
        <CardBody>
          <div className="flex justify-end">
            <SearchInputField />
          </div>
          <DataTable
            columns={columns}
            data={gradeLevels?.payload}
            progressPending={loading}
            progressComponent={<Spin />}
            selectableRows
            pagination
            paginationServer
            paginationTotalRows={gradeLevels?.total}
            paginationPerPage={limit}
            onChangePage={(page) => {
              setLoading(true);
              setPage(page);
            }}
            onChangeRowsPerPage={(rows) => {
              setLoading(true);
              setPage(1);
              setLimit(rows);
            }}
          />
        </CardBody>
      </Card>

      <Modal show={editData !== null} size="lg">
        <Modal.Header>
          <Modal.Title>Edit: {editData?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col md={4}>Name</Col>
            <Col md={8}>
              <Input
                type="text"
                placeholder="Name"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>Age Group</Col>
            <Col md={8}>
              <Select
                defaultValue={ageGroup}
                // value={ageGroup}
                options={ages}
                onChange={(e) => setAgeGroup(e.map((_d) => _d.value))}
                isMulti
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>Type</Col>
            <Col md={8}>
              <Select
                defaultValue={ageType}
                options={types}
                onChange={(e) => setAgeType(e.value)}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="text"
            color="red"
            onClick={() => setEditData(null)}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button className="bg-default_blue rounded-full" onClick={update}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export function GradeLevelsSide({ school_id }: any) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [ageGroup, setAgeGroup] = useState<any>([]);
  const [ageType, setAgeType] = useState("");
  const [color, setColor] = useState("#6666EA");

  const create_age_level = async () => {
    try {
      setOpen(false);
      ShowAlert.loading_alert("Creating Grade level");
      await baseService.post(urls.grade_levels, {
        schoolId: school_id,
        name: name,
        type: ageType,
        ageGroup: ageGroup,
        color: color,
      });
      ShowToast.success_toast("Grade level successfully");
    } catch (error: any) {
      // console.log(error);
      ShowToast.error_toast(
        error?.response?.data?.error ?? "Error creating age groups"
      );
    }
  };

  return (
    <Fragment>
      {/* {school_id} */}
      <Button
        className="flex items-center gap-2 bg-default_blue mb-2"
        size="sm"
        onClick={() => setOpen(!open)}
      >
        <PlusIcon strokeWidth={2} className="h-5 w-5" /> Add Grade level
      </Button>
      <div className="flex justify-start gap-2">
        <Button
          className="flex items-center gap-2 mb-2"
          variant="outlined"
          size="sm"
        >
          <ArrowDownOnSquareStackIcon strokeWidth={2} className="h-5 w-5" />{" "}
          Export
        </Button>
        <Button className="mb-2" size="sm" variant="outlined">
          <PrinterIcon strokeWidth={2} className="h-5 w-5" />
        </Button>
      </div>

      <Modal show={open} onHide={() => setOpen(!open)} size="lg">
        <Modal.Header>
          <Modal.Title>Create age group.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col md={4}>Name</Col>
            <Col md={8}>
              <Input
                type="text"
                placeholder="Name"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>Age Group</Col>
            <Col md={8}>
              <Select
                options={ages}
                onChange={(e) => setAgeGroup(e.map((_d: any) => _d.value))}
                isMulti
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>Type</Col>
            <Col md={8}>
              <Select
                options={types}
                onChange={(e: any) => setAgeType(e.value)}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>Color</Col>
            <Col md={8}>
              <Input
                type="color"
                // placeholder="Name"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                value={color}
                onChange={(e) => setColor(e.target.value)}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="text"
            color="red"
            onClick={() => setOpen(!open)}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            className="bg-default_blue rounded-full"
            onClick={create_age_level}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}
