import { useState } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import PageLayout from "../../components/Layouts/page-layout";
import AllTab from "./tabs/all-tabs/AllTab";
import CoursesTab from "./tabs/courses-tab/CoursesTab";
import CustomButtomSquare from "../../components/shared/CustomButtomSquare";
import AllOrderTable from "../orders/Tabs/AllOrdersTable";
import SoftWareProducts from "../products/tabs/software-products";
import { Typography } from "@material-tailwind/react";
import SearchInput from "../../components/shared/SearchInput";
import PerformanceCard from "./PerformanceCard";

const Performance = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabNames = ["All Orders", "Cancelled", "Completed"];
  const theTabs = [<PerformanceCard />, <SoftWareProducts />];

  return (
    <>
      <PageLayout>
        <div className="m-4">
          <h2 className="text-2xl text-text_primary font-semibold">
            Performance
          </h2>
          {/*top tabs */}
          <div className="my-3   w-[70%]  flex items-center justify-between gap-2">
            <div className="flex items-center gap-2 ">
              {tabNames.map((d, i) => (
                <div
                  key={i}
                  className={`${
                    activeTab === i
                      ? "bg-gradient-to-tr  from-default_blue to-light_blue text-white"
                      : "bg-white text-text_primary"
                  } shadow-sm w-fit px-7 py-3 rounded-[30px] cursor-pointer`}
                  onClick={() => setActiveTab(i)}
                >
                  <Typography variant="small">{d}</Typography>
                </div>
              ))}
            </div>
            <div>
              <div>
                <SearchInput />
              </div>
            </div>
          </div>
          {/*second tab*/}
          <div className="     flex items-center gap-2">
            {tabNames.map((d, i) => (
              <div
                key={i}
                className={`${
                  activeTab === i
                    ? "border-b-[2px] border-default_blue text_default_blue border-solid "
                    : "text_primary"
                }  w-fit px-7 py-1 text-text_primary  cursor-pointer`}
                onClick={() => setActiveTab(i)}
              >
                <Typography variant="small">{d}</Typography>
              </div>
            ))}
          </div>
          <div>{theTabs[activeTab]}</div>
        </div>
      </PageLayout>
    </>
  );
};

export default Performance;
