import CurriculumComponent from "./CurriculumComponent";

const CurriculumList = [
  {
    title: "Grade 3",
    units: 30,
    tasks: 2,
    points: 3,
  },
];
const ElectiveCurriculum = () => {
  return (
    <div className="flex flex-col gap-2 mt-3">
      {CurriculumList.map((gradeObj, index) => (
        <CurriculumComponent
          key={index}
          title={gradeObj.title}
          points={gradeObj.points}
          units={gradeObj.units}
          tasks={gradeObj.tasks}
          // onClick={onClickHandler}
        />
      ))}
    </div>
  );
};

export default ElectiveCurriculum;
