import React, { useEffect, useState } from 'react'
import { useForm } from 'antd/es/form/Form'
import { Form, Input, message, Progress, Spin, Upload, UploadProps } from 'antd'
import TagSelectorComponent from '../../components/tagSelectorComponent';
import InstructorSelectorComponent from '../../components/instructorSelectorComponent';
import CourseCategorySelectorComponent from '../../components/courseCategorySelectorComponent';
import LanguageSelectorComponent from '../../components/languageSelectorComponent';
import BaseService from '../../../../helpers/baseServices';
import { global_variables } from '../../../../helpers/globalVariables';
import { IoImagesOutline } from "react-icons/io5";
import { useParams } from 'react-router-dom';
import { PiCloudArrowUpLight } from 'react-icons/pi';
import { UpdateSingleCourse } from '../../functions/updateSingleCourse';
import DescriptionInputComponent from '../../components/descriptionInputComponent';
import { useMainContext } from '../../../../contexts/mainContext';
import TermSelector from '../../components/termSelector';
import CourseTypeSelector from '../../components/courseTypeSelector';



const EditCourseInformation = () => {

    const [form] = useForm()
    const { id } = useParams()
    const { singleCourseInfo, setSingleCourseInfo } = useMainContext()
    const [isBusy, setIsBusy] = useState(false)
    const [coverImage, setCoverImage] = useState<any>("");
    const [imageLoading, setImageLoading] = useState(false);
    const [imgProgress, setImgProgress] = useState(25)

    //onload
    useEffect(() => {

        if (singleCourseInfo) {
            setCoverImage(singleCourseInfo?.banner)
        } else {
            console.log("no course to edit")
        }

    }, [singleCourseInfo])

    //initialValues
    const initialValues = {
        image: singleCourseInfo?.banner,
        title: singleCourseInfo?.title,
        subtitle: singleCourseInfo?.subtitle,
        desc: singleCourseInfo?.desc,
        level: singleCourseInfo?.level,
        type: singleCourseInfo?.type,
        language: singleCourseInfo?.languageData?.map((item: any) => (
            { label: item?.name, value: item?._id }
        )),
        category: singleCourseInfo?.categoryData?.map((item: any) => (
            { label: item?.name, value: item?._id }
        )),
        instructor: singleCourseInfo?.educatorData?.map((item: any) => (
            { label: item?.userData?.fullName, value: item?._id }
        )),
        tags: singleCourseInfo?.tagsData?.map((item: any) => (
            { label: item?.name, value: item?._id }
        ))
    }


    //handleImageUpload
    const handleImageUpload: UploadProps = {
        action: "",
        async onChange(info) {
            const file = info.file.originFileObj;
            if (file) {
                setImageLoading(true);
                try {
                    const fileFormat = file.type.split("/")[1];
                    const uid = file.uid;
                    setImgProgress(65)

                    const imgRes: any = await BaseService.file_upload(
                        `${global_variables.file_upload}?imagename=${uid}&storage=test&mimeType=${fileFormat}`,
                        file
                    );
                    console.log("imgRes", imgRes);
                    setCoverImage(imgRes?.data?.url)
                    setImgProgress(95)
                    setImageLoading(false);
                } catch (error: any) {
                    console.log("file uploading", error);
                    message.error(error?.response?.data?.error || "Error occured while upload cover image. Please retry")
                    setImageLoading(false);
                }
            }
        },
    };

    //handleOnSubmit
    const handleOnSubmit = () => {
        form.validateFields().then((formData) => {
            console.log("validationData:", formData)

            //prep data
            let updateInfo = {
                "_id": id,
                "banner": coverImage,
                "title": formData?.title,
                "subtitle": formData?.subtitle,
                "desc": formData?.desc,
                "languageIds": formData?.language.map((lang: any) => (lang?.value)),
                "categoryIds": formData?.category.map((cat: any) => (cat?.value)),
                "educatorIds": formData?.instructor.map((ins: any) => (ins?.value)),
                "tagIds": formData?.tags.map((tg: any) => (tg?.value)),
                "countryId": "65d4b85e9ae65ff72c90900e",
                "type": formData?.type,
                "level": formData?.level,
                "activityThemes": []

            }

            console.log("data_to_send:", updateInfo)
            setIsBusy(true)

            UpdateSingleCourse(updateInfo)
                .then((response: any) => {
                    console.log("newCourseRes:", response?.data)
                    setSingleCourseInfo(response?.data?.payload)
                    message.success("Course information was upadted successfully!")
                    setIsBusy(false)
                }).catch((error: any) => {
                    console.log("newCourseErr:", error)
                    message.error(error?.response?.data?.error || "Error while updating course. Please try again")
                    setIsBusy(false)
                })
        }).catch((err) => {
            console.log("____formErrors:", err)
        })
    }


    return (
        <>
            <div className="w-full bg-white rounded-[20px] shadow-sm px-[30px] py-[24px]">
                <div className="flex justify-between items-center">
                    <p className='text-[21px] font-[600] text-text_primary'>Course Information</p>

                    <button className='px-[20px] py-[8px] rounded-[30px] bg-default_blue text-white flex gap-[5px] items-center' onClick={() => handleOnSubmit()} disabled={isBusy}>
                        <PiCloudArrowUpLight className='h-[20px] w-[20px]' />
                        <p>Save Changes</p>
                    </button>
                </div>
                <hr className='my-[10px] border-[#CCCCF8]' />

                <div className="w-full">
                    <Spin spinning={isBusy}>
                        <Form
                            className='w-full'
                            form={form}
                            onFinish={handleOnSubmit}
                            initialValues={initialValues}
                        >
                            <div className="grid grid-cols-5">
                                <div className="col-span-1">
                                    <p><span className='text-red-700'>*</span> Cover Image</p>
                                </div>

                                <div className="col-span-4">
                                    <Form.Item name="image" rules={[{ required: true, message: 'Please upload a cover image' }]}>
                                        <div className="h-[160px] w-full mb-[20px]">
                                            <Upload.Dragger
                                                showUploadList={false}
                                                {...handleImageUpload}
                                                className="w-full relative"
                                                style={{
                                                    borderColor: "#E0E0FB",
                                                    border: "none",
                                                    background: "white",
                                                    color: "#5353C2",
                                                }}
                                            >
                                                <div className="w-full h-[160px] rounded-[5px] border-[2px] border-[#CCCCF8] border-dashed flex gap-2 items-center bg-[#F9F9FF] cursor-pointer">
                                                    {
                                                        imageLoading ?
                                                            <div className='w-full px-[40px]'>
                                                                <Progress percent={imgProgress} showInfo />
                                                                <p className='animate-pulse'>Uploading...</p>
                                                            </div>
                                                            :
                                                            coverImage ?
                                                                <div className='w-full'>
                                                                    <img className='h-[160px] rounded-[5px] w-full object-cover' src={coverImage} alt="" />
                                                                </div>
                                                                :
                                                                <div className="w-full flex flex-col items-center justify-center text-text_primary">
                                                                    <IoImagesOutline className='h-[50px] w-[50px]' />
                                                                    <p>Click to upload cover image</p>
                                                                </div>
                                                    }
                                                </div>
                                            </Upload.Dragger>
                                        </div>
                                    </Form.Item>
                                    {/* <p className='text-[14px]'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequatur, aperiam cum laboriosam laudantium iure eius aut voluptatem dicta eligendi earum sint impedit iusto accusantium saepe.</p> */}
                                </div>
                            </div>


                            <div className="grid grid-cols-5 mt-[20px]">
                                <div className="col-span-1">
                                    <p><span className='text-red-700'>*</span> Course Title</p>
                                </div>

                                <div className="col-span-4">
                                    <Form.Item name="title" rules={[{ required: true, message: 'Please add course title' }]}>
                                        <Input className='h-[40px]' />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="grid grid-cols-5 mt-[10px]">
                                <div className="col-span-1">
                                    <p><span className='text-red-700'>*</span> Sub Title</p>
                                </div>

                                <div className="col-span-4">
                                    <Form.Item name="subtitle" rules={[{ required: true, message: 'Please add course sub title' }]}>
                                        <Input className='h-[40px]' />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="grid grid-cols-5 mt-[10px]">
                                <div className="col-span-1">
                                    <p><span className='text-red-700'>*</span> Description</p>
                                </div>

                                <div className="col-span-4">
                                    <Form.Item name="desc" rules={[{ required: true, message: 'Please add course description' }]}>
                                        <DescriptionInputComponent onChange={(e) => console.log(e)} />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="grid grid-cols-5 mt-[10px]">
                                <div className="col-span-1">
                                    <p><span className='text-red-700'>*</span> Choose Term</p>
                                </div>

                                <div className="col-span-4">
                                    <Form.Item name="level" rules={[{ required: true, message: 'Please select a term' }]}>
                                        <TermSelector defaultValue={initialValues?.level} />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="grid grid-cols-5 mt-[10px]">
                                <div className="col-span-1">
                                    <p><span className='text-red-700'>*</span> Course Type</p>
                                </div>

                                <div className="col-span-4">
                                    <Form.Item name="type" rules={[{ required: true, message: 'Please select a type' }]}>
                                        <CourseTypeSelector defaultValue={initialValues?.type} />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="grid grid-cols-5 mt-[10px]">
                                <div className="col-span-1">
                                    <p><span className='text-red-700'>*</span> Language of Instruction</p>
                                </div>

                                <div className="col-span-4">
                                    <Form.Item name="language" rules={[{ required: true, message: 'Please select at least one language' }]}>
                                        <LanguageSelectorComponent defaultValue={initialValues?.language} />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="grid grid-cols-5 mt-[10px]">
                                <div className="col-span-1">
                                    <p><span className='text-red-700'>*</span> Category</p>
                                </div>

                                <div className="col-span-4">
                                    <Form.Item name="category" rules={[{ required: true, message: 'Please select at least one category' }]}>
                                        <CourseCategorySelectorComponent defaultValue={initialValues?.category} />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="grid grid-cols-5 mt-[10px]">
                                <div className="col-span-1">
                                    <p><span className='text-red-700'>*</span> Course Instructor(s)</p>
                                </div>

                                <div className="col-span-4">
                                    <Form.Item name="instructor" rules={[{ required: true, message: 'Please select at least one instructor' }]}>
                                        <InstructorSelectorComponent defaultValue={initialValues?.instructor} />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="grid grid-cols-5 mt-[10px]">
                                <div className="col-span-1">
                                    <p><span className='text-red-700'>*</span> Tags</p>
                                </div>

                                <div className="col-span-4">
                                    <Form.Item name="tags" rules={[{ required: true, message: 'Please select at least one tag' }]}>
                                        <TagSelectorComponent defaultValue={initialValues?.tags} />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>

                        <div className='w-full flex justify-end mt-[10px]'>
                            <button className='px-[20px] py-[8px] rounded-[30px] bg-default_blue text-white flex gap-[5px] items-center' onClick={() => handleOnSubmit()} disabled={isBusy}>
                                <PiCloudArrowUpLight className='h-[20px] w-[20px]' />
                                <p>Save Changes</p>
                            </button>
                        </div>
                    </Spin>
                </div>
            </div>
        </>
    )
}

export default EditCourseInformation
