export function GenerateCode(length: number, name: string): string {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  // Append part of the name string to avoid duplicates
  const namePart = name
    .split("")
    .reduce((acc, char) => acc + char.charCodeAt(0).toString(36), "");

  // Randomly generate alphanumeric characters
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }

  // Combine the name-based portion with the random string
  return `${result}-${namePart.substring(0, 5)}`;
}
