import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options: any = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "right",
    },
    title: {
      display: false,
      text: "",
    },
  },
  tension: 0.4,
  scales: {
    x: {
      grid: { display: false },
    },
    y: {
      grid: { display: false },
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Revenue",
      data: [10, 29, 10, 54, 12, 83, 13, 25, 10, 17, 30, 2],
      borderColor: "rgb(102,102,234)",
      backgroundColor: "rgba(102,102,234, 0.2)",
      fill: true,
      //   gradient,
    },
  ],
};

export default function RevenueChart() {
  return (
    <div className="w-full h-full">
      <Line options={options} data={data} />
    </div>
  );
}
