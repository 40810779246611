import React from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { customer_list } from "../../../../TestData/Cusomer_List";
import SearchInput from "../../../../components/shared/SearchInput";
import CustomButtomRounded from "../../../../components/shared/CustomButtomRounded";
import { GoPlus } from "react-icons/go";
import useTableSyle from "../../../../components/shared/useTableSyle";
import { Spinner } from "@material-tailwind/react";
import CheckBox from "../../../../components/shared/CheckBoxs";
import { IoMdMore } from "react-icons/io";

const Businesses = () => {
  const [customStyles] = useTableSyle();
  const navigate = useNavigate();

  //Main Table columns
  const columns = [
    {
      name: <CheckBox />,
      cell: (row: any) => <CheckBox />,
    },
    {
      name: "Customer",
      cell: (row: any) => (
        <div className="flex justify-center items-center gap-2">
          <img
            className="rounded-md"
            src={row.picture}
            alt="..."
            height="35px"
            width="35px"
          />
          <p>{row.customer_name}</p>
        </div>
      ),
    },
    {
      name: "Email",
      cell: (row: any) => <p>{row.customer_name} &#128525;</p>,
    },
    {
      name: "Sales",
      cell: (row: any) => row.signup_date,
    },
    {
      name: "Orders",
      cell: (row: any) => row.total_sales,
    },
    {
      name: "Location",
      cell: (row: any) => row.total_orders,
    },
    {
      name: "Status",
      cell: (row: any) => (
        <p className="bg-green-100 text-green-300 px-4 rounded-xl">
          {row.status}
        </p>
      ),
    },
    {
      name: "",
      cell: (row: any) => <IoMdMore size={"2em"} className="text-[#9494f5] " />,
    },
  ];

  const handlerClick = (e: any) => {};

  return (
    <div className="bg-white p-4 rounded-[20px]">
      <div className="flex justify-between">
        <div>
          <SearchInput />
        </div>
      </div>
      <DataTable
        className="mt-4"
        columns={columns}
        data={customer_list}
        paginationServer
        // progressPending={isFetching}
        progressComponent={<Spinner />}
        pagination
        // paginationTotalRows={total}
        pointerOnHover
        highlightOnHover
        customStyles={customStyles}
        onRowClicked={(selected: any) =>
          navigate(`/Businesses/${selected._id}`)
        }
      />
    </div>
  );
};

export default Businesses;
