import { Card, Container } from "react-bootstrap";
import "react-tabs/style/react-tabs.css";
import PageLayout from "../../components/Layouts/page-layout";
import CustomersTable from "./CustomersTable";
import ViewCustomerDetails from "./ViewCustomerDetails";
import CustomButtomSquare from "../../components/shared/CustomButtomSquare";
import { RxAvatar } from "react-icons/rx";

const Customers = () => {
  return (
    <>
      <PageLayout>
        <div className="m-4">
          <div className="flex justify-between">
            <h2 className="text-2xl text-text_primary font-semibold">
              Customers
            </h2>
            <CustomButtomSquare value="Export CSV" width={"10"} />
          </div>
          <Card className="rounded-3xl border-none shadow-md mt-3">
            <div>
              <CustomersTable />
            </div>
          </Card>
        </div>
      </PageLayout>
      <ViewCustomerDetails />
    </>
  );
};

export default Customers;
