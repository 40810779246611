import React from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import SearchInput from "../../components/shared/SearchInput";
import CustomButtomRounded from "../../components/shared/CustomButtomRounded";
import useTableSyle from "../../components/shared/useTableSyle";
import { Spinner } from "@material-tailwind/react";
import { IoMdMore } from "react-icons/io";
import { dataInfo } from "./table";
import { Spin } from "antd";
const CommunicationTable = () => {
  const [customStyles] = useTableSyle();
  const navigate = useNavigate();

  //Main Table columns
  const columns = [
    {
      name: "Type",
      cell: (row: any) => <p>{row.Type}</p>,
    },
    {
      name: "Announcement",
      cell: (row: any) => <p>{row.Announcement} </p>,
      width: "35%",
    },
    {
      name: "Created",
      cell: (row: any) => row.Created,
    },
    {
      name: "Expiry",
      cell: (row: any) => row.Expiry,
    },

    {
      name: "Status",
      cell: (row: any) => row.Status,
    },
    {
      name: "",
      cell: (row: any) => <IoMdMore size={"2em"} className="text-[#9494f5] " />,
    },
  ];

  const handlerClick = (e: any) => {};

  return (
    <div className="bg-white p-4">
      <div className="flex     justify-between">
        <div>
          <SearchInput />
        </div>
        <CustomButtomRounded value={"Add Message"} onClick={handlerClick} />
      </div>
      <DataTable
        className="mt-4"
        columns={columns}
        data={dataInfo}
        paginationServer
        // progressPending={isFetching}
        progressComponent={<Spin />}
        pagination
        // paginationTotalRows={total}
        pointerOnHover
        highlightOnHover
        customStyles={customStyles}
        onRowClicked={(selected: any) => navigate(`/customers/${selected._id}`)}
      />
    </div>
  );
};

export default CommunicationTable;
