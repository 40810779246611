import React, { useState } from "react";
import PageLayout from "../../../../components/Layouts/page-layout";
import CustomButtomRounded from "../../../../components/shared/CustomButtomRounded";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import DetailiconPage from "./DetailiconPage";
import Overview from "./Tabs/Overview";
import GLevels from "./Tabs/GLevels/GLevels";
import MyCalender from "./Tabs/Calender/Calender";

const ManageRobocenterDetails = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const { id } = useParams<{ id: string }>();

  const handlerClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    navigate("/manageRobocenter");
  };

  const tabNames = [
    "Overview",
    "Calendar",
    "Grade levels",
    "Courses",
    "Students",
    "Payments",
    "Pricing",
    "Privacy and terms",
    "Badges",
    "Certification",
    "Themes",
    "Settings",
    "Forum",
  ];
  const theTabs = [
    <Overview />,
    <MyCalender />,
    <GLevels roboUrlId={id || ""} />,
  ];

  return (
    <>
      <PageLayout>
        <div className="m-4 ">
          <div className="flex mt-4 justify-between gap-4 ml-6 h-[100px]   ">
            <div className="flex  gap-4">
              <CustomButtomRounded
                icon={<FaArrowLeft />}
                value={"Back"}
                onClick={handlerClick}
              />
              <h2 className="text-4xl text-text_primary font-semibold">
                Robocentre Online (Main)
              </h2>
            </div>
            <DetailiconPage />
          </div>
          <div className=" ">
            <div className="flex items-center mt-4  flex-wrap gap-3 w-[90%] ">
              {tabNames.map((d, i) => (
                <div
                  key={i}
                  className={`${
                    activeTab === i
                      ? "border-2 border-[#9999F1] text-default_ash"
                      : "bg-white text-text_primary"
                  } shadow-sm w-fit px-4 py-2 rounded-[30px] cursor-pointer`}
                  onClick={() => setActiveTab(i)}
                >
                  <span>{d}</span>
                </div>
              ))}
            </div>
            <div>{theTabs[activeTab]}</div>

            {/* <section className=" text-text_primary  flex flex-col gap-2   sticky top-[100px] h-[600px] z-[999]  "></section> */}
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default ManageRobocenterDetails;
