import { useParams } from "react-router-dom";
import { IoSchoolSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import PageLayout from "../../../../components/Layouts/page-layout";
import CustomButtomRounded from "../../../../components/shared/CustomButtomRounded";
import TextArea from "antd/es/input/TextArea";
import { Button, Spin, Modal } from "antd";
import { useEffect, useReducer, useState } from "react";
import BaseService from "../../../../helpers/baseServices";
import RequestMoreDetails from "./RequestMoreDetails";
import { MdOutlineSmsFailed } from "react-icons/md";

const initialState = {
  schoolDetails: [],
  confirmLoading: false,
  confirmSchoolDetail: false,
  remarks: "",
  isError: false,
  errorMessage: "",
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_SCHOOL_DETAILS":
      return { ...state, schoolDetails: action.payload };
    case "SET_CONFIRM_LOADING":
      return { ...state, confirmLoading: action.payload };
    case "SET_CONFIRM_SCHOOL_DETAIL":
      return { ...state, confirmSchoolDetail: action.payload };
    case "SET_REMARKS":
      return { ...state, remarks: action.payload };
    case "SET_ERROR":
      return {
        ...state,
        isError: action.payload,
        errorMessage: action.message,
      };
    default:
      return state;
  }
};

const RequestSchoolsDetails = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { id } = useParams();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handlerClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    navigate("/signUpRequest");
  };

  const fetchSingleSchool = async () => {
    dispatch({ type: "SET_CONFIRM_SCHOOL_DETAIL", payload: true });

    try {
      const results = await BaseService.get_api(
        `/robocentre/roboschool/onboarding/school?filter={"_id": {"$$_id": ${JSON.stringify(
          id
        )}}}`
      );
      const { payload } = results.data;
      if (payload && payload.length > 0) {
        const school = payload[0];

        // Set the default remarks based on approval status
        const defaultRemarks = school.approved
          ? "School disapproved"
          : " School approved";

        dispatch({ type: "SET_SCHOOL_DETAILS", payload: [school] });
        dispatch({ type: "SET_REMARKS", payload: defaultRemarks });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch({ type: "SET_CONFIRM_SCHOOL_DETAIL", payload: false });
    }
  };

  useEffect(() => {
    if (id) {
      fetchSingleSchool();
    }
  }, [id]);

  const showConfirmation = (approved: boolean) => {
    const actionText = approved ? "approve" : "disapprove";
    const modalTitle = `Are you sure you want to ${actionText} this school?`;
    Modal.confirm({
      title: modalTitle,
      onOk: () => handleApprovalClick(approved),
      okText: approved ? "Yes, Approve" : "Yes, Disapprove",
      cancelText: "Cancel",
      okButtonProps: {
        style: {
          backgroundColor: "#6666EA",
          borderColor: "#6666EA",
          color: "#FFFFFF",
          boxShadow: "none",
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: "#FFFFFF",
          borderColor: "#d9d9d9",
          color: "#000000",
          boxShadow: "none",
        },
      },
    });
  };

  const handleApprovalClick = async (approved: boolean) => {
    if (!state.remarks.trim()) {
      dispatch({
        type: "SET_ERROR",
        payload: true,
        message: "Remarks cannot be empty",
      });
      return;
    }

    dispatch({ type: "SET_CONFIRM_LOADING", payload: true });

    try {
      const payload = {
        schoolId: id,
        approved: approved,
        remarks: state.remarks,
      };

      await BaseService.put_api(
        "/robocentre/roboschool/onboarding/approve",
        payload
      );

      dispatch({
        type: "SET_SCHOOL_DETAILS",
        payload: [{ ...state.schoolDetails[0], approved: payload.approved }],
      });
      navigate("/signUpRequest");

      dispatch({ type: "SET_REMARKS", payload: "" });
    } catch (error: any) {
      console.log("Error:", error?.response?.data?.error);
    } finally {
      dispatch({ type: "SET_CONFIRM_LOADING", payload: false });
    }
  };

  const handleRemarksChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    dispatch({ type: "SET_REMARKS", payload: value });

    if (!value.trim()) {
      dispatch({
        type: "SET_ERROR",
        payload: true,
        message: "Remarks cannot be empty",
      });
    } else {
      dispatch({ type: "SET_ERROR", payload: false, message: "" });
    }
  };

  return (
    <PageLayout>
      <Spin spinning={state.confirmSchoolDetail}>
        <div className="m-4 flex gap-8">
          <section className="w-[80%]">
            <div className="flex items-center gap-4 ml-6 mb-14">
              <CustomButtomRounded
                icon={<FaArrowLeft />}
                value={"Back"}
                onClick={handlerClick}
              />
              <h2 className="text-4xl text-text_primary font-semibold">
                School Details
              </h2>
            </div>
            <RequestMoreDetails schooData={state.schoolDetails[0]} />
          </section>
          <section className="flex flex-col gap-2 w-[20%]">
            <div className="sticky top-[100px]">
              <div>
                <TextArea
                  rows={2}
                  placeholder="Add note"
                  className={`border-[1px] ${
                    state.isError ? "border-red-500" : "border-outline_light"
                  } text-[#9494f5]`}
                  onChange={handleRemarksChange}
                  value={state.remarks}
                />
                {state.isError && (
                  <p className="text-red-500 mt-1">{state.errorMessage}</p>
                )}
              </div>
              <div className="flex flex-col  mt-4 gap-2">
                {!state.schoolDetails[0]?.approved && (
                  <Button
                    type="default"
                    icon={
                      <IoSchoolSharp
                        size={"1.5em"}
                        style={{ marginRight: 8 }}
                      />
                    }
                    className="flex items-center w-[200px]"
                    onClick={() => showConfirmation(true)}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{
                      backgroundColor: "rgba(102, 102, 234, 0.1)",
                      color: "#6666EA",
                      height: "40px",
                      border: "1px solid #6666EA",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="flex items-center justify-center">
                      <p>Approve school</p>
                    </div>
                  </Button>
                )}
                {state.schoolDetails[0]?.approved && (
                  <Button
                    type="default"
                    icon={
                      <MdOutlineSmsFailed
                        size={"1.5em"}
                        style={{ marginRight: 8 }}
                      />
                    }
                    className="flex items-center w-[200px]"
                    onClick={() => showConfirmation(false)}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={{
                      backgroundColor: "rgba(255, 99, 141, 0.1)",
                      color: "#FF638D",
                      height: "40px",
                      border: "1px solid #FF638D",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="flex items-center justify-center">
                      <p>Disapprove school</p>
                    </div>
                  </Button>
                )}
              </div>
            </div>
          </section>
        </div>
      </Spin>
    </PageLayout>
  );
};

export default RequestSchoolsDetails;
